<template>
  <section
    class="configure-document"
    :class="{
      mobile: getIsMobile,
      'is-close-RS': !getIsRSOpen,
      'is-open-RS': getIsRSOpen,
    }"
    id="drag-template-background"
    v-loading="loading"
    :element-loading-text="loadingText ? loadingText : 'Loading...'"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <!-- v-if="getCompanyDocumentDataById" -->
    <div class="inner-navbar">
      <div class="inner-navbar-2nd-level-child" style="margin-top: 0px">
        <div class="inner-navbar-1th-level-child flex-direction-row">
          <div>
            <el-button
              slot="reference"
              class="rounded-0 back-btn type-2"
              style="margin-top: 4px"
              @click="goBack"
            >
              <i class="el-icon-back">Back</i>
            </el-button>
          </div>
          <div class="flex-fill left-block vue-form ml-2">
            <el-form
              class="form-title"
              style="margin-top: 10px"
              v-if="
                templateOwner == authid ||
                (getConfigurableDocumentDataById &&
                  getConfigurableDocumentDataById.company_id &&
                  getActiveWorkspace.company_id &&
                  getConfigurableDocumentDataById.company_id ==
                    getActiveWorkspace.company_id)
              "
            >
              <el-input
                :style="this.openCk ? 'width: 300px' : 'width: 500px'"
                v-model="documentName"
                placeholder="Enter Template Title"
                class="input-type-one"
                @blur="handleUpdatingDocumentName"
              ></el-input>
              <p
                class="error"
                v-if="
                  getConfigurableDocumentErrors &&
                  getConfigurableDocumentErrors.name
                "
              >
                {{ getConfigurableDocumentErrors.name }}
              </p>
            </el-form>
          </div>
        </div>
        <div v-if="openCk" class="selectUser mb-3 d-flex flex-column">
          <div class="justify-content">
            <el-select
              v-model="recipientSelection"
              filterable
              placeholder="Select a Recipient"
              :loading="loading"
              class="w-30 mt-1"
              @change="changeRecipientColor"
            >
              <el-option
                v-for="item in documentRecipientsList"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              >
                <span style="float: left">{{ item.name }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px"
                  >{{ item.user_type }} {{ item.email }}</span
                >
              </el-option>
              <i slot="prefix" class="el-input__icon el-icon-user"></i>
            </el-select>
          </div>
        </div>
        <div
          class="right-block flex-self-end pe-3 align-items-self"
          v-if="
            templateOwner == authid ||
            (getConfigurableDocumentDataById &&
              getConfigurableDocumentDataById.company_id &&
              getActiveWorkspace.company_id &&
              getConfigurableDocumentDataById.company_id ==
                getActiveWorkspace.company_id)
          "
        >
          <el-button
            v-if="!openCk"
            class="outlined-icon-button try-using-ai-chat-mate"
            size="medium"
            @click="openAiAssistant"
            style="margin-left: -60px"
            :disabled="showAichatMate"
          >
            <div style="display: flex">
              <icons :iconName="'TryUsingAI'"></icons>
              <div style="padding-top: 6px">AI ChatMate</div>
            </div>
          </el-button>
          <el-tooltip
            v-if="getAccess('documents', 'edit_pdf', '')"
            class="item"
            effect="dark"
            :content="
              isEditMode
                ? 'Turn Off Pdf Editing Mode'
                : 'Turn On Pdf Editing Mode'
            "
            placement="top-start"
          >
            <el-switch
              v-if="!openCk"
              v-model="isEditMode"
              @change="checkPdfChanges"
              active-text="PDF Editing Mode"
              inactive-text="OFF"
              active-color="#13ce66"
              inactive-color="#EAECF0"
              class="mr-1 switch-pdf"
            ></el-switch>
          </el-tooltip>
          <div v-if="isEditMode">
            <el-button @click="editPdf" :loading="documentDataLoading"
              >Save pdf</el-button
            >
          </div>
          <div v-else>
            <el-tooltip
              v-if="openCk && getSignerUserTypeLength > 1"
              effect="dark"
              content="Update Mention Fields Filled By Value."
              placement="bottom-start"
            >
              <el-button class="item mr-2" @click="mapUserFieldsFilledBy"
                >Filled By</el-button
              >
            </el-tooltip>
            <el-badge
              :value="documentRecipientsList.length"
              class="item ml-2"
              type="danger"
            >
              <el-button
                type="danger"
                @click="handleAddReceipient"
                plain
                v-if="!getIsMobile"
                style="margin-top: -10px"
              >
                {{
                  documentRecipientsList.length > 1
                    ? "View Recipient"
                    : "Add Recipient"
                }}
              </el-button>
              <el-button
                type="danger"
                @click="handleAddReceipient"
                plain
                v-if="getIsMobile"
                style="padding: 12px 3px"
              >
                {{
                  documentRecipientsList.length > 1 ? "View Recp" : "Add Recp"
                }}
              </el-button>
            </el-badge>
            <el-link
              type="primary"
              class="type-2 ml-2 settigns-font save-link"
              @click="saveTheTemplate()"
              :underline="false"
              :style="!getIsMobile ? 'margin-top:-10px' : ''"
              >{{ getIsMobile ? "Save" : "Save Template" }}</el-link
            >
            <el-divider direction="vertical" v-if="!getIsMobile"></el-divider>
            <el-button
              v-if="
                getSenderUserType &&
                getSenderUserType.user_type &&
                getSenderUserType.user_type == 'SIGNER' &&
                getSignerUserTypeLength <= 1 &&
                !getConfigurableDocumentDataById.is_ck_editor_document
              "
              type="danger"
              class="type-2 ml-2 settigns-font button-type-one send-btn"
              @click="finishDocument()"
              v-loading="documentDataLoading"
              >{{
                getIsMobile
                  ? "Finish"
                  : this.openCk
                  ? "Fill Data"
                  : "Finish Document"
              }}</el-button
            >
            <el-button
              v-else-if="
                getSenderUserType &&
                getSenderUserType.user_type &&
                getSenderUserType.user_type == 'SIGNER' &&
                getSignerUserTypeLength <= 1 &&
                getConfigurableDocumentDataById.is_ck_editor_document
              "
              type="danger"
              class="type-2 ml-2 settigns-font button-type-one send-btn"
              @click="finishFromModal()"
              v-loading="documentDataLoading"
              :element-loading-text="loadingText"
              >{{ getIsMobile ? "Finish" : "Finish Document" }}</el-button
            >
            <el-button
              v-else-if="canShare || (canCreate && getSignerUserTypeLength > 1) ||((isSenderCCorNot.length && getSignerUserTypeLength === 1))"
              type="danger"
              class="type-2 ml-2 settigns-font button-type-one doc-plus"
              @click="fillAndSend()"
              >{{ getIsMobile ? "+ Doc" : "Create Document" }}</el-button
            >

            <el-divider direction="vertical" v-if="!getIsMobile"></el-divider>
            <el-dropdown
              @mouseleave="handleDropdownLeave"
              @focus="dropdownHasFocus = true"
              @blur="checkCkEditorFilledByModal()"
              class="el-dropdown-list"
            >
              <span class="el-dropdown-link">
                <i class="el-icon-more el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <!-- <a @click="getLinkForTemplate()"> -->
                <a
                  v-if="
                    canGetLink &&
                    getAccess('documents', 'getLink', '') &&
                    !openCk
                  "
                  :underline="false"
                  @click="getLinkForTemplate()"
                >
                  <el-dropdown-item icon="el-icon-link"
                    >Get link</el-dropdown-item
                  >
                </a>
                <a
                  v-if="
                    canEdit &&
                    getAccess('documents', 'correct_document', '') &&
                    !this.openCk
                  "
                  :underline="false"
                  @click="replaceDocumentMethod"
                >
                  <el-dropdown-item icon="el-icon-refresh"
                    >Change Document</el-dropdown-item
                  >
                </a>
                <a
                  :underline="false"
                  @click="visibleSettingsVisible"
                  v-if="getAccess('documents', 'settings', '')"
                >
                  <el-dropdown-item icon="el-icon-s-tools"
                    >Settings</el-dropdown-item
                  >
                </a>
                <el-dropdown-item
                  v-if="getConfigurableDocumentDataById && !openCk"
                >
                  <el-checkbox
                    @click.native.stop
                    v-model="
                      getConfigurableDocumentDataById.lock_template_fields
                    "
                    @change="handleLockChange"
                    :underline="false"
                  >
                    <i
                      v-if="
                        getConfigurableDocumentDataById.lock_template_fields
                      "
                      class="el-icon-unlock"
                    ></i>
                    <i v-else class="el-icon-unlock"></i>
                    Lock Fields Position & Restrict Editing
                  </el-checkbox>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <div class="action-wrapper-type-two">
              <el-button
                slot="reference"
                class="rounded-0 btn-one type-2"
                type="primary"
                v-on:click="openRightSideBar()"
                :disabled="getIsRSOpen"
                v-if="!getIsRSOpen"
                >&lt;&lt;</el-button
              >
              <el-button
                slot="reference"
                class="rounded-0 btn-one close type-2"
                type="primary"
                v-on:click="closeRightSideBar()"
                v-if="getIsRSOpen"
                >&gt;&gt;</el-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      :visible.sync="showAddData"
      top="5%"
      :width="getIsMobile ? 'width: 100%' : 'width: 50%'"
      :title="`Add New Group`"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div class="px-20">
        <div class="mb-20">
          <el-form>
            <el-row>
              <el-col class="mt-1" :span="12">
                <div class="d-flex">
                  <span class="mr-1">Group Label:</span>
                  <span>
                    <el-input
                      placeholder="Group Name"
                      v-model="check_box_group_form.label"
                    ></el-input>
                  </span>
                </div>
              </el-col>
              <el-col :span="12">
                <div class="mt-1" v-if="selectedItem.type == 'CHECKBOX'">
                  <span>Fields Filled By:</span>
                  <el-select
                    class="ml-1"
                    v-model="selectedItem.filled_by"
                    width="100"
                  >
                    <el-option
                      v-for="(user, index) of documentRecipientsList"
                      :key="index"
                      :label="user.name"
                      :value="user.value"
                      :disabled="
                        user && user.user_type && user.user_type == 'CC'
                      "
                    >
                      <span style="float: left">{{ user.name }}</span>
                      <span
                        style="float: right; color: #8492a6; font-size: 13px"
                      >
                        {{ user.email }}
                      </span>
                    </el-option>
                  </el-select>
                </div>
              </el-col>
              <el-col class="mt-1" :span="12">
                <div class="d-flex">
                  <span class="mr-1">Min Value:</span
                  ><span>
                    <el-input
                      placeholder="Minimum Value"
                      v-model="check_box_group_form.min_value"
                    ></el-input>
                  </span>
                </div>
              </el-col>
              <el-col class="mt-1" :span="12">
                <div class="d-flex">
                  <span class="mr-1">Max Value:</span>
                  <span>
                    <el-input
                      placeholder="Maximum Value"
                      v-model="check_box_group_form.max_value"
                    ></el-input>
                  </span>
                </div>
              </el-col>

              <el-col :span="12">
                <div class="mt-1" v-if="selectedItem.type == 'CHECKBOX'">
                  <el-checkbox
                    v-model="check_box_group_form.show_checkbox_label"
                    >Show Label</el-checkbox
                  >
                </div>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <span slot="footer">
          <el-button
            class="mt-1"
            style="float: right"
            type="success"
            @click="submitNewGroup"
            :disabled="!this.check_box_group_form.label"
            >Submit</el-button
          >
        </span>
      </div>
      <p v-if="dialogErr" style="color: red; font-size: 14px">
        Group Name already exist
      </p>
    </el-dialog>
    <el-dialog
      :visible.sync="blanckDocumentModal"
      title="Upload a Blank Document"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :before-close="resetBlankTemplate"
      class="upload-a-blank-document-popup"
    >
      <div v-loading="blanckDocumentData.loading">
        <span>Enter Document name</span>
        <el-input
          placeholder="Enter Document name"
          v-model="blanckDocumentData.name"
          class="mb-2 mt-1"
        ></el-input>
        <span>Select number of pages</span>
        <el-select
          placeholder="Select number of pages"
          v-model="blanckDocumentData.pages"
          style="width: 100%"
          class="mt-1"
        >
          <el-option
            v-for="(i, index) in [1, 2, 3]"
            :key="index"
            :label="i"
            :value="i"
          ></el-option>
        </el-select>
        <div class="btn-wrapper float-right mt-2">
          <el-button @click="resetBlankTemplate">Cancel</el-button>
          <el-button
            :disabled="!blanckDocumentData.name.trim()"
            type="primary"
            @click="replaceBlankDoc"
            >Continue</el-button
          >
        </div>
      </div>
    </el-dialog>
    <dialog-component
      title="Warning"
      :visible.sync="dialogVisible"
      class="warning-popup"
      @before-close="handleCloseWarning"
      :show-close="true"
    >
      <span>
        <h5>It looks like you have been modifying something...</h5>
      </span>
      <span>If you leave before updating, your changes will be lost.</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">Cancel</el-button>
        <el-button type="primary" @click="backToPrevious()">Confirm</el-button>
      </span>
    </dialog-component>
    <el-dialog
      title="Save As Template ?"
      :visible.sync="isTemplateToSave"
      width="30%"
      center
      v-loading="loading"
      element-loading-text="Loading..."
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <el-form ref="form" :model="isTemplateForm">
        <el-form-item
          label="Yes! Save it as template."
          class="mb-0"
          v-if="
            this.getConfigurableDocumentDataById &&
            !this.getConfigurableDocumentDataById.is_template
          "
        >
          <el-switch v-model="isTemplate"></el-switch>
        </el-form-item>
        <el-form-item label="Document Title" class="mb-0">
          <el-input v-model="templateTitle"></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="isTemplateToSave = false">Cancel</el-button>
        <el-button type="success" @click="saveData()">Confirm</el-button>
      </span>
    </el-dialog>

    <div
      class="document-editor-container document-container-wrapper-grand-parent"
    >
      <!-- <el-row type="flex" justify="center"> -->
      <div class="document-container-wrapper-parent">
        <!-- <el-col :span="18"> -->

        <div
          v-if="
            authid != templateOwner &&
            getConfigurableDocumentDataById &&
            getActiveWorkspace &&
            getConfigurableDocumentDataById.company_id &&
            getActiveWorkspace.company_id &&
            !(
              getConfigurableDocumentDataById.company_id ==
              getActiveWorkspace.company_id
            ) &&
            isNotInitial
          "
          style="text-align: center"
          class="mt-3"
        >
          <h1 style="color: red">Forbidden access</h1>
          <h1>You are not allowed to edit shared template</h1>
        </div>
        <div class="document-container-wrapper" v-else>
          <div class="document-container">
            <el-row :gutter="12" v-if="!openCk">
              <el-col
                v-if="!openCk"
                :span="4"
                :style="getIsMobile ? 'display: none' : ''"
                class="pdf-side-bar"
              >
                <el-scrollbar v-loading="documentDataLoading">
                  <div
                    id="page-wrapper"
                    class="pdf-page-preview mt-3"
                    v-if="!openCk"
                  >
                    <div v-for="(doc, j) in getAllPdfData" :key="j">
                      <div v-if="doc && doc.pages && !openCk">
                        <div
                          v-for="i of doc.pages"
                          :key="i"
                          class="canvas-holder"
                          :id="`${j}_preview_wrapper_${i}`"
                        >
                          <el-tooltip
                            class="item"
                            effect="dark"
                            content="Add page"
                            placement="bottom"
                            v-if="getAccess('documents', 'add_remove_page', '')"
                          >
                            <el-button
                              plain
                              icon="el-icon-plus"
                              class="add-page-top"
                              @click="addPage(i, j)"
                            ></el-button>
                          </el-tooltip>
                          <div
                            class="rotate-buttons"
                            v-if="getAccess('documents', 'rotatePage', '')"
                          >
                            <el-tooltip
                              class="item"
                              effect="dark"
                              content="Rotate right"
                              placement="bottom"
                            >
                              <el-button
                                size="mini"
                                icon="el-icon-refresh-right"
                                circle
                                plain
                                @click="rotateRight(i, j)"
                              ></el-button>
                            </el-tooltip>
                            <el-tooltip
                              class="item"
                              effect="dark"
                              content="Rotate left"
                              placement="bottom"
                            >
                              <el-button
                                size="mini"
                                icon="el-icon-refresh-left"
                                circle
                                plain
                                @click="rotateLeft(i, j)"
                              ></el-button>
                            </el-tooltip>
                            <el-tooltip
                              class="item"
                              effect="dark"
                              content="Delete page"
                              placement="bottom"
                            >
                              <el-button
                                size="mini"
                                icon="el-icon-delete"
                                circle
                                plain
                                @click="deletePage(i, j)"
                                v-if="doc.pages > 1"
                              ></el-button>
                            </el-tooltip>
                          </div>
                          <canvas
                            @click="
                              currentActivatePage = i;
                              currentActivateDoc = j;
                            "
                            :id="`${j}_canvas_page_${i}`"
                            tabindex="0"
                            :style="
                              currentActivatePage == i &&
                              currentActivateDoc == j
                                ? 'border: 1px solid #ccc; box-shadow: 0 0px 7px rgba(0, 0, 0, 0.4)'
                                : 'border: 1px solid #ccc;'
                            "
                          ></canvas>
                          <p>Page {{ pageCount(i, j) }}</p>
                        </div>
                      </div>
                    </div>
                    <el-tooltip
                      class="item"
                      effect="dark"
                      content="Add page"
                      placement="bottom"
                      v-if="getAccess('documents', 'add_remove_page', '')"
                    >
                      <el-button
                        plain
                        icon="el-icon-plus"
                        class="add-page-bottom"
                        @click="addPageOnBottom()"
                      ></el-button>
                    </el-tooltip>
                  </div>
                </el-scrollbar>
              </el-col>
              <el-col
                :span="openCk ? 24 : 20"
                :xl="openCk ? { span: 24 } : { span: 20 }"
                :lg="openCk ? { span: 24 } : { span: 20 }"
                :md="{ span: 20 }"
                :sm="{ span: 24 }"
                :xs="{ span: 24 }"
                class="pdf-main-area"
              >
                <div
                  v-if="
                    !isEditMode &&
                    activatedItem != null &&
                    (activatedItem.type == 'PRIMARY_FIELDS' ||
                      activatedItem.type == 'PARAGRAPH' ||
                      activatedItem.type == 'HEADING' ||
                      activatedItem.type == 'SINGLE_LINE_CONTENT' ||
                      this.activatedItem.type == 'SINGLE_LINE_TEXT' ||
                      this.activatedItem.type == 'MULTI_LINE_TEXT' ||
                      this.activatedItem.type == 'DATE' ||
                      this.activatedItem.type == 'DATE_TIME_RANGE' ||
                      this.activatedItem.type == 'DATE_RANGE' ||
                      this.activatedItem.type == 'DATE_TIME' ||
                      this.activatedItem.type == 'TIME' ||
                      this.activatedItem.type == 'LIST' ||
                      // this.activatedItem.type == 'RADIO' ||
                      this.activatedItem.type == 'SELECT' ||
                      this.activatedItem.type == 'MULTI_SELECT' ||
                      this.activatedItem.type == 'NUMBER' ||
                      this.activatedItem.type == 'DATE_SIGNED' ||
                      this.activatedItem.type == 'YES_OR_NO' ||
                      this.activatedItem.type == 'CURRENCY' ||
                      this.activatedItem.type == 'FORMULA' ||
                      this.activatedItem.type == 'ENTITY' ||
                      this.activatedItem.type == 'REPEATABLE_PARENT' ||
                      this.activatedItem.type == 'TIME_RANGE' ||
                      this.activatedItem.type == 'FIXED_TIME' ||
                      this.activatedItem.type == 'WEEKDAYS')
                  "
                  class="form-field-item headerStyles"
                >
                  <el-col :xl="6" :lg="6" :md="6" :sm="6" :xs="6">
                    <el-select
                      v-model="activatedItem.font.name"
                      placeholder="Select"
                      class="text-editot-slt"
                    >
                      <el-option
                        v-for="item in fontTypes"
                        :key="item"
                        :value="item"
                      >
                        <p :style="getFontType(item)">{{ item }}</p>
                      </el-option>
                    </el-select>
                  </el-col>
                  <el-col
                    :span="7"
                    v-if="
                      activatedItem.type != 'YES_OR_NO' &&
                      activatedItem.type != 'CHECKBOX' &&
                      activatedItem.type != 'SIGNATURE' &&
                      activatedItem.type != 'INITIAL' &&
                      activatedItem.type != 'SELECT' &&
                      activatedItem.type != 'DATE' &&
                      activatedItem.type != 'DATE_TIME' &&
                      activatedItem.type != 'DATE_TIME_RANGE' &&
                      activatedItem.type != 'TIME' &&
                      activatedItem.type != 'LIST' &&
                      activatedItem.type != 'TIME_RANGE' &&
                      activatedItem.type != 'DATE_RANGE' &&
                      activatedItem.type != 'DATE_SIGNED' &&
                      activatedItem.type != 'my-signature' &&
                      activatedItem.type != 'MY_INITIAL' &&
                      activatedItem.type != 'MULTI_SELECT' &&
                      activatedItem.type != 'MULTI_LINE_TEXT' &&
                      activatedItem.type != 'ATTACHMENT' &&
                      activatedItem.type != 'ENTITY' &&
                      activatedItem.type != 'FIXED_TIME' &&
                      activatedItem.type != 'WEEKDAYS'
                    "
                  >
                    <el-radio-group
                      v-model="activatedItem.alignStyles"
                      @change="setAlignStyles"
                    >
                      <el-radio-button
                        style="margin-bottom: -2px"
                        :label="'left'"
                      >
                        <img
                          src="@/assets/img/Left.svg"
                          height="15px"
                          width="15px"
                        />
                      </el-radio-button>
                      <el-radio-button
                        style="margin-bottom: -2px"
                        :label="'Center'"
                      >
                        <img
                          src="@/assets/img/Middle.svg"
                          height="15px"
                          width="15px"
                        />
                      </el-radio-button>
                      <el-radio-button
                        style="margin-bottom: -2px"
                        :label="'Right'"
                      >
                        <img
                          src="@/assets/img/Right.svg"
                          height="15px"
                          width="15px"
                        />
                      </el-radio-button>
                    </el-radio-group>
                  </el-col>
                  <el-col :xl="2" :lg="2" :md="4" :sm="4" :xs="4">
                    <el-button
                      type="plain"
                      @click="makeTextBold"
                      :disabled="activatedItem.type == 'HEADING'"
                      :class="
                        activatedItem.font.style == 1 ||
                        activatedItem.font.style == 3
                          ? 'text-bold text-editot-btn-active'
                          : 'text-bold text-editot-btn'
                      "
                    >
                      <b>B</b>
                    </el-button>
                  </el-col>
                  <el-col :xl="2" :lg="2" :md="4" :sm="4" :xs="4">
                    <el-button
                      type="plain"
                      @click="makeTextItalic"
                      :disabled="activatedItem.type == 'HEADING'"
                      :class="
                        activatedItem.font.style == 2 ||
                        activatedItem.font.style == 3
                          ? 'text-italic text-editot-btn-active'
                          : 'text-italic text-editot-btn'
                      "
                    >
                      <i>It</i>
                    </el-button>
                  </el-col>
                  <el-col :xl="4" :lg="4" :md="6" :sm="6" :xs="6">
                    <el-select
                      v-model="activatedItem.font.size"
                      placeholder="Size"
                      class="text-editot-slt-size"
                      @change="updateWidthAndHeight"
                      :disabled="activatedItem.type == 'HEADING'"
                    >
                      <el-option
                        v-for="item in fontSizes"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </el-col>
                  <el-col :xl="2" :lg="2" :md="4" :sm="4" :xs="4">
                    <el-color-picker
                      v-model="activatedItem.font.color"
                      class="text-editor-color"
                    ></el-color-picker>
                  </el-col>
                </div>
                <div v-else-if="isEditMode" class="form-field-item">
                  <el-col class="mt-1" :xl="6" :lg="6" :md="6" :sm="6" :xs="6">
                    <el-select
                      v-model="selectedText.style.fontFamily"
                      placeholder="Select"
                      class="text-editot-slt"
                      v-if="selectedText && selectedText.style && isEditingText"
                    >
                      <el-option
                        v-for="item in fontTypes"
                        :key="item"
                        :value="item"
                      >
                        <p :style="getFontType(item)">{{ item }}</p>
                      </el-option>
                    </el-select>
                  </el-col>
                  <el-col class="mt-1" :xl="3" :lg="3" :md="4" :sm="4" :xs="4">
                    <el-button
                      type="plain"
                      @click="makeSelectedTextBold"
                      :class="
                        selectedText.style.weight == 1 ||
                        selectedText.style.weight == 3
                          ? 'text-bold text-editot-btn-active'
                          : 'text-bold text-editot-btn'
                      "
                      v-if="selectedText && selectedText.style && isEditingText"
                    >
                      <b>B</b>
                    </el-button>
                  </el-col>
                  <el-col class="mt-1" :xl="2" :lg="2" :md="4" :sm="4" :xs="4">
                    <el-button
                      type="plain"
                      @click="makeSelectedTextItalic"
                      :class="
                        selectedText.style.weight == 2 ||
                        selectedText.style.weight == 3
                          ? 'text-italic text-editot-btn-active'
                          : 'text-italic text-editot-btn'
                      "
                      v-if="selectedText && selectedText.style && isEditingText"
                    >
                      <i>It</i>
                    </el-button>
                  </el-col>
                  <el-col class="mt-1" :xl="4" :lg="4" :md="6" :sm="6" :xs="6">
                    <el-select
                      v-model="selectedText.height"
                      placeholder="Size"
                      class="text-editot-slt-size"
                      v-if="selectedText && selectedText.style && isEditingText"
                    >
                      <el-option
                        v-for="item in fontSizes"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </el-col>
                  <div
                    v-on:click="changeSelectedTextColor"
                    style="display: inline-block flex"
                    v-if="selectedText && selectedText.style && isEditingText"
                  >
                    <el-color-picker
                      v-model="selectedText.style.color"
                      style="top: 15px"
                    ></el-color-picker>
                  </div>
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="Erase"
                    placement="left"
                  >
                    <el-button
                      type="plain"
                      v-if="!isEditingText"
                      @click="
                        isEarserMode = !isEarserMode;
                        isDrawLine = false;
                        isDrawingLine = false;
                        isEarserDraw = false;
                        isEarserSelect = false;
                      "
                      :class="
                        isEarserMode
                          ? 'text-bold text-editot-btn-active'
                          : 'text-bold text-editot-btn'
                      "
                      class="mt-1"
                    >
                      <img
                        src="@/assets/img/eraser-icon.png"
                        alt="icon"
                        width="20px"
                      />
                    </el-button>
                  </el-tooltip>
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="Strike out"
                    placement="right"
                  >
                    <el-button
                      type="plain"
                      v-if="!isEditingText"
                      @click="
                        isDrawLine = !isDrawLine;
                        isEarserMode = false;
                        isDrawingLine = false;
                        isEarserDraw = false;
                        isEarserSelect = false;
                      "
                      :class="
                        isDrawLine
                          ? 'text-bold text-editot-btn-active'
                          : 'text-bold text-editot-btn'
                      "
                      class="mt-1"
                    >
                      <img
                        src="@/assets/img/line-icon.png"
                        alt="icon"
                        width="20px"
                      />
                    </el-button>
                  </el-tooltip>
                  <div
                    v-on:click="isDrawLineColorChange = true"
                    style="display: inline-block"
                    v-if="isDrawLine && !isEditingText"
                  >
                    <el-color-picker
                      v-model="drawLineColor"
                      style="top: 15px"
                      @change="isDrawLineColorChange = false"
                    ></el-color-picker>
                  </div>
                </div>
                <div v-else class="form-field-item-emtpy"></div>
                <el-scrollbar
                  v-loading="documentDataLoading"
                  ref="scrollbar"
                  :element-loading-text="loadingText"
                  v-if="!openCk"
                >
                  <div class="form-builder" ref="formBuilder">
                    <div class="form-holder" v-if="!openCk">
                      <div class="form-image-holder">
                        <!-- <img
										v-for="(image, index) of backgroundImages"
										:key="index"
										:src="getBackgroundImage(image)"
										class="page-image"
                        />-->
                        <div
                          id="wrapper"
                          style="text-align: center; margin-bottom: 50px"
                        >
                          <div
                            v-for="(doc, j) in getAllPdfData"
                            :key="j"
                            :id="`wrapper_${j}`"
                          >
                            <canvas
                              v-for="i of doc.pages"
                              :key="i"
                              :id="`${j}_canvas_${i}`"
                              tabindex="0"
                            ></canvas>
                          </div>
                        </div>
                      </div>
                      <div
                        class="form-fields-holder"
                        v-loading="loadingElements"
                        v-if="!openCk"
                      >
                        <VueDraggableResizable
                          v-for="(item, index) of elements"
                          :key="item.type + '-' + item.key + '-' + index"
                          class-name-active="my-active-class"
                          :class="
                            'each-element ' +
                              item.filled_by +
                              ' ' +
                              contentFields.indexOf(item.type) !=
                            -1
                              ? 'content-fields'
                              : ''
                          "
                          @dragging="(x, y) => onDrag(x, y, item)"
                          @mousedown.native="checkFieldsPosdown(item, index)"
                          :draggable="true"
                          @dragstart="handleDragStart"
                          @resizing="
                            (x, y, width, height) =>
                              onResize(x, y, width, height, item)
                          "
                          :onDrag="chechFieldPosition"
                          :onResize="chechFieldSizes"
                          :parent="true"
                          :h="item.height"
                          :w="item.width"
                          :x="item.x"
                          :y="item.y"
                          :min-height="15"
                          :style="getColorByFilledBy(item)"
                          @keyup.delete="$emit('onDelete')"
                          @keydown.native="handleVDRKeyDown"
                          tabindex="0"
                          @activated="onActivated(item, index)"
                          :handles="
                            getHandleByType(item.type, item.selected_tag)
                          "
                          @dblclick.native="openPopup(item, index)"
                          v-on:dblclick="openPopup(item, index)"
                          :data-color="getFilledUserColor(item)"
                          :lock-aspect-ratio="
                            isAspectRatio &&
                            (item.type == 'CHECKBOX' ||
                              item.type == 'my-signature' ||
                              item.type == 'MY_INITIAL' ||
                              item.type == 'SIGNATURE' ||
                              item.type == 'INITIAL')
                              ? true
                              : false
                          "
                          :has-value="
                            item.value && item.value != '' ? '1' : '0'
                          "
                          :has-content="
                            item.content && item.content != '' ? '1' : '0'
                          "
                          :has-background="
                            item.type == 'my-signature' ? '0' : '1'
                          "
                          :onDragStart="handleFieldDragStart"
                          @mouseup.native="hideAllAlignLines"
                          :data-text="item.label"
                        >
                          <!-- :grid="[10, 10]" -->
                          <!-- <a
                      @dblclick="openPopup(item, index)"
                      style="cursor: pointer"
                          >-->
                          <!-- Table Preview -->
                          <el-table
                            v-if="
                              item.section == 'table' &&
                              item.headers &&
                              item.headers.length &&
                              !tableElementLoading
                            "
                            :data="item.table_data"
                            :style="`width=${item.width}px`"
                          >
                            <el-table-column
                              type="index"
                              width="30"
                            ></el-table-column>
                            <el-table-column
                              v-for="(header, index) of item.headers"
                              :key="index"
                            >
                              <template slot="header">{{ header }}</template>
                              <template slot-scope="scope">{{
                                item.table_data[scope.$index][header]
                              }}</template>
                            </el-table-column>
                          </el-table>
                          <!-- Logo Preview -->
                          <img
                            v-else-if="item.type == 'logo'"
                            :width="item.width"
                            :height="item.height"
                            :src="getLogoUrl"
                          />
                          <!-- Entity Preview -->
                          <div v-else-if="item.type == 'ENTITY'">
                            <ConfigureEntityField
                              :entityId="item.entity_id"
                              :form="item"
                              :isDisable="false"
                              :isFromTemplate="
                                item.field_type == 'ENTITY_VARIABLE'
                                  ? false
                                  : true
                              "
                              :isAllowToAddNewdata="
                                item.allow_to_add_new_entity_data
                              "
                              :documentUsers="documentRecipientsList"
                            ></ConfigureEntityField>
                          </div>
                          <!-- Repeatable Preview -->
                          <div
                            v-else-if="item.type == 'REPEATABLE_PARENT'"
                            :style="getFieldStyles(item)"
                          >
                            <ConfigureRepeatableField
                              :entityId="item.entity_id"
                              :form="item"
                              :isDisable="false"
                              :isFromTemplate="true"
                            ></ConfigureRepeatableField>
                          </div>
                          <!-- Formula Preview -->
                          <div
                            v-else-if="item.type == 'FORMULA'"
                            :style="getFieldStyles(item)"
                          >
                            <FormulaExecute
                              :data="item"
                              :form="documentData"
                              :is-view="false"
                              :isFromDocument="true"
                              :workflowData="{}"
                              :allFields="elements"
                            ></FormulaExecute>
                          </div>
                          <div
                            v-else-if="
                              item.type == 'FILE' ||
                              (item.type == 'ATTACHMENT' &&
                                item.field_type == 'ENTITY_FIELD') ||
                              (item.type == 'ATTACHMENT' &&
                                item.parent_entity_field_key)
                            "
                            :style="getFieldStyles(item)"
                          >
                            <!-- {{filesData}} -->
                            <DocumentExecute
                              :data="item"
                              :form="filesData"
                              :is-view="false"
                              :isFromDocument="true"
                            ></DocumentExecute>
                          </div>
                          <div
                            v-else-if="item.type === 'RADIO'"
                            :style="getFieldStyles(item, index)"
                          >
                            <RadioFieldExecute
                              :data="item"
                              :form="documentData"
                              :is-view="false"
                              :isFromDocument="true"
                              :isFromAddingFields="true"
                              :isDisable="checkFieldAllow(item)"
                            ></RadioFieldExecute>
                          </div>
                          <div
                            v-else-if="item.type == 'RADIO_BUTTON_GROUP'"
                            :style="getFieldStyles(item)"
                          >
                            <!-- {{filesData}} -->
                            <RadioGroupExecute
                              :data="item"
                              :form="documentData"
                              :is-view="false"
                              :isFromDocument="true"
                              :isFromAddingFields="true"
                              :isDisable="checkFieldAllow(item)"
                            ></RadioGroupExecute>
                          </div>
                          <div
                            v-else-if="
                              item.type == 'SINGLE_LINE_CONTENT' &&
                              item.field_type == 'ENTITY_FIELD' &&
                              item.content
                            "
                          >
                            <SingleLineContentExecute
                              :data="item"
                              :form="documentData"
                              :is-view="false"
                              :isFromDocument="true"
                              :allFields="elements"
                            >
                            </SingleLineContentExecute>
                          </div>
                          <div
                            v-else-if="
                              item.type == 'SINGLE_LINE_CONTENT' &&
                              item.field_type != 'ENTITY_FIELD' &&
                              item.content
                            "
                          >
                            <el-input
                              type="textarea"
                              class="field-textarea"
                              v-model="item.content"
                              :disabled="checkFieldDisabledOnModal(item)"
                              :style="
                                getFieldStyles(
                                  item,
                                  'width: 100% !important; height: 100% !important;'
                                )
                              "
                              @focus="handleTextAreaFocus"
                              @blur="handleTextAreaBlur"
                              :placeholder="item.content"
                            ></el-input>
                            <div
                              class="overlay"
                              :style="getTextColorByFilledBy(item)"
                            >
                              {{ item.content == "" ? item.placeholder : "" }}
                            </div>
                          </div>
                          <div
                            v-else-if="
                              item.type == 'HORIZONTAL_LINE' ||
                              (item.type == 'HORIZONTAL_LINE' &&
                                item.field_type == 'ENTITY_FIELD')
                            "
                            :style="getFieldStyles(item)"
                          >
                            <HorizontalLineExecute
                              :data="item"
                              :form="documentData"
                              :is-view="false"
                              :isFromDocument="true"
                              :allFields="elements"
                            ></HorizontalLineExecute>
                          </div>
                          <div
                            v-else-if="item.type == 'AUTO_INCREMENT_NUMBER'"
                            :style="getFieldStyles(item)"
                          >
                            <AutoIncrementExecute
                              :data="item"
                              :form="documentData"
                              :is-view="false"
                              :isFromDocument="true"
                              :allFields="elements"
                              :entityId="item.entity_id"
                            ></AutoIncrementExecute>
                          </div>
                          <!-- Heading Preview -->
                          <div
                            v-else-if="item.type == 'HEADING' && item.content"
                          >
                            <el-input
                              type="textarea"
                              class="field-textarea"
                              v-model="item.content"
                              :disabled="checkFieldDisabledOnModal(item)"
                              :style="
                                getFieldStyles(
                                  item,
                                  'width: 100% !important; height: 100% !important;'
                                )
                              "
                              @focus="handleTextAreaFocus"
                              @blur="handleTextAreaBlur"
                              :placeholder="item.content"
                            ></el-input>
                            <div
                              class="overlay"
                              :style="getTextColorByFilledBy(item)"
                            >
                              {{ item.content == "" ? item.placeholder : "" }}
                            </div>
                          </div>
                          <img
                            v-else-if="
                              item.type == 'my-signature' && item.source
                            "
                            :width="item.width"
                            :height="item.height"
                            :src="item.source"
                            class="my-signature"
                          />
                          <img
                            v-else-if="
                              item.type === 'SIGNATURE' &&
                              item.field_type === 'ENTITY_FIELD' &&
                              item.value != ''
                            "
                            :width="item.width"
                            :height="item.height"
                            :src="item.value"
                            class="my-signature"
                          />
                          <img
                            v-else-if="item.type == 'INITIAL' && item.value"
                            :src="item.value"
                            :width="item.width"
                            :height="item.height"
                          />
                          <img
                            v-else-if="item.type == 'MY_INITIAL' && item.value"
                            :src="item.value"
                            :width="item.width"
                            :height="item.height"
                            class="my-initial"
                          />

                          <img
                            v-else-if="item.type == 'IMAGE' && item.value"
                            :src="item.value"
                            :width="item.width"
                            :height="item.height"
                          />
                          <div
                            v-else-if="item.type == 'IMAGE' && !item.value"
                            :style="getTextColorByFilledBy(item)"
                            class="document-image-field"
                          >
                            {{ item.label }}
                            <i class="el-icon-edit"></i>
                          </div>
                          <div
                            v-else-if="item.type == 'ZSEAL'"
                            class="image-container"
                          >
                            <img
                              :src="item.arrowsImage"
                              height="40px"
                              width="80px"
                            />
                            <img :src="item.zseal_image_url" />
                            <!-- <div class="word-container">
                            <span v-for="(word, index) in convertedNumber" :key="index" class="digit-word">
                              <span class="number">{{ number.toString()[index] }}</span>
                              {{ word }}
                            </span>
                          </div> -->
                          </div>
                          <!-- Primary fields preview -->
                          <div
                            v-else-if="
                              item.type == 'PRIMARY_FIELDS' &&
                              (item.selected_tag == 'full_name' ||
                                item.selected_tag == 'first_name' ||
                                item.selected_tag == 'last_name') &&
                              item.is_use_boxes
                            "
                            :id="
                              item.type + '_' + item.content + '_' + item.index
                            "
                            :style="getFieldStyles(item)"
                            class="primary-field"
                          >
                            <div class="input-container">
                              <div
                                v-for="(box, index) in item.boxes"
                                :key="index"
                                class="box"
                                :class="{ 'small-box': box.value === '' }"
                                ref="boxElements"
                                :style="{
                                  width: item.boxWidth + 'px',
                                  height: item.height + 'px',
                                }"
                              >
                                <input
                                  v-model="item.boxes[index]"
                                  @input="handleInputChange(item, index)"
                                  @keydown.backspace="
                                    handleBackspace(item, index, $event)
                                  "
                                  @keydown="handleKeyDown($event, item, index)"
                                  maxlength="1"
                                  type="text"
                                  class="box-input"
                                  :autofocus="index === 0"
                                />
                              </div>
                            </div>
                            <button
                              v-if="
                                activatedItem != null &&
                                activatedItem.type &&
                                activatedItem.index == item.index
                              "
                              style="margin-top: 15px"
                              @click="addBox(item, index)"
                              @dblclick.stop="addBox(item, index)"
                            >
                              +
                            </button>
                            <button
                              v-if="
                                activatedItem != null &&
                                activatedItem.type &&
                                activatedItem.index == item.index &&
                                item.boxes.length &&
                                item.boxes.length > 0
                              "
                              style="margin-top: 10px; margin-left: 2px"
                              @click="deleteBox(item, index)"
                              @dblclick.stop="deleteBox(item, index)"
                            >
                              -
                            </button>
                            <div
                              class="overlay"
                              :style="getTextColorByFilledBy(item)"
                            >
                              {{ item.content == "" ? item.placeholder : "" }}
                            </div>
                          </div>
                          <div
                            v-else-if="
                              item.type == 'PRIMARY_FIELDS' &&
                              !item.is_use_boxes
                            "
                            :id="
                              item.type + '_' + item.content + '_' + item.index
                            "
                            :ref="`textarea_${item.key}`"
                            :style="getFieldStyles(item)"
                            class="primary-field"
                          >
                            <el-input
                              type="textarea"
                              :ref="`textarea_${item.key}`"
                              :class="
                                item.selected_tag == 'address'
                                  ? 'field-textarea textare-background'
                                  : 'field-textarea textarea-nowrap'
                              "
                              v-model="item.content"
                              :disabled="checkFieldDisabledOnModal(item)"
                              :style="
                                getFieldStyles(
                                  item,
                                  'width: 100% !important; height: 100% !important; display: table !important;',
                                  item.key
                                )
                              "
                              @focus="handleTextAreaFocus"
                              @blur="handleTextAreaBlur"
                              :id="`ADDRESS_${item.index}`"
                              @change="
                                auto_grow(
                                  `ADDRESS_${item.index}`,
                                  item.selected_tag
                                )
                              "
                              :placeholder="item.content"
                            ></el-input>
                            <div
                              class="overlay"
                              :style="getTextColorByFilledBy(item)"
                            >
                              {{ item.content == "" ? item.placeholder : "" }}
                            </div>
                          </div>
                          <div
                            v-else-if="item.type == 'CURRENCY'"
                            :style="getFieldStyles(item)"
                            class="primary-field"
                          >
                            <currency-input
                              v-if="refreshIndex !== index"
                              v-model="item.value"
                              :disabled="checkFieldDisabledOnModal(item)"
                              @input="checkCurrency(item, index)"
                              :placeholder="item.placeholder"
                              :currency="
                                item.validations && item.validations.currency
                                  ? item.validations.currency
                                  : 'USD'
                              "
                              :locale="
                                item.validations && item.validations.locale
                                  ? item.validations.locale
                                  : 'en-US'
                              "
                              class="field-textarea currency-input"
                              :style="
                                getFieldStyles(
                                  item,
                                  'width: 100% !important; height: 100% !important;display: table !important;',
                                  item.key,
                                  alignment
                                )
                              "
                            />
                          </div>
                          <!-- yes/no -->
                          <div
                            v-else-if="item.type == 'YES_OR_NO'"
                            :style="getFieldStyles(item)"
                            class="yes-or-no"
                          >
                            <el-radio v-model="item.value" label="YES"
                              >Yes</el-radio
                            >
                            <el-radio v-model="item.value" label="NO"
                              >No</el-radio
                            >
                          </div>
                          <!-- checkbox -->
                          <div
                            class="d-flex"
                            v-else-if="
                              item.type == 'CHECKBOX' && !item.is_use_cross_mark
                            "
                            :style="getFieldStyles(item)"
                          >
                            <input
                              type="checkbox"
                              v-model="item.value"
                              :disabled="checkFieldDisabledOnModal(item)"
                              @input="handleCheckBoxChange(item, index)"
                              :style="getFieldStyles(item)"
                            />
                            <span v-if="getCheckBoxLabel(item)">{{
                              item.label
                            }}</span>
                          </div>
                          <div
                            class="d-flex"
                            v-else-if="
                              item.type == 'CHECKBOX' ||
                              (item.is_check_box_group &&
                                item.is_use_cross_mark)
                            "
                            :style="getFieldStyles(item)"
                          >
                            <input
                              type="checkbox"
                              class="custom-checkbox"
                              v-model="item.value"
                              :disabled="checkFieldDisabledOnModal(item)"
                              @input="handleCheckBoxChange(item, index)"
                              :style="{
                                ...getFieldStyles(item),
                                fontSize: getCrossMarkFontSize(
                                  item.width,
                                  item.height
                                ),
                                '--cross-mark-width': item.width + 'px',
                                '--cross-mark-height': item.height + 'px',
                              }"
                            />
                            <span v-if="getCheckBoxLabel(item)">{{
                              item.label
                            }}</span>
                          </div>
                          <!-- Date -->
                          <div
                            v-else-if="item.type == 'DATE' && item.is_use_boxes"
                            :style="getFieldStyles(item)"
                          >
                            <div class="input-container">
                              <div
                                v-for="(box, index) in item.boxes"
                                :key="index"
                                class="box"
                                :class="{ 'small-box': box.value === '' }"
                                ref="boxElements"
                                :style="{
                                  width: item.boxWidth + 'px',
                                  height: item.height + 'px',
                                }"
                              >
                                <input
                                  v-model="item.boxes[index]"
                                  @input="handleInputChange(item, index)"
                                  @keydown="handleKeyDown($event, item, index)"
                                  @keydown.backspace="
                                    handleBackspace(item, index, $event)
                                  "
                                  maxlength="1"
                                  type="text"
                                  class="box-input"
                                  :autofocus="index === 0"
                                />
                              </div>
                            </div>
                            <button
                              v-if="
                                activatedItem != null &&
                                activatedItem.type &&
                                activatedItem.index == item.index
                              "
                              style="margin-top: 15px"
                              @click="addBox(item, index)"
                              @dblclick.stop="addBox(item, index)"
                            >
                              +
                            </button>
                            <button
                              v-if="
                                activatedItem != null &&
                                activatedItem.type &&
                                activatedItem.index == item.index &&
                                item &&
                                item.boxes &&
                                item.boxes.length
                              "
                              style="margin-top: 10px; margin-left: 2px"
                              @click="deleteBox(item, index)"
                              @dblclick.stop="deleteBox(item, index)"
                            >
                              -
                            </button>
                          </div>
                          <div
                            v-else-if="
                              item.type == 'DATE' && !item.is_use_boxes
                            "
                            :style="getFieldStyles(item)"
                          >
                            <el-date-picker
                              :placeholder="
                                item.placeholder
                                  ? item.placeholder
                                  : 'Select date'
                              "
                              v-model="item.value"
                              :disabled="checkFieldDisabledOnModal(item)"
                              :style="
                                getFieldStyles(
                                  item,
                                  'width: 100% !important; height: 100% !important; display: table !important;',
                                  item.key
                                )
                              "
                              v-bind:class="{
                                'date-picker-item': true,
                                'smaller-than': true,
                              }"
                              :format="
                                getDefaultDateFormat &&
                                getDefaultDateFormat.includes('Do') == false
                                  ? getDefaultDateFormat
                                      .replace('YYYY', 'yyyy')
                                      .replace('DD', 'dd')
                                  : 'MM-dd-yyyy'
                              "
                            ></el-date-picker>
                          </div>
                          <div
                            v-else-if="item.type == 'DATE_TIME'"
                            :style="getFieldStyles(item)"
                          >
                            <el-date-picker
                              type="datetime"
                              :placeholder="
                                item.placeholder
                                  ? item.placeholder
                                  : 'Select date and time'
                              "
                              v-model="item.value"
                              :disabled="checkFieldDisabledOnModal(item)"
                              prefix-icon=""
                              v-bind:class="{
                                'date-time-picker-item': true,
                                'smaller-than': true,
                              }"
                              format="MM-dd-yyyy HH:mm:ss"
                            ></el-date-picker>
                          </div>
                          <div
                            v-else-if="item.type == 'DATE_TIME_RANGE'"
                            :style="getFieldStyles(item, index)"
                          >
                            <el-date-picker
                              type="datetimerange"
                              :placeholder="
                                item.placeholder
                                  ? item.placeholder
                                  : 'Select date time range'
                              "
                              v-model="item.value"
                              :disabled="checkFieldDisabledOnModal(item)"
                              prefix-icon=""
                              :class="{
                                'date-time-picker-item': true,
                                'smaller-than': true,
                                'bold-text':
                                  item &&
                                  item.font &&
                                  (item.font.style === 1 ||
                                    item.font.style === 3),
                              }"
                              range-separator="To"
                              start-placeholder="Start date"
                              end-placeholder="End date"
                              popper-class="my-popover"
                              :format="'MM-dd-yyyy HH:mm:ss'"
                              style="white-space: nowrap"
                            ></el-date-picker>
                          </div>
                          <div
                            v-else-if="item.type == 'DATE_RANGE'"
                            :style="getFieldStyles(item, index)"
                          >
                            <el-date-picker
                              type="daterange"
                              :placeholder="
                                item.placeholder
                                  ? item.placeholder
                                  : 'Select date range'
                              "
                              v-model="item.value"
                              :disabled="checkFieldDisabledOnModal(item)"
                              prefix-icon=""
                              :class="{
                                'date-picker-item': true,
                                'smaller-than': true,
                                'bold-text':
                                  item &&
                                  item.font &&
                                  (item.font.style === 1 ||
                                    item.font.style === 3),
                              }"
                              range-separator="To"
                              start-placeholder="Start date"
                              end-placeholder="End date"
                              popper-class="my-popover"
                              :format="'MM-dd-yyyy '"
                              style="white-space: nowrap"
                            ></el-date-picker>
                          </div>
                          <div
                            v-else-if="item.type == 'TIME' && !item.is_fixed"
                            :style="getFieldStyles(item)"
                          >
                            <el-time-picker
                              type="time"
                              :placeholder="
                                item.placeholder
                                  ? item.placeholder
                                  : 'Select Time'
                              "
                              v-model="item.value"
                              :disabled="checkFieldDisabledOnModal(item)"
                              prefix-icon=""
                              v-bind:class="{
                                'time-picker-item': true,
                                'smaller-than': true,
                              }"
                              format="HH:mm:ss"
                            >
                            </el-time-picker>
                          </div>
                          <div
                            v-else-if="item.type == 'TIME_RANGE'"
                            :style="getFieldStyles(item, index)"
                          >
                            <el-time-picker
                              type="timerange"
                              :placeholder="
                                item.placeholder
                                  ? item.placeholder
                                  : 'Select date range'
                              "
                              prefix-icon=""
                              :class="{
                                'time-picker-item': true,
                                'smaller-than': true,
                                'bold-text':
                                  item &&
                                  item.font &&
                                  (item.font.style === 1 ||
                                    item.font.style === 3),
                              }"
                              is-range
                              v-model="item.value"
                              @focus="handleTimeRange(item)"
                              :disabled="checkFieldDisabledOnModal(item)"
                              range-separator="To"
                              start-placeholder="Start time"
                              end-placeholder="End time"
                              popper-class="my-popover"
                              style="white-space: nowrap"
                            ></el-time-picker>
                          </div>
                          <div
                            v-else-if="
                              item.type == 'FIXED_TIME' ||
                              (item.type == 'TIME' &&
                                item.is_fixed &&
                                item.field_type == 'ENTITY_FIELD')
                            "
                            :style="getFieldStyles(item)"
                          >
                            <el-time-select
                              :picker-options="getTimeProperties"
                              :placeholder="
                                item.placeholder
                                  ? item.placeholder
                                  : 'Select Fixed Time'
                              "
                              v-model="item.value"
                              :disabled="checkFieldDisabledOnModal(item)"
                              prefix-icon=""
                              v-bind:class="{
                                'time-picker-item': true,
                                'smaller-than': true,
                              }"
                              format="HH:mm:ss"
                            >
                            </el-time-select>
                          </div>
                          <div
                            v-else-if="
                              item.type == 'WEEKDAYS' && !item.allow_multiple
                            "
                            :style="getFieldStyles(item, index)"
                          >
                            <el-select
                              :placeholder="
                                item.placeholder
                                  ? item.placeholder
                                  : 'Please select'
                              "
                              :clearable="true"
                              :filterable="true"
                              v-model="item.value"
                              :disabled="checkFieldDisabledOnModal(item)"
                              collapse-tags
                              style="display: table !important"
                            >
                              <el-option
                                v-for="(option, index) of weekdays_options"
                                :value="option.value"
                                :label="option.name"
                                :key="index"
                              ></el-option>
                            </el-select>
                          </div>
                          <div
                            v-else-if="
                              item.type == 'WEEKDAYS' && item.allow_multiple
                            "
                            :style="getFieldStyles(item, index)"
                          >
                            <el-select
                              :placeholder="
                                item.placeholder
                                  ? item.placeholder
                                  : 'Please select'
                              "
                              :clearable="true"
                              :filterable="true"
                              multiple
                              v-model="item.value"
                              :disabled="checkFieldDisabledOnModal(item)"
                              collapse-tags
                              style="display: table !important"
                            >
                              <el-option
                                v-for="(option, index) of weekdays_options"
                                :value="option.value"
                                :label="option.name"
                                :key="index"
                              ></el-option>
                            </el-select>
                          </div>
                          <!-- <div
                            v-else-if="item.type == 'Radio'"
                            :style="getFieldStyles(item)"
                            :id="item.type" 
                          >
                            <el-radio v-model="item.value" 
                              >radio</el-radio
                          >
                          </div> -->
                          <!-- Select -->
                          <div
                            v-else-if="
                              item.type == 'SELECT' ||
                              item.type == 'MULTI_SELECT'
                            "
                            :style="getFieldStyles(item)"
                          >
                            <el-select
                              :placeholder="
                                item.placeholder
                                  ? item.placeholder
                                  : 'Please Select'
                              "
                              :clearable="true"
                              :filterable="true"
                              collapse-tags
                              @input="handleMultiSelectChange(item)"
                              :multiple="
                                item.type == 'MULTI_SELECT' ? true : false
                              "
                              :allow-create="true"
                              :default-first-option="true"
                              v-model="item.value"
                              style="display: table !important"
                              :disabled="
                                ((item.type == 'SELECT' ||
                                  item.type == 'MULTI_SELECT') &&
                                  !item.options.length) ||
                                checkFieldDisabledOnModal(item)
                              "
                            >
                              <el-option
                                v-for="op in item.options"
                                :key="op"
                                :label="op"
                                :value="op"
                              ></el-option>
                            </el-select>
                          </div>
                          <!-- list field -->
                          <div
                            v-else-if="item.type == 'LIST'"
                            :style="getFieldStyles(item)"
                          >
                            <el-select
                              :placeholder="
                                item.placeholder
                                  ? item.placeholder
                                  : 'Please Select'
                              "
                              :default-first-option="true"
                              v-model="item.value"
                              :disabled="checkFieldDisabledOnModal(item)"
                            >
                              <el-option
                                v-for="option of item.list_data"
                                :value="option.value"
                                :key="option.value"
                                :label="option.name"
                              ></el-option>
                            </el-select>
                          </div>
                          <div
                            v-else-if="item.type == 'DATA_TABLE'"
                            :style="getFieldStyles(item)"
                          >
                            <el-table
                              class="custom-table"
                              :key="tableKey"
                              :resizable="true"
                              :data="item.table_data"
                              border
                              width="100%"
                              :style="{ height: tableHeight(item.table_data) }"
                              :border-color="tableBorderColor"
                              ref="myTable"
                            >
                              <el-table-column
                                v-for="(header, index) of item.headers"
                                :key="index"
                                :resizable="true"
                              >
                                <template slot="header">
                                  {{ header }}
                                  <el-button
                                    type="text"
                                    icon="el-icon-edit"
                                    @click="editColumn(header, index, item)"
                                  ></el-button>
                                  <el-button
                                    type="text"
                                    icon="el-icon-delete"
                                    @click="
                                      deleteTableColumn(header, index, item)
                                    "
                                  ></el-button>
                                </template>

                                <template slot-scope="scope">
                                  {{ item.table_data[scope.$index][header] }}
                                </template>
                              </el-table-column>
                            </el-table>
                          </div>
                          <div
                            v-else-if="item.type == 'DATA_TABLE'"
                            :style="getFieldStyles(item)"
                          >
                            <el-table
                              class="custom-table"
                              :key="tableKey"
                              :data="item.table_data"
                              :resizable="true"
                              style="width: 100%"
                              :style="{ height: tableHeight(item.table_data) }"
                              borderColor="tableBorderColor"
                              ref="myTable"
                            >
                              <template v-if="item.map_keys">
                                <el-table-column
                                  v-for="(header, index) of item.headers"
                                  :key="index"
                                  :resizable="true"
                                >
                                  <template slot="header">
                                    {{ header }}
                                    <el-button
                                      type="text"
                                      icon="el-icon-delete"
                                      @click="
                                        deleteTableColumn(header, index, item)
                                      "
                                    ></el-button>
                                  </template>

                                  <template slot-scope="scope">
                                    {{ item.table_data[scope.$index][header] }}
                                  </template>
                                </el-table-column>
                              </template>

                              <template v-else-if="!item.map_keys">
                                <el-table-column
                                  v-for="(header, index) of item.headers"
                                  :key="index"
                                  :resizable="true"
                                >
                                  <template slot="header">
                                    <span
                                      :style="
                                        'font-size: ' +
                                        item.headerFont.size +
                                        '; '
                                      "
                                    >
                                      {{ header }}
                                    </span>
                                    <el-button
                                      type="text"
                                      icon="el-icon-delete"
                                      @click="
                                        deleteTableColumn(header, index, item)
                                      "
                                    ></el-button>
                                  </template>

                                  <template slot-scope="scope">
                                    {{ item.table_data[scope.$index][header] }}
                                  </template>
                                </el-table-column>
                              </template>
                            </el-table>

                            <el-tooltip content="add rows">
                              <el-button
                                v-if="
                                  (item.headers && item.headers.length) ||
                                  item.map_keys
                                "
                                size="mini"
                                icon="el-icon-plus"
                                @click="addTableRow(item)"
                              ></el-button>
                            </el-tooltip>

                            <el-tooltip content="delete rows">
                              <el-button
                                v-if="
                                  (item.headers && item.headers.length) ||
                                  item.map_keys
                                "
                                size="mini"
                                icon="el-icon-minus"
                                @click="deleteTableRow(item)"
                              ></el-button>
                            </el-tooltip>
                          </div>

                          <div
                            v-else-if="item.type == 'PARAGRAPH'"
                            :style="getFieldStyles(item)"
                          >
                            <el-input
                              type="textarea"
                              class="field-textarea textare-background"
                              v-model="item.content"
                              :disabled="checkFieldDisabledOnModal(item)"
                              :style="
                                getFieldStyles(
                                  item,
                                  'width: 100% !important; height: 100% !important; display: table !important;'
                                )
                              "
                              @focus="handleTextAreaFocus"
                              @blur="handleTextAreaBlur"
                              @change="
                                auto_grow(
                                  `MULTI_LINE_TEXT_${item.index}`,
                                  'MULTI_LINE_TEXT'
                                )
                              "
                              :id="`MULTI_LINE_TEXT_${item.index}`"
                            ></el-input>
                            <div
                              class="overlay"
                              :style="getTextColorByFilledBy(item)"
                            >
                              {{
                                item.content && item.content !== ""
                                  ? ""
                                  : item.placeholder
                              }}
                            </div>
                          </div>
                          <div
                            v-else-if="item.type == 'MULTI_LINE_TEXT'"
                            :style="getFieldStyles(item)"
                          >
                            <el-input
                              type="textarea"
                              class="field-textarea textare-background"
                              v-model="item.value"
                              :disabled="checkFieldDisabledOnModal(item)"
                              :ref="`textarea_${item.key}`"
                              :style="
                                getFieldStyles(
                                  item,
                                  'width: 100% !important; height: 100% !important; display: table !important;',
                                  item.key
                                )
                              "
                              @focus="handleTextAreaFocus"
                              @blur="handleTextAreaBlur"
                              @change="
                                auto_grow(
                                  `MULTI_LINE_TEXT_${item.index}`,
                                  'MULTI_LINE_TEXT'
                                )
                              "
                              :id="`MULTI_LINE_TEXT_${item.index}`"
                            ></el-input>
                            <div
                              class="overlay"
                              :style="getTextColorByFilledBy(item)"
                            >
                              {{
                                item.value && item.value !== ""
                                  ? ""
                                  : item.placeholder
                              }}
                            </div>
                          </div>
                          <div
                            v-else-if="
                              (item.type === 'SINGLE_LINE_TEXT' ||
                                (item.type === 'NUMBER' &&
                                  item.field_type !== 'ENTITY_FIELD') ||
                                (item.field_type == 'ENTITY_FIELD' &&
                                  (item.type === 'SINGLE_LINE_TEXT' ||
                                    item.type === 'NUMBER'))) &&
                              item.is_use_boxes
                            "
                            :style="getFieldStyles(item)"
                          >
                            <div class="input-container">
                              <div
                                v-for="(box, index) in item.boxes"
                                :key="index"
                                class="box"
                                :class="{ 'small-box': box.value === '' }"
                                ref="boxElements"
                                :style="{
                                  width: item.boxWidth + 'px',
                                  height: item.height + 'px',
                                }"
                              >
                                <input
                                  v-model="item.boxes[index]"
                                  @input="handleInputChange(item, index)"
                                  @keydown="handleKeyDown($event, item, index)"
                                  @keydown.backspace="
                                    handleBackspace(item, index, $event)
                                  "
                                  maxlength="1"
                                  type="text"
                                  class="box-input"
                                  :autofocus="index === 0"
                                />
                              </div>
                            </div>
                            <button
                              v-if="
                                activatedItem != null &&
                                activatedItem.type &&
                                activatedItem.index == item.index
                              "
                              style="margin-top: 15px"
                              @click="addBox(item, index)"
                              @dblclick.stop="addBox(item, index)"
                            >
                              +
                            </button>
                            <button
                              v-if="
                                activatedItem != null &&
                                activatedItem.type &&
                                activatedItem.index == item.index &&
                                item &&
                                item.boxes &&
                                item.boxes.length
                              "
                              style="margin-top: 10px; margin-left: 2px"
                              @click="deleteBox(item, index)"
                              @dblclick.stop="deleteBox(item, index)"
                            >
                              -
                            </button>
                          </div>
                          <!--  -->

                          <div
                            v-else-if="
                              item.type != 'my-signature' &&
                              item.type != 'MY_INITIAL' &&
                              item.type != 'INITIAL' &&
                              item.type != 'ATTACHMENT' &&
                              item.type != 'SIGNATURE' &&
                              (!item.is_use_boxes || item.is_use_boxes == false)
                            "
                            :style="getFieldStyles(item)"
                          >
                            <el-input
                              type="textarea"
                              class="field-textarea textarea-nowrap"
                              v-model="item.value"
                              :disabled="checkFieldDisabledOnModal(item)"
                              :ref="`textarea_${item.key}`"
                              :style="
                                getFieldStyles(
                                  item,
                                  'width: 100% !important; height: 100% !important; display: table !important;',
                                  item.key
                                )
                              "
                              @focus="handleTextAreaFocus"
                              @input="handleCheckForDateSigned(item)"
                              @blur="handleTextAreaBlur"
                            ></el-input>
                            <div
                              class="overlay"
                              :style="getTextColorByFilledBy(item)"
                            >
                              {{
                                item.value && item.value !== ""
                                  ? ""
                                  : item.placeholder
                              }}
                            </div>
                          </div>
                          <!-- Default Preview -->
                          <p v-else :style="getTextColorByFilledBy(item)">
                            {{
                              item.type != "CHECKBOX"
                                ? item.label ||
                                  (item.field_type == "TEMPLATE_FIELD"
                                    ? item.template_field_label
                                    : item.entity_field_label) ||
                                  item.type
                                : "+"
                            }}
                          </p>
                          <!-- </a> -->
                        </VueDraggableResizable>

                        <svg id="svg">
                          <line id="top_line" class="line" />
                          <line id="middle_horizontal_line" class="line" />
                          <line id="bottom_line" class="line" />
                          <line id="left_line" class="line" />
                          <line id="middle_vertical_line" class="line" />
                          <line id="right_line" class="line" />
                        </svg>
                        <div
                          class="text-highlight"
                          id="textHighlight"
                          v-click-outside="hideTextEdit"
                          v-if="isEditMode"
                        >
                          <el-link
                            class="el-icon-edit edit-icon"
                            style="cursor: pointer; color: white"
                            :underline="false"
                            @click="editSelectedText"
                            v-if="!isEditingText"
                          ></el-link>
                          <input
                            v-if="this.selectedText && isEditingText"
                            v-model="selectedTextInPdf"
                            :style="getSelectedTextStyle"
                          />
                          <!-- <div class="overlay"></div> -->
                          <!-- </el-input>  class="field-textarea textarea-nowrap" -->
                          <!-- <el-button type="danger" icon="el-icon-edit" size="mini" class="edit-icon" ></el-button> -->
                        </div>
                        <div
                          class="text-highlight"
                          id="whiteRectangle"
                          v-if="isEditMode && isEarserDraw"
                        >
                          <el-link
                            icon="el-icon-close"
                            class="edit-icon"
                            style="cursor: pointer; color: white"
                            :underline="false"
                            @click="deleteRectangle"
                          ></el-link>
                          <el-link
                            icon="el-icon-check"
                            class="save-icon"
                            style="cursor: pointer; color: white"
                            :underline="false"
                            @click="saveRectangle(false)"
                          ></el-link>
                          <el-link
                            icon="el-icon-edit"
                            class="text-icon"
                            style="cursor: pointer; color: white"
                            :underline="false"
                            v-if="!isAddingText"
                            @click="addTextRectangle"
                          ></el-link>
                          <textarea
                            v-if="isAddingText"
                            v-model="addedTextOnRectangle"
                            :style="getAddtextBoxStyle"
                            class="field-textarea currency-input"
                            id="earserTextBox"
                          ></textarea>
                        </div>
                        <div
                          class="text-added"
                          id="LineRectangle"
                          v-else-if="isEditMode && isDrawLine && isDrawingLine"
                        ></div>
                      </div>
                    </div>
                  </div>
                </el-scrollbar>
              </el-col>
            </el-row>
            <el-card v-if="openCk" shadow="never" style="margin-top: 5px">
              <el-row :gutter="20" style="margin-top: -5px">
                <el-col
                  :span="showChatbot ? 12 : 24"
                  style="flex-grow: 3; margin-left: 40px; margin-right: -50px"
                >
                  <div
                    :style="{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: showChatbot ? '' : 'center',
                    }"
                  >
                    <div
                      :style="{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: showChatbot ? '' : 'center',
                      }"
                    >
                      <span
                        >Note: Copy the content of your Word file and paste it
                        into the CKEditor.</span
                      >
                      <span class="mb-1"
                        >Note: Assign Recipient in add recipient popup to add
                        entity fields data using '@' symbol.</span
                      >
                    </div>
                    <el-button
                      v-if="openCk && !showChatbot"
                      class="outlined-icon-button try-using-ai"
                      size="medium"
                      @click="openChatbot"
                      :style="
                        showChatbot
                          ? { marginLeft: '1px' }
                          : { marginLeft: '10px' }
                      "
                    >
                      <div style="display: flex">
                        <icons :iconName="'TryUsingAI'"></icons>
                        <div style="padding-top: 6px">Try Using AI</div>
                      </div>
                    </el-button>
                  </div>
                  <ckeditor
                    :style="{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: showChatbot ? '' : 'center',
                    }"
                    v-if="openCk"
                    :key="editorKey"
                    v-model="editorContent"
                    :config="editorConfig"
                    @keydown="handleKeyDownInEditor($event)"
                    @input="handleCheckContentInEditor"
                  >
                    <textarea id="editor1"></textarea>
                  </ckeditor>
                </el-col>
                <el-col
                  :span="showChatbot ? 11 : 0"
                  v-if="showChatbot && openCk"
                  style="margin-top: 10px; flex-grow: 3; margin-left: 50px"
                >
                  <div>
                    <el-button
                      v-if="openCk && showChatbot"
                      class="outlined-icon-button try-using-ai"
                      size="medium"
                      @click="openChatbot"
                      :style="
                        showChatbot
                          ? { marginLeft: '10px' }
                          : { marginLeft: '10px' }
                      "
                    >
                      <div style="display: flex">
                        <div><i class="el-icon-close"></i>Close</div>
                      </div>
                    </el-button>
                    <ChatGpt
                      @close-chatbot="CloseChatBot"
                      @copy-content="updateEditorContent"
                      :entity_fields="allMentionableFields"
                      :global_fields="allCompanyDataFields"
                      :entity="documentName"
                      :isFromDocument="true"
                      type="document_template"
                      @change="addEditorFieldsForDocument"
                      :editorContent="editorContent"
                    />
                  </div>
                </el-col>
              </el-row>
            </el-card>
          </div>
          <!-- <el-col :span="16" style="width:640px;"> -->
        </div>
        <!-- <el-col :span="6" class="position-relative"> -->
        <div v-if="!openCk">
          <div
            class="right-side-bar"
            v-if="
              (templateOwner == authid && !openCk) ||
              (getConfigurableDocumentDataById &&
                getConfigurableDocumentDataById.company_id &&
                getActiveWorkspace.company_id &&
                getConfigurableDocumentDataById.company_id ==
                  getActiveWorkspace.company_id &&
                !openCk)
            "
          >
            <div class="form-fields-set" v-if="!openCk">
              <div class="scrollable-container">
                <el-scrollbar style="padding: 20px">
                  <div class="selectUser mt-0 mb-3">
                    <el-select
                      v-model="recipientSelection"
                      filterable
                      clearable
                      placeholder="Select a Recipient"
                      :loading="loading"
                      class="w-100"
                      @change="changeRecipientColor"
                    >
                      <el-option
                        v-for="item in documentRecipientsList"
                        :key="item.value"
                        :label="item.name"
                        :value="item.value"
                      >
                        <span style="float: left">{{ item.name }}</span>
                        <span
                          style="
                            float: right;
                            color: #8492a6;
                            margin-left: 1px;
                            font-size: 13px;
                          "
                        >
                          {{ item.email }}</span
                        >
                      </el-option>
                      <i slot="prefix" class="el-input__icon el-icon-user"></i>
                    </el-select>
                  </div>
                  <el-collapse v-model="activeCollapse" v-if="!openCk">
                    <el-collapse-item
                      title="Essential Fields"
                      name="1"
                      v-if="getAccessOfFields('documents', 'essentialFields')"
                    >
                      <div>
                        <div class="fieldSet__box">
                          <draggable
                            class="dragArea-field-element"
                            @end="addSignature()"
                            @mouseup="mouseUp()"
                            :disabled="
                              getConfigurableDocumentDataById &&
                              getConfigurableDocumentDataById.lock_template_fields
                            "
                            v-if="
                              getAccess(
                                'documents',
                                'signature',
                                'essentialFields'
                              )
                            "
                          >
                            <button
                              @mouseover="changeof('signatureEle')"
                              @mouseout="changeout('signatureEle')"
                              class="field-element"
                              ref="signatureEle"
                              id="signatureEle"
                              @mousedown="mouseDown()"
                            >
                              <img
                                :src="
                                  require(`@/assets/img/fields_icons/${
                                    recipientSelectedColor &&
                                    defined_colors.indexOf(
                                      recipientSelectedColor
                                    ) != -1
                                      ? recipientSelectedColor
                                      : '#F754A2'
                                  }/signatureEle.svg`)
                                "
                                alt="icon"
                              />
                              <div class="name d-flex">
                                Signature
                                <i
                                  v-if="
                                    getConfigurableDocumentDataById &&
                                    getConfigurableDocumentDataById.lock_template_fields
                                  "
                                  class="el-icon-lock"
                                ></i>
                              </div>
                            </button>
                          </draggable>
                          <draggable
                            class="dragArea-field-element"
                            @end="addInitial()"
                            @mouseup="mouseUp()"
                            :disabled="
                              getConfigurableDocumentDataById &&
                              getConfigurableDocumentDataById.lock_template_fields
                            "
                            v-if="
                              getAccess(
                                'documents',
                                'initial',
                                'essentialFields'
                              )
                            "
                          >
                            <button
                              @mouseover="changeof('initialEle')"
                              @mouseout="changeout('initialEle')"
                              class="field-element"
                              ref="initialEle"
                              id="initialEle"
                              @mousedown="mouseDown()"
                            >
                              <img
                                :src="
                                  require(`@/assets/img/fields_icons/${
                                    recipientSelectedColor &&
                                    defined_colors.indexOf(
                                      recipientSelectedColor
                                    ) != -1
                                      ? recipientSelectedColor
                                      : '#F754A2'
                                  }/myInitialEle.svg`)
                                "
                                alt="icon"
                              />
                              <div class="name d-flex">
                                Initial<i
                                  v-if="
                                    getConfigurableDocumentDataById &&
                                    getConfigurableDocumentDataById.lock_template_fields
                                  "
                                  class="el-icon-lock"
                                ></i>
                              </div>
                            </button>
                          </draggable>

                          <draggable
                            class="dragArea-field-element"
                            @end="addPrimaryField('fullNameEle', 'full_name')"
                            @mouseup="mouseUp()"
                            :disabled="
                              getConfigurableDocumentDataById &&
                              getConfigurableDocumentDataById.lock_template_fields
                            "
                            v-if="
                              getAccess(
                                'documents',
                                'fullName',
                                'essentialFields'
                              )
                            "
                          >
                            <button
                              @mouseover="changeof('fullNameEle')"
                              @mouseout="changeout('fullNameEle')"
                              class="field-element"
                              ref="fullNameEle"
                              id="fullNameEle"
                              @mousedown="mouseDown()"
                            >
                              <img
                                :src="
                                  require(`@/assets/img/fields_icons/${
                                    recipientSelectedColor &&
                                    defined_colors.indexOf(
                                      recipientSelectedColor
                                    ) != -1
                                      ? recipientSelectedColor
                                      : '#F754A2'
                                  }/lastNameEle.svg`)
                                "
                                alt="icon"
                              />
                              <div class="name d-flex">
                                Full Name<i
                                  v-if="
                                    getConfigurableDocumentDataById &&
                                    getConfigurableDocumentDataById.lock_template_fields
                                  "
                                  class="el-icon-lock"
                                ></i>
                              </div>
                            </button>
                          </draggable>
                          <draggable
                            class="dragArea-field-element"
                            @end="addDateSigned()"
                            @mouseup="mouseUp()"
                            :disabled="
                              getConfigurableDocumentDataById &&
                              getConfigurableDocumentDataById.lock_template_fields
                            "
                            v-if="
                              getAccess(
                                'documents',
                                'dateSigned',
                                'essentialFields'
                              )
                            "
                          >
                            <button
                              @mouseover="changeof('dateSingedEle')"
                              @mouseout="changeout('dateSingedEle')"
                              class="field-element"
                              ref="dateSingedEle"
                              id="dateSingedEle"
                              @mousedown="mouseDown()"
                            >
                              <img
                                :src="
                                  require(`@/assets/img/fields_icons/${
                                    recipientSelectedColor &&
                                    defined_colors.indexOf(
                                      recipientSelectedColor
                                    ) != -1
                                      ? recipientSelectedColor
                                      : '#F754A2'
                                  }/dateSingedEle.svg`)
                                "
                                alt="icon"
                              />
                              <div class="name d-flex">
                                Date Signed<i
                                  v-if="
                                    getConfigurableDocumentDataById &&
                                    getConfigurableDocumentDataById.lock_template_fields
                                  "
                                  class="el-icon-lock"
                                ></i>
                              </div>
                            </button>
                          </draggable>

                          <draggable
                            class="dragArea-field-element"
                            @end="addPrimaryField('companyEle', 'company_name')"
                            @mouseup="mouseUp()"
                            :disabled="
                              getConfigurableDocumentDataById &&
                              getConfigurableDocumentDataById.lock_template_fields
                            "
                            v-if="
                              getAccess(
                                'documents',
                                'company',
                                'essentialFields'
                              )
                            "
                          >
                            <button
                              @mouseover="changeof('companyEle')"
                              @mouseout="changeout('companyEle')"
                              class="field-element"
                              ref="companyEle"
                              id="companyEle"
                              @mousedown="mouseDown()"
                            >
                              <img
                                :src="
                                  require(`@/assets/img/fields_icons/${
                                    recipientSelectedColor &&
                                    defined_colors.indexOf(
                                      recipientSelectedColor
                                    ) != -1
                                      ? recipientSelectedColor
                                      : '#F754A2'
                                  }/companyEle.svg`)
                                "
                                alt="icon"
                              />
                              <div class="name d-flex">
                                Company<i
                                  v-if="
                                    getConfigurableDocumentDataById &&
                                    getConfigurableDocumentDataById.lock_template_fields
                                  "
                                  class="el-icon-lock"
                                ></i>
                              </div>
                            </button>
                          </draggable>
                          <draggable
                            class="dragArea-field-element"
                            @end="addPrimaryField('titleEle', 'title')"
                            @mouseup="mouseUp()"
                            :disabled="
                              getConfigurableDocumentDataById &&
                              getConfigurableDocumentDataById.lock_template_fields
                            "
                            v-if="
                              getAccess('documents', 'title', 'essentialFields')
                            "
                          >
                            <button
                              @mouseover="changeof('titleEle')"
                              @mouseout="changeout('titleEle')"
                              class="field-element"
                              ref="titleEle"
                              id="titleEle"
                              @mousedown="mouseDown()"
                            >
                              <img
                                :src="
                                  require(`@/assets/img/fields_icons/${
                                    recipientSelectedColor &&
                                    defined_colors.indexOf(
                                      recipientSelectedColor
                                    ) != -1
                                      ? recipientSelectedColor
                                      : '#F754A2'
                                  }/titleEle.svg`)
                                "
                                alt="icon"
                              />
                              <div class="name d-flex">
                                Title<i
                                  v-if="
                                    getConfigurableDocumentDataById &&
                                    getConfigurableDocumentDataById.lock_template_fields
                                  "
                                  class="el-icon-lock"
                                ></i>
                              </div>
                            </button>
                          </draggable>
                          <draggable
                            class="dragArea-field-element"
                            @end="addSingleLineText()"
                            @mouseup="mouseUp()"
                            :disabled="
                              getConfigurableDocumentDataById &&
                              getConfigurableDocumentDataById.lock_template_fields
                            "
                            v-if="
                              getAccess(
                                'documents',
                                'singleLineText',
                                'essentialFields'
                              )
                            "
                          >
                            <button
                              @mouseover="changeof('singleLineTextEle')"
                              @mouseout="changeout('singleLineTextEle')"
                              class="field-element"
                              ref="singleLineTextEle"
                              id="singleLineTextEle"
                              @mousedown="mouseDown()"
                            >
                              <img
                                :src="
                                  require(`@/assets/img/fields_icons/${
                                    recipientSelectedColor &&
                                    defined_colors.indexOf(
                                      recipientSelectedColor
                                    ) != -1
                                      ? recipientSelectedColor
                                      : '#F754A2'
                                  }/singleLineTextEle.svg`)
                                "
                                alt="icon"
                              />
                              <div class="name d-flex">
                                Single LineText<i
                                  v-if="
                                    getConfigurableDocumentDataById &&
                                    getConfigurableDocumentDataById.lock_template_fields
                                  "
                                  class="el-icon-lock"
                                ></i>
                              </div>
                            </button>
                          </draggable>
                          <draggable
                            class="dragArea-field-element"
                            @end="addCheckbox()"
                            @mouseup="mouseUp()"
                            :disabled="
                              getConfigurableDocumentDataById &&
                              getConfigurableDocumentDataById.lock_template_fields
                            "
                            v-if="
                              getAccess(
                                'documents',
                                'checkbox',
                                'essentialFields'
                              )
                            "
                          >
                            <button
                              @mouseover="changeof('checkboxEle')"
                              @mouseout="changeout('checkboxEle')"
                              class="field-element"
                              ref="checkboxEle"
                              id="checkboxEle"
                              @mousedown="mouseDown()"
                            >
                              <img
                                :src="
                                  require(`@/assets/img/fields_icons/${
                                    recipientSelectedColor &&
                                    defined_colors.indexOf(
                                      recipientSelectedColor
                                    ) != -1
                                      ? recipientSelectedColor
                                      : '#F754A2'
                                  }/checkboxEle.svg`)
                                "
                                alt="icon"
                              />
                              <div class="name d-flex">
                                CheckBox<i
                                  v-if="
                                    getConfigurableDocumentDataById &&
                                    getConfigurableDocumentDataById.lock_template_fields
                                  "
                                  class="el-icon-lock"
                                ></i>
                              </div>
                            </button>
                          </draggable>
                          <draggable
                            class="dragArea-field-element"
                            @end="addPrimaryField('addressEle', 'address')"
                            @mouseup="mouseUp()"
                            :disabled="
                              getConfigurableDocumentDataById &&
                              getConfigurableDocumentDataById.lock_template_fields
                            "
                            v-if="
                              getAccess(
                                'documents',
                                'address',
                                'essentialFields'
                              )
                            "
                          >
                            <button
                              @mouseover="changeof('addressEle')"
                              @mouseout="changeout('addressEle')"
                              class="field-element"
                              ref="addressEle"
                              id="addressEle"
                              @mousedown="mouseDown()"
                            >
                              <img
                                :src="
                                  require(`@/assets/img/fields_icons/${
                                    recipientSelectedColor &&
                                    defined_colors.indexOf(
                                      recipientSelectedColor
                                    ) != -1
                                      ? recipientSelectedColor
                                      : '#F754A2'
                                  }/addressEle.svg`)
                                "
                                alt="icon"
                              />
                              <div class="name d-flex">
                                Address<i
                                  v-if="
                                    getConfigurableDocumentDataById &&
                                    getConfigurableDocumentDataById.lock_template_fields
                                  "
                                  class="el-icon-lock"
                                ></i>
                              </div>
                            </button>
                          </draggable>
                        </div>
                      </div>
                      <el-collapse
                        v-model="activeEntityCollapse"
                        v-if="
                          selectedUserEntity &&
                          selectedUserEntity.templates &&
                          selectedUserEntity.templates.length &&
                          getAccess('documents', 'entityFields', '')
                        "
                        @change="activeEntityCollapseMethod"
                        accordion
                      >
                        <el-collapse-item
                          v-for="(entity, i) in selectedUserEntity.templates"
                          :key="i"
                          :name="i"
                          :title="getTempTitle(entity.name)"
                        >
                          <div>
                            <div
                              v-if="entity.is_repeatable"
                              class="mb-1"
                              style="width: 100%"
                            >
                              <!-- {{ getRepeatableData(entity) }} -->
                              <el-select
                                placeholder="Select data"
                                v-model="selectedRepeatableIndex"
                                default-first-option
                              >
                                <el-option
                                  v-for="(temp, i) in getRepeatableData(entity)"
                                  :key="i"
                                  :label="temp.name"
                                  :value="temp.indexKey"
                                  >{{ temp.name }}</el-option
                                >
                              </el-select>
                            </div>
                            <div class="fieldSet__box">
                              <draggable
                                class="dragArea-field-element"
                                v-for="(field, index) in entity.fields"
                                :key="index"
                                @mouseup="mouseUp()"
                                @end="
                                  addEntityField(field, entity.is_repeatable)
                                "
                                :options="{
                                  disabled:
                                    entity.is_repeatable &&
                                    !selectedRepeatableIndex,
                                }"
                              >
                                <el-tooltip
                                  :content="field.label"
                                  effect="dark"
                                  class="tooltip"
                                  placement="top-start"
                                >
                                  <button
                                    class="field-element"
                                    :ref="`${i}_entity_${index}`"
                                    :id="`${i}_entity_${index}`"
                                    :style="
                                      entity.is_repeatable &&
                                      !selectedRepeatableIndex
                                        ? 'cursor: no-drop;'
                                        : ''
                                    "
                                    @mouseover="
                                      changeof(`${i}_entity_${index}`)
                                    "
                                    @mouseout="
                                      changeout(`${i}_entity_${index}`)
                                    "
                                    @mousedown="mouseDown()"
                                    :disabled="
                                      entity.is_repeatable &&
                                      !selectedRepeatableIndex
                                    "
                                  >
                                    <img
                                      :src="
                                        require(`@/assets/img/fields_icons/${
                                          recipientSelectedColor &&
                                          defined_colors.indexOf(
                                            recipientSelectedColor
                                          ) != -1
                                            ? recipientSelectedColor
                                            : '#F754A2'
                                        }/entityEle.svg`)
                                      "
                                      alt="icon"
                                    />
                                    <div class="name" v-if="field.label">
                                      {{ field.label | truncate(6, "...") }}
                                    </div>
                                    <div class="name" v-else>
                                      {{ field.inputType.toLowerCase() }}
                                    </div>
                                  </button>
                                </el-tooltip>
                              </draggable>
                            </div>
                          </div>
                        </el-collapse-item>
                      </el-collapse>
                    </el-collapse-item>
                    <el-collapse-item
                      title="Content Fields"
                      name="2"
                      v-if="
                        this.recipientSelection == 'SENDER' &&
                        getAccessOfFields('documents', 'contentFields')
                      "
                    >
                      <div class="fieldSet__box">
                        <draggable
                          class="dragArea-field-element"
                          @end="addMySignature()"
                          @mouseup="mouseUp()"
                          :disabled="
                            getConfigurableDocumentDataById &&
                            getConfigurableDocumentDataById.lock_template_fields
                          "
                          v-if="
                            getAccess(
                              'documents',
                              'mySignature',
                              'contentFields'
                            )
                          "
                        >
                          <button
                            @mouseover="changeof('mySignatureEle')"
                            @mouseout="changeout('mySignatureEle')"
                            class="field-element"
                            ref="mySignatureEle"
                            id="mySignatureEle"
                            @mousedown="mouseDown()"
                          >
                            <img
                              :src="
                                require(`@/assets/img/fields_icons/${
                                  recipientSelectedColor &&
                                  defined_colors.indexOf(
                                    recipientSelectedColor
                                  ) != -1
                                    ? recipientSelectedColor
                                    : '#F754A2'
                                }/mySignatureEle.svg`)
                              "
                              alt="icon"
                            />
                            <div class="name">
                              My Signature<i
                                v-if="
                                  getConfigurableDocumentDataById &&
                                  getConfigurableDocumentDataById.lock_template_fields
                                "
                                class="el-icon-lock"
                              ></i>
                            </div>
                          </button>
                        </draggable>
                        <draggable
                          class="dragArea-field-element"
                          @end="addHeading()"
                          @mouseup="mouseUp()"
                          :disabled="
                            getConfigurableDocumentDataById &&
                            getConfigurableDocumentDataById.lock_template_fields
                          "
                          v-if="
                            getAccess('documents', 'heading', 'contentFields')
                          "
                        >
                          <button
                            @mouseover="changeof('headingEle')"
                            @mouseout="changeout('headingEle')"
                            class="field-element"
                            ref="headingEle"
                            id="headingEle"
                            @mousedown="mouseDown()"
                          >
                            <img
                              :src="
                                require(`@/assets/img/fields_icons/${
                                  recipientSelectedColor &&
                                  defined_colors.indexOf(
                                    recipientSelectedColor
                                  ) != -1
                                    ? recipientSelectedColor
                                    : '#F754A2'
                                }/headingEle.svg`)
                              "
                              alt="icon"
                            />
                            <div class="name">
                              Heading<i
                                v-if="
                                  getConfigurableDocumentDataById &&
                                  getConfigurableDocumentDataById.lock_template_fields
                                "
                                class="el-icon-lock"
                              ></i>
                            </div>
                          </button>
                        </draggable>
                        <draggable
                          class="dragArea-field-element"
                          @end="addParagraph()"
                          @mouseup="mouseUp()"
                          :disabled="
                            getConfigurableDocumentDataById &&
                            getConfigurableDocumentDataById.lock_template_fields
                          "
                          v-if="
                            getAccess('documents', 'paragraph', 'contentFields')
                          "
                        >
                          <button
                            @mouseover="changeof('paragraphEle')"
                            @mouseout="changeout('paragraphEle')"
                            class="field-element"
                            ref="paragraphEle"
                            id="paragraphEle"
                            @mousedown="mouseDown()"
                          >
                            <img
                              :src="
                                require(`@/assets/img/fields_icons/${
                                  recipientSelectedColor &&
                                  defined_colors.indexOf(
                                    recipientSelectedColor
                                  ) != -1
                                    ? recipientSelectedColor
                                    : '#F754A2'
                                }/paragraphEle.svg`)
                              "
                              alt="icon"
                            />
                            <div class="name">
                              Paragraph<i
                                v-if="
                                  getConfigurableDocumentDataById &&
                                  getConfigurableDocumentDataById.lock_template_fields
                                "
                                class="el-icon-lock"
                              ></i>
                            </div>
                          </button>
                        </draggable>
                        <draggable
                          class="dragArea-field-element"
                          @end="addImage()"
                          @mouseup="mouseUp()"
                          :disabled="
                            getConfigurableDocumentDataById &&
                            getConfigurableDocumentDataById.lock_template_fields
                          "
                          v-if="
                            getAccess('documents', 'image', 'contentFields')
                          "
                        >
                          <button
                            @mouseover="changeof('tableEle')"
                            @mouseout="changeout('tableEle')"
                            class="field-element"
                            ref="tableEle"
                            id="tableEle"
                            @mousedown="mouseDown()"
                          >
                            <img
                              :src="
                                require(`@/assets/img/fields_icons/${
                                  recipientSelectedColor &&
                                  defined_colors.indexOf(
                                    recipientSelectedColor
                                  ) != -1
                                    ? recipientSelectedColor
                                    : '#F754A2'
                                }/image.svg`)
                              "
                              alt="icon"
                            />
                            <div class="name">
                              Image<i
                                v-if="
                                  getConfigurableDocumentDataById &&
                                  getConfigurableDocumentDataById.lock_template_fields
                                "
                                class="el-icon-lock"
                              ></i>
                            </div>
                          </button>
                        </draggable>
                        <draggable
                          class="dragArea-field-element"
                          @end="addMyInitial()"
                          @mouseup="mouseUp()"
                          :disabled="
                            getConfigurableDocumentDataById &&
                            getConfigurableDocumentDataById.lock_template_fields
                          "
                          v-if="
                            getAccess('documents', 'myInitial', 'contentFields')
                          "
                        >
                          <button
                            @mouseover="changeof('myInitialEle')"
                            @mouseout="changeout('myInitialEle')"
                            class="field-element"
                            ref="myInitialEle"
                            id="myInitialEle"
                            @mousedown="mouseDown()"
                          >
                            <img
                              :src="
                                require(`@/assets/img/fields_icons/${
                                  recipientSelectedColor &&
                                  defined_colors.indexOf(
                                    recipientSelectedColor
                                  ) != -1
                                    ? recipientSelectedColor
                                    : '#F754A2'
                                }/myInitialEle.svg`)
                              "
                              alt="icon"
                            />
                            <div class="name">My Initial</div>
                          </button>
                        </draggable>
                        <draggable
                          class="dragArea-field-element"
                          @end="addHorizontalLine()"
                          @mouseup="mouseUp()"
                          :disabled="
                            getConfigurableDocumentDataById &&
                            getConfigurableDocumentDataById.lock_template_fields
                          "
                          v-if="
                            getAccess(
                              'documents',
                              'horizontalLine',
                              'contentFields'
                            )
                          "
                        >
                          <button
                            @mouseover="changeof('horizontalLineEle')"
                            @mouseout="changeout('horizontalLineEle')"
                            class="field-element"
                            ref="horizontalLineEle"
                            id="horizontalLineEle"
                            @mousedown="mouseDown()"
                          >
                            <img
                              :src="
                                require(`@/assets/img/fields_icons/#F754A2/horizontalLineEle.svg`)
                              "
                              alt="icon"
                            />
                            <div class="name">
                              Horizontal Line<i
                                v-if="
                                  getConfigurableDocumentDataById &&
                                  getConfigurableDocumentDataById.lock_template_fields
                                "
                                class="el-icon-lock"
                              ></i>
                            </div>
                          </button>
                        </draggable>
                        <draggable
                          class="dragArea-field-element"
                          @end="addZseal()"
                          @mouseup="mouseUp()"
                          v-if="
                            getAccess('documents', 'zseal', 'contentFields')
                          "
                        >
                          <button
                            @mouseover="changeof('zsealEle')"
                            @mouseout="changeout('zsealEle')"
                            class="field-element"
                            ref="zsealEle"
                            id="zsealEle"
                            @mousedown="mouseDown()"
                          >
                            <img
                              :src="
                                require(`@/assets/img/fields_icons/${
                                  recipientSelectedColor &&
                                  defined_colors.indexOf(
                                    recipientSelectedColor
                                  ) != -1
                                    ? recipientSelectedColor
                                    : '#F754A2'
                                }/currencyEle.svg`)
                              "
                              alt="icon"
                            />
                            <div class="name">Z-Seal</div>
                          </button>
                        </draggable>
                        <draggable
                          class="dragArea-field-element"
                          @end="addSingleLineContent()"
                          @mouseup="mouseUp()"
                          :disabled="
                            getConfigurableDocumentDataById &&
                            getConfigurableDocumentDataById.lock_template_fields
                          "
                          v-if="
                            getAccess(
                              'documents',
                              'singleLineContentEle',
                              'contentFields'
                            )
                          "
                        >
                          <button
                            @mouseover="changeof('singleLineContentEle')"
                            @mouseout="changeout('singleLineContentEle')"
                            class="field-element"
                            ref="singleLineContentEle"
                            id="singleLineContentEle"
                            @mousedown="mouseDown()"
                          >
                            <img
                              :src="
                                require(`@/assets/img/fields_icons/#F754A2/horizontalLineEle.svg`)
                              "
                              alt="icon"
                            />
                            <div class="name">
                              Single LineContent<i
                                v-if="
                                  getConfigurableDocumentDataById &&
                                  getConfigurableDocumentDataById.lock_template_fields
                                "
                                class="el-icon-lock"
                              ></i>
                            </div>
                          </button>
                        </draggable>
                      </div>
                    </el-collapse-item>

                    <el-collapse-item
                      title="Fillable Fields For"
                      name="3"
                      v-if="getAccessOfFields('documents', 'fillableFields')"
                    >
                      <div class="fieldSet__box">
                        <draggable
                          class="dragArea-field-element"
                          @end="addAttachment()"
                          @mouseup="mouseUp()"
                          :disabled="
                            getConfigurableDocumentDataById &&
                            getConfigurableDocumentDataById.lock_template_fields
                          "
                          v-if="
                            getAccess(
                              'documents',
                              'requestDoc',
                              'fillableFields'
                            )
                          "
                        >
                          <button
                            @mouseover="changeof('requestEle')"
                            @mouseout="changeout('requestEle')"
                            class="field-element"
                            ref="requestEle"
                            id="requestEle"
                            @mousedown="mouseDown()"
                          >
                            <img
                              :src="
                                require(`@/assets/img/fields_icons/${
                                  recipientSelectedColor &&
                                  defined_colors.indexOf(
                                    recipientSelectedColor
                                  ) != -1
                                    ? recipientSelectedColor
                                    : '#F754A2'
                                }/initialEle.svg`)
                              "
                              alt="icon"
                            />
                            <div class="name d-flex">
                              Request Document<i
                                v-if="
                                  getConfigurableDocumentDataById &&
                                  getConfigurableDocumentDataById.lock_template_fields
                                "
                                class="el-icon-lock"
                              ></i>
                            </div>
                          </button>
                        </draggable>
                        <draggable
                          class="dragArea-field-element"
                          @end="addDateElement()"
                          @mouseup="mouseUp()"
                          :disabled="
                            getConfigurableDocumentDataById &&
                            getConfigurableDocumentDataById.lock_template_fields
                          "
                          v-if="
                            getAccess('documents', 'date', 'fillableFields')
                          "
                        >
                          <button
                            @mouseover="changeof('dateEle')"
                            @mouseout="changeout('dateEle')"
                            class="field-element"
                            ref="dateEle"
                            id="dateEle"
                            @mousedown="mouseDown()"
                          >
                            <img
                              :src="
                                require(`@/assets/img/fields_icons/${
                                  recipientSelectedColor &&
                                  defined_colors.indexOf(
                                    recipientSelectedColor
                                  ) != -1
                                    ? recipientSelectedColor
                                    : '#F754A2'
                                }/dateEle.svg`)
                              "
                              alt="icon"
                            />
                            <div class="name d-flex">
                              Date<i
                                v-if="
                                  getConfigurableDocumentDataById &&
                                  getConfigurableDocumentDataById.lock_template_fields
                                "
                                class="el-icon-lock"
                              ></i>
                            </div>
                          </button>
                        </draggable>

                        <draggable
                          class="dragArea-field-element"
                          @end="addDropDown()"
                          @mouseup="mouseUp()"
                          :disabled="
                            getConfigurableDocumentDataById &&
                            getConfigurableDocumentDataById.lock_template_fields
                          "
                          v-if="
                            getAccess('documents', 'select', 'fillableFields')
                          "
                        >
                          <button
                            @mouseover="changeof('dropdownEle')"
                            @mouseout="changeout('dropdownEle')"
                            class="field-element"
                            ref="dropdownEle"
                            id="dropdownEle"
                            @mousedown="mouseDown()"
                          >
                            <img
                              :src="
                                require(`@/assets/img/fields_icons/${
                                  recipientSelectedColor &&
                                  defined_colors.indexOf(
                                    recipientSelectedColor
                                  ) != -1
                                    ? recipientSelectedColor
                                    : '#F754A2'
                                }/dropdownEle.svg`)
                              "
                              alt="icon"
                            />
                            <div class="name d-flex">
                              Select<i
                                v-if="
                                  getConfigurableDocumentDataById &&
                                  getConfigurableDocumentDataById.lock_template_fields
                                "
                                class="el-icon-lock"
                              ></i>
                            </div>
                          </button>
                        </draggable>

                        <draggable
                          class="dragArea-field-element"
                          @end="addNumber()"
                          @mouseup="mouseUp()"
                          :disabled="
                            getConfigurableDocumentDataById &&
                            getConfigurableDocumentDataById.lock_template_fields
                          "
                          v-if="
                            getAccess('documents', 'number', 'fillableFields')
                          "
                        >
                          <button
                            @mouseover="changeof('numberEle')"
                            @mouseout="changeout('numberEle')"
                            class="field-element"
                            ref="numberEle"
                            id="numberEle"
                            @mousedown="mouseDown()"
                          >
                            <img
                              :src="
                                require(`@/assets/img/fields_icons/${
                                  recipientSelectedColor &&
                                  defined_colors.indexOf(
                                    recipientSelectedColor
                                  ) != -1
                                    ? recipientSelectedColor
                                    : '#F754A2'
                                }/numberEle.svg`)
                              "
                              alt="icon"
                            />
                            <div class="name d-flex">
                              Number<i
                                v-if="
                                  getConfigurableDocumentDataById &&
                                  getConfigurableDocumentDataById.lock_template_fields
                                "
                                class="el-icon-lock"
                              ></i>
                            </div>
                          </button>
                        </draggable>
                        <draggable
                          class="dragArea-field-element"
                          @end="addYesOrNo()"
                          @mouseup="mouseUp()"
                          :disabled="
                            getConfigurableDocumentDataById &&
                            getConfigurableDocumentDataById.lock_template_fields
                          "
                          v-if="
                            getAccess('documents', 'yerOrNo', 'fillableFields')
                          "
                        >
                          <button
                            @mouseover="changeof('yesOrNoEle')"
                            @mouseout="changeout('yesOrNoEle')"
                            class="field-element"
                            ref="yesOrNoEle"
                            id="yesOrNoEle"
                            @mousedown="mouseDown()"
                          >
                            <img
                              :src="
                                require(`@/assets/img/fields_icons/${
                                  recipientSelectedColor &&
                                  defined_colors.indexOf(
                                    recipientSelectedColor
                                  ) != -1
                                    ? recipientSelectedColor
                                    : '#F754A2'
                                }/yesOrNoEle.svg`)
                              "
                              alt="icon"
                            />
                            <div class="name d-flex">
                              Yes Or No<i
                                v-if="
                                  getConfigurableDocumentDataById &&
                                  getConfigurableDocumentDataById.lock_template_fields
                                "
                                class="el-icon-lock"
                              ></i>
                            </div>
                          </button>
                        </draggable>
                        <draggable
                          class="dragArea-field-element"
                          @end="addMultilineText()"
                          @mouseup="mouseUp()"
                          :disabled="
                            getConfigurableDocumentDataById &&
                            getConfigurableDocumentDataById.lock_template_fields
                          "
                          v-if="
                            getAccess(
                              'documents',
                              'multiLineText',
                              'fillableFields'
                            )
                          "
                        >
                          <button
                            @mouseover="changeof('multiLineTextEle')"
                            @mouseout="changeout('multiLineTextEle')"
                            class="field-element"
                            ref="multiLineTextEle"
                            id="multiLineTextEle"
                            @mousedown="mouseDown()"
                          >
                            <img
                              :src="
                                require(`@/assets/img/fields_icons/${
                                  recipientSelectedColor &&
                                  defined_colors.indexOf(
                                    recipientSelectedColor
                                  ) != -1
                                    ? recipientSelectedColor
                                    : '#F754A2'
                                }/multiLineTextEle.svg`)
                              "
                              alt="icon"
                            />
                            <div class="name d-flex">
                              Multi LineText<i
                                v-if="
                                  getConfigurableDocumentDataById &&
                                  getConfigurableDocumentDataById.lock_template_fields
                                "
                                class="el-icon-lock"
                              ></i>
                            </div>
                          </button>
                        </draggable>

                        <draggable
                          class="dragArea-field-element"
                          @end="addMultiSelect()"
                          @mouseup="mouseUp()"
                          :disabled="
                            getConfigurableDocumentDataById &&
                            getConfigurableDocumentDataById.lock_template_fields
                          "
                          v-if="
                            getAccess(
                              'documents',
                              'multiSelect',
                              'fillableFields'
                            )
                          "
                        >
                          <button
                            @mouseover="changeof('multiSlectedEle')"
                            @mouseout="changeout('multiSlectedEle')"
                            class="field-element"
                            ref="multiSlectedEle"
                            id="multiSlectedEle"
                            @mousedown="mouseDown()"
                          >
                            <img
                              :src="
                                require(`@/assets/img/fields_icons/${
                                  recipientSelectedColor &&
                                  defined_colors.indexOf(
                                    recipientSelectedColor
                                  ) != -1
                                    ? recipientSelectedColor
                                    : '#F754A2'
                                }/multiSlectedEle.svg`)
                              "
                              alt="icon"
                            />
                            <div class="name d-flex">
                              Multiple Select<i
                                v-if="
                                  getConfigurableDocumentDataById &&
                                  getConfigurableDocumentDataById.lock_template_fields
                                "
                                class="el-icon-lock"
                              ></i>
                            </div>
                          </button>
                        </draggable>
                        <draggable
                          class="dragArea-field-element"
                          @end="addFormula()"
                          @mouseup="mouseUp()"
                          :disabled="
                            getConfigurableDocumentDataById &&
                            getConfigurableDocumentDataById.lock_template_fields
                          "
                          v-if="
                            getAccess('documents', 'formula', 'fillableFields')
                          "
                        >
                          <button
                            @mouseover="changeof('formulaEle')"
                            @mouseout="changeout('formulaEle')"
                            class="field-element"
                            ref="formulaEle"
                            id="formulaEle"
                            @mousedown="mouseDown()"
                          >
                            <img
                              :src="
                                require(`@/assets/img/fields_icons/${
                                  recipientSelectedColor &&
                                  defined_colors.indexOf(
                                    recipientSelectedColor
                                  ) != -1
                                    ? recipientSelectedColor
                                    : '#F754A2'
                                }/formulaEle.svg`)
                              "
                              alt="icon"
                            />
                            <div class="name d-flex">
                              Formula<i
                                v-if="
                                  getConfigurableDocumentDataById &&
                                  getConfigurableDocumentDataById.lock_template_fields
                                "
                                class="el-icon-lock"
                              ></i>
                            </div>
                          </button>
                        </draggable>
                        <draggable
                          class="dragArea-field-element"
                          @end="addCurrency()"
                          @mouseup="mouseUp()"
                          :disabled="
                            getConfigurableDocumentDataById &&
                            getConfigurableDocumentDataById.lock_template_fields
                          "
                          v-if="
                            getAccess('documents', 'currency', 'fillableFields')
                          "
                        >
                          <button
                            @mouseover="changeof('currencyEle')"
                            @mouseout="changeout('currencyEle')"
                            class="field-element"
                            ref="currencyEle"
                            id="currencyEle"
                            @mousedown="mouseDown()"
                            :disabled="
                              getConfigurableDocumentDataById &&
                              getConfigurableDocumentDataById.lock_template_fields
                            "
                          >
                            <img
                              :src="
                                require(`@/assets/img/fields_icons/${
                                  recipientSelectedColor &&
                                  defined_colors.indexOf(
                                    recipientSelectedColor
                                  ) != -1
                                    ? recipientSelectedColor
                                    : '#F754A2'
                                }/currencyEle.svg`)
                              "
                              alt="icon"
                            />
                            <div class="name d-flex">
                              Currency<i
                                v-if="
                                  getConfigurableDocumentDataById &&
                                  getConfigurableDocumentDataById.lock_template_fields
                                "
                                class="el-icon-lock"
                              ></i>
                            </div>
                          </button>
                        </draggable>
                        <draggable
                          class="dragArea-field-element"
                          @end="addDateTime()"
                          @mouseup="mouseUp()"
                          :disabled="
                            getConfigurableDocumentDataById &&
                            getConfigurableDocumentDataById.lock_template_fields
                          "
                          v-if="
                            getAccess('documents', 'dateTime', 'fillableFields')
                          "
                        >
                          <button
                            @mouseover="changeof('addDateTimeEle')"
                            @mouseout="changeout('addDateTimeEle')"
                            class="field-element"
                            ref="addDateTimeEle"
                            id="addDateTimeEle"
                            @mousedown="mouseDown()"
                          >
                            <img
                              :src="
                                require(`@/assets/img/fields_icons/${
                                  recipientSelectedColor &&
                                  defined_colors.indexOf(
                                    recipientSelectedColor
                                  ) != -1
                                    ? recipientSelectedColor
                                    : '#F754A2'
                                }/DateTime.svg`)
                              "
                              alt="icon"
                            />
                            <div class="name d-flex">
                              Date Time<i
                                v-if="
                                  getConfigurableDocumentDataById &&
                                  getConfigurableDocumentDataById.lock_template_fields
                                "
                                class="el-icon-lock"
                              ></i>
                            </div>
                          </button>
                        </draggable>
                        <draggable
                          class="dragArea-field-element"
                          @end="addDateTimeRange()"
                          @mouseup="mouseUp()"
                          :disabled="
                            getConfigurableDocumentDataById &&
                            getConfigurableDocumentDataById.lock_template_fields
                          "
                          v-if="
                            getAccess(
                              'documents',
                              'dateTimeRange',
                              'fillableFields'
                            )
                          "
                        >
                          <button
                            @mouseover="changeof('addDateTimeRangeEle')"
                            @mouseout="changeout('addDateTimeRangeEle')"
                            class="field-element"
                            ref="addDateTimeRangeEle"
                            id="addDateTimeRangeEle"
                            @mousedown="mouseDown()"
                          >
                            <img
                              :src="
                                require(`@/assets/img/fields_icons/${
                                  recipientSelectedColor &&
                                  defined_colors.indexOf(
                                    recipientSelectedColor
                                  ) != -1
                                    ? recipientSelectedColor
                                    : '#F754A2'
                                }/DateTimeRange.svg`)
                              "
                              alt="icon"
                            />
                            <div class="name d-flex">
                              Date TimeRange<i
                                v-if="
                                  getConfigurableDocumentDataById &&
                                  getConfigurableDocumentDataById.lock_template_fields
                                "
                                class="el-icon-lock"
                              ></i>
                            </div>
                          </button>
                        </draggable>
                        <draggable
                          class="dragArea-field-element"
                          @end="addDateRange()"
                          @mouseup="mouseUp()"
                          :disabled="
                            getConfigurableDocumentDataById &&
                            getConfigurableDocumentDataById.lock_template_fields
                          "
                          v-if="
                            getAccess(
                              'documents',
                              'dateRange',
                              'fillableFields'
                            )
                          "
                        >
                          <button
                            @mouseover="changeof('addDateRangeEle')"
                            @mouseout="changeout('addDateRangeEle')"
                            class="field-element"
                            ref="addDateRangeEle"
                            id="addDateRangeEle"
                            @mousedown="mouseDown()"
                          >
                            <img
                              :src="
                                require(`@/assets/img/fields_icons/${
                                  recipientSelectedColor &&
                                  defined_colors.indexOf(
                                    recipientSelectedColor
                                  ) != -1
                                    ? recipientSelectedColor
                                    : '#F754A2'
                                }/DateRange.svg`)
                              "
                              alt="icon"
                            />
                            <div class="name d-flex">
                              Date Range<i
                                v-if="
                                  getConfigurableDocumentDataById &&
                                  getConfigurableDocumentDataById.lock_template_fields
                                "
                                class="el-icon-lock"
                              ></i>
                            </div>
                          </button>
                        </draggable>

                        <draggable
                          class="dragArea-field-element"
                          @end="addTime()"
                          @mouseup="mouseUp()"
                          :disabled="
                            getConfigurableDocumentDataById &&
                            getConfigurableDocumentDataById.lock_template_fields
                          "
                          v-if="
                            getAccess('documents', 'time', 'fillableFields')
                          "
                        >
                          <button
                            @mouseover="changeof('addTimeEle')"
                            @mouseout="changeout('addTimeEle')"
                            class="field-element"
                            ref="addTimeEle"
                            id="addTimeEle"
                            @mousedown="mouseDown()"
                          >
                            <img
                              :src="
                                require(`@/assets/img/fields_icons/${
                                  recipientSelectedColor &&
                                  defined_colors.indexOf(
                                    recipientSelectedColor
                                  ) != -1
                                    ? recipientSelectedColor
                                    : '#F754A2'
                                }/Time.svg`)
                              "
                              alt="icon"
                            />
                            <div class="name d-flex">
                              Time<i
                                v-if="
                                  getConfigurableDocumentDataById &&
                                  getConfigurableDocumentDataById.lock_template_fields
                                "
                                class="el-icon-lock"
                              ></i>
                            </div>
                          </button>
                        </draggable>
                        <!-- list field -->
                        <draggable
                          class="dragArea-field-element"
                          @end="addList()"
                          @mouseup="mouseUp()"
                          :disabled="
                            getConfigurableDocumentDataById &&
                            getConfigurableDocumentDataById.lock_template_fields
                          "
                          v-if="
                            getAccess('documents', 'list', 'fillableFields')
                          "
                        >
                          <button
                            @mouseover="changeof('addListEle')"
                            @mouseout="changeout('addListEle')"
                            class="field-element"
                            ref="addListEle"
                            id="addListEle"
                            @mousedown="mouseDown()"
                          >
                            <img
                              :src="
                                require(`@/assets/img/fields_icons/${
                                  recipientSelectedColor &&
                                  defined_colors.indexOf(
                                    recipientSelectedColor
                                  ) != -1
                                    ? recipientSelectedColor
                                    : '#F754A2'
                                }/List.svg`)
                              "
                              alt="icon"
                            />
                            <div class="name d-flex">
                              List<i
                                v-if="
                                  getConfigurableDocumentDataById &&
                                  getConfigurableDocumentDataById.lock_template_fields
                                "
                                class="el-icon-lock"
                              ></i>
                            </div>
                          </button>
                        </draggable>
                        <draggable
                          class="dragArea-field-element"
                          @end="addTable()"
                          @mouseup="mouseUp()"
                          v-if="
                            getAccess(
                              'documents',
                              'dataTable',
                              'fillableFields'
                            )
                          "
                        >
                          <button
                            @mouseover="changeof('addTableEle')"
                            @mouseout="changeout('addTableEle')"
                            class="field-element"
                            ref="addTableEle"
                            id="addTableEle"
                            @mousedown="mouseDown()"
                          >
                            <img
                              :src="
                                require(`@/assets/img/fields_icons/${
                                  recipientSelectedColor &&
                                  defined_colors.indexOf(
                                    recipientSelectedColor
                                  ) != -1
                                    ? recipientSelectedColor
                                    : '#F754A2'
                                }/initialEle.svg`)
                              "
                              alt="icon"
                            />
                            <div class="name">Table</div>
                          </button>
                        </draggable>
                        <draggable
                          class="dragArea-field-element"
                          @end="addTimeRange()"
                          @mouseup="mouseUp()"
                          :disabled="
                            getConfigurableDocumentDataById &&
                            getConfigurableDocumentDataById.lock_template_fields
                          "
                          v-if="
                            getAccess(
                              'documents',
                              'timeRange',
                              'fillableFields'
                            )
                          "
                        >
                          <button
                            @mouseover="changeof('addTimeRangeEle')"
                            @mouseout="changeout('addTimeRangeEle')"
                            class="field-element"
                            ref="addTimeRangeEle"
                            id="addTimeRangeEle"
                            @mousedown="mouseDown()"
                          >
                            <img
                              :src="
                                require(`@/assets/img/fields_icons/${
                                  recipientSelectedColor &&
                                  defined_colors.indexOf(
                                    recipientSelectedColor
                                  ) != -1
                                    ? recipientSelectedColor
                                    : '#F754A2'
                                }/TimeRange.svg`)
                              "
                              alt="icon"
                            />
                            <div class="name d-flex">
                              Time Range<i
                                v-if="
                                  getConfigurableDocumentDataById &&
                                  getConfigurableDocumentDataById.lock_template_fields
                                "
                                class="el-icon-lock"
                              ></i>
                            </div>
                          </button>
                        </draggable>
                        <draggable
                          class="dragArea-field-element"
                          @end="addFixedTime()"
                          @mouseup="mouseUp()"
                          :disabled="
                            getConfigurableDocumentDataById &&
                            getConfigurableDocumentDataById.lock_template_fields
                          "
                          v-if="
                            getAccess(
                              'documents',
                              'fixedTime',
                              'fillableFields'
                            )
                          "
                        >
                          <button
                            @mouseover="changeof('addFixedTimeEle')"
                            @mouseout="changeout('addFixedTimeEle')"
                            class="field-element"
                            ref="addFixedTimeEle"
                            id="addFixedTimeEle"
                            @mousedown="mouseDown()"
                          >
                            <img
                              :src="
                                require(`@/assets/img/fields_icons/${
                                  recipientSelectedColor &&
                                  defined_colors.indexOf(
                                    recipientSelectedColor
                                  ) != -1
                                    ? recipientSelectedColor
                                    : '#F754A2'
                                }/FixedTime.svg`)
                              "
                              alt="icon"
                            />
                            <div class="name d-flex">
                              Fixed Time<i
                                v-if="
                                  getConfigurableDocumentDataById &&
                                  getConfigurableDocumentDataById.lock_template_fields
                                "
                                class="el-icon-lock"
                              ></i>
                            </div>
                          </button>
                        </draggable>
                        <draggable
                          class="dragArea-field-element"
                          @end="addWeekdays()"
                          @mouseup="mouseUp()"
                          :disabled="
                            getConfigurableDocumentDataById &&
                            getConfigurableDocumentDataById.lock_template_fields
                          "
                          v-if="
                            getAccess('documents', 'weekDays', 'fillableFields')
                          "
                        >
                          <button
                            @mouseover="changeof('weekdaysEle')"
                            @mouseout="changeout('weekdaysEle')"
                            class="field-element"
                            ref="weekdaysEle"
                            id="weekdaysEle"
                            @mousedown="mouseDown()"
                          >
                            <img
                              :src="
                                require(`@/assets/img/fields_icons/${
                                  recipientSelectedColor &&
                                  defined_colors.indexOf(
                                    recipientSelectedColor
                                  ) != -1
                                    ? recipientSelectedColor
                                    : '#F754A2'
                                }/Weekdays.svg`)
                              "
                              alt="icon"
                            />
                            <div class="name d-flex">
                              Weekdays
                              <i
                                v-if="
                                  getConfigurableDocumentDataById &&
                                  getConfigurableDocumentDataById.lock_template_fields
                                "
                                class="el-icon-lock"
                              ></i>
                            </div>
                          </button>
                        </draggable>
                      </div>
                    </el-collapse-item>
                    <el-collapse-item
                      title="Other Primary Fields"
                      name="4"
                      v-if="
                        getAccessOfFields('documents', 'otherPrimaryFields')
                      "
                    >
                      <div class="fieldSet__box">
                        <draggable
                          class="dragArea-field-element"
                          @end="addPrimaryField('firstNameEle', 'first_name')"
                          @mouseup="mouseUp()"
                          :disabled="
                            getConfigurableDocumentDataById &&
                            getConfigurableDocumentDataById.lock_template_fields
                          "
                          v-if="
                            getAccess(
                              'documents',
                              'firstName',
                              'otherPrimaryFields'
                            )
                          "
                        >
                          <button
                            @mouseover="changeof('firstNameEle')"
                            @mouseout="changeout('firstNameEle')"
                            class="field-element"
                            ref="firstNameEle"
                            id="firstNameEle"
                            @mousedown="mouseDown()"
                          >
                            <img
                              :src="
                                require(`@/assets/img/fields_icons/${
                                  recipientSelectedColor &&
                                  defined_colors.indexOf(
                                    recipientSelectedColor
                                  ) != -1
                                    ? recipientSelectedColor
                                    : '#F754A2'
                                }/firstNameEle.svg`)
                              "
                              alt="icon"
                            />
                            <div class="name">
                              First Name<i
                                v-if="
                                  getConfigurableDocumentDataById &&
                                  getConfigurableDocumentDataById.lock_template_fields
                                "
                                class="el-icon-lock"
                              ></i>
                            </div>
                          </button>
                        </draggable>
                        <draggable
                          class="dragArea-field-element"
                          @end="addPrimaryField('lastNameEle', 'last_name')"
                          @mouseup="mouseUp()"
                          :disabled="
                            getConfigurableDocumentDataById &&
                            getConfigurableDocumentDataById.lock_template_fields
                          "
                          v-if="
                            getAccess(
                              'documents',
                              'lastName',
                              'otherPrimaryFields'
                            )
                          "
                        >
                          <button
                            @mouseover="changeof('lastNameEle')"
                            @mouseout="changeout('lastNameEle')"
                            class="field-element"
                            ref="lastNameEle"
                            id="lastNameEle"
                            @mousedown="mouseDown()"
                          >
                            <img
                              :src="
                                require(`@/assets/img/fields_icons/${
                                  recipientSelectedColor &&
                                  defined_colors.indexOf(
                                    recipientSelectedColor
                                  ) != -1
                                    ? recipientSelectedColor
                                    : '#F754A2'
                                }/lastNameEle.svg`)
                              "
                              alt="icon"
                            />
                            <div class="name">
                              Last Name<i
                                v-if="
                                  getConfigurableDocumentDataById &&
                                  getConfigurableDocumentDataById.lock_template_fields
                                "
                                class="el-icon-lock"
                              ></i>
                            </div>
                          </button>
                        </draggable>
                        <draggable
                          class="dragArea-field-element"
                          @end="addPrimaryField('phoneEle', 'phone')"
                          @mouseup="mouseUp()"
                          :disabled="
                            getConfigurableDocumentDataById &&
                            getConfigurableDocumentDataById.lock_template_fields
                          "
                          v-if="
                            getAccess(
                              'documents',
                              'phoneNo',
                              'otherPrimaryFields'
                            )
                          "
                        >
                          <button
                            @mouseover="changeof('phoneEle')"
                            @mouseout="changeout('phoneEle')"
                            class="field-element"
                            ref="phoneEle"
                            id="phoneEle"
                            @mousedown="mouseDown()"
                          >
                            <img
                              :src="
                                require(`@/assets/img/fields_icons/${
                                  recipientSelectedColor &&
                                  defined_colors.indexOf(
                                    recipientSelectedColor
                                  ) != -1
                                    ? recipientSelectedColor
                                    : '#F754A2'
                                }/phoneEle.svg`)
                              "
                              alt="icon"
                            />
                            <div class="name">
                              Phone No<i
                                v-if="
                                  getConfigurableDocumentDataById &&
                                  getConfigurableDocumentDataById.lock_template_fields
                                "
                                class="el-icon-lock"
                              ></i>
                            </div>
                          </button>
                        </draggable>
                        <draggable
                          class="dragArea-field-element"
                          @end="addPrimaryField('emailEle', 'email')"
                          @mouseup="mouseUp()"
                          :disabled="
                            getConfigurableDocumentDataById &&
                            getConfigurableDocumentDataById.lock_template_fields
                          "
                          v-if="
                            getAccess(
                              'documents',
                              'emailId',
                              'otherPrimaryFields'
                            )
                          "
                        >
                          <button
                            @mouseover="changeof('emailEle')"
                            @mouseout="changeout('emailEle')"
                            class="field-element"
                            ref="emailEle"
                            id="emailEle"
                            @mousedown="mouseDown()"
                          >
                            <img
                              :src="
                                require(`@/assets/img/fields_icons/${
                                  recipientSelectedColor &&
                                  defined_colors.indexOf(
                                    recipientSelectedColor
                                  ) != -1
                                    ? recipientSelectedColor
                                    : '#F754A2'
                                }/emailEle.svg`)
                              "
                              alt="icon"
                            />
                            <div class="name">
                              Email Id<i
                                v-if="
                                  getConfigurableDocumentDataById &&
                                  getConfigurableDocumentDataById.lock_template_fields
                                "
                                class="el-icon-lock"
                              ></i>
                            </div>
                          </button>
                        </draggable>
                      </div>
                    </el-collapse-item>
                  </el-collapse>
                </el-scrollbar>
              </div>

              <el-button
                type="text"
                v-if="configurableDocumentId"
                @click="openDocumentExpirationSettings()"
              >
                <i class="el-icon-setting"></i>Expiration Settings
              </el-button>
            </div>
            <!-- </el-col> -->
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="Document Name"
      :visible.sync="generatingDocumentName"
      :show-close="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :width="getIsMobile ? '100%' : '30%'"
      v-draggable
      :resizable="true"
    >
      <label>Please Enter Document Name</label>
      <el-input v-model="generatedName"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="deleteElement()">Delete Field</el-button>
        <el-button
          type="primary"
          :loading="replaceDocumentLoading"
          @click="saveAndGeneratePdf"
          >Save</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="Edit Horizontal Line"
      :visible.sync="dialogFormVisible"
      :show-close="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <HorizontalLine
        :field="selectedItem"
        :isFromDocument="true"
        :form="documentData"
        :is-view="false"
        :allFields="elements"
      ></HorizontalLine>
      <el-checkbox v-model="selectedItem.vertical_line"
        >Use as vertical line</el-checkbox
      >
      <span slot="footer" class="dialog-footer">
        <el-button @click="deleteElement()">Delete Field</el-button>
        <el-button type="primary" @click="save(false)">Save</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :visible.sync="addItemDataDialogVisible"
      top="5vh"
      :width="
        getIsMobile
          ? '100%'
          : selectedItem && selectedItem.type == 'FORMULA'
          ? '55%'
          : '39%'
      "
      :before-close="resetImgData"
      title="Field Properties"
      class="fields-dialog"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      v-draggable
      :resizable="true"
    >
      <el-scrollbar wrap-style="max-height: 60vh;" ref="configureScroll">
        <Formula
          v-if="addItemDataDialogVisible && selectedItem.type == 'FORMULA'"
          :field="selectedItem"
          :selfTemplate="{}"
          :templatesData="elements"
          :selfTemplateId="{}"
          :isFromDocument="true"
        ></Formula>
        <ConfigureDOcumentField
          v-else-if="
            addItemDataDialogVisible &&
            selectedItem &&
            selectedItem.type != 'FORMULA'
          "
          :selectedItem="selectedItem"
          :elements="elements"
          :documentRecipientsList="documentRecipientsList"
          :getActiveWorkspace="getActiveWorkspace"
          :getAuthenticatedUser="getAuthenticatedUser"
          :allUsersEntityData="allUsersEntityData"
          :getCompanyDetails="getCompanyDetails"
          :getEntityDataByQuery="getEntityDataByQuery"
          :getTemplatesData="
            getTemplatesData && getTemplatesData.length ? getTemplatesData : []
          "
          :allTemplatesData="allTemplatesData"
          :isDraft="false"
          :isTemplate="true"
          v-on:fillImageData="fillImageData"
          :globalVariablesData="globalVariablesData"
          :id="selectedItem.key"
          :isCheckBoxGroup="selectedItem.is_check_box_group"
          :checkBoxGrpObj="this.checkBoxGrpObj"
          :checkBoxGroupArray="this.checkBoxGroupArray"
          :currentGroupIndex="this.current_group_index"
          ref="configureField"
          v-on:openAddNew="() => openAddGroupDialog()"
          :filesData="filesData"
        ></ConfigureDOcumentField>
      </el-scrollbar>

      <span slot="footer" class="dialog-footer" style="margin-bottom: -10px">
        <el-divider direction="horizontal"></el-divider>
        <el-button @click="deleteElement()">Delete Field</el-button>
        <el-button
          type="primary"
          @click="save(false)"
          :disabled="checkDisabled()"
          >Save</el-button
        >
      </span>
    </el-dialog>

    <el-dialog
      :visible.sync="previewVisible"
      top="5vh"
      width="67%"
      title="Preview"
      :destroy-on-close="false"
    >
      <div class="form-holder-preview" ref="page" v-if="previewVisible">
        <div class="form-image-holder-preview">
          <img
            v-if="this.backgroundImage"
            :src="this.backgroundImage"
            class="page-image"
          />
        </div>
        <div class="form-fields-holder-preview">
          <div v-for="(item, index) of this.elements" :key="index">
            <img
              v-if="item.type == 'logo'"
              :src="getLogoUrl"
              :style="getStyle(item)"
            />

            <img
              :height="item.height"
              :width="item.width"
              v-if="item.type == 'my-signature'"
              :src="item.source"
              :style="getStyle(item)"
            />

            <el-input
              :style="getStyle(item)"
              type="text"
              :placeholder="item.placeholder"
              v-if="item.type == 'SINGLE_LINE_TEXT'"
              v-model="item.value"
              :disabled="checkFieldAllow(item)"
            ></el-input>

            <el-input
              :style="getStyle(item)"
              type="text"
              :placeholder="item.placeholder"
              v-if="item.type == 'NUMBER'"
              v-model="item.value"
              :disabled="checkFieldAllow(item)"
            ></el-input>

            <el-date-picker
              :height="item.height"
              :width="item.width"
              :style="getStyle(item)"
              type="date"
              :placeholder="item.placeholder"
              v-if="item.type == 'DATE'"
              v-model="item.value"
              :disabled="checkFieldAllow(item)"
            ></el-date-picker>
            <el-date-picker
              :height="item.height"
              :width="item.width"
              :style="getStyle(item)"
              type="datetime"
              prefix-icon=""
              :placeholder="item.placeholder"
              v-if="item.type == 'date_time'"
              v-model="item.value"
              :disabled="checkFieldAllow(item)"
            ></el-date-picker>

            <el-date-picker
              :height="item.height"
              :width="item.width"
              :style="getStyle(item)"
              type="datetimerange"
              prefix-icon=""
              :placeholder="item.placeholder"
              v-if="item.type == 'date_time_range'"
              v-model="item.value"
              :disabled="checkFieldAllow(item)"
            ></el-date-picker>
            <el-date-picker
              :height="item.height"
              :width="item.width"
              :style="getStyle(item)"
              type="daterange"
              prefix-icon=""
              :placeholder="item.placeholder"
              v-if="item.type == 'date_range'"
              v-model="item.value"
              :disabled="checkFieldAllow(item)"
            ></el-date-picker>

            <el-time-picker
              :height="item.height"
              :width="item.width"
              :style="getStyle(item)"
              type="time"
              prefix-icon=""
              :placeholder="item.placeholder"
              v-if="item.type == 'time'"
              v-model="item.value"
              :disabled="checkFieldAllow(item)"
            ></el-time-picker>
            <el-time-picker
              :height="item.height"
              :width="item.width"
              :style="getStyle(item)"
              type="timerange"
              prefix-icon=""
              :placeholder="item.placeholder"
              v-if="item.type == 'time_range'"
              v-model="item.value"
              :disabled="checkFieldAllow(item)"
            ></el-time-picker>
            <el-time-picker
              :height="item.height"
              :width="item.width"
              :style="getStyle(item)"
              type="fixedtime"
              prefix-icon=""
              :placeholder="item.placeholder"
              v-if="item.type == 'fixed_time'"
              v-model="item.value"
              :disabled="checkFieldAllow(item)"
            ></el-time-picker>
            <el-select
              :height="item.height"
              :width="item.width"
              :style="getStyle(item)"
              v-if="item.type == 'WEEKDAYS'"
              v-model="item.value"
              :placeholder="item.placeholder"
              :disabled="checkFieldAllow(item)"
            >
              <el-option
                v-for="(option, index) of item.options"
                :key="index"
                :value="option"
                :label="option"
              ></el-option>
            </el-select>
            <!-- <el-radio
              :height="item.height"
              :width="item.width"
              :style="getStyle(item)"
              type="Radio"
              prefix-icon=""
              :placeholder="item.placeholder"
              v-if="item.type == 'Radio'"
              v-model="item.value"
              :disabled="checkFieldAllow(item)"
            ></el-radio> -->
            <el-select
              :height="item.height"
              :width="item.width"
              :style="getStyle(item)"
              v-if="item.type == 'SELECT'"
              v-model="item.value"
              :placeholder="item.placeholder"
              :disabled="checkFieldAllow(item)"
            >
              <el-option
                v-for="(option, index) of item.options"
                :key="index"
                :value="option"
                :label="option"
              ></el-option>
            </el-select>

            <input
              type="checkbox"
              :height="item.height"
              :width="item.width"
              :style="getStyle(item)"
              v-if="item.type == 'CHECKBOX' && !item.is_use_cross_mark"
              v-model="item.value"
              :disabled="checkFieldAllow(item)"
            />
            <input
              type="checkbox"
              :height="item.height"
              :width="item.width"
              :style="getStyle(item)"
              v-if="item.type == 'CHECKBOX' && item.is_use_cross_mark"
              v-model="item.value"
              :disabled="checkFieldAllow(item)"
            />
            <el-input
              type="textarea"
              :rows="item.rows"
              :style="getStyle(item)"
              v-if="item.type == 'MULTI_LINE_TEXT'"
              v-model="item.value"
              :placeholder="item.placeholder"
              :disabled="checkFieldAllow(item)"
            />

            <div v-if="item.type == 'signature'" :style="getStyle(item)">
              <a
                v-if="!item.source"
                style="cursor: pointer"
                @click="captureSignature(item, index)"
                :disabled="checkFieldAllow(item)"
              >
                {{ item.label }}
                <i class="el-icon-edit"></i>
              </a>
              <img
                v-if="item.source"
                :src="item.source"
                style="cursor: pointer"
                @click="captureSignature(item, index)"
                height="45"
                class="img-fluid"
              />
            </div>

            <span style="clear: both"></span>
          </div>
        </div>
      </div>
    </el-dialog>

    <el-dialog
      :visible.sync="captureSignatureDialog"
      top="5vh"
      width="30%"
      title="Add signature"
    >
      <template v-if="selectedSignatureItem.type == 'signature'">
        <VueSignaturePad
          height="300px"
          ref="signaturePad"
          :options="{
            penColor: selectedSignatureItem.color,
          }"
        />
        {{ selectedSignatureItem.color }}
        <el-button
          icon="el-icon-refresh-left"
          title="Undo"
          @click="undo"
        ></el-button>
        <button
          type="button"
          class="color-btn red"
          @click="selectedSignatureItem.color = 'red'"
        ></button>
        <button
          type="button"
          class="color-btn black"
          @click="selectedSignatureItem.color = 'black'"
        ></button>
        <button
          type="button"
          class="color-btn green"
          @click="selectedSignatureItem.color = 'green'"
        ></button>
      </template>
      <span slot="footer" class="dialog-footer">
        <el-button @click="captureSignatureDialog = false">Cancel</el-button>
        <el-button type="primary" @click="saveCapturedSignature()"
          >Add Signature</el-button
        >
      </span>
    </el-dialog>

    <el-dialog
      :visible.sync="showErrorElementDialog"
      class="outersize-confirm-type-one"
      title="Alert"
    >
      <h3>{{ fieldErrorMessage }}</h3>
      <div v-if="errorElements.length">
        <ul>
          <li v-for="(errorField, index) of errorElements" :key="index">
            <div>
              <p>{{ errorField.label }}</p>
            </div>
          </li>
        </ul>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showErrorElementDialog = false">Close</el-button>
      </span>
    </el-dialog>

    <el-dialog
      :visible.sync="addMySignatureStatus"
      top="5vh"
      width="45%"
      title="Add My-signature"
    >
      <template>
        <VueSignaturePad
          width="500px"
          height="300px"
          ref="signaturePad"
          :customStyle="{ border: 'black 1px solid' }"
          :options="{
            penColor: selectedSignatureItem.color,
          }"
        />
        {{ selectedSignatureItem.color }}
        <el-button
          icon="el-icon-refresh-left"
          title="Undo"
          @click="undo"
        ></el-button>
        <button
          type="button"
          class="color-btn red"
          @click="selectedSignatureItem.color = 'red'"
        ></button>
        <button
          type="button"
          class="color-btn black"
          @click="selectedSignatureItem.color = 'black'"
        ></button>
        <button
          type="button"
          class="color-btn green"
          @click="selectedSignatureItem.color = 'green'"
        ></button>
      </template>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addMySignatureStatus = false">Cancel</el-button>
        <el-button type="primary" @click="saveCapturedSignature()"
          >Add Signature</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      :visible.sync="showCheckboxErrors"
      title="Warning"
      :width="getIsMobile ? '100%' : '40%'"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :before-close="resetCheckboxErrors"
    >
      <div>
        <p>
          Please add additional checkbox fields for the following groups,to meet
          checkbox mininum value.
        </p>
        <p v-for="(el, key) in checkboxErrors" :key="key">
          Group : <span style="font-weight: bold"> {{ el.label }}</span> ,
          Minimum Value :
          <span style="font-weight: bold">{{ el.min_value }}</span
          >.
        </p>
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="showSignErrorElementDialog"
      class="outersize-confirm-type-one"
      title="Alert Message!"
      :width="getIsMobile ? '100%' : '70%'"
      :before-close="closeAlertPopup"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <p style="font-family: inter, sans-serif">Please add fields for:</p>
      <div
        class="alertPopupStyle"
        v-for="(name, index) in this.names"
        :key="index"
      >
        {{ name }}
        <br />
      </div>
      <div v-if="errorElements.length">
        <ul>
          <li v-for="(errorField, index) of errorElements" :key="index">
            <div>
              <p>{{ typeNameMap[errorField.type] }}</p>
            </div>
          </li>
        </ul>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeAlertPopup()">Close</el-button>
      </span>
    </el-dialog>

    <el-dialog
      :visible.sync="constantUsersPopup"
      class="outersize-confirm-type-one"
      title="Add Users"
      :width="getIsMobile ? '100%' : '70%'"
      :before-close="closeAlertPopup"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <p style="font-family: inter, sans-serif">
        Please assign recipient(s) for:
      </p>
      <div
        class="alertPopupStyle"
        v-for="(name, index) in this.constantUsers"
        :key="index"
      >
        {{ index + 1 + " " + "-" + " " + name.name }}
        <br />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeAlertPopup()">Close</el-button>
        <el-button type="danger" plain @click="showUsersPopup()"
          >View Recipients</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      :visible.sync="showFinishErrorElementDialog"
      class="outersize-confirm-type-one"
      title="Alert Message!"
      :width="getIsMobile ? '100%' : '70%'"
      :before-close="closeAlertPopup"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <p style="font-family: inter, sans-serif">
        Please fill the required fields:
      </p>
      <div
        class="alertPopupStyle"
        v-for="(name, index) in this.unfilledFields"
        :key="index"
      >
        {{ index + 1 + " " + "-" + " " + name }}
        <br />
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="closeAlertPopup()">Close</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :visible.sync="showServerErrorsDialog"
      top="5vh"
      width="30%"
      title="Errors"
    >
      <ol v-if="getConfigurableDocumentErrors">
        <li
          v-for="(err, index) of Object.values(getConfigurableDocumentErrors)"
          :key="index"
        >
          {{ err }}
        </li>
      </ol>

      <span slot="footer" class="dialog-footer">
        <el-button @click="showServerErrorsDialog = false">Close</el-button>
      </span>
    </el-dialog>

    <el-dialog
      :visible.sync="addMyInitialStatus"
      top="5vh"
      width="45%"
      title="Add My-initial"
      :close-on-click-mode="false"
      :close-on-press-escape="false"
    >
      <div>
        <el-input
          type="text"
          v-model="myInitial.text"
          label="Initial"
          placeholder="Enter initial"
        ></el-input>
        <el-col>
          <button
            type="button"
            class="color-btn red"
            @click="myInitial.color = '#FF0000'"
          ></button>
          <button
            type="button"
            class="color-btn black"
            @click="myInitial.color = '#000000'"
          ></button>
          <button
            type="button"
            class="color-btn green"
            @click="myInitial.color = '#008000'"
          ></button>
        </el-col>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addMyInitialStatus = false">Cancel</el-button>
        <el-button type="primary" @click="saveMyInitial()"
          >Add Initial</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      :visible.sync="getLinkModal"
      top="30vh"
      title="Get Link"
      :width="getIsMobile ? '100%' : '60%'"
      :close-on-click-mode="false"
      :close-on-press-escape="false"
    >
      <div class="link-main">
        <div class="link-head">
          <el-row>
            <el-col :span="6">
              <p class="link-title mb-1">{{ documentName }}</p>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <p class="link-sub">Choose how to distribute your template</p>
            </el-col>
            <!-- <el-col :span="6" :offset="6">
              <el-checkbox v-model="is_published" class="float-right">Publish status</el-checkbox>
            </el-col>-->
          </el-row>
        </div>
        <el-divider></el-divider>
        <el-row :gutter="30">
          <el-col :span="12" :lg="{ span: 12 }" :xs="{ span: 24 }">
            <div class="link-col">
              <p class="link-cap">Embed code</p>
              <span class="cap-sub"
                >To embed your form on your own site, copy the code below.</span
              >
              <div style="display: flex" class="mt-1">
                <el-input
                  size="mini"
                  v-model="embedCode"
                  ref="getCode"
                  v-on:focus="$event.target.select()"
                ></el-input>
                <el-button
                  type="danger"
                  icon="el-icon-copy-document"
                  class="copy-btn ml-1"
                  size="mini"
                  @click="copyCodeToClipboard"
                  >Copy Code</el-button
                >
              </div>
            </div>
          </el-col>
          <el-col :span="12" :lg="{ span: 12 }" :xs="{ span: 24 }">
            <div class="link-col">
              <p class="link-cap">Web link</p>
              <span class="cap-sub"
                >Send a direct link or paste the link in your website.</span
              >
              <div style="display: flex" class="mt-1">
                <el-input
                  size="mini"
                  v-model="getLink"
                  ref="getlink"
                  v-on:focus="$event.target.select()"
                ></el-input>
                <el-button
                  type="danger"
                  icon="el-icon-copy-document"
                  class="copy-btn ml-1"
                  size="mini"
                  @click="copyLinkToClipboard"
                  >Copy Link</el-button
                >
              </div>
            </div>
          </el-col>
        </el-row>
        <div class="link-alert mt-1">
          <div>
            <el-checkbox
              class="float-left"
              :disabled="this.is_published"
              v-model="access_to_all"
              >Allow new users to fill</el-checkbox
            >
          </div>
          <div>
            <el-checkbox
              class="float-left"
              :disabled="this.is_published"
              v-model="secure_access"
              >Secure access</el-checkbox
            >
          </div>
        </div>
      </div>
      <el-row :gutter="30" class="mt-1">
        <el-col :span="3">
          <!-- <el-checkbox
            class="float-left"
            :disabled="this.is_published"
            v-model="secure_access"
            >Secure access</el-checkbox
          > -->
          <!-- <el-checkbox
            class="float-left"
            :disabled="this.is_published"
            v-model="update_contacts"
            >Update contacts with recipient's entered data</el-checkbox
          >-->
        </el-col>
        <el-col :span="7" :offset="14" class="link-footer">
          <el-button @click="getLinkModal = false" class="link-cancel"
            >Cancel</el-button
          >
          <el-button
            type="primary"
            class="link-publish"
            @click="publishTheTemplate"
            v-if="!is_published"
            >Publish</el-button
          >
          <el-button
            type="primary"
            class="link-publish"
            @click="unpublishTheTemplate"
            v-if="is_published"
            >Unpublish</el-button
          >
        </el-col>
      </el-row>
      <!-- <span slot="footer" >
      </span>-->
    </el-dialog>
    <el-dialog
      :visible.sync="replaceDocumentModel"
      title="Change Document"
      :before-close="resetReplaceFiles"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      class="change-document-dlg"
    >
      <div
        v-loading="replaceDocumentLoading"
        element-loading-text="Uploading..."
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <h4 class="mb-1">Upload a new file to change the existing document:</h4>
        <el-upload
          class="avatar-uploader"
          action
          :on-change="checkToUploadDocument"
          :show-file-list="false"
          :auto-upload="false"
          multiple
        >
          <div class="upload-attchment-box">
            <img
              src="@/assets/img/fields/attachment-filling.svg"
              alt="Upload File"
              height="20"
            />
            <h4 class="ml-1">Upload a file</h4>
          </div>
        </el-upload>
        <div class="upload-from-other-container" id="uploadcontainer">
          <p
            class="text-muted fs-9 opacity-60 upload-a-file-upload-from-text"
            id="parastyle"
          >
            Upload From
          </p>
          <div class="from-others">
            <div class="image">
              <el-tooltip
                class="item"
                effect="dark"
                placement="bottom-start"
                content="Blank Document"
              >
                <img
                  src="@/assets/img/icons/Blank.svg"
                  alt="Nimble logo"
                  class="upload-icon"
                  @click="chooseBlankDocument"
                />
              </el-tooltip>
            </div>
            <div
              class="image"
              v-on:click="
                (e) =>
                  handleUploadFrom(e, ['googledrive', 'dropbox', 'onedrive'])
              "
            >
              <el-tooltip placeholder="top-start" content="Google Drive">
                <img
                  src="@/assets/img/google-drive.svg"
                  alt="Nimble logo"
                  class="upload-icon"
                />
              </el-tooltip>
            </div>
            <div
              class="image"
              v-on:click="
                (e) =>
                  handleUploadFrom(e, ['dropbox', 'googledrive', 'onedrive'])
              "
            >
              <el-tooltip placeholder="top-start" content="Drop Box">
                <img
                  src="@/assets/img/dropbox.svg"
                  alt="Nimble logo"
                  class="upload-icon"
                />
              </el-tooltip>
            </div>
            <div
              class="image"
              v-on:click="
                (e) =>
                  handleUploadFrom(e, ['onedrive', 'googledrive', 'dropbox'])
              "
            >
              <el-tooltip
                class="item"
                effect="dark"
                placement="bottom-start"
                content="One Drive"
              >
                <img
                  src="@/assets/img/onedrive.svg"
                  alt="Nimble logo"
                  class="upload-icon"
                />
              </el-tooltip>
            </div>
          </div>
        </div>
        <span v-if="logoError">{{ logoError }}</span>
        <el-row type="flex" justify="center" class="name-types">
          <el-col :span="22">
            <draggable v-if="this.files.length" v-model="files">
              <transition-group tag="div" name="flip-list" id="field-group">
                <div
                  class="filename"
                  v-for="(file, index) in files"
                  :key="JSON.stringify(file)"
                >
                  <a class="el-upload-list__item-name">
                    <img
                      :src="require('@/assets/img/icons/upload-active.svg')"
                      alt="icon"
                      class="img-active"
                    />
                    <img
                      v-if="
                        file.raw.type == 'image/jpeg' ||
                        file.raw.type == 'image/jpg'
                      "
                      :src="require('@/assets/img/icons/JPG.svg')"
                      alt="icon"
                      class="img-fluid"
                    />
                    <img
                      v-else-if="file.raw.type == 'image/png'"
                      :src="require('@/assets/img/icons/PNG.svg')"
                      alt="icon"
                      class="img-fluid"
                    />

                    <img
                      v-else
                      :src="require('@/assets/img/icons/pdf.svg')"
                      alt="icon"
                      class="img-fluid"
                    />
                    {{ file.raw.name }}
                  </a>
                  <div class="direction-buttons">
                    <el-button
                      type="text"
                      icon="el-icon-bottom"
                      class="directions"
                      @click="moveDown(index)"
                    ></el-button>
                    <el-button
                      type="text"
                      icon="el-icon-top"
                      class="directions"
                      @click="moveUp(index)"
                    ></el-button>
                    <a @click="deleteItem(index)">
                      <i class="el-icon-circle-close"></i>
                    </a>
                  </div>
                </div>
              </transition-group>
            </draggable>
          </el-col>
        </el-row>
        <el-row
          type="flex"
          justify="center"
          class="name-types"
          v-if="files && files.length > 1"
        >
          <el-col :span="22">
            <el-checkbox v-model="isMergeDocument"
              >Process it as a single document?</el-checkbox
            >
          </el-col>
        </el-row>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="resetReplaceFiles">Cancel</el-button>
        <el-button
          @click="checkForMergeOrUpload"
          type="primary"
          :disabled="!files.length"
          :loading="replaceDocumentLoading"
          >Continue</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      :visible.sync="ErrorMessageUpload"
      top="25vh"
      :width="getIsMobile ? '100%' : '30%'"
      title="Error"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <span>
        <p>Error uploading file. The validation of the PDF file failed.</p>
        <p>Please check the file or upload another file.</p>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="ErrorMessageUpload = false">Close</el-button>
        <el-button>Re-Upload</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :visible.sync="fieldsAlertModel"
      title="Form fields"
      :before-close="checkAddFormFields"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div>
        There are some form fields included in this pdf document. Can we replace
        these fields with our fields?
      </div>
      <div style="display: flex" class="mt-1">
        Fields filled by?
        <el-select v-model="selectedRecipient" size="mini" class="ml-1">
          <el-option
            v-for="(op, key) in documentRecipientsList"
            :key="key"
            :value="op.value"
            :label="op.name"
            :disabled="op.user_type == 'CC'"
          >
            <span style="float: left">{{ op.name }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px">
              {{ op.email }}
            </span>
          </el-option>
        </el-select>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="checkAddFormFields">Cancel</el-button>
        <el-button type="primary" @click="addAndCloseAlert"
          >Add fields</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      :title="
        this.currentIndividualEntity && this.currentIndividualEntity.name
          ? 'Add new ' + this.currentIndividualEntity.name + `'s data`
          : 'Add New Data'
      "
      :visible.sync="quickAddNewEntityData"
      width="30%"
      :before-close="handleClose"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div
        v-if="currentEntityOfQuickAdd && currentEntityOfQuickAdd.length"
        class="sidemenu"
      >
        <el-form
          :label-position="'top'"
          label-width="20px"
          :model="newEntityForm"
          v-if="quickAddNewEntityData"
        >
          <PrimaryDataForm
            :fieldsListData="currentEntityOfQuickAdd"
            :form="newEntityForm"
            :isFromDocument="false"
            :parentyEntityId="currentIndividualEntity._id"
          >
          </PrimaryDataForm>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer float-right">
        <el-button @click="handleClose">Cancel</el-button>
        <el-button
          v-if="
            this.currentIndividualEntity &&
            this.currentIndividualEntity.entity_type == 'INDIVIDUAL'
          "
          type="primary"
          :disabled="checkAllfieldsFilled"
          @click="addNewEntityDataQuickAdd"
        >
          Add
        </el-button>
        <el-button
          v-else
          type="primary"
          :disabled="checkAllfieldsFilled"
          @click="addPrimaryDataToEntity('form')"
          >Add</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="Edit Column Title"
      :visible.sync="editColumnTitle"
      width="30%"
      :before-close="handleCloseEditColumn"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-input v-model="selectedHeader"></el-input>
      <span slot="footer">
        <el-button @click="editColumnTitle = false">Cancel</el-button>
        <el-button type="primary" @click="updateTableColumn()"
          >Update</el-button
        >
      </span>
    </el-dialog>
    <dialog-component
      :visible="aIgeneratedExtraFields"
      title="Unmatched AI Fields"
      @before-close="closeAiAddedEditorFields"
      :append-to-body="false"
      width="getIsMobile ? '100%' : '85%'"
      class="fixed-dialog"
      :show-close="false"
    >
      <div class="sidemenu">
        <el-table
          ref="unmatchedFieldsTable"
          :data="unmatchedFieldLabels"
          @selection-change="handleTableSelectionChange"
          style="width: 100%"
          class="mt-2"
          border
          :row-style="setRowHeight"
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column label="S.No">
            <template slot-scope="scope">
              <p>{{ scope.$index + 1 }}</p>
            </template>
          </el-table-column>
          <el-table-column label="Edit Mention Labels" min-width="350px">
            <template slot-scope="scope">
              <el-input
                :disabled="true"
                v-model="unmatchedFieldLabels[scope.$index].label"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column label="Field Type" min-width="250px">
            <template slot-scope="scope">
              <el-select
                v-model="scope.row.input_type"
                placeholder="Select Field Type"
                :default-first-option="true"
              >
                <el-option
                  v-for="field in allFieldsData"
                  :key="field.key"
                  :label="field.name"
                  :value="field.key"
                >
                </el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column label="Select Filled By" min-width="250px">
            <template slot-scope="scope">
              <div v-if="openCk" class="selectUser mb-3 d-flex flex-column">
                <el-select
                  v-model="scope.row.filled_by"
                  filterable
                  placeholder="Select a Recipient"
                  :loading="loading"
                  class="w-100 mt-1"
                >
                  <el-option
                    v-for="item in documentRecipientsList"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value"
                  >
                    <span style="float: left">{{ item.name }}</span>
                    <span
                      style="float: right; color: #8492a6; font-size: 13px"
                      >{{ item.email }}</span
                    >
                  </el-option>
                  <i slot="prefix" class="el-input__icon el-icon-user"></i>
                </el-select>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="Required" min-width="150px">
            <template v-slot="scope">
              <el-checkbox v-model="scope.row.is_required"></el-checkbox>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- <el-form>
      <el-form-item label="Select Field" v-if="aiGeneratedFields.length > 0">
      </el-form-item>
    </el-form> -->
      <span slot="footer">
        <el-button @click="closeAiAddedEditorFields">Cancel</el-button>
        <el-button type="primary" @click="mapExtraFields">Map</el-button>
      </span>
    </dialog-component>
    <dialog-component
      :visible="changeFilledByModal"
      :title="
        getSignerUserTypeLength <= 1 ? 'Fill Data' : 'Map Fields Filled By'
      "
      @before-close="closeFillingEditorFields"
      :append-to-body="false"
      :fullscreen="true"
      class="custom-dialog"
      v-loading="documentDataLoading"
      :element-loading-text="'Fetching Fields Data...'"
    >
      <span v-if="getSignerUserTypeLength <= 1">
        <hr v-if="!previewEditorContentModal" />
        <div>
          <el-row :gutter="20" v-if="!previewEditorContentModal">
            <el-col :span="24" class="right-align">
              <span v-if="getSignerUserTypeLength <= 1">
                Hello
                <p style="display: inline; font-weight: bold">
                  {{
                    documentRecipientsList[0].first_name +
                    " " +
                    documentRecipientsList[0].last_name
                  }}
                </p>
                Some fields are still unfilled. Please fill them to complete the
                document process.
              </span>
              <el-input
                placeholder="Search by mention label..."
                v-model="searchedLabel"
                @clear="reloadEditorContent()"
                clearable
                style="margin-right: 10px; margin-left: 230px; width: 240px"
              />
              <el-progress
                v-if="progressPercentage === 100 && !searchedLabel"
                type="circle"
                status="success"
                :width="getIsMobile ? 60 : 65"
                :stroke-width="4"
                :format="getFormat"
                :percentage="progressPercentage"
                style="margin-top: 1px"
              />

              <el-progress
                v-else-if="!searchedLabel"
                type="circle"
                :width="getIsMobile ? 60 : 65"
                :stroke-width="4"
                :format="getFormat"
                :percentage="progressPercentage"
                style="margin-top: 1px"
              />
            </el-col>
          </el-row>
        </div>
        <hr v-if="!previewEditorContentModal" />
      </span>
      <div>
        <el-row v-if="previewEditorContentModal">
          <el-col :span="24">
            <div v-html="this.previewEditorContent"></div>
          </el-col>
        </el-row>
        <el-input
          v-if="getSignerUserTypeLength > 1"
          placeholder="Search by mention label..."
          v-model="searchedLabel"
          @clear="mapUserFieldsFilledBy"
          clearable
          style="margin-top: 10px"
        />
        <el-table
          v-if="!previewEditorContentModal"
          :data="
            this.getSignerUserTypeLength > 1
              ? this.filteredMapFilledByFieldLabels
              : this.editorFields
          "
          style="width: 100%"
          class="mt-2"
          border
          :row-style="setRowHeight"
        >
          <el-table-column label="S.No">
            <template slot-scope="scope">
              <p>{{ scope.$index + 1 }}</p>
            </template>
          </el-table-column>

          <el-table-column
            label="Edit Mention Labels"
            min-width="350px"
            v-if="getSignerUserTypeLength > 1"
          >
            <template slot-scope="scope">
              <el-input
                @blur="handleLabelChange(scope.$index)"
                v-model="filteredMapFilledByFieldLabels[scope.$index].label"
              ></el-input>
            </template>
          </el-table-column>

          <el-table-column
            label="Edit Mention Labels"
            min-width="350px"
            v-if="getSignerUserTypeLength <= 1"
          >
            <template slot-scope="scope">
              <el-input
                :disabled="true"
                @blur="handleLabelChangeSenderSide(scope.$index)"
                v-model="editorFields[scope.$index].label"
              ></el-input>
            </template>
          </el-table-column>

          <el-table-column
            label="Select Filled By"
            min-width="250px"
            v-if="getSignerUserTypeLength > 1"
          >
            <template slot-scope="scope">
              <div v-if="openCk" class="selectUser mb-3 d-flex flex-column">
                <el-select
                  v-model="filledBySelections[scope.$index]"
                  filterable
                  placeholder="Select a Recipient"
                  :loading="loading"
                  class="w-100 mt-1"
                >
                  <el-option
                    v-for="item in documentRecipientsList"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value"
                  >
                    <span style="float: left">{{ item.name }}</span>
                    <span
                      style="float: right; color: #8492a6; font-size: 13px"
                      >{{ item.email }}</span
                    >
                  </el-option>
                  <i slot="prefix" class="el-input__icon el-icon-user"></i>
                </el-select>
              </div>
            </template>
          </el-table-column>

          <el-table-column label="Fill Field Value" min-width="550px" v-if="!checkAllUsersAssigned.length">
            <template slot-scope="scope">
              <el-input
                class="same-width"
                v-if="isTextInputType(scope.row)"
                :placeholder="
                  scope.row.placeholder ? scope.row.placeholder : 'Enter Value'
                "
                v-model="scope.row.value"
                @input="updateValueOnlyIfSender(scope.row)"
              ></el-input>

              <el-input
                class="same-width"
                v-if="
                  scope.row &&
                  scope.row.input_type &&
                  scope.row.input_type.startsWith('PRIMARY_FIELDS')
                "
                :placeholder="
                  scope.row.placeholder ? scope.row.placeholder : 'Enter Value'
                "
                v-model="scope.row.value"
                :disabled="isEmailInPrimaryFields(scope.row)"
                @input="updateValueOnlyIfSender(scope.row)"
              ></el-input>

              <el-input
                class="same-width"
                v-if="
                  scope.row &&
                  (scope.row.input_type === 'HEADING' ||
                    scope.row.type === 'HEADING')
                "
                :placeholder="
                  scope.row.placeholder ? scope.row.placeholder : 'Enter Value'
                "
                v-model="scope.row.value"
                @input="updateValueOnlyIfSender(scope.row)"
              ></el-input>

              <el-input
                class="same-width"
                v-if="
                  scope.row &&
                  (scope.row.input_type === 'SINGLE_LINE_CONTENT' ||
                    scope.row.type === 'SINGLE_LINE_CONTENT')
                "
                :placeholder="
                  scope.row.placeholder ? scope.row.placeholder : 'Enter Value'
                "
                v-model="scope.row.value"
                @input="updateValueOnlyIfSender(scope.row)"
              ></el-input>

              <div
                class="d-flex"
                v-if="
                  scope.row.type === 'CURRENCY' ||
                  scope.row.input_type === 'CURRENCY'
                "
              >
                <currency-input
                  v-model="scope.row.value"
                  style="height: 24px; width: 70%"
                  @input="checkCurrency(scope.row, scope.$index)"
                  :currency="scope.row.validations?.currency ?? 'USD'"
                  :locale="scope.row.validations?.locale ?? 'en-US'"
                  class="field-textarea currency-input"
                />
                <el-select
                  v-if="getSignerUserTypeLength <= 1"
                  placeholder="Select Currency Type"
                  v-model="editorFields[scope.$index].validations.currency"
                  filterable
                >
                  <el-option
                    v-for="item in CurrencyTypes"
                    :key="item.value"
                    :label="item.value"
                    :value="item.value"
                  >
                    <span style="float: left">{{ item.value }}</span>
                    <span
                      style="
                        float: right;
                        color: var(--el-text-color-secondary);
                        font-size: 13px;
                      "
                      >{{ item.name }}</span
                    >
                  </el-option>
                </el-select>
                <el-select
                  v-if="getSignerUserTypeLength > 1"
                  placeholder="Select Currency Type"
                  v-model="
                    filteredMapFilledByFieldLabels[scope.$index].validations
                      .currency
                  "
                  filterable
                >
                  <el-option
                    v-for="item in CurrencyTypes"
                    :key="item.value"
                    :label="item.value"
                    :value="item.value"
                  >
                    <span style="float: left">{{ item.value }}</span>
                    <span
                      style="
                        float: right;
                        color: var(--el-text-color-secondary);
                        font-size: 13px;
                      "
                      >{{ item.name }}</span
                    >
                  </el-option>
                </el-select>
              </div>
              <el-input
                class="same-width"
                type="number"
                v-if="
                  scope.row.type === 'NUMBER' ||
                  scope.row.input_type === 'NUMBER'
                "
                :placeholder="
                  scope.row.placeholder ? scope.row.placeholder : 'Enter Value'
                "
                v-model="scope.row.value"
                @input="updateValueOnlyIfSender(scope.row)"
              ></el-input>
              <el-input
                class="same-width"
                v-if="
                  scope.row.type === 'PHONE_COUNTRY_CODE' ||
                  scope.row.input_type === 'PHONE_COUNTRY_CODE'
                "
                :placeholder="
                  scope.row.placeholder ? scope.row.placeholder : 'Enter Value'
                "
                @input="handleInputForPhoneNumber(scope.row)"
                v-model="scope.row.value"
              ></el-input>
              <el-select
                class="same-width"
                :placeholder="
                  scope.row.placeholder
                    ? scope.row.placeholder
                    : 'Please Select'
                "
                v-if="
                  scope.row.type === 'LIST' || scope.row.input_type === 'LIST'
                "
                :default-first-option="true"
                v-model="scope.row.value"
                @input="updateValueOnlyIfSender(scope.row)"
              >
                <el-option
                  v-for="option in scope.row.list_data"
                  :value="option.value"
                  :key="option.value"
                  :label="option.name"
                ></el-option>
              </el-select>

              <el-checkbox
                v-if="
                  scope.row.input_type === 'CHECKBOX' ||
                  scope.row.type === 'CHECKBOX'
                "
                v-model="scope.row.value"
                @input="updateValueOnlyIfSender(scope.row)"
              ></el-checkbox>
              <el-checkbox-group
                v-if="
                  scope.row.type === 'CHECKBOX_GROUP' ||
                  scope.row.input_type === 'CHECKBOX_GROUP'
                "
                v-model="scope.row.value"
                :max="scope.row.max_selection"
              >
                <el-checkbox
                  v-for="(option, index) in scope.row.options"
                  :label="option"
                  :key="index"
                  :value="option"
                  >{{ option }}</el-checkbox
                >
              </el-checkbox-group>

              <el-radio-group
                v-if="
                  scope.row.type === 'RADIO_BUTTON_GROUP' ||
                  scope.row.input_type === 'RADIO_BUTTON_GROUP'
                "
                v-model="scope.row.value"
                @input="updateValueOnlyIfSender(scope.row)"
              >
                <el-radio
                  v-for="option in scope.row.options || []"
                  :label="option"
                  :key="option"
                  >{{ option }}</el-radio
                >
              </el-radio-group>

              <el-select
                class="same-width"
                v-if="
                  scope.row.type === 'SELECT' ||
                  scope.row.input_type === 'SELECT'
                "
                :placeholder="
                  scope.row.placeholder ? scope.row.placeholder : 'Enter Value'
                "
                v-model="scope.row.value"
                @input="updateValueOnlyIfSender(scope.row)"
              >
                <el-option
                  v-for="op in scope.row.options"
                  :key="op"
                  :label="op"
                  :value="op"
                ></el-option>
              </el-select>

              <el-select
                class="same-width"
                v-if="
                  scope.row.type === 'MULTI_SELECT' ||
                  scope.row.input_type === 'MULTI_SELECT'
                "
                :placeholder="
                  scope.row.placeholder ? scope.row.placeholder : 'Enter Value'
                "
                v-model="scope.row.value"
                multiple
                @input="updateValueOnlyIfSender(scope.row)"
              >
                <el-option
                  v-for="(op, index) in scope.row.options"
                  :key="index"
                  :label="op"
                  :value="op"
                ></el-option>
              </el-select>

              <el-select
                class="same-width"
                v-if="
                  (scope.row.type === 'WEEKDAYS' ||
                    scope.row.input_type === 'WEEKDAYS') &&
                  (scope.row.allow_multiple === true ||
                    scope.row.allow_multiple === 'true')
                "
                :placeholder="
                  scope.row.placeholder
                    ? scope.row.placeholder
                    : 'Please select'
                "
                :clearable="true"
                :filterable="true"
                v-model="scope.row.value"
                collapse-tags
                multiple
                @input="updateValueOnlyIfSender(scope.row)"
              >
                <el-option
                  v-for="(option, index) in weekdays_options"
                  :value="option.value"
                  :label="option.name"
                  :key="index"
                ></el-option>
              </el-select>
              <el-select
                class="same-width"
                v-if="
                  (scope.row.type === 'WEEKDAYS' ||
                    scope.row.input_type === 'WEEKDAYS') &&
                  (scope.row.allow_multiple === false ||
                    scope.row.allow_multiple === 'false')
                "
                :placeholder="
                  scope.row.placeholder
                    ? scope.row.placeholder
                    : 'Please select'
                "
                :clearable="true"
                :filterable="true"
                v-model="scope.row.value"
                collapse-tags
                @input="updateValueOnlyIfSender(scope.row)"
              >
                <el-option
                  v-for="(option, index) in weekdays_options"
                  :value="option.value"
                  :label="option.name"
                  :key="index"
                ></el-option>
              </el-select>
              <el-date-picker
                class="same-width"
                v-if="
                  scope.row.type === 'DATE_RANGE' ||
                  scope.row.input_type === 'DATE_RANGE'
                "
                :format="'MM-dd-yyyy '"
                range-separator="To"
                start-placeholder="Start date"
                end-placeholder="End date"
                :placeholder="
                  scope.row.placeholder ? scope.row.placeholder : 'Enter Value'
                "
                type="daterange"
                v-model="scope.row.value"
                @input="updateValueOnlyIfSender(scope.row)"
              ></el-date-picker>
              <el-date-picker
                class="same-width"
                v-if="
                  scope.row.type === 'DATE_SIGNED' ||
                  scope.row.input_type === 'DATE_SIGNED'
                "
                :format="'MM-dd-yyyy'"
                :placeholder="
                  scope.row.placeholder ? scope.row.placeholder : 'Enter Value'
                "
                type="date"
                :disabled="true"
                v-model="scope.row.value"
                @input="updateValueOnlyIfSender(scope.row)"
              ></el-date-picker>
              <el-date-picker
                class="same-width"
                v-if="
                  scope.row.type === 'DATE' || scope.row.input_type === 'DATE'
                "
                :format="'MM-dd-yyyy'"
                :placeholder="
                  scope.row.placeholder ? scope.row.placeholder : 'Enter Value'
                "
                type="date"
                v-model="scope.row.value"
                @input="updateValueOnlyIfSender(scope.row)"
              ></el-date-picker>
              <el-time-picker
                class="same-width"
                v-if="
                  scope.row.type === 'TIME_RANGE' ||
                  scope.row.input_type === 'TIME_RANGE'
                "
                range-separator="To"
                start-placeholder="Start time"
                end-placeholder="End time"
                :placeholder="
                  scope.row.placeholder ? scope.row.placeholder : 'Enter Value'
                "
                is-range
                @focus="handleTimeRange(scope.row)"
                v-model="scope.row.value"
                value-format="HH:mm:ss"
                format="HH:mm:ss"
              ></el-time-picker>
              <el-time-picker
                class="same-width"
                v-if="
                  !scope.row.is_fixed &&
                  (scope.row.type === 'TIME' || scope.row.input_type === 'TIME')
                "
                :placeholder="
                  scope.row.placeholder ? scope.row.placeholder : 'Enter Value'
                "
                v-model="scope.row.value"
                @input="updateValueOnlyIfSender(scope.row)"
                format="HH:mm:ss"
                value-format="HH:mm:ss"
              ></el-time-picker>
              <el-time-select
                class="same-width"
                v-if="
                  scope.row.is_fixed &&
                  (scope.row.type === 'TIME' || scope.row.input_type === 'TIME')
                "
                :placeholder="
                  scope.row.placeholder ? scope.row.placeholder : 'Enter Value'
                "
                :picker-options="getTimeProperties"
                v-bind:class="{
                  'time-picker-item': true,
                  'smaller-than': true,
                }"
                v-model="scope.row.value"
                @input="updateValueOnlyIfSender(scope.row)"
                format=" HH:mm:ss"
              ></el-time-select>
              <el-date-picker
                class="same-width"
                v-if="
                  scope.row.type === 'DATE_TIME_RANGE' ||
                  scope.row.input_type === 'DATE_TIME_RANGE'
                "
                :format="'MM-dd-yyyy HH:mm:ss'"
                range-separator="To"
                start-placeholder="Start date"
                end-placeholder="End date"
                :placeholder="
                  scope.row.placeholder ? scope.row.placeholder : 'Enter Value'
                "
                type="datetimerange"
                v-model="scope.row.value"
                @input="updateValueOnlyIfSender(scope.row)"
              ></el-date-picker>
              <el-date-picker
                class="same-width"
                v-if="
                  scope.row.type === 'DATE_TIME' ||
                  scope.row.input_type === 'DATE_TIME'
                "
                :format="'MM-dd-yyyy HH:mm:ss'"
                :placeholder="
                  scope.row.placeholder ? scope.row.placeholder : 'Enter Value'
                "
                type="datetime"
                v-model="scope.row.value"
                @input="updateValueOnlyIfSender(scope.row)"
              ></el-date-picker>
              <el-button
                v-if="
                  (scope.row.type === 'IMAGE' ||
                    scope.row.input_type === 'IMAGE' ||
                    scope.row.type === 'FILE' ||
                    scope.row.input_type === 'FILE') &&
                  !scope.row.value
                "
                @click="uploadImageOuterVisible(scope.$index)"
                >Upload Image <i class="el-icon-upload"></i
              ></el-button>
              <div
                v-if="
                  (scope.row.type == 'IMAGE' ||
                    scope.row.input_type == 'IMAGE' ||
                    scope.row.type === 'FILE' ||
                    scope.row.input_type === 'FILE') &&
                  scope.row.value &&
                  scope.row.value.length
                "
              >
                <img
                  :style="`width: 80px;height:80px`"
                  @click="uploadImageOuterVisible(scope.$index)"
                  :src="scope.row.value"
                />
                <i
                  @click="uploadImageOuterVisible(scope.$index)"
                  class="el-icon-upload"
                ></i>
              </div>
              <div
                v-if="
                  (scope.row.type === 'SIGNATURE' ||
                    scope.row.input_type === 'SIGNATURE') &&
                  getSignerUserTypeLength <= 1
                "
              >
                <img
                  :style="`width: 100%;height:80px`"
                  :src="scope.row.value"
                  :disable="true"
                />
              </div>
              <div
                v-if="
                  (scope.row.type === 'SIGNATURE' ||
                    scope.row.input_type === 'SIGNATURE') &&
                  getSignerUserTypeLength > 1
                "
              >
                <el-tooltip
                  content="This fields will be enabled after the document is sent."
                  placement="top-start"
                >
                  <el-button class="same-width" :disabled="true"></el-button>
                </el-tooltip>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            v-if="getSignerUserTypeLength > 1"
            label="Required"
            min-width="150px"
          >
            <template v-slot="scope">
              <el-checkbox
                @change="updateIsRequired(scope.row)"
                v-model="scope.row.is_required"
              ></el-checkbox>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <span slot="footer">
        <el-button
          v-if="!previewEditorContentModal && getSignerUserTypeLength <= 1"
          type="primary"
          size="medium"
          @click="mapCkEditorData"
          style="margin-right: 10px"
        >
          Preview
        </el-button>
        <el-button
          v-if="getSignerUserTypeLength > 1"
          @click="closeFillingEditorFields"
          >Close</el-button
        >
        <el-button
          v-if="previewEditorContentModal && getSignerUserTypeLength <= 1"
          type="primary"
          size="medium"
          @click="editSenderData"
          style="margin-right: 10px"
        >
          Edit Data
        </el-button>
        <el-button
          v-if="getSignerUserTypeLength > 1"
          type="primary"
          @click="mapFields"
          >Map Fields</el-button
        >
        <el-button
          type="danger"
          v-else-if="getSignerUserTypeLength <= 1"
          @click="finishCkEditorDocument()"
          >Finish Document</el-button
        >
      </span>
    </dialog-component>
    <el-dialog
      :modal="false"
      :visible.sync="uploadDocuments"
      title="Upload Documents"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :before-close="closeUploadDocuments"
    >
      <el-row>
        <el-input
          class="search mb-1"
          placeholder="Search"
          v-model="searchUploadDocument"
          clearable
          prefix-icon="el-icon-search"
        ></el-input>
      </el-row>
      <div class="sidemenu">
        <el-table
          border
          :data="documentUploadData"
          :header-row-class-name="'my-header-row'"
          :header-cell-style="headerCellStyle"
          class="tableSty"
          :show-header="true"
        >
          <el-table-column class="labelSty" label="Document Name">
            <template slot-scope="scope">
              <p style="font-size: 14px" slot="reference">
                {{ scope.row.name }}
              </p>
            </template>
          </el-table-column>
          <el-table-column label="Action" width="150">
            <template slot-scope="scope">
              <ul class="action-buttons">
                <li>
                  <button class="btn btn-light btn-sm m-lr-1" type="button">
                    <span
                      class="text-muted"
                      @click="onRowDelete(scope.row)"
                      target="_blank"
                      title="Delete"
                      ref="downloadLink"
                    >
                      <i class="el-icon-delete"></i>
                    </span>
                  </button>
                </li>
              </ul>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <span slot="footer">
        <el-button @click="uploadDocuments = false"> Close </el-button>
      </span>
    </el-dialog>
    <dialog-component
      :title="'Update picture'"
      :visible="outerVisible"
      width="getIsMobile ? '100%' : '30%'"
      @before-close="outerVisible = false"
    >
      <vue-anka-cropper
        class="upload-file"
        :options="{
          aspectRatio: false,
          closeOnSave: true,
          cropArea: 'box',
          croppedHeight: 1000,
          croppedWidth: 1000,
          cropperHeight: false,
          dropareaMessage: ' Drag & Drop  file here or use the button below.',
          frameLineDash: [1, 1000],
          frameStrokeColor: 'rgba(255, 255, 255, 0.8)',
          handleFillColor: 'rgba(255, 255, 255, 0.2)',
          handleHoverFillColor: 'rgba(255, 255, 255, 0.4)',
          handleHoverStrokeColor: 'rgba(255, 255, 255, 1)',
          handleSize: 5,
          handleStrokeColor: 'rgba(255, 255, 255, 0.8)',
          layoutBreakpoint: 40,
          maxCropperHeight: 1000,
          maxFileSize: 8000000,
          overlayFill: 'rgba(0, 0, 0, 0.5)',
          previewOnDrag: true,
          previewQuality: 0.65,
          resultQuality: 0.8,
          resultMimeType: 'image/jpeg',
          resultMimeType: 'image/png',
          resultMimeType: 'image/jpg',
          resultMimeType: 'image/gif',
          selectButtonLabel: 'Select File Supported : JPG, PNG, JPEG, GIF',

          showPreview: true,
          skin: 'light',
          uploadData: {},
          uploadTo: false,
        }"
        name="logo"
        @cropper-error="fileError"
        @cropper-file-selected="insertImage"
        @cropper-preview="setImageData"
        @cropper-saved="setImageData"
        v-loading="upLoadEffect"
        accept="image/jpeg,image/png,image/jpg,image/gif"
      ></vue-anka-cropper>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="
            outerVisible = false;
            resetImgData();
          "
          >Cancel</el-button
        >
        <el-button type="primary" @click="closeImage">Save</el-button>
      </span>
    </dialog-component>
    <!-- v-if="showDocumentUserSettings" -->
    <ConfigureDocumentUsers
      v-if="addDocumentUsers"
      :documentUsers="documentRecipientsList"
      :enforceSignatureOrder="enforceSignatureOrder"
      :allElements="allElements"
      :isSubmit="submitEvent"
      v-on:close="closeDocumentUserSettings"
    ></ConfigureDocumentUsers>
    <ConfigureDocumentExpirationDates
      v-if="showDocumentExpirationDatesSettings"
      :isSubmit="submitEvent"
      :expirationSettings="expirationSettings"
      :allElements="fields"
      :documentUsers="documentUsers"
      :configurableDocumentId="configurableDocumentId"
      v-on:close="closeDocumentExpireDateSettings"
    ></ConfigureDocumentExpirationDates>

    <ConfigureDocumentReceipents
      v-if="addDocumentRecipient"
      :documentUsers="documentRecipientsList"
      :enforceSignatureOrder="enforceSignatureOrder"
      :allElements="allElements"
      v-on:close="closeDocumentUserSettings"
      :isAnyoneCanApprove="isAnyoneCanApprove"
      :editorFields="this.editorFields"
      :openCk="this.openCk"
      :editorContent="this.editorContent"
      :showVar="showVar"
    ></ConfigureDocumentReceipents>
    <ConfigureSettings
      v-if="docSettingsVisible"
      :docSettingsVisible="docSettingsVisible"
      @close="docSettingsVisible = false"
      @updateDocSettingsData="closeSettingsAndSend"
    ></ConfigureSettings>
  </section>
</template>
<script src="https://cdn.ckeditor.com/4.24.0-lts/full-all/ckeditor.js"></script>
<script src="https://cdn.ckeditor.com/4.24.0-lts/standard-all/ckeditor.js"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.4.456/pdf.js"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.4.456/pdf.worker.js"></script>
<script>
import VueDraggableResizable from "vue-draggable-resizable";
import appConfig from "@/config/app";
import vueAnkaCropper from "vue-anka-cropper";
import "vue-anka-cropper/dist/VueAnkaCropper.css";
import VueHtml2pdf from "vue-html2pdf";
import axios from "@/config/axios";
import { mapGetters, mapActions } from "vuex";
import { makePlainText } from "@/helpers/appHelper.js";
import PrimaryDataForm from "@/components/entity/PrimaryDataCollectionForm.vue";
import offerLetterBuildHelper from "@/mixins/offerLetterBuildHelper";
import DocumentsHelper from "@/mixins/CompanyDocumentsHelper";
import { bus } from "../../../main";
import { PDFDocument, StandardFonts } from "pdf-lib";
import CustomDashboardConfig from "@/mixins/CustomDashboardHelper.js";
import NavigationHelper from "@/mixins/navigationHelper";
import moment from "moment";
import { fetchEntitiesByPagination } from "@/repo/entityRepo";
import comanyDocumentsPresignedURLsHelper from "@/mixins/ComanyDocumentsPresignedURLsHelper";
import ChatGpt from "../../ChatGpt-EmailTemplates.vue";
const ConfigureSettings = () =>
  import(
    "@/components/companyDocuments/configureDocuments/ConfigureSettingsNew"
  );
import UserHelper from "@/mixins/UserHelper";
var pdfjsLib = window["pdfjs-dist/build/pdf"];
pdfjsLib.GlobalWorkerOptions.workerSrc =
  "//cdnjs.cloudflare.com/ajax/libs/pdf.js/2.4.456/pdf.worker.js";

import textToImage from "text-to-image";
import html2pdf from "html2pdf.js";

import jsPDF from "jspdf";
import UserDocumentsHelper from "@/mixins/UserDocumentsHelper";
import MobileRelatedHelper from "@/mixins/MobileRelatedHelper";
import ConfigureDocumentsHelper from "@/mixins/ConfigureDocumentHelper";
import FormbuilderHelper from "@/mixins/FormbuilderHelper.js";
import draggable from "vuedraggable";
import EntityHelper from "@/mixins/EntitiesHelper";
import entityRelationshipMixin from "@/mixins/entityRelationshipMixin";
import AccessManagement from "@/mixins/AccessManagement";
import { ckEditorConfig } from "@/assets/data/ckeditor_config.ts";
const ConfigureEntityField = () =>
  import(
    "@/components/companyDocuments/configureDocuments/ConfigureEntityField"
  );
const ConfigureRepeatableField = () =>
  import(
    "@/components/companyDocuments/configureDocuments/ConfigureRepeatableField"
  );

export default {
  name: "configureDocuments-ConfigureCompanyTemplate",
  components: {
    vueAnkaCropper,
    PrimaryDataForm,
    VueHtml2pdf,
    VueDraggableResizable,
    ChatGpt,
    RadioFieldExecute: () =>
      import("@/components/templates/formComponentsExecute/RadioExecute.vue"),
    ConfigureDocumentUsers: () => import("./ConfigureDocumentUsers"),
    ConfigureDocumentExpirationDates: () =>
      import("./ConfigureDocumentExpirationDates"),
    ConfigureDocumentReceipents: () => import("./ConfigureDocumentReceipents"),
    draggable,
    ConfigureSettings,
    ConfigureDOcumentField: () => import("./ConfigureDocumentField.vue"),
    CurrencyInput: () =>
      import("./../../vue-weblineindia-currency-input/src/component"),
    ConfigureEntityField,
    Formula: () => import("@/components/templates/formComponents/Formula.vue"),
    FormulaExecute: () =>
      import("@/components/templates/formComponentsExecute/FormulaExecute"),
    DocumentExecute: () =>
      import("@/components/templates/formComponentsExecute/FileExecute"),
    RadioGroupExecute: () =>
      import(
        "@/components/templates/formComponentsExecute/RadioButtonGroupExecute"
      ),
    SingleLineContentExecute: () =>
      import(
        "@/components/templates/formComponentsExecute/SingleLineContentExecute"
      ),
    HorizontalLine: () =>
      import("@/components/templates/formComponents/HorizontalLine.vue"),

    HorizontalLineExecute: () =>
      import(
        "@/components/templates/formComponentsExecute/HorizontalLineExecute.vue"
      ),
    AutoIncrementExecute: () =>
      import(
        "@/components/templates/formComponentsExecute/AutoIncrementExecute.vue"
      ),
    ConfigureRepeatableField,
  },
  mixins: [
    offerLetterBuildHelper,
    MobileRelatedHelper,
    UserHelper,
    UserDocumentsHelper,
    DocumentsHelper,
    ConfigureDocumentsHelper,
    EntityHelper,
    NavigationHelper,
    CustomDashboardConfig,
    entityRelationshipMixin,
    FormbuilderHelper,
    comanyDocumentsPresignedURLsHelper,
    AccessManagement,
  ],

  created() {
    let that = this;
    document.addEventListener("keyup", function (evt) {
      if (evt.keyCode === 46) {
        that.deleteActiveElement();
      }
    });
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    if (this.getIsMobile) {
      this.$store.commit("navigationOpen/setIsOpen", false, {
        root: true,
      });
      this.$store.commit("navigationOpen/setIsRSOpen", false, {
        root: true,
      });
    }
    bus.$on("userDeleted", (data) => {
      this.checkIsUserDeleted = data;
    });
    bus.$on("openUplaodDocumentsPopup", (data) => {
      (this.uploadDocuments = data.uploadDocuments),
        (this.documentUploadData = data.documentUploadData);
    });
    bus.$on("entityData-updated", (data) => {
      if (
        data.field &&
        data.field.key &&
        this.$route.name == "edit-configure-template-image"
      ) {
        this.updateEntityVariablesData(
          data.entityDataId,
          data.field,
          data.data,
          data.value,
          data.changed
        );
      }
    });
    // tharuni start
    document.addEventListener("keydown", function (event) {
      if (event.ctrlKey && event.key === "z" && that.history.length) {
        let lastActivity = that.history[that.history.length - 1];
        if (
          lastActivity?.action == "add" &&
          (lastActivity.index || lastActivity.index == 0)
        ) {
          that.elements.splice(lastActivity.index, 1);
          that.redohistory.push(lastActivity);
          that.history.splice(that.history.length - 1, 1);
        } else if (lastActivity?.action == "positionChange") {
          that.elements.map((el, index) => {
            if (index == lastActivity.index) {
              //  el.x = lastActivity.data.x;
              // el.y = lastActivity.data.y;
              let tempx = lastActivity.data.x;
              lastActivity.data.x = el.x;
              el.x = tempx;

              let tempy = lastActivity.data.y;
              lastActivity.data.y = el.y;
              el.y = tempy;
            }
          });
          that.redohistory.push(lastActivity);
          that.history.splice(that.history.length - 1, 1);
        } else if (lastActivity?.action == "delete") {
          that.elements.push(lastActivity.data);
          that.redohistory.push(lastActivity);
          that.history.splice(that.history.length - 1, 1);
        }
      } else if (
        event.ctrlKey &&
        event.key === "y" &&
        that.redohistory.length
      ) {
        let lastofredoActivity = that.redohistory[that.redohistory.length - 1];
        if (
          lastofredoActivity?.action == "add" &&
          (lastofredoActivity.index || lastofredoActivity.index == 0)
        ) {
          that.elements.push(lastofredoActivity.data);
          that.history.push(lastofredoActivity);
          that.redohistory.splice(that.redohistory.length - 1, 1);
        } else if (lastofredoActivity?.action == "positionChange") {
          that.elements.map((el, index) => {
            if (index == lastofredoActivity.index) {
              //  el.x = lastofredoActivity.data.x;
              // el.y = lastofredoActivity.data.y;
              let rtempx = lastofredoActivity.data.x;
              lastofredoActivity.data.x = el.x;
              el.x = rtempx;

              let rtempy = lastofredoActivity.data.y;
              lastofredoActivity.data.y = el.y;
              el.y = rtempy;
            }
          });
          that.history.push(lastofredoActivity);
          that.redohistory.splice(that.redohistory.length - 1, 1);
        } else if (lastofredoActivity?.action == "delete") {
          that.elements.splice(lastofredoActivity.index, 1);
          that.history.push(lastofredoActivity);
          that.redohistory.splice(that.redohistory.length - 1, 1);
        }
      }
    }),
      // tharuni end
      bus.$on("updateZsealCurrency", (data) => {});
    bus.$on("groupFieldUpdateOnChange", (data) => {
      this.checkBoxGrpObj = JSON.parse(JSON.stringify(data));
    });
    bus.$on("repeatableData-updated", (data) => {
      if (
        data.field &&
        data.field.key &&
        this.$route.name == "edit-configure-template-image"
      ) {
        this.updateRepeatableVariablesData(
          data.repeatableDataId,
          data.field,
          data.data,
          data.value
        );
      }
    });
    bus.$on("scroll-down", () => {
      if (this.$refs.configureScroll) {
        let scrollbarEl = this.$refs.configureScroll.wrap;
        scrollbarEl.scrollTop = scrollbarEl.scrollHeight;
      }
    });
    bus.$on("fields-removed", (id) => {
      if (this.$route.params.configurable_document_id == id) {
        this.checkAddFormFields();
      }
    });

    bus.$on("open-quick-entity-add-individual", (data) => {
      this.selectedEntityQuickAddField = data.entityField;
      let newEntityAddedData = [];
      this.currentEntityOfQuickAdd = [];
      this.currentIndividualEntity = [];
      this.currentEntityRelationships = [];
      newEntityAddedData = data.field;
      this.currentEntityOfQuickAdd = newEntityAddedData;
      this.currentIndividualEntity = data.currentEntity;
      this.currentEntityRelationships = data.relationshipsData;
      this.quickAddStandardTemplteIndividualEntity = data.standardTemplate;
      this.quickAddNewEntityData = true;
    });
  },
  data() {
    return {
      checkAllUsersAssigned: [],
      showAichatMate: true,
      isResizing: false,
      columnWidths: [],
      filteredResult: [],
      ck_check_image:
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAAAb0lEQVRIie2SwQ2AIBAEpwo0tgjlGtFq9AEJiBBiOH43yf1gud0FFOUHK2BninvgBpy0+ALsUfwENhUfFreE0lrkhfrO2Q+uc3FIHMCQrF+8rYtlXntEvNAyiqFYWuROpnxFCNseccQ2LzFxFKXOA8KgLcHpsapLAAAAAElFTkSuQmCC",
      allFieldsData: [
        {
          key: "SINGLE_LINE_TEXT",
          name: "Single Line Text",
          img_url: "SingleLine.svg",
        },
        {
          key: "MULTI_LINE_TEXT",
          name: "Multi Line Text",
          img_url: "MultiLine.svg",
        },
        { key: "SELECT", name: "Select", img_url: "Select.svg" },
        { key: "NUMBER", name: "Number", img_url: "Number.svg" },
        {
          key: "MULTI_SELECT",
          name: "Multiple Select",
          img_url: "MultiSelect.svg",
        },
        { key: "DATE", name: "Date", img_url: "Date.svg" },
        { key: "TIME", name: "Time", img_url: "Date.svg" },
        { key: "DATE_TIME", name: "Date Time", img_url: "Date.svg" },
        { key: "YES_OR_NO", name: "Yes or No", img_url: "YesNo.svg" },
        { key: "CHECKBOX", name: "Checkbox", img_url: "CheckBox.svg" },
        { key: "RADIO", name: "Radio", img_url: "CheckBox.svg" },
        { key: "PHONE_COUNTRY_CODE", name: "Phone", img_url: "Number.svg" },
        { key: "CURRENCY", name: "Currency", img_url: "Number.svg" },
        { key: "IMAGE", name: "Image", img_url: "File.svg" },
      ],
      isEmptyValueInTableRow: false,
      selectedOptions: [],
      aIgeneratedExtraFields: false,
      fieldTypes: [
        { input_type: "SINGLE_LINE_TEXT", label: "Single Line Text" },
        { input_type: "SINGLE_LINE_CONTENT", label: "Single Line Content" },
        { input_type: "CURRENCY", label: "Currency" },
        { input_type: "DATE", label: "Date" },
        { input_type: "NUMBER", label: "Number" },
        // {input_type:'SELECT',label:'Select'},
      ],
      CurrencyTypes: [
        {
          value: "USD",
          name: "$",
        },

        {
          value: "CAD",
          name: "$",
        },
        {
          value: "AUD",
          name: "$",
        },
        {
          value: "INR",
          name: "₹",
        },
        {
          value: "EUR",
          name: "€",
        },
      ],
      editorFieldsUsingAI: [],
      editorFieldsSelectedUsingAI: [],
      showChatbot: false,
      entityIdObject: {},
      alignment: "left",
      searchedLabel: "",
      filteredMapFilledByFieldLabels: [],
      checkIsUserDeleted: false,
      editorKey: 0,
      outerVisible: false,
      imgData: "",
      image_url: "",
      fileUploading: false,
      profileData: null,
      upLoadEffect: false,
      buttondisale: true,
      imageUrlData: "",
      filledByFromDocument: "",
      fileData: null,
      signLoading: false,
      signatureIndex: -1,
      imageFieldIndex: -1,
      previewEditorContent: "",
      mappedFieldsOfUsers: [],
      checkSignerUsers: [],
      hasCkEditorEmptyFields: false,
      uploadDocuments: false,
      searchUploadDocument: "",
      documentUploadData: [],
      generatedName: "",
      fromCKeditor: false,
      generatingDocumentName: false,
      selectedCkeditorField: "",
      dropdownHasFocus: false,
      isHorizontalLineFromFormTemplate: false,
      lockTemplateFields: false,
      editColumnTitle: false,
      editedColumnData: {},
      history: [],
      redohistory: [],
      selectedEntityQuickAddField: {},
      currentEntityOfQuickAdd: [],
      currentIndividualEntity: [],
      blanckDocumentModal: false,
      quickAddStandardTemplteIndividualEntity: [],
      quickAddNewEntityData: false,
      newEntityForm: {},
      selectedColHeader: "",
      selectedColIndex: "",
      selectedColData: "",
      filesData: {},
      checkboxErrors: [],
      showCheckboxErrors: false,
      blanckDocumentData: {
        name: "",
        pages: 1,
        loading: false,
      },
      blankTemplatesUrl: [
        "/rest/esignature/files/3cc3b9ac-ff3d-4dfa-90a9-96644c719a15.pdf",
        "/rest/esignature/files/dfc42fc5-4696-4e88-81df-8daf1a7a63dd.pdf",
        "/rest/esignature/files/56647615-6ccc-49c6-9991-b72be2706148.pdf",
      ],
      is_saveTemplate: false,
      uploadFromOthersFileName: "",
      uploadFromOthersBlobData: null,
      isAspectRatio: true,
      ErrorMessageUpload: false,
      dialogVisible: false,
      isFromFieldActivated: false,
      deltaXToMove: 0,
      lastPositions: {},
      showAddData: false,
      deltaYToMove: 0,
      deltaXForNearestField: 4,
      deltaYForNearestField: 4,
      posStep: 1,
      posStepShift: 5,
      pageWidth: 0,
      deltaXOfPageWidth: 2,
      pageHeight: 0,
      currentUserSign: null,
      currentUserInitial: null,
      isFieldEditting: false,
      recipientSelectedColor: "",
      defined_colors: ["#FF495C", "#3777FF", "#EE964B", "#03CEA4"],
      fillingEditorFields: false,
      changeFilledByModal: false,
      filledBySelections: [],
      mapFilledByFieldLabels: [],
      searchedMapLables: [],
      previewEditorContentModal: false,
      fillingFields: [],
      senderFields: [],
      receiverFields: [],
      dragItemHeight: 40,
      deltaX: 0,
      deltaY: 0,
      mouseX: 0,
      mouseY: 0,
      isDragging: false,
      tableElementLoading: false,
      activeCollapse: ["1", "2", "3"],
      activeEntityCollapse: [],
      tableLoading: false,
      tableKey: 0,
      elements: [],
      width: 0,
      height: 0,
      x: 0,
      y: 0,
      addItemDataDialogVisible: false,
      maximized: false,
      selectedItemIndex: null,
      selectedItem: {},
      refreshForm: false,
      selectedSignatureItem: {},
      selectedSignatureItemIndex: -1,
      captureSignatureDialog: false,
      hasBackground: false,
      documentId: null,
      configurableDocumentId: null,
      documentDataLoading: false,
      loadingText: "Document loading...",
      isDocumentRendered: false,
      documentFormData: null,
      documentName: "",
      loading: false,
      backgroundImage: "",
      previewVisible: false,
      activatedItem: null,
      activatedItemIndex: -1,
      fields: [],
      currentFormBuilderWidth: null,
      storedFormBuilderWidth: null,
      checkExistingData: false,
      backgroundImages: [
        "https://devnimblehrms.s3.ap-south-1.amazonaws.com/company-document-backgrounds/60468b2c0a7b7942d5781c36/60468b2c0a7b7942d5781c36-0.png",
      ],
      loadingElements: false,
      selectedTemplateFields: [],
      selectedEntityFields: [],
      templatesUsed: [],
      entitiesUsed: [],
      showErrorElementDialog: false,
      showServerErrorsDialog: false,
      showDocumentUserSettings: false,
      errorElements: [],
      showDocumentExpirationDatesSettings: false,
      fieldErrorMessage: "",
      isMergeDocument: false,
      typeNameMap: {
        logo: "Company Logo",
        SINGLE_LINE_TEXT: "Single Line Input",
        NUMBER: "Number Input",
        MULTI_LINE_TEXT: "Multi line Text",
        SELECT: "DropDown",
        CHECKBOX: "CheckBox",
        DATE: "Date",
        SIGNATURE: "Signature",
        "my-signature": "My Signature",
      },
      documentUsers: [
        {
          email: "",
          first_name: "",
          last_name: "",
          e_signature_required: true,
          value: "SENDER",
          name: "SENDER",
          default: true,
          e_signature_order: 0,
        },
      ],
      num: 0,
      num1: 0,
      tableData: [],
      enforceSignatureOrder: true,
      signaturesFields: [],
      signatureDates: [],
      signaturesConfig: [],
      submitEvent: false,
      params: {},

      expirationSettings: {
        valid_from_date: {
          is_manual: false,
          manual_type: "",
          signature_user: "",
          signature_user_post_days: "",
          admin_approve_post_days: "",
        },
        valid_to_date: {
          is_manual: false,
          manual_type: "",
          signature_user: "",
          signature_user_post_days: "",
          admin_approve_post_days: "",
        },
      },
      options: [],
      recipientSelection: "",
      list: [],
      recipientsList: [
        "Arian",
        "Arihant",
        "Aristomenis",
        "Azaan",
        "Azedine",
        "Azeem",
        "Azim",
        "Aziz",
        "Azlan",
        "Azzam",
        "Azzedine",
        "Babatunmise",
        "Babur",
        "Benn",
        "Bennett",
        "Benny",
        "Benoit",
        "Iestyn",
        "Ieuan",
        "Igor",
        "Ihtisham",
        "Ijay",
        "Ikechukwu",
        "Ikemsinachukwu",
        "Ilyaas",
        "Ilyas",
        "Iman",
        "Immanuel",
        "Jeswin",
        "Ravin",
        "Ray",
      ],
      previousDocumentRecipientsList: [],
      documentRecipientsList: [
        {
          color: "#F754A2",
          email: "",
          first_name: "",
          last_name: "",
          e_signature_required: true,
          value: "SENDER",
          type: "SENDER",
          name: "SENDER",
          default: true,
          e_signature_order: 0,
        },
      ],
      addDocumentRecipient: false,
      addDocumentUsers: false,
      addDocumentRecipientForm: {
        searchRecipient: "",
      },
      addNewRecipient: {
        email: "",
        first_name: "",
        last_name: "",
        e_signature_required: true,
        value: "RECEIVER",
        name: "RECEIVER",
        type: "RECEIVER",
        e_signature_order: 0,
      },
      // for users edit
      selectedUser: null,
      selectedUserIndex: 0,
      contentFields: [
        "PARAGRAPH",
        "HEADING",
        "logo",
        "INITIAL",
        "my-signature",
        "TABLE",
      ],
      userFields: {
        first_name: "First Name",
        last_name: "Last Name",
        company_name: "Company",
        address: "Address",
        phone: "Phone",
        email: "Email",
        full_name: "Full Name",
        title: "Title",
      },
      saveAction: "",
      isTemplate: false,
      templateTitle: "",
      isTemplateToSave: false,
      dialogVisibleForsaveDarft: false,
      isTemplateForm: {
        documentTitle: "",
        agreed: "",
      },
      columnHovered: false,
      checkBoxGroupArr: [],
      checkBoxGroupArray: [],
      formTemplatesCheckBoxGroups: [],
      dialogErr: false,
      current_group_index: -1,
      checkBoxGrpObj: {},
      check_box_group_form: {
        label: "",
        key: "",
        filled_by: "",
        min_value: 1,
        max_value: 1,
        // required: false,
        show_checkbox_label: false,
      },
      isShowPreview: false,
      showSignErrorElementDialog: false,
      showFinishErrorElementDialog: false,
      constantUsers: [],
      constantUsersPopup: false,
      unWantedFields: [
        "INITIAL",
        "SIGNATURE",
        "IMAGE",
        "HORIZONTAL_LINE",
        "HEADING",
        "PARAGRAPH",
        "my-signature",
        "MY_INITIAL",
        "DATE_SIGNED",
        "ZSEAL",
        "SINGLE_LINE_CONTENT",
        "ZSEALARROWIMAGE",
        //"CHECKBOX",
        "FORMULA",
      ],
      createDraftDoc: false,

      // table
      selectedHeader: "",
      selectedHeaderIndex: "",
      document_url_path: "",
      fontTypes: [],
      font_Size: 16,
      fontSizes: [
        {
          value: 8,
          label: 8,
        },
        {
          value: 10,
          label: 10,
        },
        {
          value: 12,
          label: 12,
        },
        {
          value: 14,
          label: 14,
        },
        {
          value: 16,
          label: 16,
        },
        {
          value: 18,
          label: 18,
        },
        {
          value: 20,
          label: 20,
        },
        {
          value: 22,
          label: 22,
        },
        {
          value: 24,
          label: 24,
        },
        {
          value: 26,
          label: 26,
        },
        {
          value: 28,
          label: 28,
        },
        {
          value: 30,
          label: 30,
        },
        {
          value: 32,
          label: 32,
        },
        {
          value: 36,
          label: 36,
        },
        {
          value: 48,
          label: 48,
        },
        {
          value: 56,
          label: 56,
        },
        {
          value: 72,
          label: 72,
        },
        {
          value: 84,
          label: 84,
        },
        {
          value: 96,
          label: 96,
        },
      ],
      templateOwner: null,
      value: "",
      uploadImageLoading: false,
      imgData: null,
      imgDataWidth: 0,
      imgDataHeight: 0,
      addMySignatureStatus: false,
      addMyInitialStatus: false,
      myInitial: {
        text: "",
        color: "",
        value: "",
      },
      authid: null,
      dialogFormVisible: false,
      isNotInitial: false,
      getLinkModal: false,
      embedCode: "",
      getLink: "",
      is_published: false,
      access_to_all: false,
      accessing_data: [
        {
          key: "ALL",
          label: "Allow new user",
          value: true,
        },
        {
          key: "CONTACT_TYPE",
          label: "Specified Recipients",
          value: false,
        },
      ],
      is_publishing: false,
      secure_access: false,
      update_contacts: false,
      attachmentLoading: false,
      selectedAttachmentFile: {
        document_name: "",
        attachment_url: "",
        valid_from: "",
        document_number: "",
        expires_on: "",
        title: "",
        description: "",
        activeDetails: [],
      },
      replaceDocumentModel: false,
      changeDocumentMethod: false,
      changeDocumentDocumentModel: false,
      files: [],
      replaceDocumentLoading: false,
      logoError: "",
      isAnyoneCanApprove: false,
      fieldsFormUpload: {
        title: "",
        type: "CUSTOM",
        file_ref_id: "",
        selectedFile: "",
        selectedFileName: "",
      },
      scale: 1,
      isInitialRender: true,
      docPages: 0,
      pdfPages: 1,
      isRightRotate: false,
      isLeftRotate: false,
      rotations: [],
      currentActivatePage: 0,
      currentActivateDoc: 0,
      setMaxDate: "",
      setMinDate: "",
      deleted_pages: [],
      currentSelectedPageIndex: -1,
      currentSelectedDocIndex: -1,
      isEditingDocument: false,
      isAddingPage: false,
      isDeletingPage: false,
      affectedFields: [],
      allPdfData: [],
      document_url_paths: [],
      document_url_relative_paths: [],
      total_documents: 1,
      reservedLabels: [
        "first name",
        "last name",
        "email",
        "title",
        "address",
        "phone",
        "company",
        "full name",
      ],
      canEdit: false,
      canShare: false,
      canGetLink: false,
      canCreate: false,
      allUsersEntityData: [],
      selectedUserEntity: {},
      allTemplatesData: {},
      names: [],
      docSettingsVisible: false,
      selectedRepeatableIndex: "",
      allFormFields: [],
      pdfFormFields: {},
      fieldsAlertModel: false,
      addFormFields: false,
      fields_found: false,
      is_xfa_pdf: false,
      selectedRecipient: "SENDER",
      textHighlightStyle: "",
      currentActiveText: {},
      textHighlightVisible: false,
      isEditingText: false,
      selectedText: {},
      selectedTextIndex: -1,
      selectedTextPageIndex: -1,
      selectedTextDocIndex: -1,
      selectedTextInPdf: "",
      selectedTextRation: 1,
      pdfEditingChanges: [],
      isPdfEditing: false,
      pdfDocData: null,
      isMakeBold: false,
      isMakeItalic: false,
      isChangeColor: false,
      isEditMode: false,
      allPdfText: {},
      allPdfTextStyle: {},
      pdfFontTypes: ["Helvetica", "TimesRoman"],
      isEarserMode: false,
      isEarserDraw: false,
      isEarserSelect: false,
      startingX: -1,
      startingY: -1,
      endingX: -1,
      endingY: -1,
      pdfRectangleChanges: [],
      documentData: {},
      pdfRectangleTextChanges: [],
      isAddingText: false,
      addedTextOnRectangle: "",
      isDrawLine: false,
      isDrawingLine: false,
      pdfLineChanges: [],
      drawLineColor: "#000000",
      isDrawLineColorChange: false,
      globalVariablesData: [],
      signerUserType: [],
      unfilledFields: [],
      zindex: -1,
      tableBorderColor: "black",
      weekdays_options: [
        { name: "Monday", value: "MON" },
        { name: "Tuesday", value: "TUE" },
        { name: "Wednesday", value: "WED" },
        { name: "Thursday", value: "THU" },
        { name: "Friday", value: "FRI" },
        { name: "Saturday", value: "SAT" },
        { name: "Sunday", value: "SUN" },
      ],
      editor: null,
      selectedCkeditorField: "",
      editorContent: "",
      openCk: false,
      editorConfig: ckEditorConfig,
      contactTypeEntityId: null,
      allMentionableFields: [],
      allCompanyFields: [],
      allCompanyDataFields: [],
      allSelectedEntityMentionFields: [],
      allEntities: [],
      generatedName: "",
      fromCKeditor: false,
      generatingDocumentName: false,
      editorFields: [],
      filterSenderFields: [],
    };
  },
  computed: {
    ...mapGetters("configurableDocuments", [
      "getConfigurableDocumentUpdateStatus",
      "getConfigurableDocumentAddStatus",
      "getConfigurableDocumentData",
      "getConfigurableDocumentErrors",
      "getConfigurableDocumentDataById",
      "getDocumentUsers",
      "getWordFileContent",
    ]),
    ...mapGetters("s3FileUpload", ["getFileUploadData", "getFileUploadStatus"]),
    ...mapGetters("auth", [
      "getAuthenticatedUser",
      "getInitialUrl",
      "getUserSignature",
      "getDefaultDateFormat",
      "getActiveWorkspace",
      "getUserType",
    ]),
    ...mapGetters("fileUpload", [
      "getFontTypes",
      "getUploadFileDataStatus",
      "getUploadStatus",
      "getAddPageStatus",
      "getCopyDocumentUrl",
      "getFetchPreSignedUrlStatus",
      "getAllDocumentFieldsRemoved",
    ]),
    ...mapGetters("documents", [
      "getDraftDocumentCreateStatus",
      "getDraftDocumentCreateData",
      "getDocumentFormFieldsData",
      "getSenderFinishDocumentStatus",
      "getSenderFinishDocumentData",
    ]),
    ...mapGetters("navigationOpen", ["getIsMobile", "getIsRSOpen"]),
    ...mapGetters("company", ["getCompanyDetails"]),
    ...mapGetters("entities", [
      "getAllEntities",
      "getEntityDataByQuery",
      "getEntitiesDataByQuery",
      "getCompanyEntityDetails",
    ]),
    ...mapGetters("companyTemplates", ["getAllCompanyTemplatesData"]),
    ...mapGetters("templatesData", [
      "getTemplatesData",
      "getAddNewRepeatableDataStatus",
      "getAddNewRepeatableData",
      "getAddNewRepeatableDataErrors",
      "getNewEntityData",
      "getPrimaryEntityDataStatus",
      "getPrimaryEntityDataErrors",
      "getEntityDataCreateByTemplateStatus",
      "getNewEntityCreateError",
    ]),
    ...mapGetters("globalVariables", ["getAllGlobalVariables"]),
    getPdfPages() {
      return this.pdfPages;
    },
    getDocumentIsEditable() {
      return false;
    },
    getTableHeaders() {
      return this.selectedItem.headers;
    },
    // tableBorderColor(){
    //   return black
    // },
    basePdfdownloadUrl() {
      return appConfig.S3_BUCKET_URL;
      // return appConfig.JAVA_BASE_URL
    },
        isSenderCCorNot() {
      return this.documentRecipientsList.filter(
        (e) => e && e.type === "SENDER" && e.user_type === "CC"
      );
    },
    totalFieldsCount() {
      return this.editorFields.length;
    },
    filledFieldsCount() {
      return this.editorFields.filter((item) => this.isFieldFilled(item))
        .length;
    },
    progressPercentage() {
      return (this.filledFieldsCount / this.totalFieldsCount) * 100;
    },

    getLogoUrl() {
      return appConfig.S3_BUCKET_URL + "/" + this.getCompanyProfileData.logo;
    },
    getTimeProperties() {
      if (this.selectedItem.time_range && this.selectedItem.step_time) {
        let properties = {};
        properties["start"] = moment(this.selectedItem.time_range[0]).format(
          "HH:mm:ss"
        );
        properties["end"] = moment(this.selectedItem.time_range[1]).format(
          "HH:mm:ss"
        );
        if (
          this.selectedItem.step_time == 60 ||
          this.selectedItem.step_time == "60"
        ) {
          properties["step"] = "01:00:00";
        } else {
          properties["step"] = "00:" + this.selectedItem.step_time + ":00";
        }
        return properties;
      }
      return {
        start: "00:00:00",
        step: "00:30:00",
        end: "23:59:00",
      };
    },
    getAllPdfData() {
      return this.allPdfData;
    },
    // tableWidth() {
    //   return 100%
    //   // (this.selectedItem.headers.length * 170) + "px"; // Assuming each column has a width of 100px
    // },

    getTotalPdfs() {
      return this.total_documents;
    },
    getBackgroundImage() {
      return (path) => appConfig.S3_BUCKET_URL + "/" + path;
    },
    allElements() {
      return this.elements;
    },
    getSenderEmail() {
      let sender = "";
      if (
        this.getSingleDocumentData &&
        this.getSingleDocumentData.data &&
        this.getSingleDocumentData.data.document_users
      ) {
        let tempSender = this.getSingleDocumentData.data.document_users.find(
          (el) => el && el.type && el.type == "SENDER"
        );
        sender = tempSender;
      }
      return sender;
    },
    getSenderUserType() {
      let data = this.documentRecipientsList.find((el) => {
        if (el && el.type == "SENDER") {
          return el;
        }
      });
      return data;
    },
    getSignerUserTypeLength() {
      let data = this.documentRecipientsList.filter((el) => {
        if ((el && el.user_type == "SIGNER") || el.user_type == "APPROVER") {
          return this.signerUserType.push(el.user_type);
        }
      });
      return data.length;
      //signerUserType
    },
    checkAllfieldsFilled() {
      if (
        Object.keys(this.newEntityForm) &&
        this.currentEntityOfQuickAdd &&
        Object.keys(this.newEntityForm).length >=
          this.currentEntityOfQuickAdd.length
      ) {
        let emptyField = this.currentEntityOfQuickAdd.find(
          (e) =>
            (e.key && this.newEntityForm[e.key] == "") ||
            this.newEntityForm[e.key] == undefined
        );
        if (emptyField) {
          return true;
        }
        return false;
      }
      return true;
    },
    getSelectedTextStyle() {
      let style = "overflow: hidden; ";
      if (this.selectedText && this.selectedText.style) {
        style += `font-size: ${
          this.selectedText.height
        } !important; font-family: ${
          this.selectedText.style.fontFamily
        }; font-style: ${
          this.selectedText.style.weight == 2 ||
          this.selectedText.style.weight == 3
            ? "italic"
            : "normal"
        };font-weight:${
          this.selectedText.style.weight == 1 ||
          this.selectedText.style.weight == 3
            ? "bold"
            : "normal"
        };color:${
          this.selectedText.style.color
            ? this.selectedText.style.color
            : "black"
        }; `;
      }
      return style;
    },
    getColorByFilledBy() {
      return (item) => {
        let style = "";
        if (item.filled_by && item.type == "HORIZONTAL_LINE") {
          style = `background: none !important;border:1px solid transparent`;
        } else if (item.type == "ZSEAL") {
          style = `background: transparent;border:1px solid transparent`;
        } else if (item.filled_by || item.selected_user) {
          this.documentRecipientsList.map((user) => {
            if (
              (user.value == item.filled_by ||
                user.value == item.selected_user) &&
              user.color
            ) {
              style = `background: ${user.color + "33"}; border: 1px solid ${
                user.color
              }; color: #000000`;
            }
          });
          return style;
        } else {
          if (item.template_field_filled_by == "SENDER") {
            style = "background:#dcffca; color: #000000";
          } else if (item.entity_field_filled_by == "SENDER") {
            style = "background:#dcffca; color: 000000";
          } else {
            style = "background:#f6d2d5; color:#000000";
          }
        }
        if (item.type != "logo") {
          return style;
        }
        return "";
      };
    },
    getTextColorByFilledBy() {
      return (item) => {
        let style = "";
        if (item.filled_by || item.selected_user) {
          this.documentRecipientsList.map((user) => {
            if (
              (user.value == item.filled_by ||
                user.value == item.selected_user) &&
              user.color
            ) {
              style = `color: ${user.color}`;
            }
          });
          return style;
        } else {
          style = "color: #000000";
        }
        if (item.type != "logo") {
          return style;
        }
        return "";
      };
    },
    getRandomString() {
      return (Math.random() + 1).toString(36).substring(7);
    },
    hasTemplateFields() {
      let fieldsnOIncludesInTemplate = [
        "SIGNATURE",
        "my-signature",
        "DATE_SIGNED",
      ];

      return fieldsnOIncludesInTemplate.indexOf(this.selectedItem.type) > -1
        ? false
        : true;
    },
    getSignaturesFields() {
      let signaturesFields = this.elements.filter((e) => e.type == "SIGNATURE");
      let duplicateKeyCounter = {};

      return signaturesFields.map((element) => {
        let newKey = this.generateKeyForField(element.label);
        if (duplicateKeyCounter[newKey]) {
          element.key = newKey + "_" + duplicateKeyCounter[newKey];
          duplicateKeyCounter[newKey]++;
        } else {
          duplicateKeyCounter[newKey] = 1;
          element.key = newKey;
        }
        return element;
      });
    },
  },
  async mounted() {
    this.loading = true;
    this.loadingText = "Please wait we are getting the things ready for you...";
    this.authid =
      this.getAuthenticatedUser && this.getAuthenticatedUser._id
        ? this.getAuthenticatedUser._id
        : null;
    this.createDraftDoc = false;
    this.currentFormBuilderWidth = this.$refs.formBuilder.clientWidth;
    this.list = this.recipientsList.map((item) => {
      return { value: `value:${item}`, label: `label:${item}` };
    });
    if (
      this.$route.params.configurable_document_id &&
      this.getDocumentFormFieldsData &&
      this.getDocumentFormFieldsData[
        this.$route.params.configurable_document_id
      ]
    ) {
      this.pdfFormFields = {
        ...this.getDocumentFormFieldsData[
          this.$route.params.configurable_document_id
        ],
      };
    }
    await this.$store.commit(
      "documents/setDocumentFormFieldsData",
      {},
      {
        root: true,
      }
    );
    await this.$store.commit("fileUpload/setAllDocumentFieldsRemoved", false, {
      root: true,
    });
    this.isTemplate = true;
    await Promise.all([
      this.fetchCompanyDetails(),
      this.fetchDocumentDetails(),
      this.getAllFontTypes(),
      this.$store.dispatch("auth/fetchInitialSignature"),
      this.$store.dispatch("auth/fetchUserSignature"),
      this.$store.dispatch("globalVariables/fetchGlobalVariables", {
        get_all: true,
      }),
    ]);
    if (this.getAllGlobalVariables && this.getAllGlobalVariables.data) {
      this.globalVariablesData = [...this.getAllGlobalVariables.data];
    }
    this.isShowPreview = false;
    if (!this.openCk) {
      this.handleScroll();
    }
    document.addEventListener("dragover", this.onDocumentDrag);
    document
      .querySelectorAll(".field-element")
      .forEach((element) =>
        element.addEventListener("mousedown", this.onDraggableElementMouseDown)
      );
    document.addEventListener("touchmove", this.onDocumentDragMobile, {
      passive: true,
    });
    document
      .querySelectorAll(".field-element")
      .forEach((element) =>
        element.addEventListener(
          "touchstart",
          this.onDraggableElementMouseDownMobile
        )
      );
    let that = this;
    that.setMaxDate = {
      disabledDate: (time) => {
        return (
          time.getTime() >
          new Date(that.selectedAttachmentFile.expires_on).getTime()
        );
      },
    };
    that.setMinDate = {
      disabledDate: (time) => {
        return (
          time.getTime() <
          new Date(that.selectedAttachmentFile.valid_from).getTime()
        );
      },
    };
    document.onmousemove = function (event) {
      that.mousePosition(event);
      that.mouseUpOnDocument(event, false);
    };
    document.onmouseup = function (event) {
      that.mouseUpOnDocument(event, true);
    };
    document.onmousedown = function (event) {
      that.mouseDownOnDocument(event);
    };
    if (
      this.getConfigurableDocumentDataById &&
      this.getConfigurableDocumentDataById?.is_ck_editor_document === true
    ) {
      this.getAllNecessaryDetails();
    }
    if (this.openCk) {
      this.previousDocumentRecipientsList = [...this.documentRecipientsList];
    }
    this.loading = false;
    console.log("this.addDocumentRecipient", this.addDocumentRecipient);
  },
  methods: {
    handleInputForPhoneNumber(item) {
      const input = event.target.value;
      const cleanedInput = input.replace(/[^0-9+]/g, "");
      item.value = cleanedInput;
    },
    handleCloseWarning() {
      this.dialogVisible = false;
      this.$forceUpdate(); // Force a re-render
    },
    handleTimeRange(item) {
      if (
        item &&
        item.type === "TIME_RANGE" &&
        (item.field_type === "ENTITY_FIELD" || this.openCk) &&
        item.value?.length === 0
      ) {
        item.value = "";
        item.time_range = [];
      } else {
        return item.value;
      }
    },
    isEmailInPrimaryFields(data) {
      if (
        data.type === "PRIMARY_FIELDS@email" ||
        data.input_type === "PRIMARY_FIELDS@email"
      ) {
        return true;
      }
      return false;
    },
    async openAiAssistant() {
      try {
        await this.$store.dispatch(
          "configurableDocuments/fetchConfigurableDocumentById",
          {
            id: this.$route.params.configurable_document_id,
            include_download_url: true,
          }
        );
        console.log(
          "this.getConfigurableDocumentDataById",
          this.getConfigurableDocumentDataById
        );
        this.documentDataLoading = true;
        this.loadingText = "Please Wait Redirecting To AI Interface...";
        let response = await axios.post("https://ai.esigns.io/kodefast_chat/", {
          admin_id: this.getConfigurableDocumentDataById._id,
          fileUrls: [
            this.getConfigurableDocumentDataById.document_download_s3_urls[0],
          ],
        });
        let data = response?.data;

        localStorage.setItem(
          "selectedAttachmentFile",
          this.getConfigurableDocumentDataById.document_download_s3_urls[0]
        );
        localStorage.setItem(
          "routeUrl",
          JSON.stringify({
            path: this.$route.path,
            params: {
              document_id: this.$route.params.document_id,
              configurable_document_id:
                this.$route.params.configurable_document_id,
            },
            query: this.$route.query,
          })
        );
        localStorage.setItem("aiData", JSON.stringify(data));
        this.$router.push({
          name: "AiAssistant",
          params: {
            selectedAttachmentFile:
              this.getConfigurableDocumentDataById.document_download_s3_urls[0],
            routeUrl: {
              path: this.$route.path,
              params: {
                document_id: this.$route.params.document_id,
                configurable_document_id:
                  this.$route.params.configurable_document_id,
              },
              query: this.$route.query,
            },
            aiData: data,
          },
        });
        this.documentDataLoading = false;
      } catch (err) {
        console.log(err);
        this.$message.error(err);
      }
    },
    handleAddReceipient() {
      this.addDocumentRecipient = true;
    },
    handleCheckContentInEditor() {
      if (this.openCk) {
        bus.$emit("updatingContentInAIPrompt", {
          editorContent: this.editorContent.trim(),
        });
      }
    },
    isEmptyField(item) {
      if (item.is_required) {
        return !item.value;
      }
    },
    uploadImageOuterVisible(index) {
      this.outerVisible = true;
      this.imageFieldIndex = index;
    },
    closeImage() {
      this.outerVisible = false;
      this.uploadmgFile(this.fileData);
    },
    async uploadmgFile(file) {
      var files = await this.dataURLtoFile(file, "image1.jpeg");
      console.log("files", files);
      this.uploadckImageFile(files);
    },
    async uploadckImageFile(file) {
      this.documentDataLoading = true;
      if (
        file.type == "image/jpeg" ||
        file.type == "image/png" ||
        file.type == "image/jpg"
      ) {
        var formData = new FormData();
        formData.append("files", file);
        await this.$store.dispatch("s3FileUpload/uploadFileToS3", formData);
        if (this.getFileUploadStatus && this.getFileUploadData) {
          this.documentDataLoading = false;
          this.$message.success("Image Uploaded Successfully..");
          if (
            this.filteredMapFilledByFieldLabels &&
            this.filteredMapFilledByFieldLabels.length &&
            this.getSignerUserTypeLength > 1
          ) {
            this.filteredMapFilledByFieldLabels[this.imageFieldIndex]["value"] =
              this.getFileUploadData;
          } else {
            this.editorFields.forEach((e) => {
              if (e && e.key === this.editorFields[this.imageFieldIndex].key) {
                e.value = this.getFileUploadData;
              }
            });
            this.editorFields[this.imageFieldIndex]["value"] =
              this.getFileUploadData;
          }

          await this.getBase64(file).then((res) => {
            var img = document.createElement("img");
            img.src = res;
            img.onload = function () {
              img.remove();
            };
          });
        } else {
          this.loading = false;
          this.$alert("Error while uploading file");
        }
      } else {
        this.loading = false;
        this.$alert("Please upload a image file");
      }
    },
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    },
    setImageData(file) {
      this.fileData = null;
      this.fileData = file;
    },
    insertImage(file) {
      // this.fileData = file;
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          this.fileData = reader.result;
        };
        reader.readAsDataURL(file);
      }
    },
    fileError() {
      this.$alert("File Supported:JPG,PNG,JPEG,Gif");
    },

    handleUpdatingDocumentName() {
      if (this.openCk) {
        bus.$emit("updatingNameInAIPrompt", {
          documentName: this.documentName,
          mentionFields: this.allMentionableFields,
        });
      }
    },
    // handleUpdatingContentToAI(){
    //   if(this.openCk){
    //     bus.$emit('updatingContentInAIPrompt',{
    //       editorContent:this.editorContent.trim()
    //   })
    //   }
    // },
    checkMultiplePrimaryFields(event, item) {
      if (
        this.filteredMapFilledByFieldLabels &&
        this.filteredMapFilledByFieldLabels.length
      ) {
        this.filteredMapFilledByFieldLabels.forEach((e, index) => {
          if (
            e &&
            e.id === item.id &&
            e.input_type.startsWith("PRIMARY_FIELDS")
          ) {
            this.$set(
              this.filteredMapFilledByFieldLabels[index],
              "value",
              event
            );
          }
        });
      } else {
        this.editorFields.forEach((e, index) => {
          if (
            e &&
            e.id === item.id &&
            e.input_type.startsWith("PRIMARY_FIELDS")
          ) {
            this.$set(this.editorFields[index], "value", event);
          }
        });
      }
    },

    updateIsRequired(data) {
      this.editorFields.forEach((e) => {
        if (e && e.label === data.label) {
          e.is_required = data.is_required;
        }
      });
    },
    isTextInputTypePrimary(item) {
      console.log("-10,ite", item);
      if (item && item.input_type) {
        const textInputTypes = ["PRIMARY_FIELDS"];
        return textInputTypes.startsWith(item.input_type);
      }
      return false;
    },
    isTextInputType(item) {
      if (item && item.input_type) {
        const textInputTypes = ["SINGLE_LINE_TEXT", "MULTI_LINE_TEXT"];
        return textInputTypes.includes(item.input_type);
      }
      return false;
    },
    addEditorFieldsForDocument(selectedItems) {
      let selectedLabels = new Set(selectedItems.map((item) => item));
      let previousEditorFields = this.editorFieldsSelectedUsingAI.slice();
      this.editorFieldsSelectedUsingAI = [];
      selectedItems.forEach((item) => {
        let matchedItem = this.allMentionableFields.find(
          (e) => e && e.label && e.label === item
        );
        if (matchedItem) {
          if (matchedItem.isStatic) {
            let matchingField = this.allMentionableFields.find(
              (field) => field.label === matchedItem.label
            );
            if (matchingField) {
              matchedItem.list_data = matchingField.list_data;
              matchedItem.options = matchingField.options;
              matchedItem.validations = matchingField.validations;
            }
            let uniqueLabel = matchedItem.label;
            let counter = 1;
            while (
              this.editorFieldsSelectedUsingAI.some(
                (e) => e.label === uniqueLabel
              )
            ) {
              uniqueLabel = `${matchedItem.label} ${counter}`;
              counter++;
            }
            matchedItem.label = uniqueLabel;
            matchedItem.fixedLabel = uniqueLabel;
            matchedItem.value = "";
            matchedItem.template_key = `${matchedItem.label}_${counter}`;
            matchedItem.id = `${matchedItem.label}_${counter}`;
            if (!matchedItem.filled_by) {
              let user = this.documentRecipientsList.find(
                (e) =>
                  e.filled_by === this.recipientSelection &&
                  e.user_type === "CC"
              );
              matchedItem.filled_by = user ? "SENDER" : this.recipientSelection;
            }
            this.editorFieldsSelectedUsingAI.push({ ...matchedItem });
          } else {
            this.calculateXandYPositions(matchedItem);
          }
        }
      });
      previousEditorFields.forEach((field) => {
        if (
          !this.editorFieldsSelectedUsingAI.some((e) => e.label === field.label)
        ) {
          this.editorFieldsSelectedUsingAI.push(field);
        }
      });
      previousEditorFields.forEach((field) => {
        if (!selectedLabels.has(field.label)) {
          const index = this.editorFieldsSelectedUsingAI.findIndex(
            (e) => e.label === field.label
          );
          if (index !== -1) {
            this.editorFieldsSelectedUsingAI.splice(index, 1);
          }
        }
      });
    },

    CloseChatBot() {
      this.showChatbot = false;
    },
    openChatbot() {
      if (!this.recipientSelection) {
        this.$message.error({
          showClose: true,
          message: "Please Select The Entity",
          center: true,
        });
        return;
      }
      this.editorFieldsUsingAI = [];
      this.editorFieldsSelectedUsingAI = [];
      this.showChatbot = !this.showChatbot;
      // this.handleUpdatingContentToAI()
    },
    startContentCheck() {
      this.contentCheckInterval = setInterval(this.checkEditorContent, 4000); // Adjust the interval as needed
    },
    checkCkEditorFilledByModal() {
      if (this.openCk && this.changeFilledByModal) {
        this.dropdownHasFocus = true;
      } else if (!this.changeFilledByModal) {
        this.dropdownHasFocus = false;
        this.changeFilledByModal = false;
      }
    },
    filterLabels(searchTerm) {
      console.log(this.searchedMapLables);
      if (searchTerm && searchTerm.length) {
        if (this.getSignerUserTypeLength > 1) {
          this.filteredMapFilledByFieldLabels = this.searchedMapLables.filter(
            (item) => {
              if (typeof item.label === "string") {
                return item.label
                  .toLowerCase()
                  .includes(searchTerm.toLowerCase());
              }
              return false;
            }
          );
          return this.filteredMapFilledByFieldLabels;
        } else {
          this.editorFields = this.searchedMapLables.filter((item) => {
            if (typeof item.label === "string") {
              return item.label
                .toLowerCase()
                .includes(searchTerm.toLowerCase());
            }
            return false;
          });
          let signLength = this.editorFields.filter(
            (e) =>
              ((e.isStatic && e.isStatic === "true") || e.isStatic === true) &&
              (e.input_type === "SIGNATURE" || e.type === "SIGNATURE")
          );
          this.editorFields.forEach((e) => {
            if (
              e &&
              e.input_type &&
              e.input_type === "CURRENCY" &&
              e.value === ""
            ) {
              e.value = 0;
            }
            if (
              e &&
              (e.isStatic === "true" || e.isStatic === true) &&
              ((e && e.input_type === "SIGNATURE") || e.type === "SIGNATURE")
            ) {
              e.value = this.getAuthenticatedUser.signature;
            }
            if (
              signLength &&
              signLength.length &&
              (e.input_type == "DATE_SIGNED" || e.type == "DATE_SIGNED") &&
              e.filled_by === "SENDER"
            ) {
              let date = new Date();
              e.value = date;
            }
          });
          return this.editorFields;
        }
      } else {
        if (this.getSignerUserTypeLength > 1) {
          return (this.filteredMapFilledByFieldLabels = this.searchedMapLables);
        } else {
          return (this.editorFields = this.searchedMapLables);
        }
      }
    },
    isFieldFilled(field) {
      if (Array.isArray(field.value)) {
        return field.value.length > 0;
      }
      return (
        field.value !== null && field.value !== undefined && field.value !== ""
      );
    },
    closeFillingEditorFields() {
      this.fillingEditorFields = false;
      this.previewEditorContentModal = false;
      this.hasCkEditorEmptyFields = false;
      this.changeFilledByModal = false;
      this.searchedLabel = "";
    },
    handleCheckForDateSigned(item) {
      if (item.type === "DATE_SIGNED") {
        this.$message.error(
          "This is a 'DATE_SIGNED' field. It is automatically filled when the signature is added."
        );
        item.value = "";
        return;
      }
    },
    async fetchEntities() {
      let params = {
        get_all: true,
      };
      await this.$store.dispatch("entities/fetchEntities", params);
      // this.allEntities = await fetchEntitiesByPagination(params);
      this.allEntities = [...this.getAllEntities.data];
    },
    companyDataFeed(opts, callback) {
      var matchProperty = "label",
        data = this.allCompanyDataFields.filter(function (item) {
          return (
            item[matchProperty] &&
            item[matchProperty]
              .toLowerCase()
              .indexOf(opts.query.toLowerCase()) !== -1
          );
        });
      data = data.sort(function (a, b) {
        if (!a[matchProperty]) return 1; // move undefined to the end
        if (!b[matchProperty]) return -1; // move undefined to the end
        return a[matchProperty].localeCompare(b[matchProperty], undefined, {
          sensitivity: "accent",
        });
      });
      callback(data);
    },
    async dataFeed(opts, callback) {
      var matchProperty = "label",
        data = this.allMentionableFields.filter(function (item) {
          return (
            item[matchProperty] &&
            item[matchProperty]
              .toLowerCase()
              .indexOf(opts.query.toLowerCase()) !== -1
          );
        });
      data = data.sort(function (a, b) {
        return a[matchProperty].localeCompare(b[matchProperty], undefined, {
          sensitivity: "accent",
        });
      });
      callback(data);
    },
    handleMouse(ev) {
      console.log("Mouse Event:", ev);
      var editor = CKEDITOR.instances["editor1"];
      // Attach a 'mousemouse' event listener
      editor.on("mousemouse", function (ev) {
        var pageOffset = ev.data.getPageOffset();
        alert(pageOffset.x); // page offset X
        alert(pageOffset.y); // page offset Y
      });
    },
    async getAllNecessaryDetails() {
      //this.getCompanyInfo();
      const screenHeight = window.innerHeight;
      const screenPercent = (screenHeight / window.screen.height) * 100;
      const screenWidth = window.innerWidth;
      await this.fetchEntities();

      this.editorConfig.uiColor = "#9fc5e8";
      this.editorConfig.height =
        screenPercent <= 80
          ? 700
          : screenPercent <= 90
          ? 600
          : screenPercent <= 100
          ? 550
          : 450;
      this.editorConfig.width = 950;
      this.editorConfig.versionCheck = false;
      this.editorConfig.contentsCss = [
        "https://cdn.ckeditor.com/4.24.0-lts/full-all/contents.css",
        "assets/css/pastefromword.css",
        "data:text/css," +
          encodeURIComponent(`
      body {
        background-color: #fefefe;
        padding: 20px;
      }
      .custom-content {
        max-width: 600px;
        margin: auto;
        background-color: #fefefe;
        padding: 20px;
        border-radius: 5px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      }
      .custom-content h2 {
        color: #333333;
      }
    `),
      ];
      this.editorConfig.mentions = [
        {
          feed: this.dataFeed,
          itemTemplate:
            '<li data-id="{id}" data-template-id="{template_key}">' +
            '<strong class="label">{label}</strong>' +
            "</li>",
          outputTemplate: (item) => {
            if (item.isStatic) {
              if (
                item.input_type === "DATE_SIGNED" &&
                !this.checkElementIsBefore("SIGNATURE", this.recipientSelection)
              ) {
                this.$message({
                  message:
                    "Sign Date is linked to the recipient user's signature. Please include a Signature field.",
                  type: "warning",
                });
                return;
              }

              let matchingField = this.allMentionableFields.find(
                (field) => field.label === item.label
              );
              if (matchingField) {
                item.list_data = matchingField.list_data;
                item.options = matchingField.options;
                item.validations = matchingField.validations;
                item.is_required = true;
              }
              const content = this.editorContent;
              // const labelExists = content.includes(item.label);
              let uniqueLabel = item.label;
              let counter = 1;

              // Generate a unique label if the original label already exists
              while (this.editorFields.some((e) => e.label === uniqueLabel)) {
                uniqueLabel = `${item.label} ${counter}`;
                counter++;
              }

              item.label = uniqueLabel;
              item.fixedLabel = uniqueLabel;
              item.template_key = `${this.recipientSelection}@${item.template_key}_${counter}`;
              item.key = item.template_key;
              item.id = `${item.id}_${counter}`;
              let user = this.documentRecipientsList.find(
                (e) =>
                  e.value === this.recipientSelection && e.user_type === "CC"
              );
              if (user) {
                item.filled_by = "SENDER";
              }
              if (!item.filled_by) {
                let user = this.documentRecipientsList.find(
                  (e) =>
                    e.value === this.recipientSelection && e.user_type === "CC"
                );
                if (user) {
                  item.filled_by = "SENDER";
                } else {
                  item.filled_by = this.recipientSelection;
                  item.selected_user = this.recipientSelection;
                }
              }

              if (
                item.input_type === "CURRENCY" &&
                item.isStatic &&
                item.value === ""
              ) {
                item.value = 0;
              }
              if (
                item &&
                item.input_type === "CHECKBOX_GROUP" &&
                (item.value === null ||
                  item.value === "" ||
                  item.value === undefined)
              ) {
                item.value = [];
              }
              console.log(item);
              this.editorFields.push(item);
            } else {
              this.calculateXandYPositions(item);
            }
            return "<span>&nbsp;[[{label}]]&nbsp;</span>";
          },
          minChars: 0,
          maxItems: 5,
          style: {
            maxHeight: "100px",
            overflowY: "auto",
          },
        },
        {
          feed: this.companyDataFeed,
          itemTemplate:
            '<li data-id="{id}">' +
            '<strong class="label">{label}</strong>' +
            "</li>",
          outputTemplate: (item) => {
            if (item.isStatic) {
              let matchingField = this.allMentionableFields.find(
                (field) => field.label === item.label
              );
              if (matchingField) {
                item.list_data = matchingField.list_data;
                item.options = matchingField.options;
                item.validations = matchingField.validations;
              }
              const content = this.editorContent;
              //const labelExists = content.includes(item.label);
              let uniqueLabel = item.label;
              let counter = 1;

              // Generate a unique label if the original label already exists
              while (this.editorFields.some((e) => e.label === uniqueLabel)) {
                uniqueLabel = `${item.label} ${counter}`;
                counter++;
              }

              item.label = uniqueLabel;
              item.fixedLabel = uniqueLabel;
              item.template_key = `${item.template_key}_${counter}`;
              item.id = `${item.id}_${counter}`;
              let user = this.documentRecipientsList.find(
                (e) =>
                  e.value === this.recipientSelection && e.user_type === "CC"
              );
              if (user) {
                item.filled_by = "SENDER";
              }
              if (!item.filled_by) {
                let user = this.documentRecipientsList.find(
                  (e) =>
                    e.value === this.recipientSelection && e.user_type === "CC"
                );
                if (user) {
                  item.filled_by = "SENDER";
                } else {
                  item.filled_by = this.recipientSelection;
                }
              }
              console.log(item);
              this.editorFields.push(item);
            } else {
              this.calculateXandYPositions(item);
            }
            return "<span>&nbsp;[[{label}]]&nbsp;</span>";
          },
          minChars: 0,
        },
      ];
      CKEDITOR.on("dialogDefinition", function (event) {
        var dialogName = event.data.name;
        var dialogDefinition = event.data.definition;
        console.log("dialogName", dialogName);

        if (dialogName === "placeholder") {
          dialogDefinition.onShow = function () {
            var dialog = this;
            var element = dialog.getContentElement("info", "name");
            if (element) {
              element.setValue("Example");
              element.disable();
            }
          };
        }
      });
      await this.getEntityDetails(true);
      this.reloadCKEditorContent();
    },
    handleEditorChange() {
      // Your logic to handle content change
      console.log("Editor content changed:", this.editorContent);
    },
    isBase64String(value) {
      const regex = /^data:image\/[a-z]+;base64,/;
      return regex.test(value);
    },
    async saveAndGeneratePdf() {
      this.loading = true;
      this.loadingText = "Generating pdf...";
      const ckEditorContent = this.editorContent;
      const element = document.createElement("div");
      element.innerHTML = ckEditorContent;
      try {
        const htmlContent = element.innerHTML;
        let companyDataId =
          this.getActiveWorkspace && this.getActiveWorkspace.company_id
            ? this.getActiveWorkspace.company_id
            : this.getActiveWorkspace.company_id._id;
        const { data } = await axios.post(`/ckeditorToPdfUpload`, {
          companyId: companyDataId,
          pdfFile: htmlContent,
        });
        console.log("data", data);
        this.loading = false;
        const params = this.prepareFields();
        params.document_upload_url = data.path;
        params.document_upload_urls = [data.path];
        await this.$store.dispatch(
          "configurableDocuments/updateConfigurableDocument",
          params
        );
        this.loading = false;
        this.generatingDocumentName = false;
      } catch (error) {
        console.error("Error generating and uploading PDF:", error);
        if (err.response && err.response.status === 413) {
          this.$notify.error({
            title: "Error",
            message: `${
              err.response.statusText
                ? err.response.statusText
                : "Payload Too Large"
            }`,
          });
        } else {
          this.$message.error("Error generating and uploading PDF.");
        }
      }
    },
    setAlignStyles(style) {
      this.alignment = style;
      if (this.activatedItem) {
        if (this.activatedItem.alignStyles) {
          this.activatedItem.alignStyles = style;
        }
      }
    },
    handleDragStart(event) {
      event.dataTransfer.setData("text/html", event.target.innerHTML);
    },
    handleDropOnEditor(event) {
      event.preventDefault();
      const data = event.dataTransfer.getData("text/html");
      const editorWrapper = document.getElementById("ckeditorWrapper");

      if (editorWrapper) {
        const editorPosition = editorWrapper.getBoundingClientRect();
        const dropPosition = {
          x: event.clientX - editorPosition.left,
          y: event.clientY - editorPosition.top,
        };

        const editorInstance = CKEDITOR.appendTo(
          editorWrapper,
          { width: "100%", height: "200px" },
          data
        );

        // Position the dropped content in the CKEditor area
        editorInstance.on("instanceReady", function () {
          editorInstance.editable().insertHtml(data);
          editorInstance.container.$.style.position = "absolute";
          editorInstance.container.$.style.left = `${dropPosition.x}px`;
          editorInstance.container.$.style.top = `${dropPosition.y}px`;
        });

        this.editorContent += data; // Append content to Vue data if needed
      }
    },
    deleteHorizontalLine() {
      this.deleteElement();
      this.dialogFormVisible = false;
    },
    handleLockChange(data) {
      this.lockTemplateFields = data;
    },
    handleDropdownLeave() {
      if (!this.dropdownHasFocus) {
        // Close the dropdown
        this.$refs.dropdown.hide();
      }
    },
    getCrossMarkFontSize(width, height) {
      // Calculate the font size based on width and height
      const fontSize = Math.min(width, height) / 1;
      return `${fontSize}px`;
    },
    tableHeight(data) {
      return data.length * 50 + 70 + "px"; // 50px for each row and 70px for header and footer
    },
    addBox(item) {
      if (!item.boxes) {
        item.boxes = []; // Initialize if not already initialized
      }
      if (item.boxes.length && item.boxes.length * item.boxWidth > item.width) {
        this.$message.error(
          "Boxes length should not be greater than the field length"
        );
      } else {
        item.boxes.push("");
      }
    },
    deleteBox(item) {
      item.boxes.pop();
    },
    handleInputChange(item, index) {
      if (item.boxes[index] === "") {
        // If the current box is empty, move focus to the next box
        if (index < item.boxes.length - 1) {
          this.$refs.boxElements[index + 1].querySelector("input").focus();
        }
      }
      if (!item.value) {
        item.value = item.boxes.join("");
        this.$refs.boxElements[index + 1].querySelector("input").focus();
        this.$refs.boxElements[index + 1].focus();
      } else {
        item.value = item.boxes.join("");
        this.$refs.boxElements[index + 1].querySelector("input").focus();
        this.$refs.boxElements[index + 1].focus();
      }
    },
    handleKeyDownInEditor(event) {
      console.log(event);
      if (event.key === "Backspace" || event.key === "Delete") {
        this.checkAndRemoveDateSignedFields();
        this.$nextTick(() => {
          this.checkAndRemoveDateSignedFields();
        });
      }
    },
    handleKeyDown(event, item, index) {
      if (event.key.length === 1 && index < item.boxes.length - 1) {
        // Automatically move to the next box when a character is entered
        this.$refs.boxElements[index + 1].querySelector("input").focus();
      }
      // Handle other key events (e.g., backspace) if needed
    },
    handleBackspace(index) {
      if (
        index > 0 &&
        this.selectedItem.boxes[index] === "" &&
        this.selectedItem.boxes[index - 1] !== ""
      ) {
        this.$refs[`box${index - 1}`][0].focus();
        this.selectedItem.value = this.selectedItem.boxes.join("");
      }
    },
    getRowClassName() {
      return "no-border-row";
    },
    async checkForMergeOrUpload() {
      /** MIXIN **/
      // if(this.openCk){
      //   this.openCk = false
      //   this.getConfigurableDocumentDataById.ck_editor_content = '' &&
      // }
      this.loading = true;
      await this.mergeOrUploadFile();
      this.loading = false;
    },
    backToPrevious() {
      this.$router.go(-1);
      this.changeDocumentMethod = false;
    },
    handleCloseEditColumn() {
      this.editColumnTitle = false;
    },
    handleClose() {
      this.newEntityForm = {};
      this.quickAddNewEntityData = false;
    },
    async addNewEntityDataQuickAdd() {
      try {
        this.loading = true;
        let re =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (this.newEntityForm && !this.newEntityForm.name) {
          this.newEntityForm.name =
            this.newEntityForm.first_name + " " + this.newEntityForm.last_name;
        }
        if (!re.test(this.newEntityForm.email)) {
          this.loading = false;

          this.$message({
            message: "Invalid email format!",
            type: "error",
          });
          return;
        }
        // let checkData = this.checkFormData()
        let data = {
          entity_details_id: null,
          template_id: this.quickAddStandardTemplteIndividualEntity.template_id,
          template_data: this.newEntityForm,
          entity_id: this.currentIndividualEntity._id,
          template_completion_status: true,
          entity_data_id: null,
        };
        await this.$store.dispatch(
          "templatesData/createEntityDataByTemplateData",
          data
        );

        if (this.getEntityDataCreateByTemplateStatus) {
          this.loading = false;
          this.$notify.success({
            title: "Success",
            message: "Data saved successfully",
          });
          if (this.getNewEntityData) {
            bus.$emit("quick-add-new-updated", {
              entity_id: this.getNewEntityData.entity_id,
              entity_data_id: this.getNewEntityData._id,
              form: this.selectedEntityQuickAddField,
              reset: true,
            });
          }
          this.newEntityForm = {};
          this.quickAddNewEntityData = false;
          this.currentEntityOfQuickAdd = [];
        } else {
          this.loading = false;
          if (
            this.getNewEntityCreateError &&
            this.getNewEntityCreateError.message
          ) {
            this.$notify.error({
              title: "Error",
              message: this.getNewEntityCreateError.message,
            });
          } else {
            this.$notify.error({
              title: "Error",
              message: "Error at saving data",
            });
          }
        }
        this.setCurrentEntityValue = true;
      } catch (err) {}
    },
    async addPrimaryDataToEntity() {
      try {
        this.loading = true;
        if (this.newEntityForm) {
          let data = {
            primaryFields: this.currentEntityOfQuickAdd,
            formData: this.newEntityForm,
            templates: this.currentIndividualEntity.templates,
            entity_id: this.currentIndividualEntity._id,
          };
          await this.$store.dispatch(
            "templatesData/createEntityPrimaryData",
            data
          );
          if (this.getPrimaryEntityDataStatus) {
            if (this.getNewEntityData && this.getNewEntityData._id) {
              await this.updateEntitiesData(this.getNewEntityData._id);
              bus.$emit("quick-add-new-updated", {
                entity_id: this.getNewEntityData.entity_id,
                entity_data_id: this.getNewEntityData._id,
                form: this.selectedEntityQuickAddField,
                reset: true,
              });
              this.quickAddNewEntityData = false;
            }
            this.$notify.success({
              title: "Success",
              message: "Primary Data saved successfully",
            });
            bus.$emit("quick-add-new-updated", {
              entity_id: this.getNewEntityData.entity_id,
              entity_data_id: this.getNewEntityData._id,
            });
            this.quickAddNewEntityData = false;
            this.loading = false;
            this.newEntityForm = {};
          } else {
            this.loading = false;
            let msg = "Error at saving data";
            if (
              this.getPrimaryEntityDataErrors &&
              this.getPrimaryEntityDataErrors.message
            ) {
              msg = this.getPrimaryEntityDataErrors.message;
            }
            this.$notify.error({
              title: "Error",
              message: msg,
            });
          }
        } else {
          this.loading = false;
          this.$notify.error({
            title: "Error",
            message: "Please fill all the fields in primary data",
          });
        }
      } catch (err) {}
    },
    async updateEntitiesData(dataId) {
      try {
        this.loading = true;
        // ** mixin method ** //
        let toAssignRelationshipsData = this.prepareRelationshipData(
          this.currentEntityOfQuickAdd,
          this.currentEntityRelationships,
          dataId,
          null,
          this.newEntityForm
        );
        if (toAssignRelationshipsData.length) {
          await this.$store.dispatch(
            "entityRelationships/assignFieldsEntityRelationshipData",
            toAssignRelationshipsData
          );
          this.loading = false;
        } else {
          // await this.assignEntitiesData();
        }
      } catch (err) {}
    },
    async assignEntitiesData() {
      this.loading = true;
      try {
        let data;
        if (this.relationshipDetails.owner_type == "PARENT") {
          data = {
            parent_entity_id: this.relationshipDetails.parent_entity_id,
            child_entity_id: this.relationshipDetails.child_entity_id,
            parent_entity_data_id:
              this.relationshipDetails.parent_entity_data_id,
            child_entity_data_ids: this.selectedChildEntitiesData,
          };
        } else {
          if (this.relationshipDetails.parent_relation_type == "MANY") {
            data = {
              parent_entity_id: this.relationshipDetails.child_entity_id,
              child_entity_id: this.relationshipDetails.parent_entity_id,
              parent_entity_data_id:
                this.relationshipDetails.parent_entity_data_id,
              child_entity_data_ids: this.selectedChildEntitiesData,
            };
          } else {
            data = {
              parent_entity_id: this.relationshipDetails.parent_entity_id,
              child_entity_id: this.relationshipDetails.child_entity_id,
              parent_entity_data_id: this.selectedChildEntitiesData[0],
              child_entity_data_ids: [
                this.relationshipDetails.parent_entity_data_id,
              ],
            };
          }
        }

        await this.$store.dispatch(
          "entityRelationships/assignEntityRelationshipData",
          data
        );
        this.loading = false;
      } catch (err) {
        this.loading = false;
      }
    },
    async editPdf() {
      this.documentDataLoading = true;
      this.document_url_paths.forEach(async (document, index) => {
        let docChanges = this.pdfEditingChanges.filter(
          (change) => change.docIndex == index
        );
        let docRectangles = this.pdfRectangleChanges.filter(
          (e) => e.docId == index
        );
        let pdfTexts = this.pdfRectangleTextChanges.filter(
          (e) => e.docId == index
        );
        let pdfLineChanges = this.pdfLineChanges.filter(
          (e) => e.docId == index
        );

        if (
          (docChanges && docChanges.length) ||
          (docRectangles && docRectangles.length) ||
          (pdfLineChanges && pdfLineChanges.length) ||
          (pdfTexts && pdfTexts.length)
        ) {
          let params = {
            addDocId: false,
            addPageNumber: false,
            stampByTsa: false,
            pdfSignatures: [],
            defaultScale: this.scale,
            keepFileID: true,
            pdfTexts: [],
            pdfLines: [],
            pdfRecs: [],
            pdfImages: [],
          };
          docChanges.forEach((el) => {
            // let pageId = el.item && el.item.currentPage ? el.item.currentPage.toString() : '0_canvas_1';
            // let selectedPage = document.getElementById(pageId);
            let x = el.item.transform[4] * this.scale;
            let y = (el.item.transform[5] + el.item.height) * this.scale;
            if (el.item.currentPageHeight) {
              y = el.item.currentPageHeight - y;
            }
            let color = [255, 255, 255];
            params.pdfTexts.push({
              content: el.text,
              location: {
                page: el.pageIndex,
                x: x,
                y: y,
                width: el.item.width * this.scale,
                height: el.item.height * this.scale,
              },
              font: {
                name:
                  el.item && el.item.style && el.item.style.fontFamily
                    ? el.item.style.fontFamily
                    : "Times New Roman",
                size: el.item && el.item.height ? el.item.height : 12,
                color:
                  el.item && el.item.style && el.item.style.color
                    ? el.item.style.color
                    : "#000000",
                style:
                  el.item && el.item.style && el.item.style.weight
                    ? el.item.style.weight
                    : 0,
              },
              zindex: el.zindex || -1,
            });
            if (
              el.backgroundColor &&
              el.backgroundColor.indexOf("(") &&
              el.backgroundColor.indexOf(")") &&
              el.backgroundColor.slice(
                el.backgroundColor.indexOf("(") + 1,
                el.backgroundColor.indexOf(")")
              )
            ) {
              let codes = el.backgroundColor.slice(
                el.backgroundColor.indexOf("(") + 1,
                el.backgroundColor.indexOf(")")
              );
              if (codes) {
                let colors = codes.split(",").map((e) => parseInt(e));
                if (colors && colors.length) {
                  color = colors;
                }
              }
            }
            params.pdfRecs.push({
              page: el.pageIndex,
              x: x,
              y: y,
              width: el.item.width * this.scale,
              height: (el.item.height + 2) * this.scale,
              lineWidth: 0,
              lineColor: color.join(","),
              opacity: 1,
              colorFill: color.join(","),
              zindex: el.zindex || -1,
            });
          });
          docRectangles.forEach((el) => {
            params.pdfRecs.push({
              page: el.pageIndex,
              x: el.x,
              y: el.y,
              width: el.width,
              height: el.height,
              lineWidth: 0,
              lineColor: el.color.join(","),
              opacity: 1,
              colorFill: el.color.join(","),
              zindex: el.zindex || -1,
            });
          });
          pdfLineChanges.forEach((el) => {
            params.pdfLines.push({
              page: el.pageIndex,
              x: el.x,
              y: el.y,
              urx: el.urx,
              ury: el.y,
              color: el.color.join(","),
              lineWidth: 2,
            });
          });
          pdfTexts.forEach((el) => {
            params.pdfTexts.push({
              content: el.text,
              wraptext: true,
              location: {
                page: el.pageIndex,
                x: el.x,
                y: el.y,
                width: el.width,
                height: el.height,
              },
              font: {
                name: "Times New Roman",
                size: 16 / this.scale,
                color: "#000000",
                style: 0,
              },
              zindex: el.zindex || -1,
            });
          });

          await this.modifyDocumentPages(params, index);
        }

        let lastDocument = false;
        if (index + 1 == this.document_url_paths.length) {
          lastDocument = true;
        }
        if (lastDocument) {
          this.documentDataLoading = false;
          this.$notify({
            title: "Success",
            message: "Document updated successfully",
            type: "success",
          });
          this.pdfEditingChanges = [];
          this.pdfRectangleChanges = [];
          this.pdfRectangleChanges = [];
          this.pdfRectangleTextChanges = [];
          this.pdfLineChanges = [];
          this.isEditMode = false;
          this.zindex = -1;
        }
      });
    },
    editSelectedText() {
      if (this.selectedText) {
        let exitedText = this.pdfEditingChanges.find(
          (e) =>
            e.index == this.selectedTextIndex &&
            e.pageIndex == this.selectedTextPageIndex &&
            e.docIndex == this.selectedTextDocIndex
        );
        let selectedText = this.selectedText;
        if (exitedText && exitedText.item) {
          exitedText.item = { ...exitedText.item, ...{ str: exitedText.text } };
          selectedText = JSON.parse(JSON.stringify(exitedText.item));
          this.selectedText = selectedText;
        }
        if (this.selectedText) {
          if (this.selectedText.str) {
            this.selectedTextRation =
              this.selectedText.width / this.selectedText.str.length;
          }
          if (!this.selectedText.style) {
            let style = {
              color: "#000000",
              weight: 0,
              fontFamily: "Times New Roman",
            };
            this.selectedText = { ...this.selectedText, ...{ style: style } };
          }
        }
        this.selectedTextInPdf = selectedText.str;
        this.isEditingText = true;
        this.isEarserMode = false;
      }
    },
    mousePosition(e) {
      if (this.$refs.formBuilder) {
        const domEle = this.$refs.formBuilder.getBoundingClientRect();
        let divX = domEle.x;
        let divY = domEle.y;
        let width = domEle.width;
        let height = domEle.height;
        if (
          divX <= e.pageX &&
          divY <= e.pageY &&
          e.pageX <= width + divX &&
          e.pageY <= height &&
          this.isEditMode &&
          !this.isEarserMode &&
          !this.isDrawLine
        ) {
          let pageBoundary = this.getPagesBoundaries(e.pageY - divY);
          let docId =
            pageBoundary &&
            pageBoundary.data &&
            Number.isInteger(pageBoundary.data.docId)
              ? pageBoundary.data.docId
              : 0;
          let pageId =
            pageBoundary &&
            pageBoundary.data &&
            Number.isInteger(pageBoundary.data.pageId)
              ? pageBoundary.data.pageId + 1
              : 1;
          let id = docId + "_canvas_" + pageId;
          let currentPage = document.getElementById(id.toString());
          if (currentPage) {
            let xOnPage = e.pageX - divX;
            let yOnPage = e.pageY - divY;

            if (
              xOnPage >= currentPage.offsetLeft &&
              xOnPage <= currentPage.offsetLeft + currentPage.width
            ) {
              this.isMakeItalic = false;
              this.isMakeBold = false;
              this.isChangeColor = false;
              let pageText = this.allPdfText[docId][pageId];
              let textStyle = this.allPdfTextStyle[docId][pageId];
              let textBox = document.getElementById("textHighlight");
              let styles = {};
              let k = -1;
              let selectedText = pageText.fields.find((u, i) => {
                let textX = u.transform[4] * this.scale;
                let textY =
                  currentPage.height -
                  u.transform[5] * this.scale +
                  currentPage.offsetTop;
                let cursorX = xOnPage - currentPage.offsetLeft;
                let cursorY = yOnPage;
                if (
                  cursorX >= textX - 5 &&
                  cursorX <= textX + u.width * this.scale + 5 &&
                  cursorY <= textY + 5 &&
                  cursorY >= textY - u.height * this.scale - 5
                ) {
                  k = i;
                  styles.x = textX;
                  styles.y = textY - u.height * this.scale;
                  styles.width = u.width * this.scale;
                  styles.height = u.height * this.scale;
                  return u;
                }
              });
              if (!this.isEditingText) {
                if (selectedText) {
                  let textFont =
                    textStyle[
                      selectedText.fontName ? selectedText.fontName : ""
                    ];
                  let font = {
                    color: "#000000",
                    weight: 0,
                    fontFamily: "Times New Roman",
                  };
                  if (textFont && textFont.fontFamily) {
                    if (textFont.fontFamily == "sans-serif") {
                      font.fontFamily = "Times New Roman";
                    } else if (
                      this.fontTypes.indexOf(textFont.fontFamily) != -1
                    ) {
                      font.fontFamily = textFont.fontFamily;
                    }
                  }
                  this.selectedTextIndex = k;
                  this.selectedTextPageIndex = pageId;
                  this.selectedTextDocIndex = docId;
                  this.selectedText = {
                    ...selectedText,
                    ...{
                      currentPage: id.toString(),
                      currentPageHeight: currentPage.height,
                      style: font,
                    },
                  };
                  textBox.style.display = "block";
                  textBox.style.height = styles.height + 5 + "px";
                  textBox.style.width = styles.width + "px";
                  textBox.style.top = styles.y + "px";
                  textBox.style.left = styles.x + currentPage.offsetLeft + "px";
                  textBox.style.zIndex = "200";
                } else {
                  this.isEditingText = false;
                  this.selectedText = {};
                  this.selectedTextInPdf = "";
                  textBox.style.display = "none";
                }
              }
            }
          }
        }
      }
    },
    rgbToHex(r, g, b) {
      if (r > 255 || g > 255 || b > 255) throw "Invalid color component";
      return ((r << 16) | (g << 8) | b).toString(16);
    },
    chooseBlankDocument() {
      this.blanckDocumentModal = true;
      this.replaceDocumentModel = false;
    },
    resetBlankTemplate() {
      this.blanckDocumentModal = false;
      this.blanckDocumentData.name = "";
      this.blanckDocumentData.pages = 1;
    },
    async replaceBlankDoc() {
      this.loading = true;
      this.blanckDocumentData.loading = true;
      this.fieldsFormUpload.title = this.blanckDocumentData.name;

      let selectedFile =
        this.blankTemplatesData[this.blanckDocumentData.pages - 1];

      const copyDocumentData = await this.getCopyDocumentsdata(selectedFile);

      if (copyDocumentData) {
        let fileUploadUrls = [copyDocumentData.path];
        let names = [this.blanckDocumentData.name];
        this.updateConfigDocument(fileUploadUrls, names);
      } else {
        this.logoError = "Error in uploading file. Please try again..";
      }

      this.blanckDocumentModal = false;
      this.replaceDocumentModel = false;
    },

    activeEntityCollapseMethod() {
      this.selectedRepeatableIndex = "";
    },
    getTempTitle(title) {
      return title.slice(0, 24);
    },
    async addAndCloseAlert() {
      this.addFormFields = true;
      this.fieldsAlertModel = false;
      await this.removeFormFieldsFromPdf();
    },
    async checkAddFormFields() {
      this.fields_found = true;
      this.fieldsAlertModel = false;
      if (this.addFormFields) {
        // await this.addFieldsOnDocument();
        await this.renderAllDocuments(true);
        this.showAichatMate = false;
      } else {
        await this.saveAddedFields();
        await this.renderAllDocuments(true);
        this.showAichatMate = false;
      }
    },
    async saveAddedFields() {
      let params = this.prepareFields();
      this.$set(params, "fields_found", true);
      if (
        this.getConfigurableDocumentDataById.pages &&
        this.getConfigurableDocumentDataById.pages[0] &&
        this.getConfigurableDocumentDataById.pages[0].fields &&
        this.getConfigurableDocumentDataById.pages[0].fields.length
      ) {
        params.pages[0].fields =
          this.getConfigurableDocumentDataById.pages[0].fields;
      }
      await this.$store.dispatch(
        "configurableDocuments/updateConfigurableDocument",
        params
      );
    },
    getRepeatableData(template) {
      let result = [];
      const selectedUser = this.documentRecipientsList.find(
        (e) => e.value == this.recipientSelection
      );
      const selectedTemplateData =
        this.getTemplatesData && this.getTemplatesData.length
          ? this.getTemplatesData.find(
              (t) =>
                (selectedUser &&
                  selectedUser.entity_data_id &&
                  t.template_id == template.template_id &&
                  t.entity_data_id == selectedUser.entity_data_id._id) ||
                t.entity_data_id == selectedUser.entity_data_id
            )
          : null;
      if (template.max_value) {
        if (selectedTemplateData && selectedTemplateData.template_data) {
          selectedTemplateData.template_data = this.setRepeatableDataStucture(
            selectedTemplateData.template_data,
            "data"
          );
        }
        let primaryTempKey =
          template.primary_field && template.primary_field.key
            ? template.primary_field.key
            : template.fields && template.fields[0]
            ? template.fields[0].key
            : "";
        let key =
          selectedTemplateData &&
          selectedTemplateData.template_data &&
          Object.keys(selectedTemplateData.template_data) &&
          Object.keys(selectedTemplateData.template_data)[0]
            ? Object.keys(selectedTemplateData.template_data)[0]
            : template.name.toLowerCase().split(" ").join("_");
        const repeatableData =
          key &&
          selectedTemplateData &&
          selectedTemplateData.template_data &&
          selectedTemplateData.template_data[key]
            ? selectedTemplateData.template_data[key]
            : [];
        for (let i = 0; i < template.max_value; i++) {
          if (repeatableData && repeatableData.length && repeatableData[i]) {
            result.push({
              name: repeatableData[i][primaryTempKey]
                ? repeatableData[i][primaryTempKey + "/name"]
                  ? repeatableData[i][primaryTempKey + "/name"]
                  : repeatableData[i][primaryTempKey]
                : "Data",
              data: repeatableData[i],
              indexKey: repeatableData[i]["indexKey"],
            });
          } else {
            result.push({
              name: "New data " + (i + 1),
              data: {},
              indexKey: "newData:" + (i + 1),
            });
          }
        }
      }
      return result;
    },
    visibleSettingsVisible() {
      this.docSettingsVisible = true;
    },
    async closeSettingsAndSend() {
      this.docSettingsVisible = false;
      if (this.docSettingsVisibleAndSend) {
        await this.createSendDoc();
      }
    },
    async fetchCompanyDetails() {
      // let id =
      //   this.getActiveWorkspace &&
      //   this.getActiveWorkspace.company_id &&
      //   this.getActiveWorkspace.company_id._id
      //     ? this.getActiveWorkspace.company_id._id
      //     : this.getActiveWorkspace.company_id;
      // await this.$store.dispatch("company/fetchCompany", id);
    },
    composeNewFileItem(file) {
      let fileItem = {};
      fileItem.name = file.name;
      fileItem.percentage = 0;
      fileItem.raw = file;
      fileItem.size = file.size;
      fileItem.status = "ready";
      fileItem.uid = file.uid;
      return fileItem;
    },
    toDataUrl(url, callback) {
      var xhr = new XMLHttpRequest();
      xhr.onload = function () {
        callback(xhr.response);
      };
      xhr.open("GET", url);
      xhr.responseType = "blob";
      xhr.send();
    },
    handleUploadDoneFromOthers(res) {
      if (
        res.filesUploaded.length > 0 &&
        res.filesUploaded[0].url !== undefined
      ) {
        this.uploadFromOthersBlobData = null;
        this.uploadFromOthersFileName = res.filesUploaded[0].filename;
        this.loading = true;
        var self = this;
        this.toDataUrl(res.filesUploaded[0].url, function (x) {
          self.uploadFromOthersBlobData = x;
          self.loading = false;

          self.files.push(
            new self.composeNewFileItem(
              new File(
                [self.uploadFromOthersBlobData],
                self.uploadFromOthersFileName
              )
            )
          );
        });
      }
    },
    handleUploadFrom(e, fromSources) {
      const client = require("filestack-js").init("AOI3ejHzTcuVrrWTbhHeaz");
      const options = {
        fromSources: fromSources,
        accept: [
          "*.doc",
          "*.pdf",
          "*.docx",
          "*.txt",
          "*.png",
          "*.jpg",
          "*.jpeg",
        ],
        allowManualRetry: true,
        transformations: {
          crop: false,
        },
        customText: {
          "Select Files to Upload": "Select Image to Upload",
        },
        onUploadDone: (res) => this.handleUploadDoneFromOthers(res),
      };
      client.picker(options).open();
    },
    pageCount(i, j) {
      let prevPagesCount = 0;
      for (let index = 0; index < j; index++) {
        prevPagesCount = prevPagesCount + this.allPdfData[index].pages;
      }
      return prevPagesCount + i;
    },
    handleVDRKeyDown(event) {
      if (
        document.activeElement &&
        (document.activeElement.tagName == "TEXTAREA" ||
          (document.activeElement.tagName == "INPUT" &&
            document.activeElement.getAttribute("type") !== "radio" &&
            document.activeElement.getAttribute("type") !== "checkbox"))
      ) {
        return;
      }
      if (this.activatedItem.x < 0) {
        this.activatedItem.x = 0;
      }
      if (this.activatedItem.y < 0) {
        this.activatedItem.y = 0;
      }
      switch (event.keyCode) {
        case 37:
          // arrow left
          this.activatedItem.x -= event.shiftKey
            ? this.posStepShift
            : this.posStep;
          this.showAlignLines(
            this.activatedItem.x,
            this.activatedItem.y,
            false
          );
          setTimeout(() => {
            this.hideAllAlignLines(false);
          }, 3000);
          event.preventDefault();
          break;
        case 38:
          // arrow up
          this.activatedItem.y -= event.shiftKey
            ? this.posStepShift
            : this.posStep;
          this.showAlignLines(
            this.activatedItem.x,
            this.activatedItem.y,
            false
          );
          setTimeout(() => {
            this.hideAllAlignLines(false);
          }, 3000);
          event.preventDefault();
          break;
        case 39:
          // arrow right
          this.activatedItem.x += event.shiftKey
            ? this.posStepShift
            : this.posStep;
          this.showAlignLines(
            this.activatedItem.x,
            this.activatedItem.y,
            false
          );
          setTimeout(() => {
            this.hideAllAlignLines(false);
          }, 3000);
          event.preventDefault();
          break;
        case 40:
          // arrow down
          this.activatedItem.y += event.shiftKey
            ? this.posStepShift
            : this.posStep;
          this.showAlignLines(
            this.activatedItem.x,
            this.activatedItem.y,
            false
          );
          setTimeout(() => {
            this.hideAllAlignLines(false);
          }, 3000);
          event.preventDefault();
          break;
      }
    },
    navigateToPageNo() {
      let scrollbarEl = this.$refs?.scrollbar?.wrap;
      scrollbarEl.scrollTop = this.getSumOfPagesHeight(
        this.currentActivatePage,
        this.currentActivateDoc
      );
    },
    handleScroll() {
      let scrollbarEl = this.$refs?.scrollbar?.wrap;
      scrollbarEl.onscroll = () => {
        let pageBreaks = [];
        let h = 0;
        let maxLength = document.getElementById("wrapper").childNodes.length;
        for (let i = 0; i < maxLength; i++) {
          h = h + document.getElementById("wrapper").childNodes[i].height;
          pageBreaks.push(h);
        }
        let pageNumber = pageBreaks.findIndex((page, index) => {
          if (scrollbarEl.scrollTop <= page + index * 6) {
            return page;
          }
        });
        if (document.getElementById(`canvas_page_${pageNumber + 1}`)) {
          // document.getElementById(`canvas_page_${pageNumber+1}`).focus();
          this.currentActivatePage = pageNumber + 1;
        }
      };
    },
    getNearestFieldByX(x, y, isUsingDelta = true) {
      var deltaXForNearestField = isUsingDelta ? this.deltaXForNearestField : 1;
      var deltaX = 0;

      let pageBoundary = this.getPagesBoundaries(y);
      let pageStartY = pageBoundary.pageNumber * pageBoundary.pageHeight;
      let pageEndY = (pageBoundary.pageNumber + 1) * pageBoundary.pageHeight;

      var nearestX = -1;
      var yForNearestX = -1;
      var diffVal = deltaXForNearestField + 1;
      for (var i = 0; i < this.elements.length; i++) {
        let el = this.elements[i];
        if (
          el.x == this.activatedItem.x &&
          el.y == this.activatedItem.y &&
          el.width == this.activatedItem.width &&
          el.height == this.activatedItem.height
        ) {
          continue;
        }
        if (el.y >= pageStartY && el.y <= pageEndY) {
          if (Math.abs(el.x - x) <= deltaXForNearestField) {
            if (Math.abs(el.x - x) < diffVal) {
              nearestX = el.x;
              yForNearestX = y >= el.y ? el.y : el.y + el.height;
              diffVal = Math.abs(el.x - x);
            }
          } else if (
            Math.abs(el.x + el.width / 2 - x) <= deltaXForNearestField
          ) {
            if (Math.abs(el.x + el.width / 2 - x) < diffVal) {
              nearestX = el.x + el.width / 2;
              yForNearestX = y >= el.y ? el.y : el.y + el.height;
              diffVal = Math.abs(el.x + el.width / 2 - x);
            }
          } else if (Math.abs(el.x + el.width - x) <= deltaXForNearestField) {
            if (Math.abs(el.x + el.width - x) < diffVal) {
              nearestX = el.x + el.width;
              yForNearestX = y >= el.y ? el.y : el.y + el.height;
              diffVal = Math.abs(el.x + el.width - x);
            }
          }
        }
      }

      deltaX = nearestX - x;
      return {
        x: nearestX,
        y: yForNearestX,
        deltaX,
      };
    },
    getNearestFieldByY(x, y, isUsingDelta = true) {
      var deltaYForNearestField = isUsingDelta ? this.deltaYForNearestField : 1;
      var deltaY = 0;
      let pageBoundary = this.getPagesBoundaries(y);
      let pageStartY = pageBoundary.pageNumber * pageBoundary.pageHeight;
      let pageEndY = (pageBoundary.pageNumber + 1) * pageBoundary.pageHeight;

      var nearestY = -1;
      var xForNearestY = -1;
      var diffVal = deltaYForNearestField + 1;
      for (var i = 0; i < this.elements.length; i++) {
        let el = this.elements[i];
        if (
          el.x == this.activatedItem.x &&
          el.y == this.activatedItem.y &&
          el.width == this.activatedItem.width &&
          el.height == this.activatedItem.height
        ) {
          continue;
        }
        if (el.y >= pageStartY && el.y <= pageEndY) {
          if (Math.abs(el.y - y) <= deltaYForNearestField) {
            if (Math.abs(el.y - y) < diffVal) {
              nearestY = el.y;
              xForNearestY = x >= el.x ? el.x : el.x + el.width;
              diffVal = Math.abs(el.y - y);
            }
          } else if (
            Math.abs(el.y + el.height / 2 - y) <= deltaYForNearestField
          ) {
            if (Math.abs(el.y + el.height / 2 - y) < diffVal) {
              nearestY = el.y + el.height / 2;
              xForNearestY = x >= el.x ? el.x : el.x + el.width;
              diffVal = Math.abs(el.y + el.height / 2 - y);
            }
          } else if (Math.abs(el.y + el.height - y) <= deltaYForNearestField) {
            if (Math.abs(el.y + el.height - y) < diffVal) {
              nearestY = el.y + el.height;
              xForNearestY = x >= el.x ? el.x : el.x + el.width;
              diffVal = Math.abs(el.y + el.height - y);
            }
          }
        }
      }

      deltaY = nearestY - y;
      return {
        x: xForNearestY,
        y: nearestY,
        deltaY,
      };
    },
    showAlignLines(x, y, isUsingDelta = true) {
      this.deltaXToMove = 0;
      this.deltaYToMove = 0;
      // var rtn = true;
      // top line
      let rtnPos = this.getNearestFieldByY(x, y, isUsingDelta);

      if (rtnPos.x >= 0 && rtnPos.y >= 0) {
        this.deltaYToMove = rtnPos.deltaY;
        var line = document.getElementById("top_line");
        line.style.display = "block";
        var x1 =
          this.activatedItem.x >= rtnPos.x
            ? this.activatedItem.x + this.activatedItem.width
            : this.activatedItem.x;
        var y1 = rtnPos.y;
        var x2 = rtnPos.x;
        var y2 = rtnPos.y;
        line.setAttribute("x1", x1);
        line.setAttribute("y1", y1);
        line.setAttribute("x2", x2);
        line.setAttribute("y2", y2);
        // rtn = false;
      } else {
        this.hideElementById("top_line");
      }

      // middle line
      rtnPos = this.getNearestFieldByY(
        x,
        y + this.activatedItem.height / 2,
        isUsingDelta
      );

      if (rtnPos.x >= 0 && rtnPos.y >= 0) {
        this.deltaYToMove = rtnPos.deltaY;
        line = document.getElementById("middle_horizontal_line");
        line.style.display = "block";
        x1 =
          this.activatedItem.x >= rtnPos.x
            ? this.activatedItem.x + this.activatedItem.width
            : this.activatedItem.x;
        y1 = rtnPos.y;
        x2 = rtnPos.x;
        y2 = rtnPos.y;
        line.setAttribute("x1", x1);
        line.setAttribute("y1", y1);
        line.setAttribute("x2", x2);
        line.setAttribute("y2", y2);
      } else {
        this.hideElementById("middle_horizontal_line");
      }

      // bottom line
      rtnPos = this.getNearestFieldByY(
        x,
        y + this.activatedItem.height,
        isUsingDelta
      );

      if (rtnPos.x >= 0 && rtnPos.y >= 0) {
        this.deltaYToMove = rtnPos.deltaY;
        line = document.getElementById("bottom_line");
        line.style.display = "block";
        x1 =
          this.activatedItem.x >= rtnPos.x
            ? this.activatedItem.x + this.activatedItem.width
            : this.activatedItem.x;
        y1 = rtnPos.y;
        x2 = rtnPos.x;
        y2 = rtnPos.y;
        line.setAttribute("x1", x1);
        line.setAttribute("y1", y1);
        line.setAttribute("x2", x2);
        line.setAttribute("y2", y2);
      } else {
        this.hideElementById("bottom_line");
      }

      // left line
      rtnPos = this.getNearestFieldByX(x, y, isUsingDelta);
      if (rtnPos.x >= 0 && rtnPos.y >= 0) {
        this.deltaXToMove = rtnPos.deltaX;
        line = document.getElementById("left_line");
        line.style.display = "block";
        x1 = rtnPos.x;
        y1 =
          this.activatedItem.y >= rtnPos.y
            ? this.activatedItem.y + this.activatedItem.height
            : this.activatedItem.y;
        x2 = rtnPos.x;
        y2 = rtnPos.y;
        line.setAttribute("x1", x1);
        line.setAttribute("y1", y1);
        line.setAttribute("x2", x2);
        line.setAttribute("y2", y2);
      } else {
        this.hideElementById("left_line");
      }

      // middle vertical line
      rtnPos = this.getNearestFieldByX(
        x + this.activatedItem.width / 2,
        y,
        isUsingDelta
      );
      if (rtnPos.x >= 0 && rtnPos.y >= 0) {
        this.deltaXToMove = rtnPos.deltaX;
        line = document.getElementById("middle_vertical_line");
        line.style.display = "block";
        x1 = rtnPos.x;
        y1 =
          this.activatedItem.y >= rtnPos.y
            ? this.activatedItem.y + this.activatedItem.height
            : this.activatedItem.y;
        x2 = rtnPos.x;
        y2 = rtnPos.y;
        line.setAttribute("x1", x1);
        line.setAttribute("y1", y1);
        line.setAttribute("x2", x2);
        line.setAttribute("y2", y2);
      } else {
        this.hideElementById("middle_vertical_line");
      }

      // right line
      rtnPos = this.getNearestFieldByX(
        x + this.activatedItem.width,
        y,
        isUsingDelta
      );
      if (rtnPos.x >= 0 && rtnPos.y >= 0) {
        this.deltaXToMove = rtnPos.deltaX;
        line = document.getElementById("right_line");
        line.style.display = "block";
        x1 = rtnPos.x;
        y1 =
          this.activatedItem.y >= rtnPos.y
            ? this.activatedItem.y + this.activatedItem.height
            : this.activatedItem.y;
        x2 = rtnPos.x;
        y2 = rtnPos.y;
        line.setAttribute("x1", x1);
        line.setAttribute("y1", y1);
        line.setAttribute("x2", x2);
        line.setAttribute("y2", y2);
      } else {
        this.hideElementById("right_line");
      }
      // return rtn;
      return true;
    },
    chechFieldPosition(x, y) {
      let pageBoundary = this.getPagesBoundaries(y);
      if (
        pageBoundary &&
        // pageBoundary.pageOffset &&
        pageBoundary.pageWidth &&
        (x < pageBoundary.pageOffset ||
          x + this.activatedItem.width >
            pageBoundary.pageOffset + pageBoundary.pageWidth)
      ) {
        return false;
      }
      document.querySelectorAll(".el-date-picker").forEach((el) => {
        el.style.visibility = "hidden";
      });

      this.showAlignLines(x, y);
    },
    chechFieldSizes(handle, x, y, width, height) {
      let pageBoundary = this.getPagesBoundaries(y);
      if (
        pageBoundary &&
        // pageBoundary.pageOffset &&
        //height
        pageBoundary.pageWidth &&
        (x < pageBoundary.pageOffset ||
          x + width > pageBoundary.pageOffset + pageBoundary.pageWidth)
      ) {
        return false;
      }
    },
    async uploadSingleFile(tempUrl) {
      try {
        this.replaceDocumentLoading = true;
        this.files.forEach((file, index) => {
          if (index == 0) {
            this.fieldsFormUpload.selectedFile =
              file.raw !== undefined ? file.raw : file;
            this.fieldsFormUpload.selectedFileName =
              file.raw !== undefined ? file.raw.name : file.name;
            this.fieldsFormUpload.title =
              file.raw !== undefined ? file.raw.name : file.name;
          }
        });

        let fileNames = this.files.flatMap((e) => e.name);
        let fileUploadUrls = tempUrl;
        this.updateConfigDocument(fileUploadUrls, fileNames);
      } catch (err) {
        this.replaceDocumentLoading = false;
      }
      this.changeDocumentDocumentModel = true;
    },
    async mergeAllDocs() {
      try {
        this.replaceDocumentLoading = true;
        var formData = new FormData();
        this.files.forEach((file, index) => {
          if (index == 0) {
            this.fieldsFormUpload.selectedFile =
              file.raw !== undefined ? file.raw : file;
            this.fieldsFormUpload.selectedFileName =
              file.raw !== undefined ? file.raw.name : file.name;
            this.fieldsFormUpload.title =
              file.raw !== undefined ? file.raw.name : file.name;
          }
          formData.append(
            "uploadedFiles",
            file.raw !== undefined ? file.raw : file
          );
        });
        let companyId =
          this.getActiveWorkspace && this.getActiveWorkspace.company_id
            ? this.getActiveWorkspace.company_id
            : this.getAuthenticatedUser._id;
        let params = {
          data: formData,
          companyId: companyId,
        };
        await this.$store.dispatch("fileUpload/uploadUserDocxFilev4", params);
        if (this.getUploadFileDataStatus) {
          let tempUrl = "";
          if (
            this.getUploadFileDataStatus.url.includes(appConfig.S3_BUCKET_URL)
          ) {
            tempUrl = this.getUploadFileDataStatus.url.replace(
              appConfig.S3_BUCKET_URL,
              ""
            );
          } else {
            tempUrl = this.getUploadFileDataStatus.url;
          }
          let fileUploadUrls = [tempUrl];
          let names = [this.fieldsFormUpload.selectedFileName];
          this.updateConfigDocument(fileUploadUrls, names);
        } else {
          this.logoError = "Error in uploading file. Please try again..";
        }
      } catch (err) {
        this.replaceDocumentLoading = false;
      }
      this.changeDocumentDocumentModel = true;
    },
    resetReplaceFiles() {
      this.files = [];
      this.replaceDocumentModel = false;
      this.fieldsFormUpload.selectedFileName = "";
      this.fieldsFormUpload.title = "";
      this.fieldsFormUpload.selectedFile = "";
    },
    replaceDocumentMethod() {
      this.replaceDocumentModel = true;
      this.changeDocumentMethod = true;
      this.changeDocumentDocumentModel = true;
    },
    checkToUploadDocument(file) {
      if (
        file.raw.type != "audio/mpeg" &&
        file.raw.type != "video/mp4" &&
        file.raw.type != "image/gif" &&
        file.raw.type != "text/html" &&
        file.raw.type != "text/css" &&
        file.raw.type != "text/javascript" &&
        file.raw.type != "application/json" &&
        file.raw.type != "" &&
        file.raw.type != "application/x-msdownload"
      ) {
        if (file.size < 25000000) {
          this.files.push(file);
        } else {
          this.$message.error("Large file. Maximum upload file size: 25 MB");
        }
      } else {
        this.$message("File format is not supported");
      }
    },
    async updateConfigDocument(files, names) {
      try {
        this.replaceDocumentLoading = true;
        this.params = this.prepareFields();
        if (names && names.length) {
          this.fieldsFormUpload.selectedFileName = names[0];
        }
        // let docId = this.params.id;
        this.$set(this.params, "document_upload_url", files[0]);
        this.$set(this.params, "document_upload_urls", files);
        this.$set(this.params, "file_names", names);
        this.$set(this.params, "name", this.fieldsFormUpload.selectedFileName);
        this.$set(this.params, "scale", 0);
        //  let newConfigFields = this.getConfigurableDocumentDataById
        // if (
        let configFields =
          this.getConfigurableDocumentDataById.pages &&
          this.getConfigurableDocumentDataById.pages[0] &&
          this.getConfigurableDocumentDataById.pages[0].fields;
        // ) {
        this.params.pages[0].fields = this.elements
          ? this.elements
          : configFields;
        // }
        await this.$store.dispatch(
          "configurableDocuments/updateConfigurableDocument",
          this.params
        );
        if (this.getConfigurableDocumentUpdateStatus) {
          this.$notify({
            title: "Success",
            message: "Document replaced successfully",
            type: "success",
          });
          this.replaceDocumentLoading = false;
          window.location.reload();
        } else {
          this.$notify({
            title: "Error",
            message: "Error in replacing document",
            type: "error",
          });
          this.replaceDocumentLoading = false;
        }
      } catch (err) {}
    },
    moveDown(index) {
      if (index == this.files.length - 1) {
        return;
      }

      let sortedUsers = this.swapSingatureUsers(index, index + 1, this.files);
      this.files = [];
      this.files = sortedUsers;
    },
    moveUp(index) {
      if (index == 0) {
        return;
      }

      let sortedUsers = this.swapSingatureUsers(index, index - 1, this.files);
      this.files = [];
      this.files = sortedUsers;
    },
    swapSingatureUsers(fromIndex, toIndex, signaturedUsers) {
      let fromUser = signaturedUsers[fromIndex];
      let toUser = signaturedUsers[toIndex];
      signaturedUsers[fromIndex] = toUser;
      signaturedUsers[toIndex] = fromUser;

      return signaturedUsers;
    },
    async deleteItem(index) {
      this.files.splice(index, 1);
    },
    removeAttachment(index) {
      this.selectedItem.files.splice(index, 1);
    },
    uploadFile(file) {
      if (
        file.raw.type != "audio/mpeg" &&
        file.raw.type != "video/mp4" &&
        file.raw.type != "image/gif" &&
        file.raw.type != "text/html" &&
        file.raw.type != "text/css" &&
        file.raw.type != "text/javascript" &&
        file.raw.type != "application/json" &&
        file.raw.type != "" &&
        file.raw.type != "application/x-msdownload"
      ) {
        if (file.size < 25000000) {
          this.attachFiles(file);
        } else {
          this.$message.error("Large file.Maximum upload file size : 25 MB!");
        }
      } else {
        this.$message("File format is not supported");
      }
    },

    async attachFiles(file) {
      try {
        this.attachmentLoading = true;
        var formData = new FormData();
        formData.append("uploadedFiles", file.raw);

        let tempUrl = this.document_url_path.split("/");
        formData.append("doc_company_id", tempUrl[2]);
        await this.$store.dispatch("fileUpload/uploadFilesToConvert", formData);
        if (this.getUploadFileDataStatus) {
          this.selectedAttachmentFile.attachment_url =
            this.getUploadFileDataStatus.url;
          // this.selectedItem.title = file.raw.name;
          if (this.selectedItem.filled_by == "SENDER") {
            this.selectedItem.files.push({
              document_name: this.selectedAttachmentFile.document_name,
              attachment_url: this.selectedAttachmentFile.attachment_url,
              valid_from: this.selectedAttachmentFile.valid_from,
              document_number: this.selectedAttachmentFile.document_number,
              expires_on: this.selectedAttachmentFile.expires_on,
              title: file.raw.name,
              description: this.selectedAttachmentFile.description,
            });
          }
          this.attachmentLoading = false;
          await this.resetSelectedAttachmentFile();
        }
      } catch (err) {
        this.attachmentLoading = false;
      }
    },
    resetSelectedAttachmentFile() {
      this.selectedAttachmentFile.document_name = "";
      this.selectedAttachmentFile.attachment_url = "";
      this.selectedAttachmentFile.valid_from = "";
      this.selectedAttachmentFile.document_number = "";
      this.selectedAttachmentFile.expires_on = "";
      this.selectedAttachmentFile.title = "";
      this.selectedAttachmentFile.description = "";
    },
    publishTheTemplate() {
      this.is_published = true;
      this.is_publishing = true;
      this.saveTemplate();
    },
    unpublishTheTemplate() {
      // this.getLinkModal = false;
      this.is_published = false;
      this.is_publishing = true;
      this.saveTemplate();
    },
    copyCodeToClipboard() {
      this.$refs.getCode.focus();
      document.execCommand("copy");
    },
    copyLinkToClipboard() {
      this.$refs.getlink.focus();
      document.execCommand("copy");
    },
    getLinkForTemplate() {
      let nonConstantUser = this.documentRecipientsList.filter(
        (user) => user.type != "SENDER" && !user.constant_user
      );
      let emptyUser = null;
      if (this.documentRecipientsList && this.documentRecipientsList.length) {
        emptyUser = this.documentRecipientsList.find((user) => {
          if (user && user.type != "SENDER" && !user.contact_type) {
            return user;
          }
        });
      }
      if (this.is_published && nonConstantUser && nonConstantUser.length == 1) {
        this.generateLinkForTemplate();
        this.generateCodeForTemplate();
      } else {
        if (
          !this.documentRecipientsList.length ||
          (this.documentRecipientsList.length &&
            this.documentRecipientsList.length == 1)
        ) {
          this.$message.warning(
            "There is no recipients. Please add one recipient."
          );
        } else if (emptyUser && emptyUser.name) {
          this.$message.warning(
            `Recipient ${emptyUser.name} don't have contact-type. Please select.`
          );
        } else if (
          this.documentRecipientsList.length &&
          nonConstantUser &&
          nonConstantUser.length == 1
        ) {
          if (this.elements && !this.elements.length) {
            this.$message.warning(
              "There is no fields. Please add some fields."
            );
          } else if (this.elements && this.elements.length) {
            let signUsers = [];
            let userCount = 0;
            signUsers = this.elements.flatMap((element) => element.filled_by);
            if (signUsers && signUsers.length) {
              //let senderSign = [];
              // senderSign = this.elements.filter(
              //   (element) => element.type == "my-signature"
              // );
              userCount = this.documentRecipientsList.filter((user) => {
                if (
                  user.user_type == "SIGNER" &&
                  user.type != "SENDER" &&
                  signUsers.indexOf(user.value) == -1
                ) {
                  return user;
                }
                // else if (
                //   user.type == "SENDER" &&
                //   user.user_type == "SIGNER"
                // ) {
                //   if (!senderSign.length) {
                //     if (signUsers.indexOf(user.value) == -1) {
                //       return user;
                //     }
                //   }
                // }
              });
              if (userCount.length) {
                this.$message.warning(
                  "Every signing user must have at least one fillable field."
                );
              } else {
                this.generateLinkForTemplate();
                this.generateCodeForTemplate();
              }
            } else {
              this.$message.warning(
                "Every signing user must have at least one fillable field."
              );
            }
          }
        } else {
          this.$message.warning("There must be only one recipient");
        }
      }
    },
    generateCodeForTemplate() {
      let configurable_document_id =
        this.$route.params.configurable_document_id;
      let code = `<div id="${configurable_document_id}"></div>`;
      let url = appConfig.APP_URL;
      // if (process.env.VUE_APP_STAGE == "DEV") {
      //   url = "https://dev.esigns.io";
      // } else if (process.env.VUE_APP_STAGE == "APP") {
      //   url = "https://app.esigns.io";
      // }

      // url = "http://localhost:8080";
      let company_document_id = this.$route.params.document_id;
      url += `/company-documents/${company_document_id}/configure/template/${configurable_document_id}/preview`;
      // code += '<script type="text/javascript">';
      code += `<script type="text/javascript"> (function () { var config = { nodeId: '${configurable_document_id}', width: '100%', height: '700px', url: '${url}', events: { loaded: function () {}, started: function (data) {}, completed: function (data) {}, exception: function (data) {} }, data: {}, }; var iframe = document.createElement('iframe'); iframe.frameBorder = 0; iframe.src = config.url; if (config.nodeId) { var node = document.getElementById(config.nodeId); node.style.height = config.height; node.style.width = config.width; iframe.height = '100%'; iframe.width = '100%'; node.append(iframe); } else { iframe.height = config.height; iframe.width = config.width; document.body.append(iframe); }})();`;

      code += "</sc";
      code += "ript>";
      this.embedCode = code;
    },
    generateLinkForTemplate() {
      let url = appConfig.APP_URL;
      // if (process.env.VUE_APP_STAGE == "DEV") {
      //   url = "https://dev.esigns.io";
      // } else if (process.env.VUE_APP_STAGE == "APP") {
      //   url = "https://app.esigns.io";
      // }
      // url = "http://localhost:8080";
      let company_document_id = this.$route.params.document_id;
      let configurable_document_id =
        this.$route.params.configurable_document_id;
      url += `/company-documents/${company_document_id}/configure/template/${configurable_document_id}/preview`;
      this.getLink = url;
      this.getLinkModal = true;
    },

    async getCurrentUserInitial() {
      await this.$store.dispatch("auth/fetchInitialSignature");
      if (this.getInitialUrl) {
        this.currentUserInitial = this.getInitialUrl;
      }
    },
    handleFieldDragStart() {
      // this.handleDragStart()
      if (
        this.getConfigurableDocumentDataById &&
        !this.getConfigurableDocumentDataById.lock_template_fields
      ) {
        document.querySelectorAll(".el-date-picker").forEach((el) => {
          el.style.visibility = "visible";
        });
        return !this.isFieldEditting;
      } else if (this.activatedItem.type !== "CHECKBOX") {
        return false;
      }
      event.dataTransfer.setData("text/plain", event.target.textContent);
    },
    hideAllAlignLinesWithDelay() {
      let self = this;
      setTimeout(() => {
        self.hideElementById("top_line");
        self.hideElementById("middle_horizontal_line");
        self.hideElementById("bottom_line");
        self.hideElementById("left_line");
        self.hideElementById("middle_vertical_line");
        self.hideElementById("right_line");
        this.isFromFieldActivated = false;
      }, 700);
    },
    hideAllAlignLines(isUsingDelta = true) {
      if (this.isFromFieldActivated) {
        return;
      }
      this.hideElementById("top_line");
      this.hideElementById("middle_horizontal_line");
      this.hideElementById("bottom_line");
      this.hideElementById("left_line");
      this.hideElementById("middle_vertical_line");
      this.hideElementById("right_line");

      if (isUsingDelta) {
        this.handleVDRMouseUp();
      }
    },
    handleVDRMouseUp() {
      this.moveFieldByDelta(this.deltaXToMove, this.deltaYToMove);
      this.deltaXToMove = 0;
      this.deltaYToMove = 0;
    },
    moveFieldByDelta(deltaX, deltaY) {
      if (this.activatedItem && this.activatedItem.x && this.activatedItem.y) {
        this.activatedItem.x += deltaX;
        this.activatedItem.y += deltaY;
      }
    },
    hideElementById(elId) {
      var line = document.getElementById(elId);
      line.style.display = "none";
    },
    handleTextAreaFocus() {
      this.isFieldEditting = true;
    },
    handleTextAreaBlur() {
      this.isFieldEditting = false;
    },
    checkRequiredFiles() {
      if (
        this.selectedItem.requiredFiles > this.selectedItem.file_names.length
      ) {
        this.selectedItem.requiredFiles =
          this.selectedItem.file_names.length > 0
            ? this.selectedItem.file_names.length
            : 1;
      }
    },
    changeof(index) {
      let user = this.documentRecipientsList.find(
        (user) => user.value == this.recipientSelection
      );
      if (user && user.color) {
        this.recipientSelectedColor = user.color;
      }
      document.getElementById(index).style.backgroundColor =
        this.recipientSelectedColor + "33";
      document.getElementById(index).style.borderColor =
        this.recipientSelectedColor;
      document.getElementById(index).style.color = this.recipientSelectedColor;
    },
    changeout(index) {
      document.getElementById(index).style.backgroundColor = "#f9f9f9";
      document.getElementById(index).style.borderColor = "#f9f9f9";
      document.getElementById(index).style.color = "#616366";
    },
    async changeRecipientColor() {
      let user = this.documentRecipientsList.find(
        (user) => user.value == this.recipientSelection
      );
      if (user && user.color) {
        this.recipientSelectedColor = user.color;
      }
      this.contactTypeEntityId =
        user.value === "SENDER"
          ? user?.entity_data_id?.entity_id
          : user.contact_type;
      if (this.documentRecipientsList.length === 1 || user.value === "SENDER") {
        await this.getEntityDetails();
      } else if (
        this.contactTypeEntityId &&
        user.contact_type &&
        typeof user.contact_type !== "object"
          ? user.contact_type !== "62b4145c3fd6fa779848accd"
          : user.contact_type._id !== "62b4145c3fd6fa779848accd"
      ) {
        await this.getEntityDetails();
      } else if (user.contact_type === "62b4145c3fd6fa779848accd") {
        await this.getEntityDetails();
      }
    },
    sendWithSubmit() {
      this.submitEvent = true;
      this.addDocumentUsers = true;
    },
    resetImgData() {
      this.imgData = null;
      if (
        (this.elements[this.selectedItemIndex] &&
          this.elements[this.selectedItemIndex].type == "SELECT") ||
        this.elements[this.selectedItemIndex].type == "MULTI_SELECT"
      ) {
        if (
          this.elements[this.selectedItemIndex] &&
          this.elements[this.selectedItemIndex].options &&
          !this.elements[this.selectedItemIndex].options.length
        ) {
          this.deleteElement();
        }
      }
      if (this.elements[this.selectedItemIndex].type == "LIST") {
        if (
          this.elements[this.selectedItemIndex] &&
          this.elements[this.selectedItemIndex].list_data &&
          !this.elements[this.selectedItemIndex].list_data.length
        ) {
          this.deleteElement();
        }
      }
      if (this.elements[this.selectedItemIndex].type == "SINGLE_LINE_CONTENT") {
        if (
          this.elements[this.selectedItemIndex] &&
          this.elements[this.selectedItemIndex].content === ""
        ) {
          this.deleteElement();
        }
      }
      if (this.elements[this.selectedItemIndex].type == "DATA_TABLE") {
        if (
          this.selectedItem.map_keys &&
          this.elements[this.selectedItemIndex] &&
          this.elements[this.selectedItemIndex].cols == 0
        ) {
          this.deleteElement();
        }
      }
      if (
        this.elements[this.selectedItemIndex] &&
        this.elements[this.selectedItemIndex].type == "my-signature"
      ) {
        if (
          this.elements[this.selectedItemIndex] &&
          !this.elements[this.selectedItemIndex].source
        ) {
          this.deleteElement();
        }
      }
      if (
        this.elements[this.selectedItemIndex] &&
        this.elements[this.selectedItemIndex].type == "MY_INITIAL"
      ) {
        if (
          this.elements[this.selectedItemIndex] &&
          !this.elements[this.selectedItemIndex].value
        ) {
          this.deleteElement();
        }
      }
      this.selectedItem.key = "";
      this.selectedItem.label = "";
      this.selectedItem.filled_by = "";
      this.selectedItem.placeholder = "";
      this.addItemDataDialogVisible = false;
      this.resetSelectedAttachmentFile();
    },
    fileError() {
      this.$alert("File Supported:JPG,PNG,JPEG,Gif");
    },
    uploadImageFile(file) {
      var self = this;
      setTimeout(() => {
        const doc = document.querySelector(".ankaCropper__previewArea");
        this.imgData = doc.querySelector("img").src;
      }, 1000);
      self.imgDataWidth = 0;
      self.imgDataHeight = 0;
      this.imgData = file;
      var img = document.createElement("img");
      img.src = file;
      img.onload = function () {
        var w = img.width;
        var h = img.height;

        self.imgDataWidth = w / 2;
        self.imgDataHeight = h / 2;
        img.remove();
      };
    },
    // uploadImageFile(file) {
    //   var self = this;
    //   if (file["size"] < 2111775) {
    //     if (
    //       file.raw.type == "image/jpeg" ||
    //       file.raw.type == "image/png" ||
    //       file.raw.type == "image/jpg"
    //     ) {
    //       self.imgDataWidth = 0;
    //       self.imgDataHeight = 0;
    //       this.getBase64(file.raw).then((res) => {
    //         this.imgData = res;

    //         // get width, height of image
    //         var img = document.createElement("img");

    //         img.src = res;
    //         img.onload = function () {
    //           var w = img.width;
    //           var h = img.height;

    //           self.imgDataWidth = w / 2;
    //           self.imgDataHeight = h / 2;
    //           img.remove();
    //         };
    //       });
    //     } else {
    //       this.$alert("Please upload a image file");
    //     }
    //   } else {
    //     this.$alert("File size must not exceed 2MB");
    //   }
    // },
    getBase64(file) {
      return new Promise(function (resolve, reject) {
        let reader = new FileReader();
        let imgResult = "";
        reader.readAsDataURL(file);
        reader.onload = function () {
          imgResult = reader.result;
        };
        reader.onerror = function (error) {
          reject(error);
        };
        reader.onloadend = function () {
          resolve(imgResult);
        };
      });
    },
    makeTextItalic() {
      if (this.activatedItem) {
        if (this.activatedItem.font.style == 2) {
          this.activatedItem.font.style = 0;
        } else if (this.activatedItem.font.style == 3) {
          this.activatedItem.font.style = 1;
        } else if (this.activatedItem.font.style == 1) {
          this.activatedItem.font.style = 3;
        } else {
          this.activatedItem.font.style = 2;
        }
      }
    },
    makeTextBold() {
      if (this.activatedItem) {
        if (this.activatedItem.font.style == 1) {
          this.activatedItem.font.style = 0;
        } else if (this.activatedItem.font.style == 3) {
          this.activatedItem.font.style = 2;
        } else if (this.activatedItem.font.style == 2) {
          this.activatedItem.font.style = 3;
        } else {
          this.activatedItem.font.style = 1;
        }
      }
    },
    getFontType(item) {
      let style = `font-family:${item}`;
      return style;
    },
    async getAllFontTypes() {
      await this.$store.dispatch("fileUpload/fetchFontTypes");
      if (this.getFontTypes) {
        this.fontTypes = this.getFontTypes.fontNames;
      }
    },
    async saveData() {
      if (this.saveAction == "saveTemplate") {
        await this.saveTemplate(true);
      } else {
        await this.fillAndSend();
      }
      this.isTemplateToSave = false;
    },
    saveTemplateConfirm() {
      this.saveAction = "saveTemplate";
      this.isTemplateToSave = true;
    },
    fillAndSendConfirm() {
      this.saveAction = "previewTemplate";
      this.addDocumentUsers = true;
      // this.isTemplateToSave = true;
    },
    getFieldClass(item) {
      let className = "each-element ";
      className += item.filled_by;
      return className;
    },
    async showPreview() {
      try {
        this.isShowPreview = true;
        await this.saveTemplate(true);
      } catch (err) {}
    },
    getFilledUserColor(field) {
      let color = "";
      if (field.filled_by) {
        this.documentRecipientsList.map((user) => {
          if (user.value == field.filled_by && user.color) {
            color = user.color;
          }
        });
      }
      return color;
    },
    editReceipentUser(row, index) {
      this.selectedUser = { ...row };
      this.selectedUserIndex = index;
    },
    updateReceipentUser() {
      this.documentRecipientsList = this.documentRecipientsList.map(
        (user, index) => {
          if (index == this.selectedUserIndex) {
            user.first_name = this.selectedUser.first_name;
            user.last_name = this.selectedUser.last_name;
            user.email = this.selectedUser.email;
            user.e_signature_required = this.selectedUser.e_signature_required;
          }
          return user;
        }
      );
      this.selectedUserIndex = null;
      this.selectedUser = null;
    },
    async fetchDocumentDetails() {
      try {
        this.loading = true;
        this.loadingElements = true;
        await this.$store.dispatch(
          "configurableDocuments/fetchConfigurableDocumentById",
          {
            id: this.$route.params.configurable_document_id,
            include_download_url: true,
          }
        );
        if (this.documentName.length == 0) {
          this.documentName =
            this.getConfigurableDocumentDataById &&
            this.getConfigurableDocumentDataById.document_names[0];
        }
        /* ckeditor code----ckeditor code--- */
        if (
          this.getConfigurableDocumentDataById &&
          this.getConfigurableDocumentDataById.ck_editor_content !== ""
        ) {
          this.editorContent =
            this.getConfigurableDocumentDataById.ck_editor_content;
        }
        if (
          this.getConfigurableDocumentDataById &&
          this.getConfigurableDocumentDataById?.is_ck_editor_document === true
        ) {
          this.openCk = true;
          this.getAllNecessaryDetails();
          if (this.getConfigurableDocumentDataById?.ck_editor_content) {
            this.editorContent = this.getConfigurableDocumentDataById
              .ck_editor_unmapped_content
              ? this.getConfigurableDocumentDataById.ck_editor_unmapped_content
              : this.getConfigurableDocumentDataById.ck_editor_content;
            this.editorFields =
              this.getConfigurableDocumentDataById?.ckeditor_fields;
          }
          this.startContentCheck();
        }
        /* ckeditor code---upto here -ckeditor code--- */
        this.templateOwner =
          this.getConfigurableDocumentDataById &&
          this.getConfigurableDocumentDataById.created_by
            ? this.getConfigurableDocumentDataById.created_by
            : null;
        await this.$store.dispatch("auth/fetchInitialSignature");
        await this.$store.dispatch("auth/fetchUserSignature");
        this.isNotInitial = true;

        this.document_url_paths =
          this.getConfigurableDocumentDataById.document_download_s3_urls &&
          this.getConfigurableDocumentDataById.document_download_s3_urls.length
            ? [
                ...this.getConfigurableDocumentDataById
                  .document_download_s3_urls,
              ]
            : [this.getConfigurableDocumentDataById.document_download_s3_url];

        this.document_url_relative_paths =
          this.getConfigurableDocumentDataById.document_upload_urls &&
          this.getConfigurableDocumentDataById.document_upload_urls.length
            ? [...this.getConfigurableDocumentDataById.document_upload_urls]
            : [this.getConfigurableDocumentDataById.document_upload_url];

        this.total_documents = this.document_url_paths.length;
        this.canEdit = this.checkPermissions("editDocument");
        this.canShare = this.checkPermissions("sharedUserCreateDocument");
        this.canGetLink = this.checkPermissions("getLink");
        this.canCreate = this.checkPermissions("userCreateDocment");
        this.scale =
          this.getConfigurableDocumentDataById &&
          this.getConfigurableDocumentDataById.scale
            ? this.getConfigurableDocumentDataById.scale
            : 0;
        this.fields_found = this.getConfigurableDocumentDataById.fields_found;
        this.documentRecipientsList =
          this.getConfigurableDocumentDataById.document_users;
        if (!this.documentRecipientsList.length) {
          await this.addSender();
        }
        let hasFields = this.checkHasFields();
        if (hasFields || !this.fields_found) {
          if (this.openCk) {
            this.addDocumentRecipient = false;
          } else {
            this.addDocumentRecipient = true;
          }
        } else if (
          /* will uncomment after ckeditor code completed */
          this.getConfigurableDocumentDataById &&
          this.getConfigurableDocumentDataById?.is_ck_editor_document === true
        ) {
          this.openCk = true;
        } else {
          this.isDocumentRendered = true;
          await this.renderAllDocuments(true);
          this.showAichatMate = false;
        }
        await Promise.all([
          await this.companyEntityDetails(),
          this.getEntityFields(),
        ]);
        await this.setEntityData();
      } catch (err) {
        this.loading = false;
        if (
          err.name == "InvalidPDFException" ||
          (err.name == "UnexpectedResponseException" && err.status == "403")
        ) {
          this.ErrorMessageUpload = true;
        } else {
        }
      }
    },
    checkHasFields() {
      let hasFields = false;
      Object.keys(this.pdfFormFields || []).forEach((doc) => {
        (Object.keys(this.pdfFormFields[doc]) || []).forEach((el) => {
          let page = this.pdfFormFields[doc][el];
          page.fields = page.fields.filter((e) => e.fieldName && e.fieldType);
          if (page.fields && page.fields.length) {
            hasFields = true;
          }
        });
      });
      return hasFields;
    },
    //render all documents
    async renderAllDocuments(isNew) {
      this.loadingText = "Rendering Document...";
      this.document_url_paths.forEach(async (docUrl, i) => {
        await this.setPdfData(docUrl, i, isNew);
      });
    },
    async setPdfData(url, id, flag) {
      const self = this;
      let i = id;
      let isNew = flag;

      const res = await fetch(url);
      let existingPdfBytes = await res.arrayBuffer();
      const base64String = await atob(this.base64ArrayBuffer(existingPdfBytes));
      try {
        const pdfDocData = await PDFDocument.load(existingPdfBytes, {
          ignoreEncryption: true,
        });
        pdfjsLib
          .getDocument({ data: base64String })
          .promise.then(setFieldsData);
        const form = pdfDocData.getForm();
        const formFields = form.getFields();
        try {
          for (const field of formFields) {
            let key = field.getName();
            const fieldData = form.getFieldMaybe(key);
            if (fieldData) {
              form.removeField(field);
            }
          }
        } catch (error) {}
        const pdfBytes = await pdfDocData.save();
        const modifiedBase64String = await atob(
          this.base64ArrayBuffer(pdfBytes)
        );
        let pdfData1 = await pdfjsLib
          .getDocument({ data: modifiedBase64String })
          .promise.then(setPDfData)
          .then(this.setPDfData);
      } catch (e) {
        this.is_xfa_pdf = true;
        let pdfData2 = await pdfjsLib
          .getDocument({ data: base64String })
          .promise.then(setFieldsData)
          .then(setPDfData)
          .then(this.setPDfDa);
      }

      function setFieldsData(pdfDoc) {
        for (let i = 1; i <= pdfDoc.numPages; i++) {
          pdfDoc.getPage(i).then(getPageInfo);
        }
        return pdfDoc;
      }
      async function getPageInfo(page) {
        const tokenizedText = await page.getTextContent();
        // self.allPdfTextStyle[page.pageNumber] = tokenizedText.styles;
        let textStyles = {};
        textStyles[page.pageNumber] = tokenizedText.styles;
        self.allPdfTextStyle[i] = {
          ...self.allPdfTextStyle[i],
          ...textStyles,
        };
        let textBox = {};
        textBox[page.pageNumber] = {
          docId: i,
          pageId: page.pageNumber,
          fields: tokenizedText.items.filter(
            (u) =>
              u.str &&
              u.str.trim() &&
              u.str.trim().length &&
              typeof u.str == "string"
          ),
        };
        self.allPdfText[i] = {
          ...self.allPdfText[i],
          ...textBox,
        };
      }
      async function setPDfData(pdfDoc) {
        // self.allPdfData.push({
        //   pdfDoc: pdfDoc,
        //   pages: pdfDoc.numPages,
        //   order: i,
        // });
        // if (self.total_documents == i + 1) {
        //   self.allPdfData = self.allPdfData.sort((a, b) => a.order - b.order);
        //   await self.document_url_paths.forEach(async (doc, index) => {
        //     await self.renderPreviewPages(index, isNew);
        //   });
        // }
        return {
          data: pdfDoc,
          order: i,
          isNew: isNew,
        };
      }
    },
    async setPDfData(pdfDoc) {
      console.log(pdfDoc);
      this.allPdfData.push({
        pdfDoc: pdfDoc.data,
        pages: pdfDoc.data.numPages,
        order: pdfDoc.order,
      });
      if (this.total_documents == this.allPdfData.length) {
        this.allPdfData = this.allPdfData.sort((a, b) => a.order - b.order);
        await this.document_url_paths.forEach(async (doc, index) => {
          await this.renderPreviewPages(index, pdfDoc.isNew);
        });
      }
    },
    base64ArrayBuffer(arrayBuffer) {
      var base64 = "";
      var encodings =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";

      var bytes = new Uint8Array(arrayBuffer);
      var byteLength = bytes.byteLength;
      var byteRemainder = byteLength % 3;
      var mainLength = byteLength - byteRemainder;

      var a, b, c, d;
      var chunk;

      // Main loop deals with bytes in chunks of 3
      for (var i = 0; i < mainLength; i = i + 3) {
        // Combine the three bytes into a single integer
        chunk = (bytes[i] << 16) | (bytes[i + 1] << 8) | bytes[i + 2];

        // Use bitmasks to extract 6-bit segments from the triplet
        a = (chunk & 16515072) >> 18; // 16515072 = (2^6 - 1) << 18
        b = (chunk & 258048) >> 12; // 258048   = (2^6 - 1) << 12
        c = (chunk & 4032) >> 6; // 4032     = (2^6 - 1) << 6
        d = chunk & 63; // 63       = 2^6 - 1

        // Convert the raw binary segments to the appropriate ASCII encoding
        base64 += encodings[a] + encodings[b] + encodings[c] + encodings[d];
      }

      // Deal with the remaining bytes and padding
      if (byteRemainder == 1) {
        chunk = bytes[mainLength];

        a = (chunk & 252) >> 2; // 252 = (2^6 - 1) << 2

        // Set the 4 least significant bits to zero
        b = (chunk & 3) << 4; // 3   = 2^2 - 1

        base64 += encodings[a] + encodings[b] + "==";
      } else if (byteRemainder == 2) {
        chunk = (bytes[mainLength] << 8) | bytes[mainLength + 1];

        a = (chunk & 64512) >> 10; // 64512 = (2^6 - 1) << 10
        b = (chunk & 1008) >> 4; // 1008  = (2^6 - 1) << 4

        // Set the 2 least significant bits to zero
        c = (chunk & 15) << 2; // 15    = 2^4 - 1

        base64 += encodings[a] + encodings[b] + encodings[c] + "=";
      }

      return base64;
    },
    //updating fields positions when deleting the page
    updateFieldPositionsDelete() {
      let i = this.currentSelectedPageIndex;
      let j = this.currentSelectedDocIndex;
      let removePage = document.getElementById(`${j}_canvas_${i}`);
      let removePageHeight = removePage.height;
      let newElements = this.elements.filter((el) => {
        let pageBoundary = this.getPagesBoundaries(el.y);
        if (pageBoundary.pageNumber != j) {
          return el;
        } else if (
          pageBoundary.pageNumber == j &&
          pageBoundary.data.pageId + 1 != i
        ) {
          return el;
        }
      });
      this.elements = [];
      newElements.forEach((el) => this.elements.push(el));
      this.elements.map((el) => {
        let pageBoundary = this.getPagesBoundaries(el.y);
        if (pageBoundary.pageNumber > j) {
          el.y = el.y - (removePageHeight + 8);
        } else if (
          pageBoundary.pageNumber == j &&
          pageBoundary.data.pageId + 1 > i
        ) {
          el.y = el.y - (removePageHeight + 8);
        }
      });
      this.resetEditFlags();
    },
    //deleting page
    async deletePage(i, j) {
      //** MIXIN */
      await this.onDeletePageEvent(i, j);
    },
    //getting affected fields
    getAffectedFieldsAdd() {
      let i = this.currentSelectedPageIndex;
      let j = this.currentSelectedDocIndex;
      this.affectedFields = [];
      this.elements.forEach((el, index) => {
        let pageBoundary = this.getPagesBoundaries(el.y);
        if (pageBoundary.pageNumber > j) {
          this.affectedFields.push(index);
        } else if (
          pageBoundary.pageNumber == j &&
          pageBoundary.data.pageId + 1 >= i
        ) {
          this.affectedFields.push(index);
        }
      });
    },
    //updating positions after page add
    updateFieldPositions() {
      let i = this.currentSelectedPageIndex;
      let j = this.currentSelectedDocIndex;
      if (
        this.currentSelectedPageIndex > -1 &&
        this.currentSelectedDocIndex > -1
      ) {
        this.currentActivatePage = i;
        this.currentActivateDoc = j;
        this.navigateToPageNo();
      } else {
        this.currentActivateDoc = this.getAllPdfData.length - 1;
        this.currentActivatePage =
          this.getAllPdfData[this.getAllPdfData.length - 1]["pages"];
        this.navigateToPageNo();
      }
      let fields = [];
      this.elements.forEach((e) => fields.push(e));
      this.elements = [];
      setTimeout(() => {
        this.elements = fields.map((el, index) => {
          if (this.affectedFields.indexOf(index) != -1) {
            let height = document.getElementById(`${j}_canvas_${i}`).height;
            el.y = el.y + height + 6;
          }
          return el;
        });
      }, 500);
      this.documentDataLoading = false;
      this.resetEditFlags();
    },
    //saving edited pdf
    async editAndSaveDocument() {
      let params = {};
      let newRotations = [];
      this.rotations.forEach((el) => {
        const data = (({ page, rotate }) => ({ page, rotate }))(el);
        newRotations.push(data);
      });
      params.keepFileID = true;
      params.pdfSignatures = [];
      params.defaultScale = 1;
      params.pdfTexts = [];
      params.pdfImages = [];
      params.pdfDeletePages = [];
      params.pdfRotatePages = newRotations;
      // params.pdfContent = this.document_url_path
      //   .slice(23, this.document_url_path.length)
      //   .split(".pdf")[0];

      let tempUrl = this.document_url_path.split("/");
      let id = tempUrl[tempUrl.length - 1].split(".")[0];
      params.pdfContent = id;
      params["doc_company_id"] = tempUrl[2];
      await this.$store.dispatch("fileUpload/fileTheUpload", params);
      params.pdfDeletePages = this.deleted_pages;
      params.pdfRotatePages = [];
      await this.$store.dispatch("fileUpload/fileTheUpload", params);
      this.rotations = [];
      this.deleted_pages = [];
      let url = this.basePdfdownloadUrl + this.document_url_path;
      await this.renderPreviewPages(url, false);
      this.isInitialRender = true;
      // if (this.getUploadStatus.responseCode == 0) {
      //   await this.saveTemplate();
      // }
    },
    //focus page
    focusDocPage(i) {
      let id = `canvas_${i}`;
      document.getElementById(id).focus();
    },
    async rotateLeft(i, j) {
      /** MIXIN **/
      await this.rotateLeftEvent(i, j);
    },
    async rotateRight(i, j) {
      /** MIXIN **/
      await this.rotateRightEvent(i, j);
    },
    checkRadioGroupElements() {
      this.elements.map((el, index) => {
        if (el && el.type == "RADIO_BUTTON_GROUP") {
          el.value = this.documentData[el.key]
            ? this.documentData[el.key]
            : el.value;
          this.elements[index].value = this.documentData[el.key];
        }
      });
    },

    //reset editing flags
    resetEditFlags() {
      this.currentSelectedPageIndex = -1;
      this.currentSelectedDocIndex = -1;
      this.isEditingDocument = false;
      this.isAddingPage = false;
      this.isDeletingPage = false;
      this.isRightRotate = false;
      this.isLeftRotate = false;
    },
    //addPageOnBottom
    async addPageOnBottom() {
      /** MIXIN */
      await this.addPageOnBottomEvent();
    },
    //adding page at top
    async addPage(i, j) {
      /** MIXIN */
      await this.addPageEvent(i, j);
    },
    async renderPreviewPages(id, flag) {
      const self = this;
      let i = id;
      let isNew = flag;
      if (i == 0) {
        this.docPages = 0;
      }
      async function renderPage(page) {
        let scale = 1;
        if (self.isInitialRender) {
          var viewport = page.getViewport({ scale: scale });
          let width = viewport.width;
          if (self.docPages < width) {
            self.docPages = width;
          }
          self.rotations.push({
            page: page.pageNumber,
            rotate: page.rotate,
            document_id: i,
          });
          if (
            page.pageNumber == self.allPdfData[i].pages &&
            i + 1 == self.total_documents
          ) {
            self.isInitialRender = false;
          }
        }
        let previewView = page.getViewport({ scale: 0.2 });
        var canvas = document.getElementById(
          `${i}_canvas_page_${page.pageNumber}`
        );
        canvas.onclick = function () {
          this.currentActivatePage = page.pageNumber;
          this.currentActivateDoc = i;
          self.navigateToPageNo();
        };
        canvas.id = `${i}_canvas_page_${page.pageNumber}`;
        canvas.style.cssText =
          "margin-top: 20px; border: 1px solid #ccc; margin-left: 10px; margin-right: 10px; cursor: pointer;";
        var ctx = canvas.getContext("2d");
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        var renderContext = {
          canvasContext: ctx,
          viewport: previewView,
        };
        canvas.height = previewView.height;
        canvas.width = previewView.width;
        await page.render(renderContext).promise;
      }
      if (
        this.allPdfData &&
        this.allPdfData.length &&
        this.allPdfData[i] &&
        this.allPdfData[i].pages
      ) {
        for (var num = 1; num <= this.allPdfData[i].pages; num++) {
          if (
            num == this.allPdfData[i].pages &&
            i + 1 == this.total_documents
          ) {
            await this.allPdfData[i].pdfDoc
              .getPage(num)
              .then(renderPage)
              .then(
                setTimeout(() => {
                  self.document_url_paths.forEach(async (doc, i) => {
                    await self.getWidthandHeight(i, isNew);
                  });
                }, 500)
              );
          } else {
            await this.allPdfData[i].pdfDoc.getPage(num).then(renderPage);
          }
        }
      }
      // }
      // pdfjsLib.disableWorker = true;
      // pdfjsLib.getDocument(url).then(this.getPages).then(renderPages);
    },
    handleSizeChange() {
      this.$nextTick(() => {
        this.$refs.dialog.$el.querySelector(
          ".el-dialog__body"
        ).style.maxHeight = window.innerHeight * 0.7 + "px";
      });
    },
    maximizeDialog() {
      this.maximized = !this.maximized;
      this.$nextTick(() => {
        this.$refs.dialog.$el.querySelector(
          ".el-dialog__body"
        ).style.maxHeight = this.maximized
          ? "none"
          : window.innerHeight * 0.7 + "px";
        this.$refs.dialog.$el.style.width = this.maximized ? "100%" : "60%";
        this.$refs.dialog.$el.style.top = this.maximized ? 0 : "15vh";
      });
    },
    getPages(pdfDoc) {
      this.pdfData = null;
      this.pdfPages = 1;
      this.pdfPages = pdfDoc.numPages;
      this.pdfData = pdfDoc;
      return pdfDoc;
    },
    async updateConfigDocumentScale() {
      try {
        this.params = this.prepareFields();
        this.$set(this.params, "scale", this.scale);
        if (
          this.getConfigurableDocumentDataById.pages &&
          this.getConfigurableDocumentDataById.pages[0] &&
          this.getConfigurableDocumentDataById.pages[0].fields &&
          this.getConfigurableDocumentDataById.pages[0].fields.length
        ) {
          this.params.pages[0].fields =
            this.getConfigurableDocumentDataById.pages[0].fields;
        }
        delete this.params.template_title;
        delete this.params.name;
        await this.$store.dispatch(
          "configurableDocuments/updateConfigurableDocument",
          this.params
        );
      } catch (err) {}
    },
    async getWidthandHeight(id, flag) {
      const self = this;
      let scale = 1.6;
      let i = id;
      let isNew = flag;
      for (let i = 0.5; i <= 2; i += 0.1) {
        if (self.docPages && self.docPages * i <= 955) {
          scale = i;
        }
      }
      if (this.scale == 0) {
        this.scale = scale;
        this.updateConfigDocumentScale();
      } else {
        scale = this.scale;
      }
      async function renderPage(page) {
        var viewport = page.getViewport({ scale: scale });
        var canvas = document.getElementById(`${i}_canvas_${page.pageNumber}`);
        canvas.style.cssText =
          "box-shadow: 0 1px 1px rgba(0,0,0,0.12), 0 2px 2px rgba(0,0,0,0.12), 0 4px 4px rgba(0,0,0,0.12), 0 8px 8px rgba(0,0,0,0.12),0 16px 16px rgba(0,0,0,0.12);";
        var ctx = canvas.getContext("2d");
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        var renderContext = {
          canvasContext: ctx,
          viewport: viewport,
        };
        canvas.height = viewport.height;
        canvas.width = viewport.width - 2;
        await page.render(renderContext).promise;
        self.pageWidth = viewport.width;
        self.pageHeight += viewport.height;
      }
      for (var num = 1; num <= this.allPdfData[i].pages; num++) {
        if (num == this.allPdfData[i].pages) {
          await this.allPdfData[i].pdfDoc
            .getPage(num)
            .then(renderPage)
            .then(
              setTimeout(() => {
                if (this.isAddingPage && this.isEditingDocument) {
                  this.updateFieldPositions();
                } else if (this.isDeletingPage && this.isEditingDocument) {
                  this.documentDataLoading = false;
                } else if (
                  (this.isRightRotate || this.isLeftRotate) &&
                  this.isEditingDocument
                ) {
                  this.documentDataLoading = false;
                } else if (this.isPdfEditing) {
                  this.documentDataLoading = false;
                  this.isPdfEditing = false;
                } else {
                  if (i + 1 == this.total_documents && isNew) {
                    this.previewDocumentData(isNew);
                  }
                }
                this.documentDataLoading = false;
              }, 500)
            );
        } else {
          await this.allPdfData[i].pdfDoc.getPage(num).then(renderPage);
        }
      }
    },
    showUsersPopup() {
      this.addDocumentRecipient = true;
      this.constantUsersPopup = false;
    },
    checkBoxDisabled(item) {
      if (item && item.type == "CHECKBOX" && item.group_key) {
        let currentActiveGroupOfAField = this.checkBoxGroupArray.find(
          (gkey) => gkey.key == item.group_key
        );
        let groupSelectedFields = this.elements.filter(
          (field) =>
            field.group_key &&
            field.group_key == item.group_key &&
            field.value == true
        );

        let maxValue = parseInt(
          currentActiveGroupOfAField && currentActiveGroupOfAField.max_value
        );
        if (maxValue == 0 || !maxValue) {
          return false;
        }
        let fieldCount = groupSelectedFields.length;
        if (fieldCount >= maxValue && !item.value) {
          this.$message.error(
            "You have reached the maximum allowed selections."
          );
          return true;
        } else {
          return false;
        }
      }
      return false;
    },
    handleCheckBoxChange(item, index) {
      let currentActiveGroupOfAField = this.checkBoxGroupArray.find(
        (gkey) => gkey.key == item.group_key
      );
      if (item && item.type === "CHECKBOX" && item.group_key) {
        const groupCheckboxes = this.elements.filter(
          (field) =>
            field.type === "CHECKBOX" && field.group_key === item.group_key
        );
        if (
          groupCheckboxes.length === 2 &&
          currentActiveGroupOfAField.max_value === 1
        ) {
          this.elements.forEach((checkbox) => {
            if (
              checkbox.type == "CHECKBOX" &&
              currentActiveGroupOfAField.key === checkbox.group_key &&
              checkbox.key !== item.key
            ) {
              checkbox.value = false;
            }
          });
        } else {
          let groupSelectedFields = this.elements.filter(
            (field) =>
              field.group_key &&
              field.group_key == item.group_key &&
              field.value == true
          );
          let maxValue = parseInt(
            currentActiveGroupOfAField && currentActiveGroupOfAField.max_value
          );
          if (
            groupCheckboxes.length > 2 &&
            currentActiveGroupOfAField.max_value >= 1
          ) {
            let fieldCount = groupSelectedFields.length + 1;
            if (!item.value && fieldCount > maxValue) {
              this.$message.error(
                "You have reached the maximum allowed selections."
              );
              setTimeout(() => {
                this.elements.forEach((checkbox) => {
                  if (
                    checkbox.type == "CHECKBOX" &&
                    checkbox.key === item.key
                  ) {
                    checkbox.value = false;
                  }
                });
                this.$nextTick(() => {});
              });
            }
          }
        }
      }
    },
    getCheckBoxLabel(item) {
      if (item && item.type == "CHECKBOX" && item.group_key) {
        let checkLabel = this.checkBoxGroupArray.find(
          (el) => el && el.key == item.group_key
        );
        if (checkLabel && checkLabel.show_checkbox_label) {
          return true;
        }
      } else {
        if (item.is_show_label) {
          return true;
        }
      }
      return false;
    },
    openAddGroupDialog() {
      this.showAddData = true;
    },

    async submitNewGroup() {
      this.check_box_group_form.filled_by = this.selectedItem.filled_by;
      this.dialogErr = false;
      let hasVal = -1;
      if (this.checkBoxGroupArray) {
        hasVal = this.checkBoxGroupArray.findIndex(
          (g) => g.key == this.check_box_group_form["label"].toLowerCase()
        );
      }
      if (hasVal > -1) {
        this.dialogErr = true;
      }
      // else if (
      //   this.check_box_group_form.min_value == 0 ||
      //   (!this.check_box_group_form.min_value &&
      //     this.check_box_group_form.required)
      // ) {
      //   this.$message.error(
      //     "The minimum value for the required field cannot be 0."
      //   );
      // }
      else if (
        parseInt(this.check_box_group_form.min_value) >
        parseInt(this.check_box_group_form.max_value)
      ) {
        this.$message.error("Minimum value cannot exceed maximum value.!");
      } else if (!this.check_box_group_form.filled_by) {
        this.$message.error("Kindly choose fields filled by.!");
      } else {
        this.check_box_group_form["key"] = this.generateKeyForField(
          this.check_box_group_form["label"]
        );
        this.checkBoxGrpObj = JSON.parse(
          JSON.stringify(this.check_box_group_form)
        );
        this.selectedItem.group_key = this.check_box_group_form.key;
        let groupObjects = JSON.parse(
          JSON.stringify(this.check_box_group_form)
        );
        this.checkBoxGroupArray.push(groupObjects);

        this.showAddData = false;
        this.cancelGroupPopup();
      }
    },
    cancelGroupPopup() {
      this.check_box_group_form.label = "";
      this.check_box_group_form.key = "";
      this.check_box_group_form.max_value = 1;
      this.check_box_group_form.min_value = 1;
      // this.check_box_group_form.required = false;
      this.check_box_group_form.show_checkbox_label = false;
      this.check_box_group_form.filled_by = "";
    },
    generateKeyForField(label) {
      let parsedLabel = label.replace(/[^a-zA-Z 0-9]/g, "");
      parsedLabel = parsedLabel.toLowerCase().split(" ").join("_");
      return parsedLabel;
    },
    async finishDocument() {
      try {
        let constantUser = this.documentRecipientsList.filter((el) => {
          if (
            el &&
            el.type == "RECEIVER" &&
            el.user_type == "CC" &&
            !el.constant_user
          ) {
            return el;
          }
        });
        this.constantUsers = constantUser;
        if (constantUser && constantUser.length) {
          this.constantUsersPopup = true;
        } else {
          await this.updateCurrentUserEntityData(this.documentRecipientsList);
          this.checkboxErrors = [];
          this.documentDataLoading = true;
          this.loadingText = "Document completion in progress...";
          let senderEmptyfields = [];
          senderEmptyfields = this.elements.filter((el) => {
            if (this.unWantedFields.indexOf(el.type) == -1) {
              if (
                el.filled_by == "SENDER" &&
                el.type == "MULTI_SELECT" &&
                el.value &&
                !el.value.length
              ) {
                return el;
              } else if (
                el.filled_by == "SENDER" &&
                el.type == "CHECKBOX" &&
                el.group_key &&
                (this.checkIsCheckBoxFilledOrNot(el) == true ||
                  this.checkIsCheckBoxFilledOrNot(el))
              ) {
                return el;
              } else if (
                el.filled_by == "SENDER" &&
                el.type == "CHECKBOX" &&
                (el.required || el.required == true) &&
                !el.group_key &&
                !el.value
              ) {
                return el;
              } else if (el.filled_by == "SENDER" && el.type == "ATTACHMENT") {
                if (el.requiredFiles) {
                  if (el.files && el.files.length < el.requiredFiles) {
                    return el;
                  }
                } else {
                  if (el.files && !el.files.length) {
                    return el;
                  }
                }
              } else if (
                el.filled_by == "SENDER" &&
                el.type == "PRIMARY_FIELDS"
              ) {
                if (!el.content) {
                  return el;
                }
              } else if (
                el.filled_by == "SENDER" &&
                el.type == "ENTITY" &&
                !el.entity_data_id
              ) {
                return el;
              } else if (
                el.filled_by == "SENDER" &&
                el.field_type == "CUSTOM_FIELD" &&
                !el.group_key &&
                el.required &&
                !el.value
              ) {
                return el;
              } else if (
                el.filled_by == "SENDER" &&
                !el.group_key &&
                el.required &&
                !el.value
              ) {
                return el;
              }
            }
          });
          this.unfilledFields = [];
          this.checkBoxGroupArray.forEach((grp) => {
            let notCheckboxFilled = this.elements.filter(
              (el) => el.type == "CHECKBOX" && el.group_key === grp.key
            );
            if (
              notCheckboxFilled.length &&
              notCheckboxFilled.length > 0 &&
              notCheckboxFilled.length < parseInt(grp.min_value)
            ) {
              this.checkboxErrors.push(grp);
            }
          });
          if (this.checkboxErrors.length && this.checkboxErrors.length > 0) {
            this.showCheckboxErrors = true;
            this.documentDataLoading = false;
          } else if (senderEmptyfields && senderEmptyfields.length) {
            senderEmptyfields.forEach((field) => {
              let checksData = this.checkBoxGroupArray.find(
                (el) => el && el.key && el.key == field.group_key
              );
              let groupFilledFields = this.elements.filter(
                (e) =>
                  e.type == "CHECKBOX" &&
                  e.group_key &&
                  checksData &&
                  e.group_key == checksData.key &&
                  e.value
              );
              if (
                field.type == "CHECKBOX" &&
                field.group_key &&
                this.unfilledFields.indexOf(field.group_key) === -1 &&
                groupFilledFields &&
                groupFilledFields.length < checksData.min_value
              ) {
                this.unfilledFields.push(field.group_key);
                return;
              } else if (
                groupFilledFields &&
                checksData &&
                groupFilledFields.length >= checksData.min_value
              ) {
                let index = this.unfilledFields.indexOf(field.group_key);
                if (index !== -1) {
                  this.unfilledFields.splice(index, 1);
                  return;
                }
              } else if (
                !field.group_key &&
                this.unfilledFields.indexOf(field.label) === -1
              ) {
                this.unfilledFields.push(field.label);
              }
            });
            //this.unfilledFields = senderEmptyfields;
            this.showFinishErrorElementDialog = true;
            this.documentDataLoading = false;
          } else {
            this.elements.map((element) => {
              if (
                element.filled_by == "SENDER" ||
                element.selected_user == "SENDER"
              ) {
                if (
                  element.type == "SIGNATURE" ||
                  element.type == "my-signature"
                ) {
                  if (!element.source) {
                    if (
                      this.getAuthenticatedUser &&
                      this.getAuthenticatedUser.signature
                    ) {
                      element.source = this.getAuthenticatedUser.signature;
                    } else {
                      this.isUserHasSign = false;
                      this.$message.error(
                        "Please add a signature in the 'My Signature' field or update the signature in the profile settings."
                      );
                    }
                  }
                } else if (
                  element.type == "MY_INITIAL" ||
                  element.type == "INITIAL"
                ) {
                  if (!element.value) {
                    if (this.getInitialUrl) {
                      element.value = this.getInitialUrl;
                    } else {
                      this.isUserHasSign = false;
                      this.$message.error(
                        "Please add initial in my initial field or update initial in profile settings "
                      );
                    }
                  }
                }
                if (element.type == "DATE_SIGNED") {
                  let date = new Date();
                  element.value = date;
                }
                if (element.type == "FORMULA") {
                  element.value = this.documentData[element.key];
                }
              }
            });

            this.elements = this.mapCoordinates(
              this.elements,
              this.document_url_paths
            );
            this.checkboxErrors = [];
            const hasEmptyFields = this.elements.some((element) => {
              if (
                (element.type === "SIGNATURE" ||
                  element.type === "my-signature") &&
                element.filled_by === "SENDER" &&
                (!element.source || element.source === "")
              ) {
                return true;
              }

              if (
                (element.type === "INITIAL" || element.type === "MY_INITIAL") &&
                element.filled_by === "SENDER" &&
                (!element.value || element.value === "")
              ) {
                return true;
              }

              return false;
            });
            if (!hasEmptyFields && !senderEmptyfields.length) {
              let documentData = this.prepareDocumentData();
              let params = await this.prepareFields();
              let documentUsers = params.document_users;
              (params.document_data = documentData),
                (params.configurabledocumentid =
                  this.$route.params.configurable_document_id),
                (params.document_template_id = this.$route.params.document_id),
                (params.document_users = documentUsers),
                (params.is_template = true);

              await this.$store.dispatch(
                "configurableDocuments/updateConfigurableDocument",
                params
              );

              await this.$store.dispatch(
                "documents/finishSenderDocument",
                params
              );
              if (this.getSenderFinishDocumentStatus) {
                this.$notify.success({
                  title: "Success",
                  message: "Document Completed Successfully",
                });
                let id = this.getSenderFinishDocumentData.employeeDocument._id;
                this.$router.push({
                  name: "employee-documents-custom-document",
                  params: {
                    employee_document_id: id,
                  },
                });
              }
            }
            this.documentDataLoading = false;
          }
        }
      } catch (err) {
        this.documentDataLoading = false;
      }
    },
    checkIsCheckBoxFilledOrNot(item) {
      let currentActiveGroupOfAField = this.checkBoxGroupArray.find(
        (gkey) => gkey.key == item.group_key
      );
      if (
        (currentActiveGroupOfAField &&
          currentActiveGroupOfAField.min_value <= 0) ||
        !currentActiveGroupOfAField.min_value
      ) {
        return false;
      } else {
        let groupSelectedFields = this.elements.filter(
          (field) =>
            field.group_key &&
            field.group_key == item.group_key &&
            field.value == true
        );
        let minValue = parseInt(currentActiveGroupOfAField.min_value);
        let fieldCount = groupSelectedFields.length;
        if (
          currentActiveGroupOfAField.filled_by == "SENDER" &&
          fieldCount < minValue
        ) {
          return true;
        } else if (
          currentActiveGroupOfAField.filled_by == "SENDER" &&
          fieldCount == minValue
        ) {
          return false;
        }
        return false;
      }
    },
    resetCheckboxErrors() {
      this.showCheckboxErrors = false;
    },
    //update field positions
    updateFieldPositionsRotations() {
      let id =
        this.currentSelectedDocIndex +
        "_canvas_" +
        this.currentSelectedPageIndex;
      let pageBreaks = [];
      let h = 0;
      let maxLength = document.getElementById("wrapper").childNodes.length;
      for (let i = 0; i < maxLength; i++) {
        h = h + document.getElementById("wrapper").childNodes[i].height;
        pageBreaks.push(h);
      }
      let newPageY = document.getElementById(id).width;
      let oldPageX = document.getElementById(id).height;
      let newFields = [];
      this.elements.forEach((el) => {
        let pageBoundary = this.getPagesBoundaries(el.y);
        if (pageBoundary.pageNumber > this.currentSelectedDocIndex) {
          if (oldPageX > newPageY) {
            el.y = el.y - (oldPageX - newPageY);
          } else {
            el.y = el.y + (newPageY - oldPageX);
          }
        } else if (
          pageBoundary.pageNumber == this.currentSelectedDocIndex &&
          pageBoundary.data.pageId + 1 > this.currentSelectedPageIndex
        ) {
          if (oldPageX > newPageY) {
            el.y = el.y - (oldPageX - newPageY);
          } else {
            el.y = el.y + (newPageY - oldPageX);
          }
        }
        newFields.push(el);
      });
      this.elements = [];
      newFields.forEach((e) => {
        this.elements.push(e);
      });
    },
    checkDeleteFields() {
      if (this.elements && this.pageHeight) {
        let extraElements = [];
        this.elements.forEach((el, index) => {
          if (el && el.y && el.y > this.pageHeight) {
            extraElements.push(index);
          }
        });
        if (extraElements && extraElements.length) {
          extraElements.forEach((el) => this.elements.splice(el, 1));
        }
      }
    },
    getFormat() {
      let format = "";
      if (this.totalFieldsCount) {
        format = this.filledFieldsCount + "/" + this.totalFieldsCount;
      } else {
        format = "0/0";
      }
      return format;
    },
    async fillSignatureDateSigned() {
      const signatureFieldsWithValue = this.editorFields.filter(
        (e) =>
          (e.input_type === "SIGNATURE" || e.type === "SIGNATURE") &&
          e.value !== null
      );
      console.log(signatureFieldsWithValue.length, signatureFieldsWithValue);
      this.editorFields.forEach((matchingField) => {
        if (!matchingField.template_key) {
          return;
        }
        const isDateSignedField =
          matchingField.input_type === "DATE_SIGNED" ||
          matchingField.type === "DATE_SIGNED";
        if (
          signatureFieldsWithValue.length &&
          isDateSignedField &&
          (matchingField.value === null || matchingField.value === "")
        ) {
          let date = new Date();
          matchingField.value = date;
        }
      });
    },
    async replaceSenderSideVariables() {
      this.loading = true;
      this.loadingText =
        "Mapping mentioned fields with user entity fields data...";
      this.hasCkEditorEmptyFields = false;
      let modifiedContents = [];
      const variableRegex = /\[\[(.*?)\]\]/g;
      let modifiedContent = this.editorContent;
      const matches = this.editorContent.match(/\[\[(.*?)\]\]/g);
      const labels = matches
        ? matches.map((label) => this.normalizeLabel(label.slice(2, -2)))
        : [];
      const labelCountMap = {};
      labels.forEach((label) => {
        if (labelCountMap[label]) {
          labelCountMap[label] += 1;
        } else {
          labelCountMap[label] = 1;
        }
      });
      const filteredEditorFields = [];
      this.editorFields.forEach((field) => {
        const normalizedFieldLabel = this.normalizeLabel(field.label);
        if (labelCountMap[normalizedFieldLabel] > 0) {
          for (let i = 0; i < labelCountMap[normalizedFieldLabel]; i++) {
            filteredEditorFields.push(field);
          }
          labelCountMap[normalizedFieldLabel] = 0;
        }
      });
      this.editorFields = filteredEditorFields;
      modifiedContent = modifiedContent.replace(
        variableRegex,
        (match, variableName) => {
          const matchingField = this.editorFields.find(
            (field) => field.label === variableName
          );
          if (matchingField) {
            if (
              matchingField.value === null ||
              matchingField.value === undefined ||
              matchingField.value === ""
            ) {
              this.hasCkEditorEmptyFields = true;
            } else if (
              matchingField &&
              (matchingField.input_type === "SIGNATURE" ||
                matchingField.type === "SIGNATURE") &&
              this.getAuthenticatedUser &&
              this.getAuthenticatedUser.signature
            ) {
              matchingField.value =
                matchingField.isStatic || matchingField.isStatic === "true"
                  ? this.getAuthenticatedUser.signature
                  : matchingField.value;
              return `<img src="${matchingField.value}" style="margin-bottom:-20px;margin-left:-10px;" width="${matchingField.width}px" height="${matchingField.height}px" alt="${variableName}"/>`;
            } else if (
              (matchingField && matchingField.input_type === "DATE_SIGNED") ||
              matchingField.type === "DATE_SIGNED"
            ) {
              let date = new Date();
              matchingField.value = matchingField.value
                ? matchingField.value
                : date;
              const format = "MM/DD/YYYY";
              let dt = moment(matchingField.value).format(format);
              let val = dt ? dt : "";
              return `<span style="color: #000000;">${dt}</span>`;
            } else if (
              matchingField.type === "IMAGE" ||
              matchingField.input_type === "IMAGE" ||
              matchingField.type === "FILE" ||
              matchingField.input_type === "FILE"
            ) {
              if (matchingField.value) {
                let imageHtml = `<img src="${matchingField.value}" width="${
                  matchingField.width ? matchingField.width : 100
                }px" height="${
                  matchingField.height ? matchingField.height : 100
                }px" alt="${variableName}"/>`;
                console.log("vimageHtml", imageHtml);
                return imageHtml;
              } else {
                return match;
              }
            } else if (
              matchingField.value &&
              matchingField.value !== null &&
              matchingField.value !== "" &&
              matchingField.value !== undefined &&
              (matchingField.type === "DATE_TIME" ||
                matchingField.input_type === "DATE_TIME")
            ) {
              const format = "MM-DD-YYYY HH:mm:ss";
              let dt = moment(matchingField.value).format(format);
              return `<span style="color: #000000;">${dt}</span>`;
            } else if (
              matchingField.input_type === "CHECKBOX" ||
              matchingField.type === "CHECKBOX"
            ) {
              if (matchingField.value && matchingField.value === true) {
                let check_image = this.ck_check_image;
                let imgCheck = `<img src=${check_image} width="15px" height="15px" alt="${matchingField.label}"/>`;
                return imgCheck;
              } else {
                return match;
              }
            } else if (
              matchingField.value &&
              matchingField.value !== null &&
              matchingField.value !== "" &&
              matchingField.value !== undefined &&
              (matchingField.type === "DATE_TIME_RANGE" ||
                matchingField.input_type === "DATE_TIME_RANGE")
            ) {
              const format = "MM-DD-YYYY HH:mm:ss";
              if (typeof matchingField.value === "string") {
                matchingField.value = matchingField.value.split(",");
              }
              let newAr = [];
              for (var i = 0; i < matchingField.value.length; i++) {
                let obj = moment(matchingField.value[i]).format(format);
                newAr.push(obj);
              }
              let contentValue = newAr.join(" To ");
              return `<span style="color: #000000;">${contentValue}</span>`;
            } else if (
              matchingField.value &&
              matchingField.value !== null &&
              matchingField.value !== "" &&
              matchingField.value !== undefined &&
              (matchingField.type === "DATE_RANGE" ||
                matchingField.input_type === "DATE_RANGE")
            ) {
              const format = "MM-DD-YYYY ";
              if (typeof matchingField.value === "string") {
                matchingField.value = matchingField.value.split(",");
              }
              let newAr = [];
              for (i = 0; i < matchingField.value.length; i++) {
                let obj = moment(matchingField.value[i]).format(format);
                newAr.push(obj);
              }
              let contentValue = newAr.join(" To ");

              return `<span style="color: #000000;">${contentValue}</span>`;
            } else if (
              matchingField.value &&
              matchingField.value !== null &&
              matchingField.value !== "" &&
              (matchingField.type === "TIME_RANGE" ||
                matchingField.input_type === "TIME_RANGE")
            ) {
              const format = "HH:mm:ss";
              let timeOnlyRegex = /^(?:[01]\d|2[0-3]):[0-5]\d(?::[0-5]\d)?$/;
              let newAr = [];
              for (let i = 0; i < matchingField.value.length; i++) {
                let val = "";
                if (timeOnlyRegex.test(matchingField.value[i])) {
                  val = matchingField.value[i];
                } else {
                  val = moment(matchingField.value[i]).format(format);
                }
                newAr.push(val);
              }
              let contentValue = newAr.join(" To ");
              return `<span style="color: #000000;">${contentValue}</span>`;
            } else if (
              matchingField.value &&
              matchingField.value !== null &&
              matchingField.value !== "" &&
              !matchingField.is_fixed &&
              (matchingField.type === "TIME" ||
                matchingField.input_type === "TIME")
            ) {
              let val = "";
              const format = "HH:mm:ss";
              const timeOnlyRegex = /^(?:[01]\d|2[0-3]):[0-5]\d(?::[0-5]\d)?$/;
              if (timeOnlyRegex.test(matchingField.value)) {
                val = matchingField.value;
              } else {
                val = moment(matchingField.value).format(format);
              }
              return `<span style="color: #000000;">${val}</span>`;
            } else if (
              matchingField.value &&
              matchingField.value !== null &&
              matchingField.value !== "" &&
              matchingField.value !== undefined &&
              matchingField.is_fixed &&
              (matchingField.type === "TIME" ||
                matchingField.input_type === "TIME")
            ) {
              // let format = " HH:mm:ss";
              // let dt = moment(matchingField.value).format(format);
              let val = matchingField.value ? matchingField.value : "";
              return `<span style="color: #000000;">${val}</span>`;
            } else if (
              matchingField.value &&
              matchingField.value !== null &&
              matchingField.value !== "" &&
              matchingField.value !== undefined &&
              (matchingField.type === "WEEKDAYS" ||
                matchingField.input_type === "WEEKDAYS")
            ) {
              if (Array.isArray(matchingField.value)) {
                let newArr = [];
                for (let i = 0; i < matchingField.value.length; i++) {
                  if (
                    this.weekdays_options[i].value === matchingField.value[i]
                  ) {
                    newArr.push(this.weekdays_options[i].name);
                  }
                }
                return `<span style="color: #000000;">${newArr}</span>`;
              } else {
                if (matchingField.value) {
                  let val = this.weekdays_options.find(
                    (e) => e.value === matchingField.value
                  );
                  if (val) {
                    return `<span style="color: #000000;">${val.name}</span>`;
                  } else {
                    return `<span style="color: #000000;"></span>`;
                  }
                }
              }
            } else if (
              matchingField.value &&
              matchingField.value !== null &&
              matchingField.value !== "" &&
              matchingField.value !== undefined &&
              (matchingField.type === "DATE" ||
                matchingField.input_type === "DATE")
            ) {
              const format = "MM/DD/YYYY";
              let dt = moment(matchingField.value).format(format);
              let val = dt ? dt : "";
              return `<span style="color: #000000;">${val}</span>`;
            } else if (
              matchingField.value === "" ||
              matchingField.value === null ||
              matchingField.value === undefined ||
              matchingField.value === "null" ||
              matchingField.value === "undefined"
            ) {
              if (matchingField.input_type.startsWith("PRIMARY_FIELDS")) {
                this.mappingPrimaryFieldsData(matchingField);
              }
            } else if (
              matchingField.value !== null ||
              matchingField.value !== undefined ||
              (matchingField.value !== "" &&
                matchingField.input_type !== "TIME")
            ) {
              return matchingField.value;
            }
          }
          return match;
        }
      );
      modifiedContents.push(modifiedContent);
      this.loading = false;
      return modifiedContents;
    },
    async previewDocumentData() {
      try {
        this.backgroundImages =
          this.getConfigurableDocumentDataById["background_images"];
        this.documentName =
          this.getConfigurableDocumentDataById["name"].includes(".") &&
          this.getConfigurableDocumentDataById["name"].split(".")[0]
            ? this.getConfigurableDocumentDataById["name"]
                .split(".")[0]
                .replaceAll("_", " ")
            : this.getConfigurableDocumentDataById["name"].replaceAll("_", " ");
        this.is_published = this.getConfigurableDocumentDataById["is_published"]
          ? this.getConfigurableDocumentDataById["is_published"]
          : false;
        this.secure_access = this.getConfigurableDocumentDataById[
          "secure_access"
        ]
          ? this.getConfigurableDocumentDataById["secure_access"]
          : false;
        this.access_to_all = this.getConfigurableDocumentDataById[
          "access_to_all"
        ]
          ? this.getConfigurableDocumentDataById["access_to_all"]
          : false;
        this.update_contacts = this.getConfigurableDocumentDataById[
          "update_contacts"
        ]
          ? this.getConfigurableDocumentDataById["update_contacts"]
          : false;
        this.pages = this.getConfigurableDocumentDataById.pages;
        if (this.pages && this.pages.length && this.pages[0].page_base_width) {
          this.storedFormBuilderWidth = this.pages[0].page_base_width;
        }
        this.templateTitle =
          this.getConfigurableDocumentDataById.template_title || "";

        if (
          this.getConfigurableDocumentDataById.is_template &&
          !this.$route.query.is_template
        ) {
          this.isTemplate = this.getConfigurableDocumentDataById.is_template;
        }
        this.documentRecipientsList = this.documentRecipientsList.map(
          (user) => {
            if (!user.default && !user.constant_user) {
              delete user.email;
              delete user.first_name;
              delete user.last_name;
              delete user.contact;
              delete user.phone;
              delete user.address;
            }
            if (user.type == "SENDER" && !this.openCk) {
              delete user.contact_type;
            }
            return user;
          }
        );
        this.loadingElements = true;
        this.enforceSignatureOrder =
          this.getConfigurableDocumentDataById.enforce_signature_order;
        this.isAnyoneCanApprove =
          this.getConfigurableDocumentDataById.is_anyone_can_approve;
        this.documentRecipientsList = this.documentRecipientsList.map(
          (user) => {
            if (user.name == "RECEIVER") {
              user.color = "#f36371";
            }

            if (user.name == "SENDER") {
              user.color = "#F754A2";
            }

            return user;
          }
        );
        await Promise.all([
          await this.companyEntityDetails(),
          this.getEntityFields(),
        ]);
        const constUsers = this.documentRecipientsList
          .filter((e) => e.type == "SENDER" || (e.constant_user && e.value))
          .flatMap((e) => e.value);
        setTimeout(async () => {
          if (this.addFormFields) {
            await this.addFieldsOnDocument();
            await this.saveAddedFields();
          }
          if (this.pages && this.pages.length && this.pages[0].fields) {
            let fields = [];
            let checkbox_group = [];
            this.pages[0].fields.forEach((el) => {
              // if (el.y <= this.pageHeight) {
              fields.push(el);
              // }
            });
            this.elements = [...this.elements, ...fields];
            this.pages[0].checkbox_group.forEach((el) => {
              checkbox_group.push(el);
            });
            this.checkBoxGroupArray = [
              ...this.checkBoxGroupArray,
              ...checkbox_group,
            ];
          }
          let tempKeyValue = {};
          let count = 1;
          this.elements = this.elements.map((field) => {
            let isNonConfigureField =
              field.indexKey && field.indexKey.includes(":") ? false : true;
            if (
              field.value &&
              field.type != "IMAGE" &&
              field.type != "MY_INITIAL" &&
              field.type != "my-signature" &&
              field.field_type != "ENTITY_VARIABLE" &&
              field.type == "CURRENCY"
            ) {
              field.value = field.value ? field.value : 0;
            }
            if (field.type === "DATE_SIGNED" && field.value !== "") {
              field.value = "";
            }
            if (
              field.value &&
              field.type != "IMAGE" &&
              field.type != "MY_INITIAL" &&
              field.type != "my-signature" &&
              field.field_type != "ENTITY_VARIABLE" &&
              field.type !== "CURRENCY"
            ) {
              field.value = field.value ? field.value : "";
            }
            if (
              field &&
              field.template_id &&
              field.indexKey &&
              field.field_type != "ENTITY_VARIABLE" &&
              field.selected_user != "SENDER" &&
              isNonConfigureField
            ) {
              if (
                tempKeyValue[
                  field.template_id +
                    "-" +
                    field.selected_user +
                    "-" +
                    field.indexKey
                ]
              ) {
                let fieldKey = field.key.split("-")[2];
                let count =
                  tempKeyValue[
                    field.template_id +
                      "-" +
                      field.selected_user +
                      "-" +
                      field.indexKey
                  ];
                field.key =
                  field.selected_user + "-newData:" + count + "-" + fieldKey;
                field.indexKey = "newData:" + count;
              } else {
                tempKeyValue[
                  field.template_id +
                    "-" +
                    field.selected_user +
                    "-" +
                    field.indexKey
                ] = count;
                let fieldKey = field.key.split("-")[2];
                field.key =
                  field.selected_user + "-newData:" + count + "-" + fieldKey;
                field.indexKey = "newData:" + count;
                count++;
              }
            }
            if (
              field &&
              field.type == "PRIMARY_FIELDS" &&
              constUsers &&
              constUsers.indexOf(field.selected_user) == -1
            ) {
              field.content = "";
            }
            if (!field.font) {
              field.font = {
                name: "Helvetica",
                size: 16,
                style: 0,
                color: "#000000",
              };
            }
            return field;
          });
          this.setUsersEntityData();
          this.checkDeleteFields();
          // this.elements = this.setSizeAndPositionForElements(this.elements);
          // this.setEmployeDataToForm();
          // this.setDocumentFields();
          this.loading = false;
          this.loadingElements = false;
          if (this.is_published) {
            this.$confirm(
              "The template has been published, so if you try to edit it, it will become unpublished. Are you sure you want to continue editing it?",
              "Warning",
              {
                confirmButtonText: "Yes",
                cancelButtonText: "No",
                type: "warning",
              }
            )
              .then(() => {})
              .catch(() => {
                this.backToDashCompanyDocuments();
              });
          }
        }, 6000);
      } catch (err) {
        this.loading = false;
        this.loadingElements = false;
      }
    },

    addSender() {
      let companyName = "";
      if (
        this.getActiveWorkspace.plan_type != "INDIVIDUAL" &&
        this.getActiveWorkspace.company_id &&
        this.getActiveWorkspace.company_id != "0"
      ) {
        companyName =
          this.getActiveWorkspace.plan_type != "INDIVIDUAL" &&
          this.getCompanyDetails &&
          this.getCompanyDetails.legal_name != ""
            ? this.getCompanyDetails.legal_name
            : this.getCompanyDetails && this.getCompanyDetails.name
            ? this.getCompanyDetails.name
            : "";
      } else {
        companyName = this.getAuthenticatedUser.company_name;
      }
      this.documentRecipientsList.push({
        color: "#F754A2",
        default: true,
        email: this.getAuthenticatedUser.email,
        first_name: this.getAuthenticatedUser.first_name,
        last_name: this.getAuthenticatedUser.last_name,
        title: this.getAuthenticatedUser.title,
        address:
          this.getActiveWorkspace.company_id &&
          this.getActiveWorkspace.plan_type != "INDIVIDUAL" &&
          this.getActiveWorkspace.company_id != "0"
            ? this.getCompanyDetails && this.getCompanyDetails.address
              ? this.getCompanyDetails.address
              : this.getAuthenticatedUser.address
            : this.getAuthenticatedUser.address,
        phone: this.getAuthenticatedUser.phone,
        company_name: companyName,
        e_signature_required: true,
        value: "SENDER",
        name: "SENDER",
        type: "SENDER",
        e_signature_order: 0,
        e_signature_fields: [],
        e_signature_date_fields: [],
        has_approval_access: false,
        is_cc: false,
        user_type: "SIGNER",
        user_id: this.getAuthenticatedUser._id,
      });
    },
    addReceipentUser() {
      this.documentRecipientsList.push({
        email: this.addNewRecipient.email,
        first_name: this.addNewRecipient.first_name,
        last_name: this.addNewRecipient.last_name,
        e_signature_required: this.addNewRecipient.e_signature_required,
        name: "RECEIVER-" + this.documentRecipientsList.length,
        value: "RECEIVER" + this.documentRecipientsList.length,
        e_signature_order: this.documentRecipientsList.length + 1,
        type: "RECEIVER",
        default: false,
      });

      this.addNewRecipient = {
        email: "",
        first_name: "",
        last_name: "",
        e_signature_required: true,
        value: "RECEIVER",
        name: "RECEIVER",
        type: "RECEIVER",
        default: false,
        e_signature_order: 0,
      };
    },
    remoteMethod(query) {
      if (query !== "") {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
          this.options = this.list.filter((item) => {
            return item.label.toLowerCase().indexOf(query.toLowerCase()) > -1;
          });
        }, 200);
      } else {
        this.options = [];
      }
    },
    // addTableHeader(header) {
    //   this.tableLoading = true;
    //   header = header.trim();
    //   this.selectedItem.headers.push(header);
    //   this.selectedItem.latestHeader = "";
    //   // this.selectedItem.width = this.selectedItem.headers.length * 60;
    //   if (this.selectedItem.data && this.selectedItem.data.length) {
    //     let data = [];
    //     this.selectedItem.data.map((item) => {
    //       let rowHeader = {};
    //       this.selectedItem.headers.map((header) => {
    //         rowHeader[header] = item[header] || "";
    //       });
    //       data.push(rowHeader);
    //     });
    //     this.selectedItem.data = data;
    //   }
    //   this.tableLoading = false;
    // },
    async addTableRow(data) {
      this.tableLoading = true;
      let row = {};
      let duplicateKeyCounter = {};
      data.headers.map((header) => {
        let newKey = this.generateKeyForField(header);
        if (duplicateKeyCounter[newKey]) {
          let index = parseInt(duplicateKeyCounter[newKey]);
          row[newKey] = newKey + "_" + index + 1;
          duplicateKeyCounter[newKey]++;
        } else {
          duplicateKeyCounter[newKey] = 1;
          row[newKey] = newKey;
          duplicateKeyCounter[newKey]++;
        }
        row[newKey] = "";
      });
      data.table_data.push(row);
      this.tableLoading = false;
      const elTableColumn = document.querySelector(".el-table__header");
      const tableColumns = elTableColumn.querySelectorAll("th");
      tableColumns.forEach(async (column) => {
        const boundingRect = await column.getBoundingClientRect();
        const x = boundingRect.left - this.deltaX; // x position
        const y = boundingRect.top - this.deltaY; // y position
        const width = await boundingRect.width; // width
        const height = await boundingRect.height; // height
        data.table_columns_data.map((e) => {
          //let newKey =
          if (data.map_keys) {
            (e.x = x), (e.y = y), (e.width = width), (e.height = height);
          }
          if (e && column.innerText.trim() == e.name) {
            (e.x = x), (e.y = y), (e.width = width), (e.height = height - 30);
          }
        });
      });
      return data;
    },
    // editTableColumn(index) {

    //   this.selectedHeader = this.selectedItem.headers[index];
    //   this.selectedHeaderIndex = index;
    // },

    async closeDocumentExpireDateSettings({ expirationSettings }) {
      this.showDocumentExpirationDatesSettings = false;

      if (expirationSettings) {
        this.expirationSettings = expirationSettings;
        await this.saveConfigurableDocument();
      }
    },
    openDocumentExpirationSettings(submit = false) {
      this.showDocumentExpirationDatesSettings = true;
      this.prepareFields();
      this.submitEvent = submit;
    },
    calculateRelativeLeft(x) {
      if (
        this.storedFormBuilderWidth &&
        this.currentFormBuilderWidth &&
        this.currentFormBuilderWidth != this.storedFormBuilderWidth
      ) {
        return Math.round(
          (this.currentFormBuilderWidth / this.storedFormBuilderWidth) * x
        );
      }
      return x;
    },

    calculateRelativeTop(y) {
      if (
        this.storedFormBuilderWidth &&
        this.currentFormBuilderWidth &&
        this.currentFormBuilderWidth != this.storedFormBuilderWidth
      ) {
        return Math.round(
          (this.currentFormBuilderWidth / this.storedFormBuilderWidth) * y
        );
      }
      return y;
    },
    calculateRelativeWidth(width) {
      if (
        this.storedFormBuilderWidth &&
        this.currentFormBuilderWidth &&
        this.currentFormBuilderWidth != this.storedFormBuilderWidth
      ) {
        return Math.round(
          (this.currentFormBuilderWidth / this.storedFormBuilderWidth) * width
        );
      }
      return width;
    },
    getPositionSizeInPercentages({ x, y, height, width }) {
      const formBuilderRef = this.$refs.formBuilder;
      const formBuilderWidth = formBuilderRef.clientWidth;
      const formBuilderHeight = formBuilderRef.clientHeight;
      x = (x / formBuilderWidth) * 100;
      y = (y / formBuilderHeight) * 100;

      height = (height / formBuilderHeight) * 100;
      width = (width / formBuilderWidth) * 100;

      return {
        x,
        y,
        height,
        width,
      };
    },
    setPrevElements() {
      if (
        this.getConfigurableDocumentData &&
        this.getConfigurableDocumentData.length
      ) {
        this.elements = JSON.parse(
          JSON.stringify(this.getConfigurableDocumentData)
        );
      } else {
        this.elements = [];
      }
    },

    setSizeAndPositionForElements(elements) {
      return elements.map((field) => {
        field.x = this.calculateRelativeLeft(field.x);
        field.y = this.calculateRelativeTop(field.y);
        field.width = this.calculateRelativeWidth(field.width);
        field.height = this.calculateRelativeWidth(field.height);
        return field;
      });
    },

    // Drag & drop
    mouseDown() {
      this.isDragging = true;
    },
    mouseUp() {
      this.isDragging = false;
    },
    onDocumentDrag(evt) {
      if (evt.clientX == 0 && evt.clientY == 0) {
        return;
      }
      this.mouseX = evt.clientX;
      this.mouseY = evt.clientY;
    },
    onDocumentDragMobile(evt) {
      let touch =
        (evt.touches && evt.touches[0]) ||
        (evt.pointerType && evt.pointerType === "touch" && evt);
      let clientX = (touch || evt).clientX;
      let clientY = (touch || evt).clientY;
      if (clientX == 0 && clientY == 0) {
        return;
      }
      this.mouseX = clientX;
      this.mouseY = clientY;
    },
    onDraggableElementMouseDown(e) {
      var parent = e.target.parentNode;
      var rect = parent.getBoundingClientRect();
      var x = e.clientX - rect.left; //x position within the element.
      var y = e.clientY - rect.top; //y position within the element.
      this.deltaX = x;
      this.deltaY = y;
    },
    onDraggableElementMouseDownMobile(evt) {
      let touch =
        (evt.touches && evt.touches[0]) ||
        (evt.pointerType && evt.pointerType === "touch" && evt);
      let clientX = (touch || evt).clientX;
      let clientY = (touch || evt).clientY;

      var parent = evt.target.parentNode;
      var rect = parent.getBoundingClientRect();
      var x = clientX - rect.left; //x position within the element.
      var y = clientY - rect.top; //y position within the element.
      this.deltaX = x;
      this.deltaY = y;
    },
    createNewElement() {
      let offset = this.getElementYOffet("logoEle");
      this.elements.push({
        type: "logo",
        section: "display",
        label: "logo",
        height: 100,
        width: 100,
        field_type: "CUSTOM_FIELD",
        url: this.getCompanyProfileData.logo ? this.getLogoUrl : "",
        x: 20,
        y: offset,
      });
    },
    addMyInitial() {
      var x =
        this.mouseX +
        this.$refs.formBuilder.scrollLeft -
        this.$refs.formBuilder.getBoundingClientRect().x -
        this.deltaX;
      const y =
        this.mouseY +
        this.$refs.formBuilder.scrollTop -
        this.$refs.formBuilder.getBoundingClientRect().y -
        this.deltaY +
        this.dragItemHeight -
        50;
      let pageBoundary = this.getPagesBoundaries(y);
      if (
        pageBoundary &&
        // pageBoundary.pageOffset &&
        pageBoundary.pageWidth &&
        (x < pageBoundary.pageOffset ||
          // x + 100 > pageBoundary.pageOffset + pageBoundary.pageWidth)
          x >= pageBoundary.pageOffset + pageBoundary.pageWidth)
      ) {
        return;
      }
      if (
        pageBoundary &&
        pageBoundary.pageWidth &&
        (x < pageBoundary.pageOffset ||
          x + 100 > pageBoundary.pageOffset + pageBoundary.pageWidth)
      ) {
        x = pageBoundary.pageOffset + pageBoundary.pageWidth - 100;
      }
      let indx = this.getLastIndexOfElement("MY_INITIAL");
      indx = indx + 1;
      var fieldHeight = 50;
      var fieldWidth = 100;
      let sizeOfLast = this.getLastElementOfType("MY_INITIAL");
      if (sizeOfLast !== null) {
        fieldWidth = sizeOfLast.width;
        fieldHeight = sizeOfLast.height;
      }
      if (this.getInitialUrl) {
        this.elements.push({
          type: "MY_INITIAL",
          section: "display",
          label: "My Initial",
          height: fieldHeight,
          width: fieldWidth,
          field_type: "CUSTOM_FIELD",
          value: this.getInitialUrl || "",
          x: x,
          y:
            this.mouseY +
            this.$refs.formBuilder.scrollTop -
            this.$refs.formBuilder.getBoundingClientRect().y -
            this.deltaY +
            this.dragItemHeight -
            50,
          text: "",
          color: "#000",
          font: "",
          index: indx,
          filled_by: "SENDER",
        });
      } else {
        let index = this.elements.length;
        this.elements.push({
          type: "MY_INITIAL",
          section: "display",
          label: "My Initial",
          height: fieldHeight,
          width: fieldWidth,
          field_type: "CUSTOM_FIELD",
          value: "",
          x:
            this.mouseX +
            this.$refs.formBuilder.scrollLeft -
            this.$refs.formBuilder.getBoundingClientRect().x -
            this.deltaX,
          y:
            this.mouseY +
            this.$refs.formBuilder.scrollTop -
            this.$refs.formBuilder.getBoundingClientRect().y -
            this.deltaY +
            this.dragItemHeight -
            50,
          text: "",
          color: "#000",
          font: "",
          index: indx,
          filled_by: "SENDER",
        });
        const choosenElement = JSON.parse(JSON.stringify(this.elements[index]));
        this.selectedItem = { ...{}, ...choosenElement };
        this.selectedItemIndex = index;
        this.addItemDataDialogVisible = true;
      }
    },
    getPlaceHolder(type, field) {
      let rtnPlaceHolder = "";
      if (type === "PRIMARY_FIELDS") {
        switch (field) {
          case "first_name":
            rtnPlaceHolder = "Enter First Name";
            break;
          case "last_name":
            rtnPlaceHolder = "Enter Last Name";
            break;
          case "full_name":
            rtnPlaceHolder = "Enter Full Name";
            break;
          case "title":
            rtnPlaceHolder = "Enter Title";
            break;
          case "phone":
            rtnPlaceHolder = "Enter Phone";
            break;
          case "email":
            rtnPlaceHolder = "Enter Email";
            break;
          case "address":
            rtnPlaceHolder = "Enter Address";
            break;
          case "company_name":
            rtnPlaceHolder = "Enter Company";
            break;
        }
      }
      return rtnPlaceHolder;
    },
    getTheLastMySignatureSize() {
      if (this.elements.length) {
        let size = {};
        const citrus = this.elements
          .slice()
          .reverse()
          .findIndex((x) => x["type"] === "my-signature");
        let count = this.elements.length - 1;
        let finalIndex = citrus >= 0 ? count - citrus : citrus;
        if (finalIndex > -1) {
          let lastItem = this.elements[finalIndex];
          size.width = lastItem.width;
          size.height = lastItem.height;
        } else {
          return null;
        }
        return size;
      }
      return null;
    },
    getSumOfPagesHeight(pageNo, docNo) {
      var sumOfHeights = 0;
      sumOfHeights = document.getElementById(`${docNo}_canvas_${pageNo}`);
      if (sumOfHeights) {
        return sumOfHeights.offsetTop;
      }
      return 0;
    },
    getPagesBoundaries(y) {
      let pageBreaks = [];
      let h = 0;
      let maxLength = document.getElementById("wrapper").childNodes.length;
      for (let i = 0; i < maxLength; i++) {
        let innerChildLength =
          document.getElementById("wrapper").childNodes[i].childNodes.length;
        for (let j = 0; j < innerChildLength; j++) {
          h =
            h +
            document.getElementById("wrapper").childNodes[i].childNodes[j]
              .height +
            6;
          pageBreaks.push({
            docId: i,
            pageId: j,
            height: h,
          });
        }
      }
      let pageNumber = pageBreaks.find((page) => {
        if (y <= page.height + page.docId) {
          return page;
        }
      });

      let data = {
        pageOffset: document.getElementById(`wrapper_${pageNumber.docId}`)
          .childNodes[pageNumber.pageId].offsetLeft,
        pageWidth: document.getElementById(`wrapper_${pageNumber.docId}`)
          .childNodes[pageNumber.pageId].width,
        pageHeight: document.getElementById(`wrapper_${pageNumber.docId}`)
          .childNodes[pageNumber.pageId].height,
        data: pageNumber,
        pageNumber: pageNumber.docId,
      };
      return data;
    },
    getFieldVal(field, userType, isCompanyAddress) {
      let fieldVal = "";
      if (field == "full_name") {
        this.documentRecipientsList.map((user) => {
          if (user.value == userType) {
            if (user["first_name"] && user["last_name"]) {
              fieldVal = user["first_name"] + " " + user["last_name"];
            } else {
              fieldVal = "";
            }
          }
        });
      } else if (field === "address") {
        this.documentRecipientsList.map((user) => {
          if (user.value == userType) {
            if (user.type == "SENDER") {
              // if (isCompanyAddress) {
              //   fieldVal = this.getCompanyDetails.address;
              // } else {
              //   fieldVal = this.getAuthenticatedUser.address;
              // }
              if (
                isCompanyAddress &&
                this.getCompanyDetails.address &&
                this.getCompanyDetails.address !== ""
              ) {
                if (
                  this.selectedItem.content &&
                  this.selectedItem.content !== this.getCompanyDetails.address
                ) {
                  fieldVal = this.selectedItem.content;
                } else {
                  fieldVal = this.getCompanyDetails.address;
                }
              } else if (
                !isCompanyAddress &&
                this.getAuthenticatedUser.address &&
                this.getAuthenticatedUser.address !== ""
              ) {
                if (
                  this.selectedItem.content &&
                  this.selectedItem.content !== this.getCompanyDetails.address
                ) {
                  fieldVal = this.selectedItem.content;
                } else {
                  fieldVal = this.getAuthenticatedUser.address;
                }
              }
            } else {
              fieldVal = user.address;
            }
          }
        });
      } else if (field === "company_name") {
        this.documentRecipientsList.map((user) => {
          if (user.value == userType) {
            fieldVal = user[field];
            if (user.type == "SENDER") {
              fieldVal =
                this.getCompanyDetails && this.getCompanyDetails.legal_name
                  ? this.getCompanyDetails.legal_name
                  : this.getCompanyDetails && this.getCompanyDetails.name
                  ? this.getCompanyDetails.name
                  : this.getAuthenticatedUser.company_name;
            } else {
              if (
                user.entity_data_id &&
                user.entity_data_id.entity_prime_data &&
                Object.keys(user.entity_data_id.entity_prime_data)[0]
              ) {
                fieldVal =
                  user.entity_data_id.entity_prime_data[
                    Object.keys(user.entity_data_id.entity_prime_data)[0]
                  ];
              } else {
                fieldVal = "";
              }
            }
          }
        });
      } else {
        this.documentRecipientsList.map((user) => {
          if (user && user.type == "SENDER") {
            if (!user.title) user.title = this.getAuthenticatedUser.title;
            if (!user.address) user.address = this.getAuthenticatedUser.address;
          }
          if (user.value == userType) {
            fieldVal = user[field];
          }
        });
      }
      if (!fieldVal) {
        if (field === "company_name") {
          this.documentRecipientsList.map((user) => {
            if (user.value == userType) {
              if (user.user_id && user.user_id.company_name) {
                fieldVal = user.user_id.company_name;
              }
            }
          });
        }
      }
      return fieldVal;
    },
    getLastIndexOfElement(type, subType = "") {
      if (subType === "") {
        let typeEle = this.elements.filter((element) => element.type == type);
        if (typeEle && typeEle.length) {
          return typeEle[typeEle.length - 1] &&
            typeEle[typeEle.length - 1].index
            ? typeEle[typeEle.length - 1].index
            : 0;
        }
      } else {
        let typeEle = this.elements.filter(
          (element) => element.type == type && element.selected_tag == subType
        );
        if (typeEle && typeEle.length) {
          return typeEle[typeEle.length - 1] &&
            typeEle[typeEle.length - 1].index
            ? typeEle[typeEle.length - 1].index
            : 0;
        }
      }
      return 0;
    },
    checkElementIsBefore(type, user_type) {
      let fields = this.openCk ? this.editorFields : this.elements;
      return fields.filter((element) => {
        if (
          (element.type ? element.type : element.input_type) === type &&
          element.filled_by == user_type
        ) {
          return true;
        }
      }).length
        ? true
        : false;
    },
    async globalVariableValue(globalVariableId) {
      return this.globalVariablesData.find((e) => {
        if (e && e._id && e._id == globalVariableId) {
          return e;
        }
      });
    },
    async addEntityField(item, isRepeatable) {
      let globalVariableVal;
      if (item && item.is_global_variable && item.global_variable_id) {
        globalVariableVal = await this.globalVariableValue(
          item.global_variable_id
        );
      }
      if (item.inputType == "FORMULA") {
        let isCurrency = item.selected_fields.find(
          (e) => e.input_type == "CURRENCY"
        );
        let isCurrencyType =
          item.validations && item.validations.currency ? true : false;
        if (item.result_type) {
          item.inputType = item.result_type;
        } else if (isCurrency || isCurrencyType) {
          item.inputType = "CURRENCY";
        } else if (item.date_result_type) {
          item.inputType = "DATE";
        } else {
          item.inputType = "NUMBER";
        }
      }
      if (item.inputType == "PHONE_COUNTRY_CODE") {
        item.inputType = "SINGLE_LINE_TEXT";
      }
      // if (item.inputType == "FILE") {
      //   item.inputType = "ATTACHMENT";
      // }
      let groupKeyValue = "";
      let exKey = "";
      let fieldsKey = this.recipientSelection;
      if (isRepeatable) {
        exKey = this.selectedRepeatableIndex;
      }
      if (isRepeatable) {
        fieldsKey = fieldsKey + "-" + exKey;
      }
      if (item.type == "CONTENT" && !item.key) {
        let key = this.generateKeyForField(item.label);
        fieldsKey = fieldsKey + "-" + key + "#" + item.template_id;
      }
      let entityId =
        item.inputType == "ENTITY"
          ? item.entity_id
          : this.selectedUserEntity && this.selectedUserEntity.id
          ? this.selectedUserEntity.id
          : "";
      fieldsKey = fieldsKey + "-" + item.key + "#" + item.template_id;
      let indx = this.getLastIndexOfElement(item.inputType);
      let font = {
        name: "Helvetica",
        size: 16,
        style: 0,
        color: "#000000",
      };
      let fontOfLast = this.getTheLastFont();
      if (fontOfLast !== null) {
        font = fontOfLast;
      }
      let fieldWidth = 150;
      let fieldHeight = font.size * 1.5;
      let sizeOfLast = this.getTheLastSize();
      if (sizeOfLast !== null) {
        fieldWidth = sizeOfLast.width;
      }
      if (
        item.inputType == "CHECKBOX" &&
        item.group_key &&
        item.inputType != "CHECKBOX_GROUP"
      ) {
        groupKeyValue = isRepeatable
          ? item.group_key + "/" + exKey
          : item.group_key;
        fieldWidth = 30;
        fieldHeight = 30;
        const citrus = this.elements
          .slice()
          .reverse()
          .findIndex((x) => x["type"] === "CHECKBOX");
        let count = this.elements.length - 1;
        let finalIndex = citrus >= 0 ? count - citrus : citrus;
        if (finalIndex > -1) {
          fieldWidth = this.elements[finalIndex].width;
          fieldHeight = this.elements[finalIndex].height;
        }
        this.selectedUserEntity.templates.forEach((group) => {
          group.checkBoxGroup.find((el) => {
            if (el.key == item.group_key) {
              let exists =
                this.checkBoxGroupArray.findIndex(
                  (element) => element.key === el.key
                ) > -1;
              if (!exists) {
                //   el.filled_by = this.recipientSelection;
                //   item.is_show_label = el.show_checkbox_label;
                //    if(!el.isFromFormTemplate){
                //   el.isFromFormTemplate = true
                //  }
                let obj = {
                  key: isRepeatable ? el.key + "/" + exKey : el.key,
                  name: el.label,
                  filled_by: this.recipientSelection,
                  min_value: el.min_value,
                  max_value: el.max_value,
                  show_checkbox_label: el.show_checkbox_label,
                  isFromFormTemplate: true,
                };
                this.checkBoxGroupArray.push(obj);
              }
            }
          });
        });
      }

      if (
        item.inputType == "CHECKBOX" &&
        !item.group_key &&
        item.inputType != "CHECKBOX_GROUP"
      ) {
        fieldWidth = 30;
        fieldHeight = 30;
        const citrus = this.elements
          .slice()
          .reverse()
          .findIndex((x) => x["type"] === "CHECKBOX");
        let count = this.elements.length - 1;
        let finalIndex = citrus >= 0 ? count - citrus : citrus;
        if (finalIndex > -1) {
          fieldWidth = this.elements[finalIndex].width;
          fieldHeight = this.elements[finalIndex].height;
        }
      }
      if (item.inputType == "HORIZONTAL_LINE") {
        this.isHorizontalLineFromFormTemplate = true;
      }
      if (item.inputType == "MULTI_LINE_TEXT") {
        fieldWidth = (300 * font.size) / 16;
        fieldHeight = 3 * font.size;
      }
      var x = this.openCk
        ? 100
        : this.mouseX +
          this.$refs.formBuilder.scrollLeft -
          this.$refs.formBuilder.getBoundingClientRect().x -
          this.deltaX;
      const y = this.openCk
        ? 100
        : this.mouseY +
          this.$refs.formBuilder.scrollTop -
          this.$refs.formBuilder.getBoundingClientRect().y -
          this.deltaY +
          this.dragItemHeight -
          fieldHeight;
      let pageBoundary = this.openCk ? 1350 : this.getPagesBoundaries(y);
      if (
        pageBoundary &&
        pageBoundary.pageWidth &&
        (x < pageBoundary.pageOffset ||
          x >= pageBoundary.pageOffset + pageBoundary.pageWidth)
      ) {
        return;
      }
      if (
        pageBoundary &&
        pageBoundary.pageWidth &&
        (x < pageBoundary.pageOffset ||
          x + fieldWidth > pageBoundary.pageOffset + pageBoundary.pageWidth)
      ) {
        x = pageBoundary.pageOffset + pageBoundary.pageWidth - fieldWidth;
      }
      let templateValue = this.setEntityDataToField(
        fieldsKey,
        item.template_id,
        isRepeatable ? this.selectedRepeatableIndex : null,
        item.input_type,
        item.allow_multiple
      );
      if (item.inputType == "CHECKBOX_GROUP") {
        this.checkBoxGroupArray.push({
          key: isRepeatable ? item.key + "/" + exKey : item.key,
          name: item.label,
          filled_by: this.recipientSelection,
          min_value: item.min_selection,
          max_value: item.max_selection,
          // required: item.validations.required,
          isFromFormTemplate: true,
          show_checkbox_label: item.show_checkbox_label,
        });
        item.width = 30;
        item.height = 30;

        for (let i = 0; i < item?.options?.length; i++) {
          let checkOption;
          if (item?.options[i].includes(".")) {
            checkOption = item?.options[i].replace(/\./g, " ");
          } else {
            checkOption = item?.options[i];
          }

          let data = {
            type: "CHECKBOX",
            is_checkbox_group: true,
            section: "input",
            style: "background:#cbe2d8;",
            addNewField:
              this.selectedRepeatableIndex == "newData" ? true : false,
            height: item.height,
            width: item.width,
            value:
              templateValue && templateValue.indexOf(item?.options[i]) !== -1
                ? true
                : false,
            template_field_id: item._id,
            template_id: item.template_id,
            indexKey: isRepeatable ? exKey : null,
            entity_id: entityId,
            options: item?.options || [],
            list_data: item.list_data || [],
            x: x + (i * item.width + 3),
            y: y,
            validations: item.validations,
            font: item.styles && item.styles.font ? item.styles.font : font,
            index: indx,
            key: fieldsKey + "/" + checkOption,
            label: item?.options[i],
            placeholder: item.placeholder || item.label,
            required:
              item.validations && item.validations.required
                ? item.validations.required
                : false,
            allow_to_add_new_entity_data: item.allow_quick_add,
            group_key: isRepeatable ? item.key + "/" + exKey : item.key,
            field_type: "ENTITY_FIELD",
            filled_by:
              ccUsers && checkUsersFromDraft && checkUsersFromDraft.length <= 2
                ? "SENDER"
                : this.recipientSelection || "SENDER_OR_RECEIVER",
            selected_user: this.recipientSelection || "SENDER_OR_RECEIVER",
          };
          let addFieldToHistory = {
            data: data,
            action: "add",
            index: this.elements.length,
          };
          this.history.push(addFieldToHistory);
          this.elements.push(data);
        }
        return;
      }
      let checkUsersFromDraft = this.documentRecipientsList
        ? this.documentRecipientsList
        : this.documentUsers;
      let ccUsers = checkUsersFromDraft.find(
        (user) => user.type != "SENDER" && user.user_type === "CC"
      );
      console.log("ffffffffielsonj", item);
      let fieldObj = {
        type: item.inputType ? item.inputType : item.input_type,
        section: "input",
        style: "background:#cbe2d8;",
        height: this.openCk
          ? 24
          : item.inputType == "IMAGE" || item.inputType == "SIGNATURE"
          ? item.height
          : fieldHeight,
        width: this.openCk
          ? 100
          : item.inputType == "IMAGE" ||
            item.inputType == "SIGNATURE" ||
            this.openCk
          ? item.width
          : fieldWidth,
        value: item.default_value
          ? item.default_value
          : templateValue
          ? item.input_type == "ENTITY" &&
            templateValue.includes("-") &&
            templateValue.split("-")[1]
            ? templateValue.split("-")[1]
              ? templateValue.split("-")[1]
              : ""
            : templateValue
            ? templateValue
            : " "
          : item.is_global_variable && item.global_variable_id
          ? item.default_value
            ? item.default_value
            : globalVariableVal.value
            ? globalVariableVal.value
            : item.value
            ? item.value
            : ""
          : item.image_url
          ? item.image_url
          : item.content
          ? item.content
          : "",
        image_url: item.image_url,
        template_field_id: item._id,
        template_id: item.template_id,
        indexKey: isRepeatable ? exKey : null,
        entity_id: entityId,
        group_key: groupKeyValue,
        options: item.options || [],
        list_data: item.list_data || [],
        boxes: [],
        boxNumber: 0,
        boxWidth: 20,
        time_range: item.properties?.time_range,
        step_time: item.properties?.step_time,
        is_fixed: item.is_fixed,
        is_use_boxes: false,
        auto_increment_prefix: item.auto_increment_prefix,
        auto_increment_starting_number: item.auto_increment_starting_number,
        auto_increment_mapped_field: item.auto_increment_mapped_field,
        is_fixed: item.is_fixed,
        x: x,
        y: this.openCk
          ? 100
          : this.mouseY +
            this.$refs.formBuilder.scrollTop -
            this.$refs.formBuilder.getBoundingClientRect().y -
            this.deltaY +
            this.dragItemHeight -
            fieldHeight,
        font: item.styles && item.styles.font ? item.styles.font : font,
        index: indx,
        key: fieldsKey,
        validations: item.validations,
        label: item.label ? item.label : item.inputType.toLowerCase() + indx,
        placeholder: item.placeholder || item.label,
        required:
          item.validations && item.validations.required
            ? item.validations.required
            : false,
        allow_to_add_new_entity_data: item.allow_quick_add,
        field_type: "ENTITY_FIELD",
        filled_by:
          ccUsers && checkUsersFromDraft && checkUsersFromDraft.length <= 2
            ? "SENDER"
            : this.recipientSelection || "SENDER_OR_RECEIVER",
        selected_user: this.recipientSelection || "SENDER_OR_RECEIVER",
      };

      if (item.input_type == "ENTITY_VARIABLE") {
        fieldObj.parent_entity_field_key =
          this.recipientSelection +
          "-" +
          item.relationship_key +
          "#" +
          item.template_id;
        fieldObj.parent_template_field = item.global_variable_entity_field;
      }
      if (item.input_type == "ENTITY" && templateValue) {
        fieldObj.entity_data_id = templateValue
          ? typeof templateValue == "string" &&
            templateValue.includes("-") &&
            templateValue.split("-")[0]
            ? templateValue.split("-")[0]
            : templateValue
          : "";
      }
      if (
        item.input_type === "MULTI_SELECT" ||
        item.inputType === "MULTI_SELECT"
      ) {
        fieldObj.min_selection =
          item.min_selection === undefined || item.min_selection === "undefined"
            ? 0
            : item.min_selection;
        fieldObj.max_selection =
          item.max_selection === undefined || item.max_selection === "undefined"
            ? 0
            : item.max_selection;
      }
      if (item.inputType == "ATTACHMENT") {
        fieldObj = {
          ...fieldObj,
          ...{
            files: [],
            sample_files: [],
            requiredFiles: 1,
            file_names: [],
          },
        };
      }
      if (item.inputType === "CURRENCY" && templateValue === "") {
        fieldObj.value = 0;
      }
      if (item.inputType == "RADIO_BUTTON_GROUP") {
        this.documentData[fieldObj.key] = templateValue;
      }
      if (item.inputType == "HEADING") {
        fieldObj.value = item.label;
        fieldObj.content = item.label;
        fieldObj.selected_tag = item.heading_type.toLocaleLowerCase();
      }
      if (
        item.inputType == "FILE" ||
        (item.type == "ATTACHMENT" && item.field_type == "ENTITY_FIELD") ||
        (item.type == "ATTACHMENT" && item.parent_entity_field_key)
      ) {
        // if(templateValue&&templateValue.path)
        if (templateValue && templateValue.name) {
          this.filesData[fieldObj.key] = templateValue;
        }
        fieldObj = {
          ...fieldObj,
          ...{
            files: [],
            sample_files: [],
            requiredFiles: 1,
            file_names: [],
            height: 50,
          },
        };
      }
      let addFieldToHistory = {
        data: fieldObj,
        action: "add",
        index: this.elements.length,
      };
      this.history.push(addFieldToHistory);
      if (
        fieldObj &&
        (fieldObj.type == "DATE_TIME" ||
          fieldObj.type == "LIST" ||
          fieldObj.type == "SELECT" ||
          fieldObj.type == "MULTI_SELECT") &&
        (fieldObj.value == "null" || fieldObj.value == undefined)
      ) {
        fieldObj.value = "";
      }
      if (
        fieldObj.type == "DATE" &&
        typeof fieldObj.value == "object" &&
        fieldObj.value &&
        fieldObj.value.length == 0
      ) {
        fieldObj.value = "";
      }
      if (fieldObj.type === "TIME_RANGE" && !fieldObj.value.length) {
        fieldObj.value = null;
      }
      if (fieldObj.type === "CURRENCY" && fieldObj.value === "") {
        fieldObj.value = 0;
      }
      if (item.inputType == "WEEKDAYS") {
        fieldObj.allow_multiple = item.allow_multiple;
      }
      if (
        fieldObj &&
        fieldObj.type === "TIME" &&
        !item.is_fixed &&
        fieldObj.value !== ""
      ) {
        let formattedTime = fieldObj.value;

        // Check if the time is not in ISO format
        if (!formattedTime.includes("T")) {
          const [hours, minutes, seconds] = formattedTime.split(":");
          const time = new Date();
          time.setHours(hours);
          time.setMinutes(minutes);
          time.setSeconds(seconds);
          formattedTime = time.toISOString();
        }
        fieldObj.value = formattedTime;
      }
      this.elements.push(fieldObj);
    },
    getTemplateCheckBoxGroups(data) {
      let checkBoxGroup = [];
      data.sections.forEach((e) => {
        checkBoxGroup = [...checkBoxGroup, ...e.checkBoxGroup];
      });
      // checkBoxGroup = checkBoxGroup.map((e) => {
      //   e.group_key = data.group_key;
      //   return e;
      // });
      return checkBoxGroup;
    },
    async checkElementsHaveFiles() {
      let fileFields = this.elements.filter(
        (e) =>
          e.type == "FILE" ||
          (e.type == "ATTACHMENT" && e.field_type == "ENTITY_FIELD") ||
          (e.type == "ATTACHMENT" && e.parent_entity_field_key)
      );
      let filePaths = [];
      await Promise.all(
        this.elements.map(async (e) => {
          if (
            (e.type == "FILE" ||
              (e.type == "ATTACHMENT" && e.field_type == "ENTITY_FIELD") ||
              (e.type == "ATTACHMENT" && e.parent_entity_field_key)) &&
            this.filesData &&
            this.filesData[e.key] &&
            this.filesData[e.key].files
          ) {
            let path = await this.EntityUploadFile(
              this.filesData[e.key].files,
              e
            );
            filePaths.push(path);
            this.filesData[e.key].path = path;

            this.filesData[e.key].new = false;
            e.value = this.filesData[e.key];
            e.files = this.filesData[e.key];
          }
        })
      );
      return fileFields ? fileFields.length : 0;
    },

    async EntityUploadFile(file, e) {
      var formData = new FormData();
      let uploadFileToApi = false;
      try {
        await Promise.all(
          file.map(async (el) => {
            let fileName = el.name;
            fileName = fileName.replace(/\//g, "-");
            fileName = fileName.replace(/ /g, "_");
            fileName = fileName.replace(/[()]/g, "");
            let name = `template-data-documents/${fileName}`;

            if (e && e.value && e.value.path && e.value.path.length) {
              if (e.value.path.includes(name)) {
                console.log("same file", e.value.path.includes(name), name);
                return;
              } else {
                uploadFileToApi = true;
              }
            } else if (
              e &&
              e.value &&
              (!e.value?.files?.length || !e.value.path)
            ) {
              uploadFileToApi = true;
            } else {
              uploadFileToApi = false;
            }

            if (uploadFileToApi) {
              formData.append("files", el.raw !== undefined ? el.raw : el);
            }
          })
        );

        if (uploadFileToApi && formData.has("files")) {
          let filesData = await axios.post(`/presignMultiUpload`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
          return filesData.data.data;
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getFileUploadUrl(file) {
      let data = {
        file_name: file.raw.name,
        file_type: file.raw.type,
        file_size_in_kb: file.raw.size / 1000,
      };
      await this.$store.dispatch("templatesData/fetchDocumentUploadUrl", data);
    },
    async uploadSelectedDocument(file) {
      this.loadingText = "Uploading Document...";
      // uploding to S3
      let params = {
        file: file,
        upload_url: this.getFileUploadURL,
      };
      await this.$store.dispatch(
        "templatesData/uploadDocumentToGeneratedUrl",
        params
      );

      if (this.getDocumentUploadStatus) {
        // UPDATEING STATUS
        let params = {
          file_ref_id: this.getFileUploadRefId,
        };
        await this.$store.dispatch(
          "templatesData/updateDocumentUploadStatus",
          params
        );
        if (this.getDocumentUploadStatusUpdated) {
        } else {
          this.$message("Sorry ! Error while file uploading");
        }
      } else {
        this.$message("Sorry ! Error while file uploading");
      }
    },

    async addMySignature() {
      var fieldHeight = 36;
      var fieldWidth = 225;
      var lastElSize = this.getTheLastMySignatureSize();
      if (lastElSize) {
        // fieldWidth = lastElSize.width;
        // fieldHeight = (fieldWidth * lastElSize.height) / lastElSize.width;
        fieldHeight = lastElSize.height;
        fieldWidth = lastElSize.width;
      } else if (this.getAuthenticatedUser.signature) {
        var dimensions = await this.getImageDimensions(
          this.getAuthenticatedUser.signature
        );
        fieldHeight = (fieldWidth * dimensions.h) / dimensions.w;
      }

      var x =
        this.mouseX +
        this.$refs.formBuilder.scrollLeft -
        this.$refs.formBuilder.getBoundingClientRect().x -
        this.deltaX;
      const y =
        this.mouseY +
        this.$refs.formBuilder.scrollTop -
        this.$refs.formBuilder.getBoundingClientRect().y -
        this.deltaY +
        this.dragItemHeight -
        fieldHeight;
      let pageBoundary = this.getPagesBoundaries(y);
      if (
        pageBoundary &&
        // pageBoundary.pageOffset &&
        pageBoundary.pageWidth &&
        (x < pageBoundary.pageOffset ||
          // x + 200 > pageBoundary.pageOffset + pageBoundary.pageWidth)
          x >= pageBoundary.pageOffset + pageBoundary.pageWidth)
      ) {
        return;
      }
      if (
        pageBoundary &&
        pageBoundary.pageWidth &&
        (x < pageBoundary.pageOffset ||
          x + fieldWidth > pageBoundary.pageOffset + pageBoundary.pageWidth)
      ) {
        x = pageBoundary.pageOffset + pageBoundary.pageWidth - fieldWidth;
      }
      let indx = this.getLastIndexOfElement("my-signature");
      indx = indx + 1;

      // if (x > this.pageWidth - this.deltaXOfPageWidth) {
      //   return;
      // }
      // let offset = this.getElementYOffet("mySignatureEle");
      if (this.getUserSignature && this.getUserSignature.signature) {
        this.elements.push({
          type: "my-signature",
          section: "display",
          label: "My signature",
          style: "background:#b78686;",
          height: fieldHeight,
          width: fieldWidth,
          color: "red",
          source: this.getUserSignature.signature,
          x: x,
          y:
            this.mouseY +
            this.$refs.formBuilder.scrollTop -
            this.$refs.formBuilder.getBoundingClientRect().y -
            this.deltaY +
            this.dragItemHeight -
            fieldHeight,
          field_type: "CUSTOM_FIELD",
          index: indx,
          filled_by: "SENDER",
        });
      } else if (
        this.getAuthenticatedUser &&
        this.getAuthenticatedUser.signature
      ) {
        this.elements.push({
          type: "my-signature",
          section: "display",
          label: "My signature",
          style: "background:#b78686;",
          height: fieldHeight,
          width: fieldWidth,
          color: "red",
          source: this.getAuthenticatedUser.signature,
          x:
            this.mouseX +
            this.$refs.formBuilder.scrollLeft -
            this.$refs.formBuilder.getBoundingClientRect().x -
            this.deltaX,
          y:
            this.mouseY +
            this.$refs.formBuilder.scrollTop -
            this.$refs.formBuilder.getBoundingClientRect().y -
            this.deltaY +
            this.dragItemHeight -
            fieldHeight,
          field_type: "CUSTOM_FIELD",
          index: indx,
          filled_by: "SENDER",
        });
      } else {
        let index = this.elements.length;
        this.elements.push({
          type: "my-signature",
          section: "display",
          label: "My signature",
          style: "background:#b78686;",
          height: fieldHeight,
          width: fieldWidth,
          color: "red",
          source: "",
          x:
            this.mouseX +
            this.$refs.formBuilder.scrollLeft -
            this.$refs.formBuilder.getBoundingClientRect().x -
            this.deltaX,
          y:
            this.mouseY +
            this.$refs.formBuilder.scrollTop -
            this.$refs.formBuilder.getBoundingClientRect().y -
            this.deltaY +
            this.dragItemHeight -
            fieldHeight,
          field_type: "CUSTOM_FIELD",
          index: indx,
          filled_by: "SENDER",
        });
        const choosenElement = JSON.parse(JSON.stringify(this.elements[index]));
        this.selectedItem = { ...{}, ...choosenElement };
        this.selectedItemIndex = index;
        this.addItemDataDialogVisible = true;
      }
    },
    addHeading() {
      let indx = this.getLastIndexOfElement("HEADING");
      indx = indx + 1;
      // let offset = this.getElementYOffet("headingEle");
      let font = {
        name: "Helvetica",
        size: 32,
        style: 1,
        color: "#000000",
      };
      //calculating previous heading field
      if (this.elements.length) {
        const citrus = this.elements
          .slice()
          .reverse()
          .findIndex((x) => x["type"] === "HEADING");
        let count = this.elements.length - 1;
        let finalIndex = citrus >= 0 ? count - citrus : citrus;
        if (finalIndex > -1) {
          let lastItem = this.elements[finalIndex];
          font.name = lastItem.font.name;
          font.color = lastItem.font.color;
        }
      }
      let fieldWidth = 175;
      let fieldHeight = 50;

      var x =
        this.mouseX +
        this.$refs.formBuilder.scrollLeft -
        this.$refs.formBuilder.getBoundingClientRect().x -
        this.deltaX;
      const y =
        this.mouseY +
        this.$refs.formBuilder.scrollTop -
        this.$refs.formBuilder.getBoundingClientRect().y -
        this.deltaY +
        this.dragItemHeight -
        fieldHeight;
      let pageBoundary = this.getPagesBoundaries(y);
      if (
        pageBoundary &&
        // pageBoundary.pageOffset &&
        pageBoundary.pageWidth &&
        (x < pageBoundary.pageOffset ||
          // x + fieldWidth > pageBoundary.pageOffset + pageBoundary.pageWidth)
          x >= pageBoundary.pageOffset + pageBoundary.pageWidth)
      ) {
        return;
      }
      if (
        pageBoundary &&
        pageBoundary.pageWidth &&
        (x < pageBoundary.pageOffset ||
          x + fieldWidth > pageBoundary.pageOffset + pageBoundary.pageWidth)
      ) {
        x = pageBoundary.pageOffset + pageBoundary.pageWidth - fieldWidth;
      }
      // if (x > this.pageWidth - this.deltaXOfPageWidth) {
      //   return;
      // }
      this.elements.push({
        type: "HEADING",
        section: "text",
        style: "background:#cbe2d8;",
        label: "Heading " + indx,
        placeholder: "Heading " + indx,
        height: fieldHeight,
        field_type: "CUSTOM_FIELD",
        selected_tag: "h1",
        content: "",
        font: font,
        width: fieldWidth,
        x: x,
        y:
          this.mouseY +
          this.$refs.formBuilder.scrollTop -
          this.$refs.formBuilder.getBoundingClientRect().y -
          this.deltaY +
          this.dragItemHeight -
          fieldHeight,
        index: indx,
      });
      this.getFieldsFromElements();
    },
    addImage() {
      let indx = this.getLastIndexOfElement("IMAGE");
      indx = indx + 1;
      // let offset = this.getElementYOffet("mySignatureEle");
      var x =
        this.mouseX +
        this.$refs.formBuilder.scrollLeft -
        this.$refs.formBuilder.getBoundingClientRect().x -
        this.deltaX;
      const y =
        this.mouseY +
        this.$refs.formBuilder.scrollTop -
        this.$refs.formBuilder.getBoundingClientRect().y -
        this.deltaY +
        this.dragItemHeight -
        30;
      let pageBoundary = this.getPagesBoundaries(y);
      if (
        pageBoundary &&
        // pageBoundary.pageOffset &&
        pageBoundary.pageWidth &&
        (x < pageBoundary.pageOffset ||
          // x + 100 > pageBoundary.pageOffset + pageBoundary.pageWidth)
          x >= pageBoundary.pageOffset + pageBoundary.pageWidth)
      ) {
        return;
      }
      if (
        pageBoundary &&
        pageBoundary.pageWidth &&
        (x < pageBoundary.pageOffset ||
          x + 100 > pageBoundary.pageOffset + pageBoundary.pageWidth)
      ) {
        x = pageBoundary.pageOffset + pageBoundary.pageWidth - 100;
      }
      // if (x > this.pageWidth - this.deltaXOfPageWidth) {
      //   return;
      // }

      this.elements.push({
        type: "IMAGE",
        section: "text",
        style: "background:#cbe2d8;",
        label: "Image " + indx,
        height: 30,
        field_type: "CUSTOM_FIELD",
        width: 100,
        required: true,
        value: "",
        x: x,
        y:
          this.mouseY +
          this.$refs.formBuilder.scrollTop -
          this.$refs.formBuilder.getBoundingClientRect().y -
          this.deltaY +
          this.dragItemHeight -
          30,
        index: indx,
      });
    },
    addParagraph() {
      let indx = this.getLastIndexOfElement("PARAGRAPH");
      indx = indx + 1;
      // let offset = this.getElementYOffet("paragraphEle");
      let font = {
        name: "Helvetica",
        size: 16,
        style: 0,
        color: "#000000",
      };

      let fontOfLast = this.getTheLastFont();
      if (fontOfLast !== null) {
        font = fontOfLast;
      }

      let fieldWidth = (120 * font.size) / 16;
      let fieldHeight = 1.5 * font.size;
      // if (this.elements.length) {
      //   const citrus = this.elements
      //     .slice()
      //     .reverse()
      //     .findIndex((x) => x["type"] === "PARAGRAPH");
      //   let count = this.elements.length - 1;
      //   let finalIndex = citrus >= 0 ? count - citrus : citrus;
      //   if (finalIndex > -1) {
      //     let lastItem = this.elements[finalIndex];
      //     font.name = lastItem.font.name;
      //     font.size = lastItem.font.size;
      //     font.style = lastItem.font.style;
      //     font.color = lastItem.font.color;
      //   }
      // }
      var x =
        this.mouseX +
        this.$refs.formBuilder.scrollLeft -
        this.$refs.formBuilder.getBoundingClientRect().x -
        this.deltaX;
      const y =
        this.mouseY +
        this.$refs.formBuilder.scrollTop -
        this.$refs.formBuilder.getBoundingClientRect().y -
        this.deltaY +
        this.dragItemHeight -
        fieldHeight;
      let pageBoundary = this.getPagesBoundaries(y);
      if (
        pageBoundary &&
        // pageBoundary.pageOffset &&
        pageBoundary.pageWidth &&
        (x < pageBoundary.pageOffset ||
          // x + fieldWidth > pageBoundary.pageOffset + pageBoundary.pageWidth)
          x >= pageBoundary.pageOffset + pageBoundary.pageWidth)
      ) {
        return;
      }
      if (
        pageBoundary &&
        pageBoundary.pageWidth &&
        (x < pageBoundary.pageOffset ||
          x + fieldWidth > pageBoundary.pageOffset + pageBoundary.pageWidth)
      ) {
        x = pageBoundary.pageOffset + pageBoundary.pageWidth - fieldWidth;
      }
      // if (x > this.pageWidth - this.deltaXOfPageWidth) {
      //   return;
      // }

      this.elements.push({
        type: "PARAGRAPH",
        section: "text",
        style: "background:#cbe2d8;",
        label: "Paragraph " + indx,
        placeholder: "Paragraph " + indx,
        height: fieldHeight,
        font: font,
        field_type: "CUSTOM_FIELD",
        selected_tag: "p",
        content: "",
        width: fieldWidth,
        x: x,
        y:
          this.mouseY +
          this.$refs.formBuilder.scrollTop -
          this.$refs.formBuilder.getBoundingClientRect().y -
          this.deltaY +
          this.dragItemHeight -
          fieldHeight,
        index: indx,
      });
    },

    // addTable() {
    //   let indx = this.getLastIndexOfElement("TABLE");
    //   indx = indx + 1;
    //   let offset = this.getElementYOffet("tableEle");
    //   this.elements.push({
    //     type: "TABLE",
    //     section: "table",
    //     style: "background:#cbe2d8;",
    //     label: "Table " + indx,
    //     placeholder: "Table " + indx,
    //     height: 30,
    //     field_type: "CUSTOM_FIELD",
    //     width: 100,
    //     x: 20,
    //     y: offset,
    //     headers: [],
    //     data: [],
    //     index: indx,
    //   });
    // },

    setTemplateFields(templateId) {
      let selectedTemplate = this.getAllCompanyTemplatesData.data.find(
        (template) => template._id == templateId
      );

      this.selectedTemplateFields = selectedTemplate.sections[0].fields.filter(
        (field) => field.input_type == this.selectedItem.type
      );
    },

    setEntityFields(entityId) {
      let selectedEntity = this.getAllCompanyEntitiesData.data.find(
        (entity) => entity._id == entityId
      );
      this.selectedEntityFields = [];
      selectedEntity.sections.map((section) => {
        if (section.fields && section.fields.length) {
          this.selectedEntityFields = [
            ...this.selectedEntityFields,
            ...section.fields,
          ];
        }
        return;
      });
      this.selectedEntityFieldId = "";
    },

    setSeletedTemplateField(fieldId) {
      let seletedField = this.selectedTemplateFields.find(
        (field) => field._id == fieldId
      );

      this.$set(this.selectedItem, "template_field_label", seletedField.label);
      this.$set(this.selectedItem, "template_field_id", fieldId);
      this.$set(
        this.selectedItem,
        "template_field_filled_by",
        seletedField.filled_by
      );

      this.$set(this.selectedItem, "entity_id", "");
      this.$set(this.selectedItem, "entity_field_label", "");
      this.$set(this.selectedItem, "entity_field_id", "");
      this.$set(this.selectedItem, "entity_field_filled_by", "");
    },

    setSeletedEntityField(fieldId) {
      let seletedField = this.selectedEntityFields.find(
        (field) => field._id == fieldId
      );

      this.$set(this.selectedItem, "entity_field_label", seletedField.label);
      this.$set(this.selectedItem, "entity_field_id", fieldId);
      this.$set(
        this.selectedItem,
        "entity_field_filled_by",
        seletedField.filled_by
      );
      this.$set(this.selectedItem, "template_id", "");
      this.$set(this.selectedItem, "template_field_label", "");
      this.$set(this.selectedItem, "template_field_id", "");
      this.$set(this.selectedItem, "template_field_filled_by", "");
    },

    async fetchAllCompanyTemplates() {
      let params = {
        get_all: true,
      };
      await this.$store.dispatch(
        "companyTemplates/getAllCompanyTemplates",
        params
      );
    },

    async fetchAllCompanyEntities() {
      let params = {
        get_all: true,
      };
      await this.$store.dispatch(
        "companyEntities/getAllCompanyEntities",
        params
      );
    },

    onResize(x, y, width, height, item) {
      item.width = width;
      item.height = height;
      item.x = x;
      item.y = y;
      item.changed = true;
      if (
        this.activatedItem &&
        this.activatedItem.type === "HORIZONTAL_LINE" &&
        this.activatedItem.vertical_line &&
        item.key === this.activatedItem.key
      ) {
        bus.$emit("updatingFieldHeight", {
          fieldHeight: this.activatedItem.height,
        });
      }
    },
    onDrag(x, y, item) {
      item.x = x;
      item.y = y;
      item.changed = true;
    },
    checkFieldsPosdown(item, index) {
      let obj1 = { ...item };
      let addFieldToHistory = {
        data: obj1,
        action: "positionChange",
        index: index,
      };
      this.history.push(addFieldToHistory);
    },
    onDelete(event) {},

    getElementYOffet(elementRef) {
      let elementOffsetY = 0;
      let pageOffsetPageY = 0;
      if (
        this.$refs[elementRef] &&
        this.$refs[elementRef].getBoundingClientRect()
      ) {
        elementOffsetY = this.$refs[elementRef].getBoundingClientRect().y;
        pageOffsetPageY = this.$refs.formBuilder.getBoundingClientRect().y;
      }
      let offset = elementOffsetY - pageOffsetPageY;

      return offset + this.$refs.formBuilder.scrollTop;
    },

    onActivated(item, index) {
      this.activatedItem = item;
      this.activatedItemIndex = index;
      this.showAlignLines(this.activatedItem.x, this.activatedItem.y, false);
      this.isFromFieldActivated = true;
      this.hideAllAlignLinesWithDelay();
    },
    onDeactivated() {
      this.activatedItem = {};
      this.activatedItemIndex = -1;
    },
    changePositionOfElementToDown() {
      if (this.activatedItem && this.activatedItemIndex > -1) {
        if (this.elements[this.activatedItemIndex].y < 1935) {
          this.elements[this.activatedItemIndex].y =
            this.elements[this.activatedItemIndex].y + 10;
        }
      }
    },
    changePositionOfElementToUpward() {
      if (this.activatedItem && this.activatedItemIndex > -1) {
        if (this.elements[this.activatedItemIndex].y > 10) {
          this.elements[this.activatedItemIndex].y =
            this.elements[this.activatedItemIndex].y - 10;
        }
      }
    },
    changePositionOfElementToRight() {
      if (this.activatedItem && this.activatedItemIndex > -1) {
        if (this.elements[this.activatedItemIndex].x < 695) {
          this.elements[this.activatedItemIndex].x =
            this.elements[this.activatedItemIndex].x + 10;
        }
      }
    },
    changePositionOfElementToLeft() {
      if (this.activatedItem && this.activatedItemIndex > -1) {
        if (this.elements[this.activatedItemIndex].x > 5) {
          this.elements[this.activatedItemIndex].x =
            this.elements[this.activatedItemIndex].x - 10;
        }
      }
    },
    deleteActiveElement() {
      // if (this.activatedItem && this.activatedItemIndex > -1) {
      //   this.elements.splice(this.activatedItemIndex, 1);
      // }

      if (
        this.activatedItem &&
        this.activatedItemIndex > -1 &&
        this.elements[this.activatedItemIndex]
      ) {
        if (
          (this.elements[this.activatedItemIndex]["type"] == "SIGNATURE" ||
            this.elements[this.activatedItemIndex]["type"] == "my-signature") &&
          this.elements[this.activatedItemIndex]["filled_by"] == "SENDER"
        ) {
          let selectedUserSignatures = this.elements.filter(
            (el) =>
              (el.type == "SIGNATURE" || el.type == "my-signature") &&
              el.filled_by == "SENDER"
          );
          if (selectedUserSignatures && selectedUserSignatures.length == 1) {
            // let dateSignedIndex = this.elements.findIndex(el => el.type == 'DATE_SIGNED' && el.filled_by == 'SENDER');
            // this.elements.splice(dateSignedIndex, 1);
            let dateSignedIndexs = [];
            this.elements.forEach((el, index) => {
              if (el.type == "DATE_SIGNED" && el.filled_by == "SENDER") {
                dateSignedIndexs.push(index);
              }
            });
            if (dateSignedIndexs.length) {
              dateSignedIndexs.forEach((el) => this.elements.splice(el, 1));
            }
          }
        }
        if (
          this.elements[this.activatedItemIndex]["type"] == "SIGNATURE" &&
          this.elements[this.activatedItemIndex]["filled_by"] != "SENDER"
        ) {
          let selectedUserSignatures = this.elements.filter(
            (el) =>
              el.type == "SIGNATURE" &&
              el.filled_by ==
                this.elements[this.activatedItemIndex]["filled_by"]
          );
          if (selectedUserSignatures && selectedUserSignatures.length == 1) {
            // let dateSignedIndex = this.elements.filter(el => el.type == 'DATE_SIGNED' && el.filled_by == this.elements[this.selectedItemIndex]['filled_by']);
            let dateSignedIndexs = [];
            this.elements.forEach((el, index) => {
              if (
                el.type == "DATE_SIGNED" &&
                el.filled_by ==
                  this.elements[this.activatedItemIndex]["filled_by"]
              ) {
                dateSignedIndexs.push(index);
              }
            });
            if (dateSignedIndexs.length) {
              dateSignedIndexs.forEach((el) => this.elements.splice(el, 1));
            }
          }
        }
        if (
          this.elements[this.activatedItemIndex] &&
          this.elements[this.activatedItemIndex]["type"] == "ENTITY" &&
          this.checkForChildrenWithActiveParent
        ) {
          this.$message({
            message:
              "Some fields are associated to this entity field. Can't delete.",
            type: "warning",
          });
          return;
        }
        if (
          this.elements[this.activatedItemIndex] &&
          this.elements[this.activatedItemIndex]["type"] ==
            "REPEATABLE_PARENT" &&
          this.checkForRepeatableChild
        ) {
          this.$message({
            message:
              "Some fields are associated to this repeatable field. Can't delete.",
            type: "warning",
          });
          return;
        }
        let addFieldToHistory = {
          data: this.activatedItem,
          action: "delete",
          index: this.activatedItemIndex,
        };
        this.history.push(addFieldToHistory);
        this.elements.splice(this.activatedItemIndex, 1);
      }
    },

    // async fetchCompanyDetails() {
    //   await this.$store.dispatch("company/getCompany");
    // },
    async fetchCompanyDocument() {
      this.loading = true;
      this.documentId = this.$route.params.document_id;
      this.configurableDocumentId = this.$route.params.configurable_document_id;
      let params = {
        id: this.documentId,
        name: this.documentName
          ? this.documentName
          : this.getCompanyDocumentDataById.document_names[0],
      };
      await this.$store.dispatch(
        "companyDocuments/fetchCompanyDocumentById",
        params
      );
      await this.$store.dispatch("configurableDocuments/clearErrors");
      if (this.configurableDocumentId) {
        await this.fetchDocumentData();
      } else {
        this.documentName = this.getCompanyDocumentDataById.title
          ? this.getCompanyDocumentDataById.title
          : this.getCompanyDocumentDataById.document_names[0];
        this.backgroundImage = this.getBackgroundImage(
          this.getCompanyDocumentDataById.document_background ||
            this.getCompanyDocumentDataById.document_backgrounds[0]
        );

        this.backgroundImages =
          this.getCompanyDocumentDataById.document_backgrounds.map((e) => {
            return this.getBackgroundImage(e);
          });
      }
      this.loading = false;
    },

    async fetchDocumentData() {
      this.documentDataLoading = true;
      await this.$store.dispatch(
        "configurableDocuments/fetchConfigurableDocumentById",
        {
          id: this.configurableDocumentId,
        }
      );

      this.documentFormData = JSON.parse(
        JSON.stringify(this.getConfigurableDocumentDataById)
      );

      this.backgroundImage = this.documentFormData.pages[0].background_image;

      if (
        this.documentFormData.pages[0].background_images &&
        this.documentFormData.pages[0].background_images.length
      ) {
        this.backgroundImages =
          this.documentFormData.pages[0].background_images;
      } else {
        this.backgroundImages = [this.backgroundImage];
      }
      this.documentName = this.documentFormData.name
        ? this.documentFormData.name
        : this.getCompanyDocumentDataById.document_names[0];
      if (this.documentFormData) {
        this.storedFormBuilderWidth =
          this.documentFormData.pages[0].page_base_width;
      }
      this.documentUsers = this.documentFormData.document_users;

      if (this.documentFormData.document_expiration_settings) {
        this.expirationSettings =
          this.documentFormData.document_expiration_settings;
      }
      this.enforceSignatureOrder =
        this.documentFormData.enforce_signature_order || false;

      this.loadingElements = true;

      setTimeout(() => {
        this.setFormElements();
        this.loadingElements = false;
      }, this.backgroundImages.length * 500);
      this.documentDataLoading = false;
    },
    setFormElements() {
      this.elements = this.setSizeAndPositionForElements(
        // JSON.parse(JSON.stringify(this.documentFormData.pages[0].fields))
        JSON.parse(JSON.stringify(this.pages[0].fields))
      );
    },

    async goBack() {
      console.log("this.dialogVisible", this.dialogVisible);
      if (this.changeDocumentMethod || this.changeDocumentDocumentModel) {
        this.dialogVisible = true;
      }
      let tempSender =
        this.getConfigurableDocumentDataById.document_users.filter(
          (el) =>
            el &&
            el.type &&
            el.type != "SENDER" &&
            el.user_type !== "CC" &&
            el.user_type !== "APPROVER"
        );
      let query = this.getNavigationQuery(this.$route.query);
      if (
        this.elements &&
        !this.elements.length &&
        tempSender &&
        tempSender.length
      ) {
        if (this.openCk && !this.is_saveTemplate) {
          this.dialogVisible = true;
        } else if (this.openCk && this.is_saveTemplate) {
          this.$router.push({
            path: "/templates",
            query,
          });
        } else {
          await this.checkFieldsOfUser();
        }
      } else if (
        this.getConfigurableDocumentDataById?.pages[0]?.fields?.length !=
          this.elements.length &&
        !this.is_saveTemplate
      ) {
        this.dialogVisible = true;
      } else if (
        this.getConfigurableDocumentDataById.name != this.documentName &&
        !this.is_saveTemplate
      ) {
        this.dialogVisible = true;
      } else if (
        this.getConfigurableDocumentDataById &&
        this.getConfigurableDocumentDataById.pages &&
        this.getConfigurableDocumentDataById.pages[0] &&
        this.getConfigurableDocumentDataById.pages[0].fields &&
        this.getConfigurableDocumentDataById.pages[0].fields.length
      ) {
        let diffrField = false;
        this.getConfigurableDocumentDataById.pages[0].fields.forEach((el) => {
          this.elements.forEach((e) => {
            if (
              el &&
              el.key &&
              e &&
              e.key &&
              e.key == el.key &&
              (e.chaned ||
                el.changed ||
                e.label != el.label ||
                e.placeholder != el.placeholder ||
                (e.content && el.content && e.content != el.content) ||
                (e.value && el.vale && e.value != el.value) ||
                (e.source && el.source && e.source != el.source) ||
                (e.filled_by && el.filled_by && e.filled_by != el.filled_by) ||
                (e.selectedUser &&
                  el.selectedUser &&
                  e.selectedUser != el.selectedUser))
            ) {
              diffrField = true;
            }
          });
        });

        if (diffrField && !this.is_saveTemplate) {
          this.dialogVisible = true;
        } else if (diffrField && this.is_saveTemplate) {
          this.dialogVisible = false;
          this.$router.push({
            path: "/templates",
            query,
          });
        } else {
          this.dialogVisible = false;
          this.$router.push({
            path: "/templates",
            query,
          });
        }
      } else {
        this.$router.push({
          path: "/templates",
          query,
        });
      }
    },

    getHeadingStyles(item) {
      return `color:${item.font.color};font-family:${item.font.name}`;
    },
    getFieldStyles(item, additionalStyle = "", index, alignment = "left") {
      if (item.type == "SIGNATURE" || item.type == "CHECKBOX") {
        return;
      }
      let primaryFieldsStyRef;
      let textareaRef;
      if (item.type == "PRIMARY_FIELDS") {
        const textareaElement = this.$refs[`textarea_${index}`];
        if (textareaElement && textareaElement[0] && textareaElement[0].$refs) {
          primaryFieldsStyRef = textareaElement[0].$refs.textarea;
          primaryFieldsStyRef.style.textAlign = item.alignStyles;
        }
      }
      if (
        item.type != "YES_OR_NO" &&
        item.type != "CHECKBOX" &&
        item.type != "SIGNATURE" &&
        item.type != "INITIAL" &&
        item.type != "SELECT" &&
        item.type != "DATE" &&
        item.type != "LIST" &&
        item.type != "DATE_TIME" &&
        item.type != "DATE_TIME_RANGE" &&
        item.type != "TIME" &&
        item.type != "TIME_RANGE" &&
        item.type != "DATE_RANGE" &&
        item.type != "DATE_SIGNED" &&
        item.type != "my-signature" &&
        item.type != "MY_INITIAL" &&
        item.type != "MULTI_SELECT" &&
        item.type != "MULTI_LINE_TEXT" &&
        item.type != "ATTACHMENT" &&
        item.type != "ENTITY" &&
        item.type != "FIXED_TIME" &&
        item.type != "WEEKDAYS"
      ) {
        const textareaElement = this.$refs[`textarea_${index}`];
        if (textareaElement && textareaElement[0] && textareaElement[0].$refs) {
          textareaRef = textareaElement[0].$refs.textarea;
          textareaRef.style.textAlign = item.alignStyles;
        }
      }
      let style = `font-size:${
        item && item.font && item.font.size ? item.font.size : "16"
      }px;font-style: ${
        (item && item.font && item.font.style == 2) ||
        (item && item.font && item.font.style == 3)
          ? "italic"
          : "normal"
      };font-weight:${
        (item && item.font && item.font.style == 1) ||
        (item && item.font && item.font.style == 3)
          ? "bold"
          : "normal"
      };color:${
        item && item.font && item.font.color ? item.font.color : "black"
      };text-align:${item.alignStyles};font-family:${
        item && item.font && item.font.name ? item.font.name : "helvetica"
      }`;
      return (
        style +
        ";" +
        additionalStyle +
        ";" +
        textareaRef +
        ";" +
        primaryFieldsStyRef
      );
    },
    getStyle(item) {
      let style = `position:absolute;left:${item.x}px;top:${item.y}px;height:${item.height}px;width:${item.width}px;`;
      return style;
    },
    getImageStyle(item) {
      let style = `width:${item.width}px;;height:${item.height}px;`;
      return style;
    },
    async openPopup(item, index) {
      // if (item.type == "MY_INITIAL" && item.value) {
      //   return "";
      // }
      if (item && item.type == "HORIZONTAL_LINE") {
        this.dialogFormVisible = true;
        const choosenElement = JSON.parse(JSON.stringify(this.elements[index]));
        this.selectedItem = { ...{}, ...choosenElement };
        this.selectedItemIndex = index;
      } else {
        this.activatedItem = item;
        this.activatedItemIndex = index;
        // this.activatedItem = {};
        // this.activatedItemIndex = -1;
        this.addItemDataDialogVisible = true;
        const choosenElement = JSON.parse(JSON.stringify(this.elements[index]));
        this.selectedItem = { ...{}, ...choosenElement };
        this.selectedItemIndex = index;
        if (
          this.selectedItem.type == "CHECKBOX" &&
          this.selectedItem.group_key
        ) {
          let gIndex = -1;
          gIndex = this.checkBoxGroupArray.findIndex(
            (g) => g.key == this.selectedItem.group_key
          );
          this.current_group_index = gIndex;
          this.checkBoxGrpObj = this.checkBoxGroupArray[gIndex];
        }
      }
    },
    async getEntityFields() {
      try {
        const selectedContactTypes = this.documentRecipientsList
          .filter((e) => e.type != "SENDER")
          .flatMap((e) => e.contact_type);
        if (
          this.getCompanyEntityDetails &&
          this.getCompanyEntityDetails.entity_details &&
          this.getCompanyEntityDetails.entity_details[0] &&
          this.getCompanyEntityDetails.entity_details[0]._id
        ) {
          selectedContactTypes.push(
            this.getCompanyEntityDetails.entity_details[0]._id
          );
        }
        let params = {
          ids: selectedContactTypes,
        };
        await this.$store.dispatch("entities/fetchEntitiesByQuery", params);
        if (this.getEntityDataByQuery) {
          this.allUsersEntityData = [];
          const enitityData = [...this.getEntityDataByQuery];
          enitityData.forEach((dt) => {
            let data = {};
            data.name = dt.name;
            data.id = dt._id;
            data.templates = [];
            dt.templates.forEach((temp) => {
              if (
                temp.template_id &&
                temp.template_id.type != "STANDARD" &&
                temp.template_id.slug != "contactdetails"
              ) {
                let fields = this.getTemplateFields(temp.template_id);
                fields = fields.filter((e) => e.inputType !== "QUESTION");
                fields = this.setGlobalVariables(
                  fields,
                  this.globalVariablesData
                );
                let checkBoxGroup = this.getTemplateCheckBoxGroups(
                  temp.template_id
                );
                data.templates.push({
                  name: temp.template_id.name,
                  checkBoxGroup: checkBoxGroup,
                  fields: fields,
                  is_repeatable: temp.is_repeatable,
                  template_id: temp.template_id._id,
                  max_value: temp.max_records,
                  primary_field: temp.template_id.primary_fields[0]
                    ? temp.template_id.primary_fields[0]
                    : null,
                });
              }
            });
            this.allUsersEntityData.push(data);
          });

          let value = this.recipientSelection
            ? this.recipientSelection
            : "SENDER";
          if (value == "SENDER") {
            if (this.getCompanyDetails && this.getCompanyDetails._id) {
              this.selectedUserEntity = this.allUsersEntityData.find(
                (e) => e.name == this.getCompanyDetails._id
              );
            }
          }
        }
      } catch (err) {}
    },
    getTemplateFields(data) {
      let fields = [];
      data.sections.forEach((e) => {
        fields = [...fields, ...e.fields];
      });
      fields = fields.map((e) => {
        e.template_id = data._id;
        return e;
      });
      return fields;
    },
    updateSignatureFilledBy() {
      this.selectedItem.filled_by = this.selectedItem.signature_field.filled_by;
      this.selectedItem.signature_field_key =
        this.selectedItem.signature_field.key;
    },

    checkFieldAllow(item) {
      return item.filled_by === "RECEIVER";
    },
    checkFieldDisabledOnModal(item) {
      if (
        (this.activatedItem &&
          this.activatedItem.key === item.key &&
          this.addItemDataDialogVisible) ||
        this.addItemDataDialogVisible === true
      ) {
        this.isFieldEditting = false;
        return true;
      } else {
        return false;
      }
    },

    onPreview() {
      this.previewVisible = true;
    },
    isEmpty(value) {
      return !value;
    },
    async saveCapturedSignature() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      if (!isEmpty) {
        await this.$store.dispatch("auth/updateUserSignature", {
          signature: data,
        });
        await this.$store.dispatch("auth/fetchUserSignature");
        this.selectedSignatureItem.source = data;

        this.elements[this.selectedSignatureItemIndex] =
          this.selectedSignatureItem;
      }
      this.$refs.signaturePad.clearSignature();
      this.selectedSignatureItem = {};
      this.selectedSignatureItemIndex = -1;
      this.captureSignatureDialog = false;
      this.addMySignatureStatus = false;
    },
    undo() {
      this.$refs.signaturePad.undoSignature();
    },

    captureSignature(item, index) {
      this.selectedSignatureItem = item;
      this.selectedSignatureItemIndex = index;
      this.captureSignatureDialog = true;
    },
    generateTextImageForMyIntial(text, color) {
      let url = "";
      textToImage
        .generate(text, {
          textColor: color,
          fontSize: 25,
          lineHeight: 35,
          fontFamily: "Dancing Script",
          maxWidth: 100,
        })
        .then((dataUri) => {
          this.myInitial.value = dataUri;
        });
      return url;
    },

    generateTextImage(text, color) {
      let url = "";
      textToImage
        .generate(text, {
          textColor: color,
          fontSize: 25,
          lineHeight: 35,
          fontFamily: "Dancing Script",
          maxWidth: 100,
        })
        .then((dataUri) => {
          this.selectedItem.value = dataUri;
        });
      return url;
    },
    async saveMyInitial() {
      await this.generateTextImageForMyIntial(
        this.myInitial.text,
        this.myInitial.color
      );
      this.$store.dispatch("auth/addInitialSignature", {
        initial: this.myInitial.value,
      });
      await this.$store.dispatch("auth/fetchInitialSignature");
      this.selectedSignatureItem.value = this.myInitial.value;
      this.elements[this.selectedSignatureItemIndex] =
        this.selectedSignatureItem;
      this.selectedSignatureItem = {};
      this.addMyInitialStatus = false;
    },
    getImageDimensions(file) {
      return new Promise(function (resolved) {
        var i = new Image();
        i.onload = function () {
          resolved({ w: i.width, h: i.height });
        };
        i.src = file;
      });
    },
    checkDuplicateLabel() {
      let duplicateLabel = false;
      this.elements.forEach((el, index) => {
        if (index != this.selectedItemIndex) {
          if (
            this.selectedItem.label &&
            el &&
            el.label &&
            this.selectedItem.label.toLowerCase() == el.label.toLowerCase()
          ) {
            duplicateLabel = true;
          }
        }
      });
      return duplicateLabel;
    },
    reloadForm() {
      this.refreshForm = true;
      setTimeout(() => {
        this.refreshForm = false;
      });
      this.$store.dispatch(
        "configurableDocuments/saveCurrentDocumentFields",
        this.elements
      );
    },
    deleteTableRow(data) {
      this.tableLoading = true;
      data.table_data.pop();
      this.tableLoading = false;
    },
    deleteTableColumn(header, index, item) {
      if (item.map_keys) {
        item.table_columns_data.splice(item.table_columns_data[index], 1);
        item.cols;
      } else {
        item.headers.splice(index, 1);
        item.table_data.forEach((data) => {
          let key = item.table_columns_data.find((e) => {
            if (e && e.name === header) {
              return e.key;
            }
          });
          delete data[key.key];
        });
        const columnDataIndex = item.table_columns_data.findIndex(
          (column) => column.name === header
        );
        if (columnDataIndex !== -1) {
          item.table_columns_data.splice(columnDataIndex, 1);
        }
      }
    },
    editColumn(header, index, data) {
      (this.selectedColHeader = header),
        (this.selectedColIndex = index),
        (this.selectedColData = data);
      this.editColumnTitle = true;
      this.selectedHeader = data.headers[index];
      this.selectedHeaderIndex = index;
    },
    async updateTableColumn() {
      this.tableLoading = true;
      const prevHeader = this.selectedColData.headers[this.selectedColIndex];
      this.selectedColData.headers[this.selectedColIndex] = this.selectedHeader;
      this.selectedColData.table_columns_data.forEach((item) => {
        if (item.name === prevHeader) {
          item.name = this.selectedHeader;
          item.key = this.generateKeyForField(this.selectedHeader);
        }
      });
      const rowKey = this.generateKeyForField(prevHeader);
      const newKey = this.generateKeyForField(this.selectedHeader);
      const rowData = this.selectedColData.headers
        ? this.selectedColData.headers
        : this.selectedColData.table_data[0];
      if (rowData.hasOwnProperty(rowKey)) {
        const value = rowData[rowKey];
        delete rowData[rowKey];
        rowData[newKey] = value;
      }
      this.selectedColData.headers = [...this.selectedColData.headers];
      this.selectedColData.table_columns_data = [
        ...this.selectedColData.table_columns_data,
      ];
      this.selectedColData.table_data = [rowData];
      this.selectedHeader = "";
      this.selectedHeaderIndex = "";
      this.editColumnTitle = false;

      const indexOfElementToReplace = this.elements.findIndex(
        (element) => element.key === this.selectedColData.key
      );
      if (indexOfElementToReplace !== -1) {
        this.$set(this.elements, indexOfElementToReplace, this.selectedColData);
      }
      this.tableKey++;
      return this.elements;
    },
    async saveTheTemplate() {
      let tableRowsData = this.elements.find(
        (e) =>
          e && e.type == "DATA_TABLE" && e.table_data && !e.table_data.length
      );
      try {
        if (this.documentName.length == 0) {
          this.$message.error("Please add document title");
        } else if (tableRowsData && tableRowsData.length) {
          this.$message.error("Please add table rows..!");
        } else {
          let validateData = await this.validateForm();
          let checkFields = await this.checkFieldsOfUser();
          if (checkFields && validateData) {
            this.loading = true;
            this.elements = this.mapCoordinates(
              this.elements,
              this.document_url_paths
            );
            this.params = this.prepareFields();
            this.params.document_users = this.params.document_users.map(
              (user) => {
                if (user.type != "SENDER" && !user.constant_user) {
                  delete user.email;
                  delete user.first_name;
                  delete user.last_name;
                  delete user.contact;
                  delete user.phone;
                  delete user.address;
                }
                if (user.type == "SENDER" && !this.openCk) {
                  delete user.contact_type;
                }
                return user;
              }
            );
            await this.updateForm(this.params);
            this.loading = false;
          } else {
            this.loading = true;
            this.params = this.prepareFields();
            const signerCount = this.params.document_users.filter(
              (user) => user.user_type === "SIGNER" && user.type === "SENDER"
            ).length;
            const ccUserCount = this.params.document_users.filter(
              (user) =>
                user.type === "RECEIVER" &&
                (user.user_type === "CC" || user.user_type === "APPROVER")
            ).length;
            if (
              signerCount === 1 &&
              ccUserCount === this.params.document_users.length - 1 &&
              !this.openCk
            ) {
              await this.updateForm(this.params);
              this.loading = false;
            }
            this.loading = false;
          }
        }
      } catch (err) {
        this.loading = false;
      }
      this.is_saveTemplate = true;
    },
    async saveTemplate(template = null) {
      try {
        this.loading = true;
        let validateData = await this.validateForm();
        if (validateData) {
          this.params = this.prepareFields();
          if (template) {
            this.params.is_template = true;
          }
          this.params.document_users = this.params.document_users.map(
            (user) => {
              if (user.type != "SENDER" && !user.constant_user) {
                delete user.email;
                delete user.first_name;
                delete user.last_name;
                delete user.contact;
                delete user.phone;
                delete user.address;
              }
              if (user.type == "SENDER" && !this.openCk) {
                delete user.contact_type;
              }
              return user;
            }
          );
          let checkSign = await this.checkSignUser();
          if (checkSign) {
            console.log("update form");
            if (this.enforceSignatureOrder) {
              this.params.document_users.find((el) => {
                if (
                  el &&
                  el.type === "SENDER" &&
                  el.e_signature_order &&
                  (el.e_signature_order > 0 || el.e_signature_order != 0) &&
                  el.e_signature_required
                ) {
                  this.elements.map((el) => {
                    if (
                      (el &&
                        el.filled_by == "SENDER" &&
                        el.type === "DATE_SIGNED") ||
                      el.type === "SIGNATURE" ||
                      el.type == "MY_INITIAL" ||
                      el.type == "INITIAL"
                    ) {
                      el.source = "";
                      el.value = "";
                    }
                  });
                }
              });
            }
            await this.updateForm(this.params);
            console.log(this.params);
          }
        } else {
          this.showErrorElementDialog = true;
        }
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.$notify.error({
          title: "Error",
          message: "Error at saving form",
        });
      }
    },

    async saveUpdateData() {
      await this.updateForm(this.params);
    },
    async validateForm() {
      let senderAttachments = true;
      this.errorElements = [];
      if (
        this.elements &&
        this.elements.length &&
        this.elements.length > 0 &&
        !this.openCk
      ) {
        this.updatingProfileInfo();
        this.elements.forEach((e) => {
          if (
            e.type == "ATTACHMENT" &&
            e.filled_by == "SENDER" &&
            e.required &&
            e.files &&
            e.files.length < e.requiredFiles
          ) {
            senderAttachments = false;
          }
        });
        this.errorElements = this.elements.filter((e) => {
          return (
            !e.template_field_id &&
            !e.entity_field_id &&
            e.type !== "logo" &&
            e.type !== "FORMULA" &&
            e.type !== "my-signature" &&
            e.type !== "HEADING" &&
            e.type !== "SINGLE_LINE_CONTENT" &&
            e.type !== "PARAGRAPH" &&
            e.section !== "table" &&
            e.type != "MY_INITIAL" &&
            e.type != "PRIMARY_FIELDS" &&
            e.type != "IMAGE" &&
            (!e.label || e.label.length == 0 || !e.filled_by)
          );
        });

        if (this.errorElements.length) {
          this.fieldErrorMessage = `Please config below Fields`;
          return false;
        } else if (!senderAttachments) {
          this.fieldErrorMessage =
            "Please upload required documents for attachments";
          this.showErrorElementDialog = true;
          return false;
        }
        {
          return true;
        }
      } else {
        if (this.editorFields && this.editorFields.length > 0) {
          return true;
        } else {
          this.fieldErrorMessage =
            "There was No fields. Please place some fields";
          return false;
        }
      }
    },
    async addNewForm(params) {
      let query = this.getNavigationQuery(this.$route.query);
      await this.$store.dispatch(
        "configurableDocuments/addConfigurableDocument",
        params
      );
      if (this.getConfigurableDocumentAddStatus) {
        this.$notify.success({
          title: "Success",
          message: "Template Saved Successfully",
        });

        this.$router.push({
          path: "/templates",
          query,
        });
      } else {
        if (this.getConfigurableDocumentErrors) {
          this.showServerErrorsDialog = true;
        } else {
          this.$notify.error({
            title: "Error",
            message: "Error at saving form",
          });
        }
      }
    },

    async updateForm(params) {
      try {
        this.checkboxErrors = [];
        this.checkBoxGroupArray.forEach((grp) => {
          let notCheckboxFilled = this.elements.filter(
            (el) => el.type == "CHECKBOX" && el.group_key === grp.key
          );
          if (
            notCheckboxFilled.length &&
            notCheckboxFilled.length > 0 &&
            notCheckboxFilled.length < parseInt(grp.min_value)
          ) {
            this.checkboxErrors.push(grp);
          }
        });
        if (
          this.getConfigurableDocumentDataById.name &&
          this.getConfigurableDocumentDataById.name != this.documentName &&
          !this.checkPermissions("renameRename")
        ) {
          this.$notify.error({
            title: "Error",
            message:
              "Permission denied for rename document.Please contact owner",
          });
        } else if (
          this.checkboxErrors.length &&
          this.checkboxErrors.length > 0
        ) {
          this.showCheckboxErrors = true;
          this.documentDataLoading = false;
        } else {
          await this.$store.dispatch(
            "configurableDocuments/updateConfigurableDocument",
            params
          );
          await this.updateParentDataByEntityVariableInDocuments(this.elements);
          if (this.getConfigurableDocumentUpdateStatus) {
            // this.setDocumentFields();
            this.$notify.success({
              title: "Success",
              message: "Template Saved Successfully",
            });
            this.is_publishing = false;
            if (this.createDraftDoc) {
              await this.createDraft();
            }
            // await this.fetchDocumentDetails();
            if (this.isShowPreview) {
              let query = this.getNavigationQuery(this.$route.query);
              this.$router.push({
                name: "edit-configure-template-image-preview",
                params: this.$route.params,
                query,
              });
            }
          } else {
            this.isShowPreview = false;
            this.createDraftDoc = false;
            if (this.getConfigurableDocumentErrors) {
              this.showServerErrorsDialog = true;
            } else {
              this.$notify.error({
                title: "Error",
                message: "Error at saving form",
              });
            }
          }
        }
      } catch (err) {
        this.isShowPreview = false;
      }
    },
    prepareFields() {
      let signUsers = this.elements
        .filter((element) => element.type == "SIGNATURE")
        .flatMap((element) => element.filled_by);
      let requiredUsers = this.elements.flatMap((el) => el.filled_by);
      if (
        this.getConfigurableDocumentDataById &&
        this.getConfigurableDocumentDataById?.ck_editor_content &&
        this.getConfigurableDocumentDataById?.ck_editor_content.length
      ) {
        const matches = this.editorContent.match(/\[\[(.*?)\]\]/g);
        const labels = matches
          ? matches.map((label) => this.normalizeLabel(label.slice(2, -2)))
          : [];
        const labelCountMap = {};
        labels.forEach((label) => {
          if (labelCountMap[label]) {
            labelCountMap[label] += 1;
          } else {
            labelCountMap[label] = 1;
          }
        });
        const filteredEditorFields = [];
        this.editorFields.forEach((field) => {
          const normalizedFieldLabel = this.normalizeLabel(field.label);
          if (labelCountMap[normalizedFieldLabel] > 0) {
            for (let i = 0; i < labelCountMap[normalizedFieldLabel]; i++) {
              filteredEditorFields.push(field);
            }
            labelCountMap[normalizedFieldLabel] = 0;
          }
        });
        this.editorFields = filteredEditorFields;
      }
      let users = this.documentRecipientsList.map((user, index) => {
        user.e_signature_order = index;
        if (signUsers.indexOf(user.value) != -1) {
          user.e_signature_required = true;
        } else {
          user.is_cc = true;
          user.e_signature_required = false;
        }
        if (user.contact_type && user.contact_type._id) {
          user.contact_type = user.contact_type._id;
        }
        if (!user.color) {
          let exst = this.getConfigurableDocumentDataById.document_users
            .filter((user) => user.color)
            .map((user) => user.color);
          // getting random color for user fields
          let userColor = this.getRandomColor(exst);
          user.color = userColor;
        }
        return user;
      });
      users.map((user) => {
        if (requiredUsers.indexOf(user.value) != -1) {
          user.fields_required = true;
        } else {
          user.fields_required = false;
        }
      });
      this.is_published = this.is_publishing ? this.is_published : false;
      // this.mapDocumentFields();
      let inOrderFields = this.openCk
        ? this.elements
        : this.getFieldsFromElements();
      inOrderFields.sort((a, b) => {
        return a.y - b.y;
      });
      let timezone = this.getTimeZone();
      let data = {
        id: this.$route.params.configurable_document_id || undefined,
        name: this.documentName,
        type: "CUSTOM",
        configure_type: "DYNAMIC",
        company_document_id: this.$route.params.document_id,
        background_images: this.backgroundImages,
        document_users: users,
        // document_expiration_settings: this.expirationSettings,
        //document_users:this.getConfigurableDocumentDataById.document_users,
        // enforce_signature_order: this.enforceSignatureOrder || false,
        is_anyone_can_approve: this.isAnyoneCanApprove,
        is_template: this.isTemplate,
        template_title: this.documentName,
        is_published: this.is_published,
        lock_template_fields: this.lockTemplateFields,
        access_to_all: this.access_to_all,
        secure_access: this.secure_access,
        update_contacts: this.update_contacts,
        sender_time_zone: timezone,
        ck_editor_content: this.editorContent,
        ckeditor_fields: this.editorFields,
        ck_editor_unmapped_content: this.editorContent,
        senderTimeZoneFormatOffset: this.getTimeZone(),
        pages: [
          {
            page_no: 1,
            background: this.hasBackground,
            background_image: this.backgroundImage,
            background_images: this.backgroundImages,
            style:
              "position: relative;width: 1240px;border: 1px solid #ccc;margin: 1em;max-height: 90vh;overflow: scroll;padding: 20px;",
            fields: inOrderFields,
            page_base_width: this.currentFormBuilderWidth,
            templates_used: Array.from(new Set(this.templatesUsed)),
            entities_used: Array.from(new Set(this.entitiesUsed)),
            checkbox_group: this.checkBoxGroupArray,
          },
        ],
      };

      if (
        this.getConfigurableDocumentDataById.description &&
        this.getConfigurableDocumentDataById.description.length
      ) {
        data.description = this.getConfigurableDocumentDataById.description;
      }

      return data;
    },
    mapDocumentFields() {
      this.elements = this.elements.map((element) => {
        let pageBoundary = this.getPagesBoundaries(element.y);
        if (
          pageBoundary &&
          pageBoundary.data &&
          pageBoundary.data.pageId > -1 &&
          pageBoundary.data.docId > -1
        ) {
          element.page = pageBoundary.data.pageId + 1;
          element.docId = pageBoundary.data.docId;
          let selectedPage = document.getElementById(
            `${pageBoundary.data.docId}_canvas_${pageBoundary.data.pageId + 1}`
          );
          if (selectedPage.offsetTop) {
            element.y = element.y - selectedPage.offsetTop;
            element.offsetTop = selectedPage.offsetTop;
          }
          if (selectedPage.offsetLeft) {
            element.x = element.x - selectedPage.offsetLeft;
            element.offsetLeft = selectedPage.offsetLeft;
          }
        }
        return element;
      });
    },
    setDocumentFields() {
      this.elements = this.elements.map((element) => {
        if (element.docId > -1 && element.page) {
          let selectedPage = document.getElementById(
            `${element.docId}_canvas_${element.page}`
          );
          if (selectedPage.offsetTop) {
            element.y = element.y + selectedPage.offsetTop;
          }
          if (selectedPage.offsetLeft) {
            element.x = element.x + selectedPage.offsetLeft;
          }
        }
        return element;
      });
    },
    getKeyForTemplateField(templateId, fieldId) {
      let selectedTemplate = this.getAllCompanyTemplatesData.data.find(
        (template) => template._id == templateId
      );

      let selectedTemplateField = selectedTemplate.sections[0].fields.find(
        (field) => field._id == fieldId
      );
      return (
        selectedTemplate.name.split(" ").join("") +
        "#" +
        selectedTemplateField.key
      );
    },

    getKeyForEntityField(entityId, fieldId) {
      let selectedEntity = this.getAllCompanyEntitiesData.data.find(
        (entity) => entity._id == entityId
      );

      let selectedEntityField = {};
      let fields = [];
      if (
        selectedEntity &&
        selectedEntity.sections &&
        selectedEntity.sections.length
      ) {
        selectedEntity.sections.map((section) => {
          if (section.fields && section.fields.length) {
            fields = section.fields;
            for (let index = 0; index < fields.length; index++) {
              if (fields[index]._id == fieldId) {
                selectedEntityField = fields[index];
              }
            }
            return;
          }
        });
        return (
          selectedEntity.name.split(" ").join("") +
          "#" +
          selectedEntityField.key
        );
      } else {
        return "";
      }
    },
    openDocumentUserSettings() {
      this.saveUpdateData();
    },
    async reloadEditorContent() {
      this.loading = true;
      this.loadingText = "Rendering Editor...";
      await this.$nextTick();
      this.editorKey = Date.now(); // Forces re-render of CKEditor component
      this.$nextTick(() => {
        this.editorContent = this.getNewContent(); // Fetch or set new content
      });
      this.loading = false;
    },
    getNewContent() {
      console.log(this.editorContent);
      return this.editorContent;
    },
    async reloadCKEditorContent() {
      this.loading = true;
      this.loadingText = "Rendering Editor...";
      if (this.openCk) {
        this.documentDataLoading = true;
        await this.$store.dispatch(
          "configurableDocuments/fetchConfigurableDocumentById",
          {
            id: this.$route.params.configurable_document_id,
          }
        );
        // Update editorContent with the fetched data
        this.editorContent =
          this.getConfigurableDocumentDataById.ck_editor_content;
        this.editorFields =
          this.getConfigurableDocumentDataById.ckeditor_fields;
        // Force CKEditor to reload by changing the key
        this.editorKey += 1;
        this.documentDataLoading = false;
      }
      this.loading = false;
    },
    async closeDocumentUserSettings({ elements }) {
      this.addDocumentRecipient = false;
      this.documentDataLoading = true;
      // this.loadingText = "Fetching document users...";
      await this.$store.dispatch(
        "configurableDocuments/fetchConfigDocumentUsers",
        {
          id: this.$route.params.configurable_document_id,
        }
      );
      if (this.getDocumentUsers && this.getDocumentUsers.document_users) {
        this.documentRecipientsList = [];
        this.documentRecipientsList = [...this.getDocumentUsers.document_users];
        if (elements && elements.length) {
          this.elements = elements;
        }
        this.documentRecipientsList = this.documentRecipientsList.map(
          (user) => {
            if (user.type == "SENDER") {
              let companyName = "";
              if (
                this.getActiveWorkspace.plan_type != "INDIVIDUAL" &&
                this.getActiveWorkspace.company_id &&
                this.getActiveWorkspace.company_id != "0"
              ) {
                companyName =
                  this.getCompanyDetails &&
                  this.getCompanyDetails.name &&
                  this.getCompanyDetails.legal_name &&
                  this.getCompanyDetails.legal_name != ""
                    ? this.getCompanyDetails.legal_name
                    : this.getCompanyDetails.name &&
                      this.getCompanyDetails.name != ""
                    ? this.getCompanyDetails.name
                    : "";
              } else {
                companyName = this.getAuthenticatedUser.company_name;
              }
              user.email = this.getAuthenticatedUser.email;
              user.first_name = this.getAuthenticatedUser.first_name;
              user.last_name = this.getAuthenticatedUser.last_name;
              user.company_name = companyName;
              user.phone = this.getAuthenticatedUser.phone;
              (user.address =
                this.getActiveWorkspace &&
                this.getActiveWorkspace.company_id != "0" &&
                this.getActiveWorkspace.plan_type != "INDIVIDUAL"
                  ? this.getCompanyDetails && this.getCompanyDetails.address
                    ? this.getCompanyDetails.address
                    : this.getAuthenticatedUser.address
                  : this.getAuthenticatedUser.address),
                (user.constant_user = true);
            } else if (!user.constant_user) {
              if (user.first_name) {
                user.first_name = "";
              }
              if (user.last_name) {
                user.last_name = "";
              }
              if (user.email) {
                user.email = "";
              }
              if (user.contact) {
                delete user.contact;
              }
              if (user.entity_data_id) {
                delete user.entity_data_id;
              }
              if (user.address) {
                user.address = "";
              }
              if (user.phone) {
                user.phone = "";
              }
              if (user.company_name) {
                user.company_name = "";
              }
            }

            if (!user.user_type) {
              user.user_type = "SIGNER";
            }
            if (user.type != "SENDER" && !user.user_type) {
              user.user_type = "SIGNER";
            }
            return user;
          }
        );
        await Promise.all([
          await this.companyEntityDetails(),
          this.getEntityFields(),
        ]);
        await this.setEntityData();
        this.documentRecipientsList.forEach((user) => {
          if (this.elements && this.elements.length) {
            this.elements.map((el) => {
              if (
                el.type == "PRIMARY_FIELDS" &&
                el.selected_user &&
                user.value == el.selected_user
              ) {
                el.content = this.getFieldVal(
                  el.selected_tag,
                  el.selected_user,
                  el.selected_tag == "address" &&
                    el.addressInfo === "Company Address"
                    ? true
                    : false
                );
              }
            });
          }
        });
        this.enforceSignatureOrder =
          this.getDocumentUsers.enforce_signature_order;
        this.isAnyoneCanApprove = this.getDocumentUsers.is_anyone_can_approve;
        this.documentDataLoading = false;
      }
      this.documentDataLoading = false;
      let hasFields = this.checkHasFields();
      if (hasFields && !this.fields_found) {
        this.documentDataLoading = true;
        this.loadingText = "Fetching fields...";
        this.fieldsAlertModel = true;
        // this.removeFormFieldsFromPdf();
      } else if (
        this.getConfigurableDocumentDataById &&
        this.getConfigurableDocumentDataById?.is_ck_editor_document === true
      ) {
        this.openCk = true;
        if (this.checkIsUserDeleted?.isUserDeleted === true) {
          this.documentDataLoading = true;
          this.loadingText = "Rendering Editor...";
          this.editorContent = this.checkIsUserDeleted?.ckCont;
          (this.editorFields = this.checkIsUserDeleted?.ckFields),
            await this.reloadEditorContent();
          this.documentDataLoading = false;
        }
        this.documentDataLoading = false;
        this.loading = false;
        this.checkAllUsersAssigned = (this.documentRecipientsList || []).filter((user) => {
        return user && !user.email;
      });
      } else if (!this.fields_found && !this.openCk) {
        await this.saveAddedFields();
        if (!this.isDocumentRendered) {
          this.isDocumentRendered = true;
          this.documentDataLoading = true;
          await this.renderAllDocuments(true);
          this.showAichatMate = false;
        }
      }
      this.submitEvent = false;
      this.showDocumentUserSettings = false;
      this.addDocumentUsers = false;
      if (
        this.documentRecipientsList &&
        this.documentRecipientsList.length > 0 &&
        this.openCk
      ) {
        await this.checkRecipientPositionChange();
      }
    },
    async checkRecipientPositionChange() {
      const currentList = this.documentRecipientsList.map((item) => item.value);
      const previousList = this.previousDocumentRecipientsList.map(
        (item) => item.value
      );
      if (JSON.stringify(currentList) !== JSON.stringify(previousList)) {
        await this.changeRecipientColor();
      }
      this.previousDocumentRecipientsList = [...this.documentRecipientsList];
    },
    async setEntityData() {
      this.allTemplatesData = {};
      let params = {
        entity_data_ids: [],
      };
      this.documentRecipientsList.forEach((e) => {
        if (e.type == "SENDER" || e.constant_user) {
          if (e && e.entity_data_id && e.entity_data_id._id) {
            params.entity_data_ids.push(e.entity_data_id._id);
          } else if (e.entity_data_id) {
            params.entity_data_ids.push(e.entity_data_id);
          }
        }
      });
      // .filter((e) => e.type == "SENDER" && e.entity_data_id)
      // .flatMap((e) => e.entity_data_id),
      await this.$store.commit("templatesData/setTemplatesData", [], {
        root: true,
      });
      if (params.entity_data_ids && params.entity_data_ids.length) {
        await this.$store.dispatch(
          "templatesData/fetchUserTemplateData",
          params
        );
        await this.$store.dispatch("entities/fetchEntityDataByQuery", params);
      }
      this.setUsersEntityData();
    },
    setUsersEntityData() {
      this.documentRecipientsList.forEach((user) => {
        if (this.getTemplatesData && this.getTemplatesData.length) {
          const seletedUserTemplateData = this.getTemplatesData.filter(
            (e) =>
              (user.entity_data_id &&
                user.entity_data_id._id &&
                e.entity_data_id == user.entity_data_id._id) ||
              e.entity_data_id == user.entity_data_id
          );
          seletedUserTemplateData.forEach((e) => {
            this.allTemplatesData[user.value + "-" + e.template_id] =
              e.template_data;
          });
        }
        this.elements.map((el) => {
          if (
            el.template_id &&
            el.type != "ENTITY" &&
            el.field_type != "ENTITY_VARIABLE" &&
            el.selected_user == user.value &&
            this.allTemplatesData
          ) {
            if (el.type == "REPEATABLE_PARENT") {
              let value = this.setRepeatableUserDataToField(
                el.selected_user,
                el.template_id
              );
              if (value) {
                el.repeatable_data = value;
              } else {
                el.repeatable_data = [];
              }
              bus.$emit("repeatable-data-selected", {
                key: el.key,
                repeatableData: value,
              });
            } else {
              let isNonConfigureField =
                el.indexKey && el.indexKey.includes(":") ? true : false;
              if (el && el.template_id && el.indexKey && isNonConfigureField) {
                let tempData =
                  this.allTemplatesData[
                    el.selected_user + "-" + el.template_id
                  ];
                if (
                  tempData &&
                  Object.keys(tempData) &&
                  Object.keys(tempData)[0]
                ) {
                  const repeatable = tempData[Object.keys(tempData)[0]];
                  if (repeatable && repeatable.length) {
                    let i = el.indexKey.split(":")[1]
                      ? el.indexKey.split(":")[1] - 1
                      : 0;
                    el.indexKey =
                      repeatable[i] && repeatable[i].indexKey
                        ? repeatable[i].indexKey
                        : el.indexKey;
                    let flKey = el.key.split("-")[2];
                    el.key =
                      repeatable[i] && repeatable[i].indexKey
                        ? el.selected_user +
                          "-" +
                          repeatable[i].indexKey +
                          "-" +
                          flKey
                        : el.key;
                  }
                }
              }
              let value;
              if (
                el.type == "CHECKBOX" &&
                el.group_key &&
                el.is_checkbox_group
              ) {
                value = this.setCheckboxesDataToEntityField(
                  el.key,
                  el.template_id
                );
              } else {
                value = this.setEntityDataToField(
                  el.key,
                  el.template_id,
                  el.indexKey,
                  el.type,
                  el.allow_multiple
                );
              }
              if (value) {
                if (el.type == "ENTITY") {
                  el.entity_data_id = value
                    ? typeof value == "string" &&
                      value.includes("-") &&
                      value.split("-")[0]
                      ? value.split("-")[0]
                      : value
                    : "";
                }
                el.value = value
                  ? typeof value == "string" &&
                    el.type == "ENTITY" &&
                    value.includes("-") &&
                    value.split("-")[1]
                    ? value.split("-")[1]
                    : value
                  : "";
              } else {
                el.value = "";
              }
            }
          }
        });
        // this.elements = this.elements.map((el) => {
        //   if (
        //     el.template_id &&
        //     el.selected_user == user.value
        //   ) {
        //     el.value = this.setEntityDataToField(
        //       el.key,
        //       el.template_id,
        //       el.indexKey
        //     );
        //     // if (value) {
        //     //   el.value = el.type == "CURRENCY" ? parseInt(value) : value;
        //     // } else {
        //     //   el.value = "";
        //     // }
        //   }
        //   return el
        // });
      });
      this.updateUserEntityFieldsRelations(this.documentRecipientsList);
      this.getRecipientSelection();
    },
    setCheckboxesDataToEntityField(checkKey, tempId) {
      let dataCheck =
        this.allTemplatesData[checkKey.split("-")[0] + "-" + tempId];
      let key = checkKey.split("-")[1];
      if (key.includes("#")) {
        key = key.split("#")[0];
      }
      let checkBoxKey = checkKey.split("/")[1];
      return dataCheck &&
        dataCheck[key] &&
        dataCheck[key].indexOf(checkBoxKey) !== -1
        ? true
        : false;
    },
    setEntityDataToField(
      rawKey,
      template_id,
      indexKey,
      type,
      allow_multiple = false
    ) {
      let dataValues =
        this.allTemplatesData[rawKey.split("-")[0] + "-" + template_id];
      if (dataValues) {
        // if it is a repeatable template it will execute if condition
        if (indexKey && Object.keys(dataValues)[0]) {
          dataValues = this.setRepeatableDataStucture(dataValues, "data");
          let key = rawKey.split("-")[2];
          if (key.includes("#")) {
            key = key.split("#")[0];
          }
          let innerData = dataValues[Object.keys(dataValues)[0]];
          if (innerData && innerData.length) {
            let selectedInnerData = innerData.find(
              (e) => e && e.indexKey == indexKey
            );
            if (selectedInnerData && selectedInnerData[key]) {
              return (
                selectedInnerData[key] +
                "" +
                (selectedInnerData[key + "/name"]
                  ? "-" + selectedInnerData[key + "/name"]
                  : "")
              );
            } else {
              return "";
            }
          }
          return "";
        } else {
          // if it is not repeatable, it will execute this portion

          let key = rawKey.split("-")[1];
          if (key.includes("#")) {
            key = key.split("#")[0];
          }
          if (
            (((allow_multiple && type == "WEEKDAYS") ||
              type === "TIME_RANGE" ||
              type === "DATE_RANGE" ||
              type === "DATE_TIME_RANGE" ||
              type === "MULTI_SELECT" ||
              type === "TIME_RANGE") &&
              dataValues[key] !== undefined) ||
            (dataValues[key] != null && typeof dataValues[key] === "object")
          ) {
            return dataValues[key] ? dataValues[key] : [];
          }

          return dataValues[key]
            ? dataValues[key] +
                (dataValues[key + "/name"]
                  ? "-" + dataValues[key + "/name"]
                  : "")
            : "";
        }
      } else {
        return "";
      }
    },
    async companyEntityDetails() {
      try {
        await this.$store.dispatch("entities/fetchCompanyEntityDetails");
        if (
          this.getCompanyEntityDetails &&
          this.getCompanyEntityDetails.entity_data_details &&
          this.getCompanyEntityDetails.entity_data_details[0] &&
          this.documentRecipientsList.length
        ) {
          this.documentRecipientsList.forEach((e) => {
            if (e.type == "SENDER") {
              this.$set(
                e,
                "entity_data_id",
                this.getCompanyEntityDetails.entity_data_details[0]._id
              );
              this.$set(
                e,
                "contact_type",
                this.getCompanyEntityDetails.entity_data_details[0].entity_id
              );
            }
          });
        }
      } catch (err) {}
    },
    checkFieldsOfUser() {
      let fields =
        this.openCk ||
        this.getConfigurableDocumentDataById?.ckeditor_fields.length
          ? this.editorFields
          : this.elements;
      let userCount = 0;
      let signUsers = [];
      let groupFilledBy = [];
      signUsers = fields.flatMap((element) => element.filled_by);
      groupFilledBy = this.checkBoxGroupArray.flatMap(
        (group) => group.filled_by
      );
      signUsers = [...signUsers, ...groupFilledBy];
      userCount = this.documentRecipientsList
        .filter((user) => {
          if (
            user.user_type == "SIGNER" &&
            user.type != "SENDER" &&
            signUsers.indexOf(user.value) == -1
          ) {
            return user;
          }
        })
        .flatMap((e) => e.name);
      if (userCount.length) {
        userCount.forEach((e) => {
          this.names.push(e);
        });
        this.fieldErrorMessage = `${userCount.join(",")} `;
        this.showSignErrorElementDialog = true;
      }

      return userCount.length ? false : true;
    },
    closeAlertPopup() {
      this.names = [];
      this.showSignErrorElementDialog = false;
      this.showFinishErrorElementDialog = false;
      this.constantUsersPopup = false;
    },
    async fillAndSend() {
      this.loading = true;
      this.loadingText = "Creating Document From Template...";
      if (!this.checkPermissions("userCreateDocment")) {
        this.$notify.error({
          title: "Error",
          message: "Permission denied for create document.Please contact owner",
        });
      } else {
        await this.updateParentDataByEntityVariableInDocuments(this.elements);
        if (this.documentName.length == 0) {
          this.$message.error("Please add document title");
        } else {
          let checkFields = await this.checkFieldsOfUser();
          if (checkFields) {
            this.loading = true;
            this.createDraftDoc = true;
            this.updatingProfileInfo();
            await this.saveTemplate();
            this.loading = false;
          }
        }
      }
      this.loading = false;
    },
    async documentSend() {
      this.loading = true;
      await this.saveTemplate();
      let checkSign = await this.checkSignUser();
      if (checkSign) {
        let query = this.getNavigationQuery(this.$route.query);
        this.$router.push({
          name: "send-template-mail",
          id: this.$route.configurable_document_id,
          query,
        });
      }
      // await this.createSendDoc();
      this.loading = false;
    },
    getTimeZone() {
      let date1 = new Date();
      var sign = date1.getTimezoneOffset() > 0 ? "-" : "+";
      var offset = Math.abs(date1.getTimezoneOffset());
      var hours =
        Math.floor(offset / 60) < 10
          ? "0" + Math.floor(offset / 60)
          : Math.floor(offset / 60);
      var minutes = offset % 60 < 10 ? "0" + (offset % 60) : offset % 60;
      let timezoneoffset = sign + hours + ":" + minutes;
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      return timezone + ", UTC" + timezoneoffset;
    },
    async createSendDoc() {
      try {
        let checkSign = await this.checkSignUser();
        if (checkSign) {
          this.loading = true;
          let documentData = this.prepareDocumentData();

          let companayDocumentData = this.prepareFields();
          let timeZone = this.getTimeZone();
          let params = {
            document_template_id: this.$route.params.document_id,
            document_users: this.documentRecipientsList,
            enforce_signature_order: true,
            sender_time_zone: timeZone,
            document_data: documentData,
            companayDocumentData: companayDocumentData,
          };
          await this.$store.dispatch("documents/createSentDocument", params);

          if (this.getDraftDocumentCreateStatus) {
            let draftId = this.getDraftDocumentCreateData.data._id;

            let query = this.getNavigationQuery(this.$route.query);
            this.$router.push({
              name: "employee-documents-custom-document",
              params: {
                employee_document_id: draftId,
              },
              query,
            });
          }
          this.loading = false;
        }
      } catch (err) {
        this.loading = false;
      }
    },
    async createDraft() {
      try {
        this.loading = true;
        let documentData = this.prepareDocumentData();
        let companayDocumentData = this.prepareFields();
        let params = {
          document_template_id: this.$route.params.document_id,
          document_users: this.documentRecipientsList,
          enforce_signature_order: this.enforceSignatureOrder || false,
          document_data: documentData,
          companayDocumentData: companayDocumentData,
          is_anyone_can_approve: this.isAnyoneCanApprove,
          configurabledocumentid: this.$route.params.configurable_document_id,
          is_document: false,
        };
        if (this.openCk) {
          (params.is_from_ck_editor = this.openCk ? true : false),
            (params.ckeditor_fields = this.openCk ? this.editorFields : []),
            (params.ck_editor_content = this.openCk ? this.editorContent : "");
        }
        await this.$store.dispatch("documents/createDraftDocument", params);

        if (this.getDraftDocumentCreateStatus) {
          let draftId = this.getDraftDocumentCreateData.data._id;

          let query = this.getNavigationQuery(this.$route.query);
          this.$router.push({
            name: "employee-documents-custom-document-edit",
            params: {
              employee_document_id: draftId,
            },
            query,
          });
        }
        // }
        this.loading = false;
      } catch (err) {
        this.loading = false;
      }
    },
    checkSignUser() {
      let userCount = 0;
      let signUsers = [];
      let groupFilledBy = [];
      signUsers = this.elements
        .filter((e) => !(e.type == "CHECKBOX" && e.group_key))
        .flatMap((element) => element.filled_by);
      groupFilledBy = this.checkBoxGroupArray.flatMap(
        (group) => group.filled_by
      );
      signUsers = [...signUsers, ...groupFilledBy];
      //
      userCount = this.documentUsers
        .filter((user) => {
          if (
            user.user_type == "SIGNER" &&
            user.type != "SENDER" &&
            signUsers.indexOf(user.value) == -1
          ) {
            return user;
          }
        })
        .flatMap((e) => e.name);
      if (userCount.length) {
        userCount.forEach((e) => {
          this.names.push(e);
        });
        this.fieldErrorMessage = `${userCount.join(",")} `;
        this.showSignErrorElementDialog = true;
      }
      return userCount.length ? false : true;
    },
    prepareDocumentData() {
      let data = {};
      this.elements.forEach((element) => {
        if (element && element.key) {
          if (element.type == "CHECKBOX" && element.group_key) {
            data[element.key] =
              this.documentData[element.key] == true || element.value == true
                ? true
                : false;
          } else if (element.content) {
            data[element.key] = element.content;
          } else if (element.value) {
            data[element.key] = element.value;
          } else if (element.source) {
            data[element.key] = element.source;
          } else if (element.files && element.files.length) {
            data[element.key] = element.files;
          } else if (element.type != "CHECKBOX" && !element.group_key) {
            data[element.key] = "";
          }
        }
      });
      return data;
    },
    deleteRow(index) {
      this.documentRecipientsList.splice(index, 1);
    },
    checkPermissions(permission) {
      let hasUser =
        this.getConfigurableDocumentDataById &&
        this.getConfigurableDocumentDataById.shared_users
          ? this.getConfigurableDocumentDataById.shared_users.findIndex(
              (cu) => cu == this.getAuthenticatedUser.email
            )
          : -1;
      let isCreator =
        this.getConfigurableDocumentDataById &&
        this.getConfigurableDocumentDataById.created_by
          ? this.getConfigurableDocumentDataById.created_by.toString() ==
            this.getAuthenticatedUser._id.toString()
          : false;
      if (hasUser != -1 || isCreator) {
        return true;
      } else if (this.getUserType && this.getUserType.permissionSet) {
        return this.getUserType.permissionSet.includes(permission)
          ? true
          : false;
      } else {
        return true;
      }
    },
    getRecipientSelection() {
      const selectedUser = this.documentRecipientsList.find(
        (e) => this.recipientSelection == e.value
      );
      this.recipientSelection = selectedUser.value;
      this.selectedUserEntity = {};
      if (selectedUser) {
        if (selectedUser.contact_type) {
          this.selectedUserEntity = this.allUsersEntityData.find(
            (e) => e.id == selectedUser.contact_type
          );
        } else if (this.getCompanyDetails && this.getCompanyDetails._id) {
          this.selectedUserEntity = this.allUsersEntityData.find(
            (e) => e.name == this.getCompanyDetails._id
          );
        } else if (
          selectedUser.entity_data_id &&
          selectedUser.entity_data_id.company
        ) {
          this.selectedUserEntity = this.allUsersEntityData.find(
            (e) => e.name == selectedUser.entity_data_id.company
          );
        }
      }
    },
  },
  watch: {
    addDocumentRecipient(newVal) {
      this.showVar = newVal;
    },
    documentRecipientsList() {
      if (this.documentRecipientsList) {
        if (
          this.documentRecipientsList.length &&
          this.documentRecipientsList.length == 1
        ) {
          this.recipientSelection = this.documentRecipientsList[0].value;
        } else {
          let nonCCUser = this.documentRecipientsList.find((user) => {
            return user.user_type != "CC";
          });
          this.recipientSelection =
            nonCCUser && nonCCUser.value ? nonCCUser.value : null;
        }
        this.getRecipientSelection();
      }
    },
    searchedLabel: function (newVal) {
      console.log(newVal);
      this.filterLabels(newVal);
    },
    handler: function () {
      this.generateTable();
    },
    recipientSelection() {
      this.getRecipientSelection();
    },
    documentData() {},
    getAllDocumentFieldsRemoved() {
      if (this.getAllDocumentFieldsRemoved) {
        this.checkAddFormFields();
      }
    },
    // async "$route.params.configurable_document_id"() {
    //   window.location.reload();
    // },
    // async "$route.name"() {
    //   window.location.reload();
    // },
    elements: {
      handler: function () {
        bus.$emit("formula-updated");
      },
      deep: true,
    },
  },
  beforeDestroy() {
    console.log("Removing event listener for beforeunload");
    for (var instanceName in CKEDITOR.instances) {
      CKEDITOR.remove(CKEDITOR.instances[instanceName]);
    }
    // bus.$off("entityData-updated");
    // this.$store.commit("documents/setDraftDocumentCreateStatus", null, {
    //   root: true,
    // });
    // this.$store.commit("documents/setDraftDocumentCreateData", null, {
    //   root: true,
    // });
    // this.$store.commit("fileUpload/setFontTypes", null, { root: true });
    // this.$store.commit("fileUpload/setUploadFileDataStatus", null, {
    //   root: true,
    // });
    // this.$store.commit("fileUpload/setUploadStatus", null, { root: true });
    // this.$store.commit("fileUpload/setAddPageStatus", null, { root: true });
    // this.$store.commit("settings/setApplicationSettings", null, { root: true });
    // this.$store.commit("entities/setEntityDataByQuery", null, { root: true });
    // this.$store.commit("entities/setEntitiesDataByQuery", null, { root: true });
    // this.$store.commit("entities/setCompanyEntityDetails", null, {
    //   root: true,
    // });
    // this.$store.commit(
    //   "configurableDocuments/setConfigurableDocumentUpdateStatus",
    //   null,
    //   { root: true }
    // );
    // this.$store.commit(
    //   "configurableDocuments/setConfigurableDocumentAddStatus",
    //   null,
    //   { root: true }
    // );
    // this.$store.commit(
    //   "configurableDocuments/setConfigurableDocumentData",
    //   null,
    //   { root: true }
    // );
    // this.$store.commit(
    //   "configurableDocuments/setConfigurableDocumentErrors",
    //   null,
    //   { root: true }
    // );
    // this.$store.commit(
    //   "configurableDocuments/setConfigurableDocumentDataById",
    //   null,
    //   { root: true }
    // );
    // this.$store.commit(
    //   "configurableDocuments/setConfigurableDocumentExpirationSettigns",
    //   null,
    //   { root: true }
    // );
    // this.$store.commit("configurableDocuments/setDocumentUsers", null, {
    //   root: true,
    // });
  },
};
</script>

<style lang="scss" scoped>
.ankaCropper__navigation a:nth-child(4n + 4) {
  display: none !important;
}
.ankaCropper__navigation a:nth-child(5n + 5) {
  display: none !important;
}
.text-added {
  position: absolute !important;
}
.text-highlight {
  border: red solid 1px;
  position: absolute !important;
  // background: transparent !important;
  top: 0;
  input {
    white-space: nowrap !important;
    vertical-align: baseline !important;
    width: 100% !important;
    height: 100% !important;
    border: none !important;
    // background: transparent !important;
    resize: none !important;
    overflow: hidden;
    color: inherit;
  }
  .field-textarea {
    &.el-input-nowrap {
      el-input {
        white-space: nowrap !important;
      }
    }
    height: 100% !important;
    &.el-input {
      vertical-align: baseline !important;
      display: table !important;
    }
  }
  &:hover:before {
    visibility: visible;
    opacity: 1;
  }
  el-input .el-input__inner {
    padding: 0px 5px;
    width: 100% !important;
    height: 100% !important;
    border: none !important;
    background: transparent !important;
    resize: none !important;
    overflow: hidden;
    color: inherit;
  }
  .overlay {
    position: absolute;
    left: 5px !important;
    top: 0px !important;
    width: 100%;
    height: 100%;
    z-index: 5;
    padding: 0px !important;
  }
  .overlay {
    overflow: hidden;
    white-space: nowrap;
    width: 82% !important;
  }
  .edit-icon {
    background: #f754a2;
    position: absolute !important;
    // widows: 5px;
    // height: 5px;
  }
  .save-icon {
    background: #55ff00;
    position: absolute !important;
    left: 20px;
  }
  .text-icon {
    background: #006aff;
    position: absolute !important;
    left: 40px;
  }
}
.tooltip {
  padding: 0 !important;
}
.name-types {
  margin-top: 2%;
  .filename {
    height: 56px;
    margin-top: 1%;
    cursor: pointer;
    background: #ffffff;
    border: 1px solid #dfe3eb;
    box-sizing: border-box;
    border-radius: 2px;
    .el-upload-list__item-name {
      margin-top: 1em;
      .img-active {
        visibility: hidden;
        width: 6px !important;
        margin-right: 10px;
        margin-left: 10px;
        position: relative;
        top: 3px;
      }
      .img-fluid {
        position: relative;
        top: 5px;
        width: 18.43px !important;
      }
    }
    // .delete-doc {
    //   visibility: hidden;
    // }
    .direction-buttons {
      display: flex;
      visibility: hidden;
      position: relative;
      top: -35%;
      left: 97%;
      .directions {
        position: relative;
        right: 10%;
        top: -10px !important;
      }
    }
    &:hover {
      .img-active {
        visibility: visible;
        width: 6px !important;
        margin-right: 10px;
        margin-left: 10px;
        position: relative;
        top: 3px;
      }
      .direction-buttons {
        display: flex;
        visibility: visible;
        position: relative;
        top: -35%;
        left: 97%;
        .directions {
          position: relative;
          right: 10%;
          top: -10px !important;
        }
      }
      // .delete-doc {
      //   visibility: visible;
      //   position: relative;
      //   top: -60%;
      //   left: 97%;
      // }
    }
  }
}
.tooltip {
  padding: 0 !important;
}
.avatar-uploader {
  width: 100% !important;
  height: 70px !important;
  border: 1px dashed #409eff;
  background-color: #f7f9ff;
  text-align: center;
  .upload-attchment-box {
    display: flex;
    // padding: 25px;
    h4 {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #4b545c;
    }
  }
}
.form-builder {
  width: 950px;
  margin: 1.5em auto;
  max-height: 100%;
  border-radius: 4px;
  background-color: #ffffff;
  border: none;
  // -webkit-box-shadow: 0 0px 5px rgba(0, 0, 0, 0.15);
  // box-shadow: 0 0px 5px rgba(0, 0, 0, 0.15);

  .vdr {
    border: 1px solid rgba(#f36371, 0.5);
    padding: 2px 5px;
  }

  .form-image-holder {
    width: 100%;

    // div {
    //   -webkit-box-shadow: 0 0px 5px rgba(0, 0, 0, 0.15);
    //   box-shadow: 0 0px 5px rgba(0, 0, 0, 0.15);
    //   width: 100%;
    //   height: 100%;
    //   background: #eee;
    //   canvas {
    //     width: 100%;
    //     height: 100%;
    //   }
    // }
  }

  .form-fields-holder {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    .each-element img {
      width: 100%;
    }
  }
}

#drag-template-background {
  .img-fluid {
    height: auto !important;
  }

  .inner-navbar {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    height: 60px;
    z-index: 100;
    background-color: #ffffff;
    border: 1px solid #efefef;
    margin-bottom: 0 !important;

    & > .d-flex {
      gap: 10px;

      .back-btn {
        height: inherit;
      }
    }

    .left-block {
      .el-input__inner {
        min-width: 250px;
        max-width: 300px;
        background: #fff;
        height: 47px;
      }
    }
  }

  .form-fields-set {
    z-index: 99;
    height: calc(100vh - 85px);
    overflow-y: scroll;
    background-color: #ffffff;
    padding: 1.25em 1em;
    border-radius: 0px;
  }

  .document-editor-container {
    .form-field-item {
      display: flex;
      margin-bottom: 17px;
    }
    .form-field-item-emtpy {
      display: inline-block;
      height: 40px;
    }
    .fields-navigators {
      // float: right;
      // margin-top: 2%;
      position: absolute;
      top: 0px;
    }
    .text-editot-btn-active {
      border: 1px solid #a5a3a3cc;
      background: #dad9d9;
    }
    .text-editor-color {
      position: relative;
      //top: 15px;
      margin-left: 5px;
    }
    .text-editot-btn {
      margin-top: 2px;
      border: none;
      background: transparent;
      margin-bottom: 5px;
    }
    .text-editot-slt-size {
      width: 65px;
      background-color: transparent;
    }
    .text-editot-slt input {
      border: none;
    }
    td {
      padding-top: 0;
      vertical-align: top;

      &:nth-child(1) {
        width: 20%;
      }

      &:nth-child(2) {
        width: 80%;
      }
    }

    .inputs-list {
      background: #fff;
      padding: 15px;
      box-shadow: 0 1px 5px 0px rgba(0, 0, 0, 0.06),
        0 1px 0px rgba(0, 0, 0, 0.08);
      border-radius: 5px;

      .el-button {
        border: none;
        background: rgba(236, 245, 255, 0.7);

        &:hover {
          background: rgba(236, 245, 255, 1);
        }
      }

      li {
        &:not(:last-child) {
          margin-bottom: 15px;
        }
      }
    }
  }

  .form-image {
    max-width: 770px;
    min-height: 1000px;
    background-color: #e6dcdc;
  }

  .el-input__inner {
    display: table !important;
  }

  .form-holder {
    width: 100%;
    position: relative;

    .el-input__inner,
    .el-textarea__inner {
      height: 100% !important;
      line-height: inherit !important;
    }

    input[type="checkbox"] {
      margin: 0 !important;
    }

    // .draggable.resizable {
    //   position: absolute;
    //   z-index: 100;
    // }
  }

  .sample-form1 {
    position: relative;
    width: 100%;
    max-height: 91vh;
    overflow: scroll;
    border: 1px solid #ccc;
    margin-left: 20px;
    margin-bottom: 45px;

    ///
    .each-page {
      position: relative;
      width: 900px;
      display: block;
      height: 800px;
      overflow-y: auto;

      .form-image {
        height: 800px;
        position: absolute;
        width: 850px;
        top: 0;
        left: 0;
      }

      .form-fields {
        height: 800px;
        position: absolute;
        width: 850px;
        top: 0;
        left: 0;
        z-index: 100;
      }

      .page-image {
        width: 850px;
        margin: 0 auto;
      }
    }

    // .draggable.resizable {
    //   position: absolute;
    //   z-index: 100;
    // }
  }

  .color-btn {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    margin: 10px;

    &.red {
      background-color: red;
    }

    &.black {
      background-color: black;
    }

    &.green {
      background-color: green;
    }
  }

  // Fields
  .scrollable-container {
    .fieldSet__box {
      margin-bottom: 1em;
      // padding-bottom: 1em;
      // border-bottom: 1px solid #efefef;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 13px;

      .field-element {
        position: relative;
        padding: 0.5em 1em;
        // width: 94%;
        display: grid;
        grid-template-columns: 25px 1fr;
        align-items: center;
        font-size: 0.5em;
        white-space: normal;
        line-height: 1;
        background-color: #f9f9f9;
        border: 1px solid #f1f1f1;
        height: 40px;
        color: #606266;
        border-radius: 3px;
        &:focus {
          // color: #f36371;
          border-color: #fdcfd4;
          background-color: #857b7c;
        }

        img {
          max-height: 24px;
          padding-right: 10px;
        }

        .name {
          padding-left: 1px;
          line-height: 1.3;
          font-size: 0.8rem;
          text-align: left;
          font-weight: 500;
          margin-left: 2px;
          margin-right: 10px;

          i {
            width: 100%;
            font-size: 1em;
            padding-bottom: 0.5em;
          }
        }
      }
    }
  }

  // .draggable {
  //   height: auto !important;
  //   background: rgba(236, 245, 255, 0.48);
  // }
}
.inner-navbar-2nd-level-child {
  display: -webkit-box;
  display: -ms-flexbox;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
</style>

<style lang="scss">
.ankaCropper__navigation a:nth-child(4n + 4) {
  display: none !important;
}
.ankaCropper__navigation a:nth-child(5n + 5) {
  display: none !important;
}
.el-dropdown-list {
  @media (max-width: 767.98px) {
    margin: 2px !important;
  }
  margin: 20px !important;
}
.doc-plus {
  @media (max-width: 767.98px) {
    margin-left: 2px !important;
  }
  margin-left: 20px !important;
}
.mobile {
  .right-block {
    .el-link--primary {
      &.type-2 {
        &.settigns-font {
          &.save-link {
            @media (max-width: 767.98px) {
              margin-left: 4px !important;
            }
            margin-left: 14px !important;
          }
        }
      }
    }
  }
}
.el-badge {
  margin-left: 0px !important;
}
.change-document-dlg {
  .el-dialog__body {
    scrollbar-width: none !important;
  }
}
.form-fields-holder {
  .draggable {
    z-index: 100 !important;
  }
  #svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }

  .line {
    stroke-width: 1px;
    stroke: rgb(9, 60, 230);
    z-index: 200 !important;
  }
}

#drag-template-background {
  .document-editor-container {
    .content-fields {
      textarea.el-textarea__inner {
        min-height: 10px !important;
        // line-height: 15px !important;
        display: table !important;
      }
    }
  }
}
textarea {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  font-style: inherit;
}
.link-main {
  .link-head {
    .link-title {
      font-weight: 500;
      font-size: 16px;
      color: #303133;
      .link-sub {
        font-weight: normal;
        font-size: 14px;
        color: #4c4b4e;
      }
    }
  }
  .link-col {
    background: #ffffff;
    border: 1px solid rgba(225, 225, 225, 0.4);
    box-sizing: border-box;
    box-shadow: 0px 11px 30px rgba(234, 236, 241, 0.58);
    border-radius: 2px;
    padding: 10px;
    .link-cap {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #303133;
    }
    .cap-sub {
      font-weight: normal;
      font-size: 12px;
      line-height: 15px;
      color: #4c4b4e;
    }
    .copy-btn {
      background: #f754a2;
      border-radius: 2px;
    }
  }
  .link-alert {
    background: #edf5fa;
    border-left: 0.1px solid #409eff;
    box-sizing: border-box;
    // display: flex;
    padding: 10px;
    margin: auto;
    .alert-text {
      margin-top: 10px;
      color: #4c4b4e;
      font-weight: normal;
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 0.01em;
    }
  }
}
.link-footer {
  .link-publish {
    background: #409eff;
    border-radius: 2px;
  }
  .link-cancel {
    color: #409eff;
    background: transparent;
    border-radius: 2px;
    border: 1px solid #409eff;
  }
}
.content-fields {
  min-width: 15px !important;
  min-height: 15px !important;
  .field-textarea {
    &.currency-input {
      width: 100% !important;
      height: 100% !important;
      border: none !important;
      border-color: transparent !important;
      background: transparent !important;
    }
    &.currency-input:focus {
      border: none !important;
      outline: none !important;
    }
    &.textarea-nowrap {
      textarea {
        white-space: nowrap !important;
      }
    }
    &.textare-background {
      textarea {
        padding: 0 !important;
      }
    }
    height: 100% !important;
    &.el-textarea {
      vertical-align: baseline !important;
    }
  }
  .overlay {
    overflow: hidden;
    white-space: nowrap;
    width: 82% !important;
  }
  // position:relative; // for tooltip
  &[data-text*="DATE "] {
    background: none !important;
  }
  &:before {
    content: attr(data-text);
    position: absolute;
    top: -25px;
    -webkit-transform: translateY(-50%);
    left: -17px;
    margin-left: 15px;
    width: 142px;
    height: 10px;
    padding: 10px;
    border-radius: 9px;
    background: #555;
    color: white;
    text-align: center;
    line-height: 10px;
    visibility: hidden;
    opacity: 0; /* define initial transition property */
    transition: opacity 1s;
  }
  &:after {
    content: "";
    position: absolute;
    top: -10px;
    left: 47px;
    margin-left: -11px;
    border-width: 9px 9px 10px 0px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
    visibility: hidden;
    opacity: 0; /* define initial transition property */
    transition: opacity 1s;
  }
  &:hover:before {
    visibility: visible;
    opacity: 1;
  }
  &:hover:after {
    visibility: visible;
    opacity: 1;
  }
  &:focus-visible {
    outline: none !important;
  }
  &.el-textarea {
    width: 100% !important;
    height: 100% !important;
  }
  padding: 0 !important;
  &.my-active-class {
    border-width: 2px !important;
    background: transparent !important;
    .overlay {
      display: none;
    }
  }
  &[has-value="1"] {
    background: transparent !important;
  }
  &[has-content="1"] {
    background: transparent !important;
  }
  &[has-background="0"] {
    background: transparent !important;
  }
  &[is-border-none="1"] {
    border: none !important;
  }
  & > p {
    &:last-child {
      margin: 12px 7px;
      white-space: nowrap;
      overflow: hidden;
    }
  }
  & > div {
    &:last-child {
      width: 100% !important;
      height: 100% !important;
      background: none;
      textarea.el-textarea__inner {
        padding: 0px 5px;
        width: 100% !important;
        height: 100% !important;
        border: none !important;
        background: transparent !important;
        resize: none !important;
        overflow: hidden;
        color: inherit;
      }
      .overlay {
        position: absolute;
        left: 5px !important;
        top: 0px !important;
        width: 100%;
        height: 100%;
        z-index: 5;
        padding: 0px !important;
      }
      .el-select {
        width: 100% !important;
        height: 100% !important;
        font-family: inherit !important;
        font-size: inherit !important;
        font-weight: inherit !important;
        font-style: inherit !important;
        color: inherit !important;
        padding: 0px !important;
        .el-input {
          width: 100% !important;
          height: 100% !important;
          font: inherit !important;
          font-family: inherit !important;
          font-size: inherit !important;
          font-weight: inherit !important;
          font-style: inherit !important;
          color: inherit !important;
          padding: 0px !important;
          .el-input__inner {
            width: 100% !important;
            height: 100% !important;
            font-family: inherit !important;
            font-size: inherit !important;
            font-weight: inherit !important;
            font-style: inherit !important;
            color: inherit !important;
            padding: 0px !important;
          }
        }
        .el-input__suffix-inner {
          i {
            line-height: 100% !important;
          }
        }
        .el-tag {
          height: 100%;
          font: inherit;
          // .el-select__tags-text {
          //   font: inherit;
          // }
        }
      }
      .el-date-editor {
        width: 100% !important;
        height: 100% !important;
        display: flex;
        flex-direction: row-reverse;
        font: inherit;
        background: none;
        .el-input__inner {
          width: 100%;
          height: 100%;
          font: inherit;
          border: none;
        }
        .el-input__prefix {
          position: relative;
          i {
            line-height: 100%;
            display: inline !important;
          }
        }
      }
      min-width: 17px;
      min-height: 17px;
      input[type="checkbox"] {
        width: 100%;
        height: 100%;
        min-width: 15px;
        min-height: 15px;
      }
      &.yes-or-no {
        // padding-left: 5px;
        .el-radio {
          font: inherit !important;
          .el-radio__label {
            font: inherit !important;
          }
          &:first-child {
            margin-right: 10px !important;
          }
          // &:last-child {
          // }
        }
      }
      .primary-field {
        text-area.el-textarea__inner {
          background: transparent !important;
        }
      }
      &.image-field {
        // padding: 3px 12px;
        color: inherit;
        position: absolute;
        left: 5px !important;
        top: 5px !important;
        padding: 0px !important;
        width: 80% !important;
        white-space: nowrap;
        overflow: hidden;
      }
      &.document-image-field {
        color: inherit;
        position: absolute;
        left: 0px !important;
        top: 0px !important;
        padding: 0px !important;
        width: 80% !important;
        white-space: nowrap;
        overflow: hidden;
        display: flex !important;
      }
    }
  }
  & > img {
    &.my-signature {
      background: transparent !important;
      width: 100%;
      // height: 100%;
      height: auto;
      visibility: visible !important;
    }
    &.my-initial {
      background: transparent !important;
      width: 100%;
      // height: 100%;
      height: auto;
    }
  }
}

.pdf-page-preview {
  width: 100%;
  text-align: center;
  border-right: 1px solid #ccc;

  .add-page-bottom {
    background: transparent;
    border: none;
  }

  .add-page-top {
    border: none;
    position: relative;
    background: transparent;
    top: 30px;
  }

  .rotate-buttons {
    position: relative;
    top: 60px;

    .el-button {
      visibility: hidden;
    }
  }

  .canvas-holder {
    position: relative;
    top: -25px;

    &:hover {
      .rotate-buttons {
        .el-button {
          visibility: visible;
        }
      }
    }
  }
}

.settings-btn {
  cursor: pointer;
  color: #4c4b4e;
  span {
    margin-left: 5px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
  }
}
.settigns-font {
  margin-left: 5px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
}
.document-editor-container {
  position: relative;
  height: calc(100vh - 62px);
  overflow: hidden;
  .el-scrollbar {
    height: calc(100vh - 145px) !important;
  }
}
.configure-document {
  .el-collapse-item__header {
    &.is-active {
      background-color: #ffffff;
    }
  }
  .el-collapse-item__content {
    padding-bottom: 10px;
  }
  padding-top: 0 !important;
  background-color: #f8f9fa;
  .handle {
    width: 8px;
    height: 8px;
    background: inherit;
    border: 1px solid inherit;
  }
  .each-element.my-active-class {
    border: 1px dashed #f36371 !important;
  }
  .form-title {
    .el-input {
      position: relative;
      input {
        padding: 0;
        line-height: 1;
        background-color: rgba(255, 255, 255, 0.35);
        border: none;
        font-size: 1.5em;
        height: 60px !important;
        font-weight: 500;
        letter-spacing: -0.5px;
        margin-bottom: 0;
        color: #222222;
        box-shadow: none;
        &:focus,
        &.field-focused {
          outline: 1px solid yellow;
        }
      }
      &::before {
        position: absolute;
        content: "";
        top: 0;
        height: 20px;
        width: 10px;
        //background-image: url(/img/hover-dots.ecfb2fa2.svg);
        background-size: 10px 20px;
        background-repeat: no-repeat;
        background-position: center;
        left: 0px;
        opacity: 0;
        z-index: 10;
        transition: 0.3s all ease-in-out;
        -webkit-transition: 0.3s all ease-in-out;
      }
    }
  }

  .right-block {
    height: inherit;
    display: flex;
    align-items: center;
  }
  .switch-pdf {
    display: flex;
    align-items: center;
    .el-switch__label--right,
    .el-switch__label--left {
      display: inline-block;
      white-space: nowrap;
    }
  }
}

.fields-dialog {
  .el-select {
    width: 100%;
  }
  .el-dialog__body {
    padding-right: 5px !important;
    scrollbar-width: none;
    .el-scrollbar__bar {
      &.is-horizontal {
        height: 0px !important;
      }
    }
    .el-form {
      padding: 0px 13px 5px 0px !important;
    }
  }
}
.form-fields-holder {
  .date-picker-item {
    &.smaller-than {
      .el-input__prefix {
        display: none;
      }
      .el-input__inner {
        padding: 0 !important;
      }
    }
  }
}
@media screen and (max-width: 1455px) {
  .pdf-side-bar {
    display: block !important;
  }
  .document-container-wrapper {
    .document-container {
      .el-row {
        display: flex;
        justify-content: center;
      }
    }
  }
}
.from-others {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  .image {
    cursor: pointer;
    margin-left: 10px;
    margin-right: 10px;
    .upload-icon {
      width: 25px;
      height: 25px;
    }
    @media (max-width: 991.98px) {
      margin-bottom: 16px;
    }
  }
}
#uploadcontainer {
  margin-top: 10px;
  border-top: 1px dashed #409eff;
}
#parastyle {
  text-align: center;
  margin-top: 8px;
}

// .custom-table {
//   border-collapse: separate;
//   border: 1px solid green;
// }

// .custom-table .el-table__body-wrapper tr {
//   //border: 1px solid #ccc;
//   border: none !important;
//   display: none;
// }

// .custom-table .el-table__body-wrapper td {
//   border-right: 1px solid #cdcdcd;
//   border-bottom: 1px solid #cdcdcd;
// }

// .custom-table .el-table__body-wrapper tr:hover {
//   border-color: #000000; /* Red border color on hover */
// }

// .custom-table .el-table__body-wrapper td:hover {
//   background-color: #000000; /* Red background color on hover */
// }

// .custom-table .el-table__header-wrapper th {
//   border: 1px solid #cdcdcd;
//   border-bottom: 1px solid #cdcdcd;
//   background-color: gainsboro !important;
// }

// .custom-table .el-table__header-wrapper th:hover {
//   border-color: #000000; /* Red border color on hover */
// }

// .custom-table .cell {
//     white-space: nowrap !important;
// }

// .custom-table .el-table__header {
//     width: auto !important;
//     height: -webkit-fill-available;
// }

// .custom-table .el-table__footer-wrapper, .el-table__header-wrapper {
//     overflow: auto !important;
//     scrollbar-width: thin !important;
//     background-color: gainsboro;
//     height: 120px;
// }

// .custom-table {
//   border: 1px solid #e6e6e6;
// }
</style>
<style scoped>
.custom-checkbox {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: var(
    --cross-mark-width,
    25px
  ); /* Apply item.width using CSS variable */
  height: var(
    --cross-mark-height,
    25px
  ); /* Apply item.height using CSS variable */
  border: 1px solid #999;
  position: relative;
  background: none;
}

.custom-checkbox:checked::before {
  content: "\00D7"; /* Cross mark character */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* //font-size: 32px; */
  color: black;
  background: none;
}
.custom-checkbox:not(:checked) {
  background-color: white;
}
</style>
<style scoped>
.input-container {
  display: flex;
}
.sidemenu {
  /* width: 100%; */
  height: 450px;
  overflow-y: auto;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}
.sidemenu::-webkit-scrollbar {
  width: 1em;
  height: 0.5em;
}
.sidemenu::-webkit-scrollbar-horizontal {
  /* Select the down or left scroll button when it's being hovered by the mouse */
  -webkit-border-radius: 10px;
  background: #ffffff;
  -webkit-box-shadow: inset 0 0 6px rgba(172, 169, 169, 0.9);
}
.sidemenu::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  background: #ffffff;
  -webkit-box-shadow: inset 0 0 6px rgba(172, 169, 169, 0.9);
}
.sidemenu::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #f5f5f5;
  background-color: #f5f5f5;
}
.sidemenu::-webkit-scrollbar-button:single-button {
  width: 16px;
  height: 13px;
  background-color: #bbbbbb;
  display: block;
  border-style: solid;
}

.sidemenu::-webkit-scrollbar-button:single-button:horizontal:decrement:hover {
  border-color: transparent transparent #777777 transparent;
}
/* Down */

.sidemenu::-webkit-scrollbar-button:horizontal:single-button:increment:hover {
  border-color: #777777 transparent transparent transparent;
}

.box {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid black;
  margin-right: -1px;
  border-top: 1px solid black;
}

.small-box {
  width: 20px;
  height: 20px;
  border-top: 1px solid black;
}

.box-input {
  width: 100%;
  height: 100%;
  font-size: 16px;
  text-align: center;
  border: none;
  outline: none;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}
.field_number {
  line-height: 8px;
  width: 95%;
  border: none;
  background: none;
}
input:focus {
  outline: none;
}
.headerStyles {
  display: inline-flex;
  padding: 6px 0px 6px 0px;
  justify-content: center;
  align-items: center;
  /* //  background: #e2e2e2; */
  color: #000;
  width: 700px;
  margin-left: 50px;
  border-bottom: 1px solid rgb(193, 193, 193);
}
.centered-button {
  display: block;
  margin: 0 auto;
}
.same-width {
  width: 70%;
}

.field-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.label-column {
  margin-right: 2px;
}
.filledby-column {
  margin-left: 2px;
}
.separator-line {
  width: 100%;
  height: 1px;
  background-color: #dcdcdc;
  margin: 10px 0;
}
.bold-text ::v-deep .el-range-input {
  font-weight: bold !important;
}
.try-using-ai {
  display: flex;
  align-items: center;
  margin-left: 350px;
  padding: 8px 18px;
  margin-bottom: 20px;
  border-radius: 35px;
  transition: all 0.3s ease;
  border-color: var(--primary-color) !important;
}
.try-using-ai-chat-mate {
  display: flex;
  align-items: center;
  padding: 6px 18px;
  margin-bottom: 4px;
  margin-right: 10px;
  border-radius: 35px;
  transition: all 0.3s ease;
  border-color: var(--primary-color) !important;
}
.try-using-ai:hover {
  color: #fff;
}

.try-using-ai img {
  margin-right: 10px;
}
.el-card {
  border: 1px solid #ebeef5;
  background-color: #fff;
  overflow: hidden;
  color: #303133;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  box-shadow: 0 0 10px 2px rgba(27, 72, 126, 0.1294117647) !important;
  border-radius: 14px;
  overflow-y: auto;
  max-height: 90vh;
  scrollbar-width: thin;
}
.fixed-dialog {
  margin-top: 30px;
  width: 100%;
}
.input-error::after {
  content: "";
  position: absolute;
  transform: translateY(-50%);
  width: 30px;
  top: -12px;
  right: 25px;
  height: 30px;
  background-image: url("../../../assets/img/icons/Hand.svg");
  background-size: contain;
  background-repeat: no-repeat;
  animation: finger-blink 1s infinite;
}
.custom-dialog {
  height: 99%;
  width: 100%;
}
.right-align {
  display: flex;
  align-items: center;
}
</style>
